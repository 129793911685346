import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MatOptionSelectionChange, MAT_DIALOG_DATA } from '@angular/material';
import { UserPasswordResetConfirmationModal } from 'app/features/main/users/users-view/users-view.component';
import { FuseSidebarService } from 'app/shared/components/sidebar/sidebar.service';
import { SnackbarCongiration } from 'app/shared/components/snack-bar/SnackbarConfiguration';
import { MxGraphService } from 'app/support/services/mx-graph.service';
import { ReportsService } from 'app/support/services/reports.service';
import { SmartTestService } from 'app/support/services/smart-test.service';

@Component({
  selector: 'app-assign-smart-test-modal',
  templateUrl: './assign-smart-test-modal.component.html',
  styleUrls: ['./assign-smart-test-modal.component.scss']
})
export class AssignSmartTestModalComponent implements OnInit {

  clinicsList: Array<Object> = [];
  assignClinicForm: FormGroup;
  @ViewChild('clinicSearch', { static: false, }) clinicSearch: ElementRef<HTMLInputElement>;
  clinicFilterChange: Array<any> = [];
  defaultList: Array<any> = [];
  assignedClinics: Array<any> = []; 
  tempassignedClinics: Array<any> = []; 
  remove_workflow: any;
  clinic_names: Array<any> = [];;
  unassigned_clinics: Array<any> = [];;
  ;
  selectedClinics: any[];
  workFlowData: any;
  clinicControl: FormControl = new FormControl('');
  dataLoad: boolean = false;
  assignClinicLoading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private mx_graphService: MxGraphService,
    private smartTestService: SmartTestService,
    private snackBar: SnackbarCongiration,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AssignSmartTestModalComponent>,
    private dialog: MatDialog,
  ) {
    this.assignClinicForm = this.fb.group({
      clinic: [[]],
      all_clinic: []
    })
    this.smartTestService.getClinic(data).subscribe(res => {
      this.workFlowData = res;
      this.assignedClinics = res['assigned_clinic_list'];
      this.defaultList = res['assigned_clinic_list'];
      this.tempassignedClinics = res['assign_clinic'];
      this.clinicsList = res['assign_clinic'];
      if (this.assignedClinics.length == this.clinicsList.length) {
        this.assignClinicForm.controls['clinic'].setValue(this.clinicsList);
        this.assignClinicForm.get('all_clinic').setValue(true);
      } else {
        this.setAssignedClinic();
      }
    })
  }
  ngOnInit() {
    this.setAssignedClinic()
  }

  setAssignedClinic() {
    this.selectedClinics = [];
    this.clinicsList.filter((element: any) => {
      if (this.assignedClinics.includes(element['clinic_name'])) {
        this.selectedClinics.push(element);
      }
    });
    this.assignClinicForm.controls['clinic'].setValue(this.selectedClinics);
    this.handleSelectAll()
  }

  clinicChange(event) {
    if (event.source.selected) {
      event.source.value['checked'] = true;
      this.selectedClinics.push(event.source.value);
    } else {
      event.source.value['checked'] = false;
      this.selectedClinics.splice(this.selectedClinics.findIndex(el => el['id'] == event.source.value['id']), 1);
    }
    let newList = [];
    this.selectedClinics.forEach(el => {
        if (newList.findIndex(nEl => nEl['id'] == el['id']) == -1) newList.push(el);
    });
    this.selectedClinics = newList.concat([]);
  }

  filterClinic(event: any) {
    const filterValue = event.target.value.toLowerCase();
    this.tempassignedClinics = this.clinicsList.filter(
      option => option['clinic_name'].toLowerCase().indexOf(filterValue) > -1
    ).concat([]);
  }

  clinicSelectReset(isOpened: boolean){
    this.clinicControl.reset();
    this.tempassignedClinics = this.clinicsList.concat([]);
    if (isOpened) this.clinicSearch.nativeElement.focus();
    else {
      let selChange = [];
      this.clinicsList.filter((element: any) => {
          for (let selected of this.selectedClinics)
              if (element['id'] == selected['id']) selChange.push(element);
      });
      this.assignClinicForm.get('clinic').setValue(selChange);
    }
    this.handleSelectAll()
  }

  handleSelectAll() {
    if (this.tempassignedClinics.length === this.selectedClinics.length) {
      this.assignClinicForm.get('all_clinic').setValue(true);
    } else {
      this.assignClinicForm.get('all_clinic').setValue(false);
    }
  }


  selectAllClinics(event) {
    if (event.checked) {
      this.assignClinicForm.get('all_clinic').setValue(true);
      this.assignClinicForm.get('clinic').setValue(this.tempassignedClinics.concat([]));
    } else {
      this.assignClinicForm.get('all_clinic').setValue(false);
      this.assignClinicForm.get('clinic').setValue([]);
    }
    this.handleSelectAll()
  }

  closePanel() {
    this.dialogRef.close()
  }

  checkClinic(){
    let count = this.assignClinicForm.controls['clinic'].value;
    if (count.length == this.clinicsList.length) {
      this.assignClinicForm.get('all_clinic').setValue(true);
    } else {
      this.assignClinicForm.get('all_clinic').setValue(false);
    }
  }

  assignWorkflowToClinics() {
    this.assignClinicLoading = true
    this.dataLoad = true;
    let clincIds = [];
    let new_clinic = [];
    this.assignClinicForm.value['clinic'].forEach(el => {
      clincIds.push(el['id']);
      if(!this.defaultList.includes(el['clinic_name'])){
        new_clinic.push(el['id']);
      }
    })
    let _data = {
      "dynamic_smart_test_id": this.workFlowData['id'],
      "assign_clinic": clincIds,
      "confirm_delete": this.unassigned_clinics.length>0 ? true:false
    }
    if(this.data['is_active'] == false){
      if(this.defaultList.length == 0 || new_clinic.length >= 1){
        this.snackBar.triggerSnackBar({
          message: 'Clinics cannot be assigned until the workflow is activate',
          type: 'failure'
        })
        this.dataLoad = false;
      }else{
        this.smartTestService.assignClinic(_data, this.workFlowData['id']).subscribe(
          (response: any) => {
            this.clinic_names = response['clinic_names'];
            this.unassigned_clinics = response['unassigned_clinic']
            this.remove_workflow = this.clinic_names.length > 0 ? true : false
            if (this.remove_workflow) {
              this.removeClinicWorkflow(this.clinic_names)
              // this.remove_workflow = false;
            }
            else{
              // this.assignWorkflowToClinics();
              if(response['data']['assigned_clinic_list'].length == 0){
                this.dialogRef.close("empty")
              }else{
                this.dialogRef.close("success")
              }
              this.dataLoad = false;
              this.reloadCurrentPage()
            }
          }
        )
      }
    }else{
      this.smartTestService.assignClinic(_data, this.workFlowData['id']).subscribe(
        (response: any) => {
          this.clinic_names = response['clinic_names'];
          this.unassigned_clinics = response['unassigned_clinic']
          this.remove_workflow = this.clinic_names.length > 0 ? true : false
          if (this.remove_workflow) {
            this.removeClinicWorkflow(this.clinic_names)
            // this.remove_workflow = false;
          }
          else{
            // this.assignWorkflowToClinics();
            if(response['data']['assigned_clinic_list'].length == 0){
              this.dialogRef.close("empty")
            }else{
              this.dialogRef.close("success")
            }
            this.dataLoad = false;
            this.reloadCurrentPage()
          }
        }
      )
    }
    
    this.assignClinicLoading = false
  }

  removeClinicWorkflow(clinic_names) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40%';
    dialogConfig.data = { 'yes': null, 'no': null };
    dialogConfig.position = { top: '70px' };
    dialogConfig.disableClose = true,
      dialogConfig.data = {
        title: 'Remove Workflow in assigned clinics?',
        sub_title: 'Workflow will be removed from assigned clinics?',
        no_button: 'No, Wait',
        yes_button: 'Yes, Remove',
        clinicnames: clinic_names
      }
    let dialogRef = this.dialog.open(DeleteAssignClinicWorkflowComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(value => {
      if (value == "yes") {
        this.remove_workflow = false;
        this.dataLoad = true;
        let clincIds = [];
        this.assignClinicForm.value['clinic'].forEach(el => {
          clincIds.push(el['id']);
        })
        let data = {
          "dynamic_smart_test_id": this.workFlowData['id'],
          "assign_clinic": clincIds,
          "confirm_delete": true

        }
        this.smartTestService.assignClinic(data, this.workFlowData['id']).subscribe(
          (response: any) => {
            this.dialogRef.close("success")
            this.dataLoad = false;
            this.reloadCurrentPage()
          })
      }
      else{
        this.dataLoad = false;
      }
    })
  }

  reloadCurrentPage() {
    this.smartTestService.refreshRowData('refresh');
  }
}

@Component({
  selector: 'app-assign-smart-test-modal',
  templateUrl: './remove-assign-clinic-workflow-dialog.component.html',
})

export class DeleteAssignClinicWorkflowComponent implements OnInit {
  yes: any;
  no: any;
  /**
     * Constructor
     *
     * @param {MatDialogRef<DialogComponent>} dialogRef
     */
  constructor(
    public dialogRef: MatDialogRef<DeleteAssignClinicWorkflowComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
  }

  closeModal(param): void {
    this.dialogRef.close(param);
  }
}