import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InterfaceService } from './auth/interface.service';
import { map, catchError, tap , shareReplay} from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { GetApiurl, getToken } from '../app-config';
import { FindValueSubscriber } from 'rxjs/internal/operators/find';

@Injectable({
    providedIn: 'root'
})
export class UsersService extends InterfaceService {

    constructor(
        private http: HttpClient,
        private router: Router,
        private authService: AuthService
    ) {
        super();
    }

    public checkEmail(email, role_type?: Array<any>) {
        let data = { "email": email };
        data['role_type'] = role_type ? role_type : []
        return this.http.post(this.getApiUrl(`users/check-email/`), data, this.getHttpOptions('json', true)).pipe(
            map(Response => Response),
            catchError(this.handleError)
        );
    }

    public checkAlternateId(alternate_id){
        let data = {'alternate_id': alternate_id}
        return this.http.post(this.getApiUrl('users/check-alternate-id/'),data,this.getHttpOptions('json',true)).pipe(
            map(Response => Response),
            catchError(this.handleError)
        )
    }
    public checkFccAlternateId(alternate_id,email){
        let data = {
            'alternate_id': alternate_id,
            'email':email
        }
        return this.http.post(this.getApiUrl('users/check-fcc-alternate-id/'),data,this.getHttpOptions('json',true)).pipe(
            map(Response => Response),
            catchError(this.handleError)
        )
    }

    public sendHelpMail(data) {
        return this.http.post(this.getApiUrl(`users/send-help-mail/`), data, this.getHttpOptions('json', true)).pipe(
            map(Response => Response),
            catchError(this.handleError)
        );
    }

    public createUser(data) {
        data['base_path'] = window.location.origin + this.router.createUrlTree([`/set-password`]).toString();
        data['view_link'] = window.location.origin + this.router.createUrlTree([`users/view/`], { queryParams: { view_link: "user" } }).toString();
        return this.http.post(this.getApiUrl(`users/`), data, this.getHttpOptions('json', true)).pipe(
            map(Response => Response),
            catchError(this.handleError)
        );
    }

    changePassword(data) {
        return this.http.post(this.getApiUrl(`users/change-password/`), data, this.getHttpOptions('json', true)).pipe(
            map(Response => Response),
            catchError(this.handleError)
        );
    }

    migrationData(data) {
        return this.http.post(this.getApiUrl(`migration/migration-data/`), data, this.getHttpOptions('json', false)).pipe(
            map(Response => Response),
            catchError(this.handleError)
        );
    }

    downloadPatientCSV(data) {
        // return this.http.get(this.getApiUrl(`migration/download-patient-csv/`), this.getHttpOptions('json', false)).pipe(
        //     map(Response => Response),
        //     catchError(this.handleError)
        // ); 
        const url = this.getApiUrl(`migration/download-patient-csv/`);
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);
            // xhr.setRequestHeader('Authorization', `JWT ${getToken()}`);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xhr.send(JSON.stringify(data));
            xhr.onreadystatechange = function () {
                if (xhr.readyState == XMLHttpRequest.DONE && xhr.status == 200) {
                    resolve(xhr);
                }
                else if(xhr.readyState == 4) {
                    // console.log(xhr);
                    return reject(xhr.responseText);
                }
            };
        })
    }

    checkMigrationKey(data) {
        return this.http.post(this.getApiUrl(`migration/check-key/`), data, this.getHttpOptions('json', false)).pipe(
            map(Response => Response),
            catchError(this.handleError)
        );
    }

    public getRoleNames() {
        return this.http.get(this.getApiUrl(`users/get-role-names`), this.getHttpOptions('json', true)).pipe(
            map(Response => Response),
            catchError(this.handleError)
        );
    }

    public getUserList() {
        return this.http.get(this.getApiUrl(`users/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getUser(userId: string) {
        return this.http.get(this.getApiUrl(`users/${userId}/get-user/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getViewUser(userId: string) {
        return this.http.get(this.getApiUrl(`users/${userId}/get-view-user/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getAgencies(agency_id: string) {
        return this.http.get(this.getApiUrl(`users/${agency_id}/get-agency`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        )
    }

    getUserData(userId: string) {
        return this.http.get(this.getApiUrl(`users/${userId}/get-user-data/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getUsertypeRoles(clinic_id) {
        return this.http.get(this.getApiUrl(`users/get-create-data/${clinic_id}`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    checkActivationLink(value) {
        return this.http.post(this.getApiUrl(`users/check-activation-link/`), value, this.getHttpOptions('json', true)).pipe(
            map(Response => Response),
            catchError(this.handleError)
        );

    }

    deactivateUser(userId: string) {
        return this.http.get(this.getApiUrl(`users/${userId}/deactivate-user/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    activateUser(token: string) {
        return this.http.get(this.getApiUrl(`users/activate-email-user/${token}`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    updateUser(userId: string, data: any) {
        return this.http.put(this.getApiUrl(`users/${userId}/`), data, this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    updateProfile(data) {
        return this.http.post(this.getApiUrl(`users/update-profile/`), data, this.getHttpOptions('json', true)).pipe(
            map(Response => Response),
            catchError(this.handleError)
        );
    }

    verifySetPasswordTokenValidity(resetToken) {
        var data = {
            token: resetToken
        }
        return this.http.post(this.getApiUrl('api-set-password-verify/'), data, this.getHttpOptions('json', false))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            )
    }

    setPassword(data) {
        return this.http.post(this.getApiUrl('api-set-password/'), data, this.getHttpOptions('json', false))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            )
    }

    sendForgotPasswordRequest(data) {
        if (data.hasOwnProperty('reset')) {
            data['base_path'] = window.location.origin + this.router.createUrlTree([`/reset-password`]).toString();
        } else {
            data['base_path'] = window.location.origin + this.router.createUrlTree([`/forgot-password`]).toString();
        }
        return this.http.post(this.getApiUrl(`users/forgot-password-request/`), data, this.getHttpOptions('json', false)).pipe(
            map(Response => Response),
            catchError(this.handleError)
        );
    }

    // sendForgotPasswordLink(data)
    // {
    //     return this.http.post(this.getApiUrl(`users/forgot-password/`), data, this.getHttpOptions('json', true)).pipe(
    //         map(Response => Response),
    //         catchError(this.handleError)
    //     );
    // }

    verifyForgotPasswordTokenValidity(resetToken) {
        var data = {
            token: resetToken
        }
        return this.http.post(this.getApiUrl(`api-forgot-password-verify/`), data, this.getHttpOptions('json', false)).pipe(
            map(Response => Response),
            catchError(this.handleError)
        );
    }

    forgotPasswordConfirm(data) {
        return this.http.post(this.getApiUrl('api-forgot-password-confirm/'), data, this.getHttpOptions('json', false))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            )
    }

    updateContact(userId: string, data: any) {
        return this.http.put(this.getApiUrl(`users/${userId}/update-contact/`), { update_fields: data }, this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getZipCode(value: any) {
        return this.http.get(this.getApiUrl(`users/${value}/get-zip-code/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    checkValidMobile(userId: any, value: any) {
        return this.http.get(this.getApiUrl(`users/${userId}/check-valid-mobile`, value), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getUserNotificationPreferences() {
        return this.http.get(this.getApiUrl(`users/get-user-preference-notifications/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    updateUserNotificationPreferences(data: any) {
        return this.http.post(this.getApiUrl(`users/update-user-preference-notifications/`), data, this.getHttpOptions('json', true))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            )
    }

    getSystemSettings() {

        return this.http.get(this.getApiUrl(`users/get-system-settings/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    updateSystemSettings(data: any) {
        // console.log(data);
        return this.http.post(this.getApiUrl(`users/update-system-settings/`), data, this.getHttpOptions('json', true)).pipe(
            tap(Response => Response),
            catchError(this.handleError)
        );
    }

    profilePicUpdate(data) {
        return this.http.post(this.getApiUrl('users/profile-image/'), data, this.getHttpOptions('json', true))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            )
    }

    getMHTTwofactor() {
        return this.http.get(this.getApiUrl(`users/get-mht-two-factor`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    setMHTTwofactor(data) {
        return this.http.post(this.getApiUrl('users/set-mht-two-factor/'), data, this.getHttpOptions('json', true)).pipe(
            map(Response => Response),
            catchError(this.handleError)
        );
    }

    mhtMobileUsers() {
        return this.http.get(this.getApiUrl(`users/mht-mobile-users/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    deviceLogin(data: any) {
        return this.http.post(GetApiurl('device/login/'), data, this.getHttpOptions('json', false)).pipe(
            tap(response => this.authService.handleAuthResponse(response)),
            catchError(this.handleError)
        );
    }

    deviceResendOtp(data: any) {
        return this.http.post(GetApiurl('device/resend-otp/'), data, this.getHttpOptions('json', false)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    resetUserTracking(userId: any) {
        return this.http.get(this.getApiUrl(`users/reset-user-tracking/`, { user_id: userId }), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    registerClinicDevice(){
        return this.http.get(GetApiurl('users/registerdevice/'), this.getHttpOptions('json')).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getClinicDevice(params?: object){
        return this.http.get(this.getApiUrl(`clinicdevice/get-registered-device-list`, params), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    deleteRegisteredDevice(deviceId){
        return this.http.get(this.getApiUrl(`clinicdevice/${deviceId}/delete-device/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
          );
    }

    public searchRegisteredDevice(url, params?: any) {
        return this.http.post(this.getApiUrl(`${url}/`, params), this.getHttpOptions('json', true)).pipe(
            shareReplay(1),
            catchError(this.handleError)
        );
    }
}
