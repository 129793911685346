import { Component, OnInit, ViewChild } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { PatientGroupDeleteComponent } from "app/features/main/patients/patient-group-delete/patient-group-delete.component";
import {
    MatDialogConfig,
    MatDialog,
    MatMenuTrigger,
} from "@angular/material";
import { SnackbarCongiration } from "app/shared/components/snack-bar/SnackbarConfiguration";
import { PatientService } from "app/support/services/patient.service";
import { GlobalService } from "app/support/services/auth/global.service";
import { PatientGroupComponent } from "app/features/main/patients/patient-group/patient-group.component";
import { Subscription } from "rxjs/internal/Subscription";
import { MxGraphService } from "app/support/services/mx-graph.service";

@Component({
    selector: "app-cell-renderer",
    templateUrl: "./cell-renderer.component.html",
    styleUrls: ["./cell-renderer.component.scss"],
})
export class CellRendererComponent implements OnInit, ICellRendererAngularComp {
    @ViewChild(MatMenuTrigger, { static: false }) trigger: MatMenuTrigger;
    dialogbox;
    public params: any;
    header: any;
    templateRef: Array<string>;
    table: string;
    multiSelect: boolean = false;
    group: any;
    grouplistnumber: Array<any> = [];
    rowlist;
    roles: any;
    useridemail: number;
    createby: any;
    groupsdetails: any;
    grouplength: any;
    accessrights: boolean;
    unCheckedList: Array<any> = [];
    isSelectAll: boolean;
    groupsList: any;
    respondenderList: any;
    user: any;
    checked: boolean = false;

    constructor(
        private dialog: MatDialog,
        private patientservice: PatientService,
        private snackbar: SnackbarCongiration,
        private mx_graphService: MxGraphService,
        private globalService: GlobalService
    ) {}

    selectedRowsSubscription: Subscription;

    ngOnInit() {
        this.globalService.user$.subscribe((data) => {
            this.roles = data.roles;
            this.useridemail = data.id;
        });

        this.selectedRowsSubscription = this.patientservice.selectAll$.subscribe(
            (data) => {
                if (data === "select") {
                    console.log('hi');
                    this.params.api.forEachNode((node) => {
                        if (this.unCheckedList.includes(node.id)) {
                            node.setSelected(false);
                        } else {
                            node.setSelected(true);
                        }
                    });
                } else {
                    this.params.api.forEachNode((node) =>
                        node.setSelected(false)
                    );
                }
            }
        );
    }

    public rowEvent(action: string) {
        this.params.context.gridRenderer.emitRowEvent({
            action: action,
            data: this.params.data,
        });
    }

    public changeStatus(event, action: string) {
        this.params.context.gridRenderer.emitChangeStatus({
            action: action,
            data: this.params.data,
            isActivate: event.checked,
        });
    }

    public activeStatus(event, action: string) {
        this.params.context.gridRenderer.emitActiveStatus({
            action: action,
            data: this.params.data,
            isActivate: event.checked,
        });
    }

    public assignClinic(action: string) {
        this.params.context.gridRenderer.emitAssignClinic({
            action: action,
            data: this.params.data,
        });
        this.mx_graphService.getTempData(this.params.data);
    }

    agInit(params: any): void {
        this.globalService.user$.subscribe((user) => (this.user = user));
        this.params = params;
        if (params.node.data) {
            let dataType = typeof params.node.data.groups;
            if (dataType === "string") {
                let tempArray = new Array();
                if (params.node.data.groups) {
                    tempArray = params.node.data.groups.split(",");
                }
                let tempArrayResponder = new Array();
                if (params.node.data.responders) {
                    tempArrayResponder = params.node.data.responders.split(",");
                }
                this.groupsList = tempArray;
                this.respondenderList = tempArrayResponder;
            } else {
                this.groupsList = params.node.data.groups;
                this.respondenderList = params.node.data.responders;
            }
        } else {
            this.groupsList = [];
            this.respondenderList = [];
        }
        this.header = params.colDef.headerName;
        params.context.cellRenderer.forEach((element) => {
            if (element.column == this.header) {
                this.templateRef = element.renderer;
            }
        });
        this.table = params.context.table;
    }

    PatientGroupViewEdit() {
        this.createby = this.params["data"].create_by;
        if (this.roles.includes("Admin") || this.roles.includes("Director")) {
            this.accessrights = true;
        } else if (
            this.roles.includes("Clinician") &&
            this.useridemail == this.createby
        ) {
            this.accessrights = true;
        } else {
            this.accessrights = false;
        }

        if (this.accessrights) {
            this.patientservice.viewEditDataFunction(this.params["data"]);
            let dialogConfig = new MatDialogConfig();
            dialogConfig.width = "800px";
            dialogConfig.disableClose = true;
            dialogConfig.restoreFocus = false;
            this.dialogbox = this.dialog.open(
                PatientGroupComponent,
                dialogConfig
            );
        } else {
            this.snackbar.triggerSnackBar({
                message: "You are not authroized to access this",
                type: "error",
            });
        }
    }

    DeleteGroup() {
        this.createby = this.params["data"].create_by;
        if (this.roles.includes("Admin") || this.roles.includes("Director")) {
            this.accessrights = true;
        } else if (
            this.roles.includes("Clinician") &&
            this.useridemail == this.createby
        ) {
            this.accessrights = true;
        } else {
            this.accessrights = false;
        }

        if (this.accessrights) {
            this.patientservice.DeletePatientorGroup("GroupDelete");
            this.patientservice.viewEditDataFunction(this.params["data"]);
            let dialogConfig = new MatDialogConfig();
            dialogConfig.width = "600px";
            dialogConfig.scrollStrategy;
            dialogConfig.disableClose = true;
            dialogConfig.restoreFocus = false;
            this.dialogbox = this.dialog.open(
                PatientGroupDeleteComponent,
                dialogConfig
            );
        } else {
            this.snackbar.triggerSnackBar({
                message: "You are not authroized to access this",
                type: "error",
            });
        }
    }

    changeview(value: string, action: string) {
        this.createby = this.params["data"].create_by;
        if (this.roles.includes("Admin") || this.roles.includes("Director")) {
            this.accessrights = true;
        } else if (
            this.roles.includes("Clinician") &&
            this.useridemail == this.createby
        ) {
            this.accessrights = true;
        } else {
            this.accessrights = false;
        }
        if (this.accessrights) {
            this.patientservice.assignPatientsDataFunction(this.params["data"]);
            let stateMaintain = {
                screenName: value,
                disableGroup: true,
                action: action,
            };
            this.patientservice.PatientViewChange(stateMaintain);
        } else {
            this.snackbar.triggerSnackBar({
                message: "You are not authroized to access this",
                type: "error",
            });
        }
    }

    refresh(): boolean {
        return false;
    }
}
