import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-no-navigation',
  templateUrl: './no-navigation.component.html',
  styleUrls: ['./no-navigation.component.scss']
})
export class NoNavigationComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<NoNavigationComponent>,
  ) { }

  ngOnInit() {
  }

  closeModel(): void {
    this.dialogRef.close()
  }

}
