import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from "@angular/core"
import { Observable, of, throwError, EMPTY } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ConfimerComponent } from 'app/shared/components/dialog-box/confimer/confimer.component';
import { SyncDataService } from 'app/support/services/sync-data.service';
import { timeout } from 'rxjs/operators';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(private router: Router, public dialog: MatDialog,
        private logoutService: SyncDataService) { }
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        
        return next.handle(req).pipe(
            tap(evt => {
                if (evt instanceof HttpResponse) {
                    if (evt.body && evt.body.hasOwnProperty('admin_logout') && evt.body['admin_logout']) {
                        // redirect to login page
                        if (!this.logoutService.getNewValue) {
                            localStorage.removeItem("token");
                            localStorage.removeItem("firebase:host:phoenix-d7e6a.firebaseio.com");
                            this.router.navigate(['/login']);
                            this.openConfirmer();
                            this.logoutService.setValue = true;
                            return throwError('You have been logged out');
                        }
                    }
                    if (evt.body && evt.body.hasOwnProperty('session_logout') && evt.body['session_logout']) {
                        // redirect to login page
                        if (!this.logoutService.getNewValue) {
                            localStorage.removeItem("token");
                            localStorage.removeItem("firebase:host:phoenix-d7e6a.firebaseio.com");
                            this.router.navigate(['/login']);
                            this.logoutService.setValue = true;
                            window.location.reload();
                            return throwError('You have been logged out');
                        }
                    }

                }
            }), 
        );
    }

    openConfirmer() {
        let dialogConfig = new MatDialogConfig();
        // dialogConfig.position = { top: '70px' };
        dialogConfig.width = '600px';
        dialogConfig.disableClose = true;
        dialogConfig.restoreFocus = false;
        dialogConfig.data = {
            message: 'You have been logged out by the administrator, please login again to continue',
            type: 'admin_logout'
        }
        this.dialog.open(ConfimerComponent, dialogConfig);
    }

}
