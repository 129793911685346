import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InterfaceService } from './auth/interface.service';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LiabilityService extends InterfaceService {

  constructor(private http: HttpClient) {
    super();
  }

  public createLiability(data) {
    return this.http.post(this.getApiUrl(`liability/`), data, this.getHttpOptions('json', true)).pipe(
      map(Response => Response),
      catchError(this.handleError)
    );
  }

  public getLiabilityList(params?: object) {
    return this.http.get(this.getApiUrl(`liability/`, params), this.getHttpOptions('json', true)).pipe(
      map(response => response),
      catchError(this.handleError)
    );
  }

  public updateLiability(id, data) {
    return this.http.put(this.getApiUrl(`liability/${id}/`), { update_fields: data }, this.getHttpOptions('json', true)).pipe(
      map(response => response),
      catchError(this.handleError)
    );

  }

  public getLiabilityTypes()
  {
    return this.http.get(this.getApiUrl(`liability/get-liability-types/`), this.getHttpOptions('json', true)).pipe(
      map(response => response),
      catchError(this.handleError)
    );
  }

  public deleteLiability(liabilityId) {
    return this.http.get(this.getApiUrl(`liability/${liabilityId}/delete-liability/`), this.getHttpOptions('json', true)).pipe(
      map(response => response),
      catchError(this.handleError)
    );
  }

  public getLiabilityData() {
    return this.http.get(this.getApiUrl(`liability/get-liability-data/`), this.getHttpOptions('json', true)).pipe(
      map(response => response),
      catchError(this.handleError)
    );
  }

  acceptLiabities(data){
    return this.http.post(this.getApiUrl(`liability/accept-liability/`), data, this.getHttpOptions('json', true)).pipe(
      map(Response => Response),
      catchError(this.handleError)
    );
  }

}
