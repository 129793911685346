
import { ENTER } from '@angular/cdk/keycodes';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger, MatSelectChange } from '@angular/material';
import { InterfaceService } from 'app/support/services/auth/interface.service';
import { Observable } from 'rxjs';
import { map, startWith, last } from 'rxjs/operators';
import { SearchList, SearchUrl } from '../slim-search/slim-search';
import * as _ from 'lodash';
import { NgSelectComponent } from '@ng-select/ng-select';
import { PatientService } from 'app/support/services/patient.service';
import { ReportsService } from 'app/support/services/reports.service';
import { SnackbarCongiration } from 'app/shared/components/snack-bar/SnackbarConfiguration';
import { CommonService } from 'app/support/services/common.service';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'apply-filter',
  templateUrl: './applyfilter.component.html',
  styleUrls: ['./applyfilter.component.scss']
})
export class ApplyfilterComponent extends InterfaceService implements OnInit, OnDestroy {

  removable: boolean = true;
  loading: boolean = false;
  separatorKeyCodes: number[] = [ENTER];
  searchControl: FormControl = new FormControl();
  subscriptions: Object = {};

  filterResults: Array<Object> = []
  table_filter_columns: any = {}
  selectedName: any = null;
  fields: any[] = [];
  masterFields: any = {};
  search_by_save_report: boolean = false;

  clinicList: Array<Object> = [];
  shareClinic: Object;
  noneClinics: Object = { clinic_id: null };
  allClinics: Object = { clinic_id: 'all' };
  clinic_id
  filterResult: any;
  public defaultOperatorMap: { [key: string]: Object } = {
    text: {
      "Equals": 'equals', "Not Equals": 'not_equals', "Starts With": 'startswith',
      "Ends With": 'endswith', "Contains": 'contains', "Doesn't Contains": "doesnot_contains",
      "Empty": 'empty', "Not Empty": 'not_empty'
    },
    number: { "=": 'equals', "!=": 'not_equals', ">": 'gt', ">=": 'gte', "<": 'lt', "<=": 'lte' },

    date: {
      "Date Range": 'date_range', 'Yesterday': "yesterday", "Today": 'today', "Tomorrow": 'tomorrow',
      "Last Week": 'previous_week', 'This Week': 'this_week', "Next Week": 'next_week',
      "Last Month": 'last_month', "This Month": 'this_month', "Next Month": 'next_month',
      "Past 30 Days": 'last_30_days', "Next 30 Days": 'next_30_days',
      "Past 90 Days": 'last_90_days', "Next 90 Days": 'next_90_days',
    },
    boolean: { 'Yes': 'true', 'No': 'false' },
    dropdown: { "Equals": 'equals', "Not Equals": 'not_equals' },
    dictionary: { "Equals": 'equals', "Not Equals": 'not_equals' },
    single_qualifier: { "Equals": 'equals' },
    phone_number: { "Equals": 'equals', "Not Equals": 'not_equals' }

  };

  filter_fields: Object = {};


  // filteredOptions: Observable<string[]>;
  filteredOptions: Array<string> = [];
  selectedOptions: string[] = [];
  currentOption: Object = {};
  isKey = true
  allOptions: Array<any> = [];
  tempOptions: Array<any> = [];
  searchClicked: boolean = false;

  // filterQuery:Array<any> = [];
  groupId: Number;
  Model: any;
  query: any;
  subscription1: Subscription;
  subscription2: Subscription;
  subscription3: Subscription;
  subscription4: Subscription;
  patientGroupFilter: boolean;
  // dynamicGroupType:string;

  @ViewChild(NgSelectComponent, { static: false }) ngSelectComponent: any;




  constructor(private http: HttpClient,
    private patientService: PatientService,
    private reportService: ReportsService,
    private snackBar: SnackbarCongiration,
    private cs: CommonService) {
    super();
    this.loading = false;
    // this.setFilter();
  }
  @Input('ModelType')
  set ModelType(ModelType: String) {
    this.Model = ModelType;
  }
  @Input('GroupId')
  set GroupId(GroupId: Number) {
    this.groupId = GroupId;

  }


  ngOnInit() {

    this.shareClinic = this.allClinics;
    this.subscription1 = this.patientService.clinicData$.subscribe(data => {
      this.subscription4 = this.patientService.patientGroupButton$.subscribe(res => {
        this.patientGroupFilter = res;
        this.patientService.newPatientsGetColumns(this.Model).subscribe(data => {
          if (!this.patientGroupFilter) {
            delete data['data']['fields']['Patient Group']
          }
          this.fields = Object.values(data['data']['fields']);
          this.masterFields = data['data']['fields'];
          this.filter_fields = JSON.parse(JSON.stringify(data['data']['filter_fields']));
        })
        if(this.subscription4) {
          this.subscription4.unsubscribe();
        }
        
      })
      if (data['value'] == '') {
        this.shareClinic = this.allClinics;
      }
      else {
        this.shareClinic['clinic_id'] = data['value'];
      }
      this.selectedOptions = []
    })

    this.reportService.newReportsgetQuery().then(
      (response: any) => {
        this.clinicList = response['clinic'].concat([]);
      }
    );

    this.patientService.filterQueryDataFunction().subscribe(data => {

      if (data['query']) {
        for (let report_val of data['query']) {
          for (let val of this.fields) {
            if (val.name === report_val.name && report_val.type in { 'dropdown': 'dropdown', 'dictionary': 'dictionary', 'single_qualifier': 'single_qualifier' }) {
              Object.assign(report_val, { 'dropdown_value': val.dropdown_value })
            }
          }
          this.selectedOptions.push(JSON.parse(JSON.stringify(report_val)));
        }

        if (data['query'].length != 0) {
          this.patientService.newPatientsGetColumns(this.Model).subscribe(data => {
            this.fields = Object.values(data['data']['fields']);
            this.masterFields = data['data']['fields'];
            this.filter_fields = JSON.parse(JSON.stringify(data['data']['filter_fields']));
            this.search();
          })
        }

      }
    })




    // this.patientService.newPatientsGetColumns(this.Model).subscribe(data =>{
    //   // console.log(data)
    //  // delete data['data']['fields']['Patient Group']
    //   this.fields = Object.values(data['data']['fields']);
    //   console.log(this.fields)
    //   this.masterFields = data['data']['fields'];
    //   console.log(this.masterFields)
    //   this.filter_fields = JSON.parse(JSON.stringify( data['data']['filter_fields']));
    //    console.log(this.filter_fields)

    // })

    this.patientService.emptyFilterQueryDataFunction();
    // this.patientService.dynamicFilter(false);
    // this.patientService.setApplydynamicFilter(false);
  }

  // setFilter(initialValue: any = null) {
  // if (!initialValue) {
  //     this.filteredOptions = [...this.allOptions];
  // }
  // this.filteredOptions = this.searchControl.valueChanges.pipe(
  //     startWith(initialValue),
  //     map((options: any) => options ? this._filter(options) : this.allOptions)
  // );
  // }

  ngOnDestroy() {
    // this.patientService.destoryQueryDataFunction()
    this.subscription1.unsubscribe();
    this.subscription4.unsubscribe();
    this.patientService.emptyFilterQueryDataFunction();
    // this.patientService.setApplydynamicFilter(false);
  }

  onDropdownClosed() {
    this.patientService.clearSearchClicked()
    if (!this.selectedName) {
      return;
    }
    let name = this.selectedName;
    let ruleField = Object.assign({}, this.masterFields[name])
    ruleField['value'] = ruleField["type"] === 'dropdown' || ruleField["type"] === 'dictionary' || ruleField["type"] === 'single_qualifier' ? ruleField['dropdown_value'] : '';
    let initial_qualifier = this.defaultOperatorMap[ruleField['type']]
    ruleField['qualifier'] = Object.values(initial_qualifier)[0];
    if (ruleField.filter_fields) {
      Object.assign(this.filter_fields, ruleField.filter_fields)
    }
    this.selectedOptions.push(ruleField);

    setTimeout(() => {
      this.ngSelectComponent.handleClearClick();
    }, 300);
    this.search_by_save_report = false;
  }
  changeColumnOrder(bool_value: boolean) {
    this.search_by_save_report = false;
    this.patientService.clearSearchClicked()
  }

  removeRuleItem(ruleIndex: number) {
    let remove_value = this.selectedOptions.splice(ruleIndex, 1)
    let same_model_count = 0;
    this.selectedOptions.forEach(el => {
      if (el["model"] == remove_value[0]["model"]) {
        same_model_count += 1;
      }
    });
    if (same_model_count == 0) {
      if (remove_value[0]["filter_fields"]) {
        Object.keys(remove_value[0]["filter_fields"]).forEach(el => {
          delete this.filter_fields[el];
        })
      }
    }

    let data = { 
      "queryGiven":this.selectedOptions
    }
    this.patientService.checkFilterQueryLength(data);
    this.patientService.resetSelectCheckbox(true);
  }

  clearAppFiltQuery() {
    this.selectedOptions = [];
    if (this.Model == 'Patients') {
      this.patientService.clearOrFilterPatients(false);
    }
    this.patientService.emptyFilterQueryDataFunction();
    if (this.Model == 'PatientGroup') {
      this.patientService.clearOrFilterPatientGroup(false);
    }
    // this.patientService.setApplydynamicFilter(false);
  }
  search() {
    this.patientService.setSearchClicked(true)
    this.patientService.resetSelectCheckbox(true);
    if (!this.selectedOptions.length)
      this.snackBar.triggerSnackBar({
        message: 'Enter query',
        type: 'warning'
      });

    if (this.Model == 'Patients') {
      let filter_details: Object = {
        patient_filter: true,
        model: this.Model,
        query: this.selectedOptions,
        filter_fields: this.filter_fields,
        clinics: this.shareClinic["clinic_id"] === "all" ? this.clinicList : [this.shareClinic]
      }
      this.cs.setPatientFilterResullts(filter_details);
      this.patientService.assignPatientsQuery(filter_details);
      // this.patientService.clearOrFilterPatients(true)
    }
    if (this.Model == 'PatientGroup') {
      let filter_details: Object = {
        model: this.Model,
        query: this.selectedOptions,
        filter_fields: this.filter_fields,
        clinics: this.shareClinic["clinic_id"] === "all" ? this.clinicList : [this.shareClinic],
        group_id: this.groupId
      }
      this.cs.setPatientFilterResullts(filter_details);
      this.patientService.clearOrFilterPatientGroup(true);
    }




    this.patientService.clearOrFilterPatients(true);
    // this.patientService.PatientDynamicFilterEventPatient().subscribe(data=>{

    //   if(data){
    //     this.patientService.dynamicFilter(true);
    //   }

    //   if(this.selectedOptions[0]['value']){
    //     this.patientService.setApplydynamicFilter(true);
    //   }

    // })

  }





}
