import { Injectable } from "@angular/core";
import { getToken } from '../app-config';
import { InterfaceService } from "./auth/interface.service";
import { HttpClient } from "@angular/common/http";
import { map, catchError, shareReplay } from "rxjs/operators";
import { Router } from "@angular/router";
import * as moment from "moment";

@Injectable({
    providedIn: "root",
})
export class AssessmentsService extends InterfaceService {
    constructor(private http: HttpClient, private router: Router) {
        super();
    }

    public createAssessment(data: any) {
        return this.http
            .post(
                this.getApiUrl(`assessments/`),
                data,
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((Response) => Response),
                catchError((err) => this.handleError(err))
            );
    }
    public getAlertNavigation(data: any) {
        return this.http
            .get(
                this.getApiUrl(`alert-navigation/${data}/`),
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((Response) => Response),
                catchError((err) => this.handleError(err))
            );
    }

    public DeleteAlertUrlObject(data: any) {
        return this.http
            .post(
                this.getApiUrl(`alert-navigation/delete_alert_navigation/`),
                data,
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((Response) => Response),
                catchError((err) => this.handleError(err))
            );
    }
    public getAlertAssessment(data: any) {
        return this.http
            .post(
                this.getApiUrl(`alert-navigation/get-active-assessment/`),
                data,
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((Response) => Response),
                catchError((err) => this.handleError(err))
            );
    }
    public setAlertPriority(data: any) {
        return this.http
            .post(
                this.getApiUrl(`alert-navigation/alert-priority/`),
                data,
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((Response) => Response),
                catchError((err) => this.handleError(err))
            );
    }
    public createAlertNavigation(data: any) {
        return this.http
            .post(
                this.getApiUrl("alert-navigation/"),
                data,
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((Response) => Response),
                catchError((err) => this.handleError(err))
            );
    }
    public saveAlertNavigation(data: any, id) {
        return this.http
            .put(
                this.getApiUrl(`alert-navigation/${id}/`),
                data,
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((Response) => Response),
                catchError((err) => this.handleError(err))
            );
    }

    public createAlertSettings(data: any, clinic_assess) {
        return this.http
            .post(
                this.getApiUrl(
                    `alerts/${clinic_assess}/create-alert-settings/`
                ),
                data,
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((Response) => Response),
                catchError((err) => this.handleError(err))
            );
    }

    public getAssessmentCreateData() {
        return this.http
            .get(
                this.getApiUrl(`assessments/get-assessment-create-data/`),
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((Response) => Response),
                catchError(this.handleError)
            );
    }

    public getAssessmentUpdateData(assessment_id) {
        return this.http
            .get(
                this.getApiUrl(
                    `assessments/${assessment_id}/get-assessment-update-data/`
                ),
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    public updateAssessment(assessment_id, data) {
        return this.http
            .put(
                this.getApiUrl(`assessments/${assessment_id}/`),
                data,
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    public getAssessmentList() {
        return this.http
            .get(
                this.getApiUrl(`assessments/get-assessment-list/`),
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    public updateAssessmentClinics(data: any) {
        return this.http
            .post(
                this.getApiUrl(`assessments/update-assessment-clinics/`),
                data,
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    public getClinicAssessments() {
        return this.http
            .get(
                this.getApiUrl(`assessments/get-clinic-assessments/`),
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    public updateClinicAssessments(data: any) {
        return this.http
            .put(
                this.getApiUrl(`assessments/update-clinic-assessments/`),
                data,
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }
    public checkAssessmentExists(data) {
        let url = this.getApiUrl("assessments/check-assessment-exists/");
        return this.http
            .post(url, data, this.getHttpOptions("json", true))
            .pipe(
                map((response) => response),
                catchError((err) => this.handleError(err))
            );
    }
    public updateAssessmentQueueViewStatus(run_id: any) {
        return this.http
            .get(
                this.getApiUrl(
                    `assessments/${run_id}/update-assessment-queue-status/`
                ),
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    public ScheduleAssessmentQueue(data: any) {
        data["base_path"] =
            window.location.origin +
            this.router.createUrlTree([`/take-assessment`]).toString() +
            "/";
        return this.http
            .post(
                this.getApiUrl(`assessments/schedule-assessment/`),
                data,
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    public DeleteRunAssessment(data) {
        return this.http
            .post(
                this.getApiUrl(`assessments/delete-run-assessment/`),
                data,
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    public getReAssignableClinicians(queueId) {
        return this.http
            .get(
                this.getApiUrl(
                    `assessments/${queueId}/get-clinicians-under-clinic/`
                ),
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((Response) => Response),
                catchError(this.handleError)
            );
    }

    public ReAssignClinicianForAssessment(data) {
        return this.http
            .post(
                this.getApiUrl(`assessments/reassign-clinician/`),
                data,
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((Response) => Response),
                catchError(this.handleError)
            );
    }

    public getAssessmentQueue(params?: any) {
        return this.http
            .get(
                this.getApiUrl(`assessments/get-assessment-queue/`, params),
                this.getHttpOptions("json", true)
            )
            .pipe(
                shareReplay(1),
                catchError((err) => this.handleError(err))
            );
    }

    public getDetailedAssessmentQueue(data) {
        return this.http
            .post(
                this.getApiUrl(`assessments/get-detailed-queue/`),
                data,
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((response) => response),
                catchError((err) => this.handleError(err))
            );
    }

    public getAssessmentAlert(clinic_assess, assessment_id) {
        return this.http
            .get(
                this.getApiUrl(
                    `alerts/${clinic_assess}/clinic-assessment-alert/${assessment_id}`
                ),
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((response) => response),
                catchError((err) => this.handleError(err))
            );
    }

    public getPrioritySettings(data) {
        return this.http
            .post(
                this.getApiUrl(`alert-navigation/priority-settings/`),
                data,
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((response) => response),
                catchError((err) => this.handleError(err))
            );
    }

    public getClinicUsers(pk) {
        return this.http
            .get(
                this.getApiUrl(`alerts/${pk}/get-clinic-users/`),
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((response) => response),
                catchError((err) => this.handleError(err))
            );
    }

    public sortAssessmentQueue(data) {
        return this.http
            .post(
                this.getApiUrl(`assessments/sort-assessment-queue/`),
                data,
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((response) => response),
                catchError((err) => this.handleError(err))
            );
    }

    public searchAssessmentQueue(url, searchQuery, params?: any) {
        return this.http
            .post(
                this.getApiUrl(`${url}/`, params),
                JSON.stringify(searchQuery),
                this.getHttpOptions("json", true)
            )
            .pipe(
                shareReplay(1),
                catchError((err) => this.handleError(err))
            );
    }

    public addClinicgeneralsetting(data: any) {
        return this.http
            .post(
                this.getApiUrl(`clinic-general-setting/`),
                data,
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((Response) => Response),
                catchError((err) => this.handleError(err))
            );
    }

    public importAssessment(jsonData: Object) {
        let options = this.getHttpOptions("json", true);
        return this.http.post(this.getApiUrl(`assessments/import/`), jsonData, options)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            )
        }

    public exportAssessment(id: number) {
        let options = this.getHttpOptions("json", true);
        options["responseType"] = "text";
        return this.http
            .get(this.getApiUrl(`assessments/${id}/export`), options)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    convertDateTime(assessmentQueueData) {
        assessmentQueueData.forEach((element) => {
            element["completed_date"] = element["completed_date"]
                ? this.getRunDate(element["completed_date"])
                : null;
            element["pending_date"] = element["pending_date"]
                ? this.getRunDate(element["pending_date"])
                : null;
            element["completed_viewed"].forEach((completedElem) => {
                completedElem["run_date"] = completedElem["run_date"]
                    ? this.getRunDate(completedElem["run_date"])
                    : null;
                completedElem["end_time"] = completedElem["end_time"]
                    ? this.getRunDate(completedElem["end_time"])
                    : null;
            });
            element["not_started_inprogress"].forEach((notStartedElem) => {
                notStartedElem["run_date"] = notStartedElem["run_date"]
                    ? this.getRunDate(notStartedElem["run_date"])
                    : null;
                notStartedElem["end_time"] = notStartedElem["end_time"]
                    ? this.getRunDate(notStartedElem["end_time"])
                    : null;
            });
        });
        return assessmentQueueData;
    }

    getRunDate(dateString) {
        return moment(dateString).format("lll");
    }
}
