import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';

// -- Services --
import { AuthService } from './auth.service';
import { GlobalService } from './global.service';
import { Permissions } from 'app/support/permissions/permissions';
import { User } from 'app/support/classes';

@Injectable()
export class AuthGuardService implements CanActivate {
    user: any
    constructor(private router: Router, private authService: AuthService,
        private route: ActivatedRoute,
        private globalService: GlobalService,
        private permissions: Permissions,
    ) {
        this.globalService.user$.subscribe(user => { this.user = user });
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
       
        // Validating url params
        if (Object.keys(route.data).indexOf('regex') >= 0) {
            const params = route.params;
            for (const param of Object.keys(params)) {
                if (Object.keys(route.data['regex']).indexOf(param) >= 0) {
                    const regex_str = `^${route.data['regex'][param]}$`;
                    const exp = new RegExp(regex_str, 'g');

                    if (!exp.test(params[param])) {
                        this.router.navigate(['/not-found']);
                        return false;
                    }
                }
            }
        }

        // Checks is user authenticated
        if (!this.authService.authenticated()) {
            if((location.href.includes('clinics/view') || location.href.includes('users/view') || location.href.includes('detail-view')) && location.href.includes('view_link')){
                let path = window.location.pathname;
                localStorage.setItem('notification_view', path);
            }
            if(location.href.includes('assessments/detail-view')){
                let path = window.location.pathname + window.location.search;
                localStorage.setItem('assessment_detail_view', path);
            }
            this.router.navigate(['/login']);
            return false;
        }

        // Checks is user device accepted or not

        // TODO - if localstorage is cleared and navigating should show hipaa ??
        if(this.user) {
            if (this.user['is_auth_logged_in'] == false) {
                this.router.navigate(['/device-accept']);
                return false;
            }
        }


        // Checks permission if roles provided
        if (Object.keys(route.data).indexOf('roles') === -1) {
            return true;
        } else {
            let userTypes: Array<string> = null;

            if (Object.keys(route.data).indexOf('userTypes') >= 0) {
                userTypes = route.data['userTypes'];
            }

            if (this.authService.permitted(route.data['roles'], userTypes) && !('super_user' in route.data)) {
                return true;
            }
            else if ('super_user' in route.data && this.user['is_superuser']) {
                return true;
            }
            else if ('system_admin' in route.data && this.user['system_admin']) {
                return true;
            }
            else {
                this.router.navigate(['/permission-denied']);
                return false;
            }
        }

        return true;

    }
}

// Authenticated redirecting services, it helps to redirect directly to homepage if the user is authenticated already
@Injectable()
export class AuthRedirectorService implements CanActivate {
    user: User
    constructor(private router: Router, private authService: AuthService,
    ) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        // if(state.url.includes('/set-password?token=') || state.url.includes('/forgot-password?token=')
        // || state.url.includes('/reset-password?token=') || state.url.includes('/new-password?token=')) {
        //     this.authService.logout(false);
        //     return true
        // }

        // Redirecting Authenticated user   

        if (this.authService.authenticated()) {
            this.authService.redirectToHome();
            return false;

        } else {
            return true;
        }
    }

}

@Injectable()
export class PermissionService implements CanActivate {
    accesses: any
    constructor(private globalService: GlobalService,
        private permissions: Permissions,
        private router: Router,
        private authService: AuthService) {
        this.globalService.userPermissions$.subscribe(data => {
            this.accesses = data
        })
    }

    canActivate(route: ActivatedRouteSnapshot) {
        // this.router['routerState'].snapshot.url

        if (!this.authService.authenticated()) {
            this.router.navigate(['/login']);
            return false;
        }

        var url = route['_routerState'].url
        var nav = [
            {
                "url": url
            }
        ]
        // console.log(nav)
        var access = this.permissions.setNavigationMenusAccesses(nav, this.accesses)
        if (access[0].hidden) {
            this.router.navigate(['/permission-denied'])
            return false;
        }
        return true;
    }
}

@Injectable()
export class DeviceAcceptanceGuard implements CanActivate {
    user: User
    constructor(private globalService: GlobalService,
        private router: Router,
        private authService: AuthService,
        private permission: Permissions) {
        this.globalService.user$.subscribe(data => {
            this.user = data
        })
    }

    canActivate(route: ActivatedRouteSnapshot) {
        // if(this.user) {
        //     if (this.user['is_auth_logged_in'] == false) {
        //         this.router.navigate(['/device-accept']);
        //         return false;
        //     }
        // }

        // Todo: update if the user enters to device accept navigate him to device-accept/view 
        // based on hippaa accpt

        if (!this.authService.authenticated()) {
            this.router.navigate(['/login']);
            return false;
        }
        return true;
    }
}

@Injectable()
export class LiabilityAcceptanceGuard implements CanActivate {
    user: User
    constructor(private globalService: GlobalService,
        private router: Router,
        private authService: AuthService,
        private permission: Permissions) {
        this.globalService.user$.subscribe(data => {
            this.user = data
        })
    }

    canActivate(route: ActivatedRouteSnapshot) {

        // Todo: update if the user enters to device accept navigate him to device-accept/view 
        // based on hippaa accpt

        if (!this.authService.authenticated()) {
            this.router.navigate(['/login']);
            return false;
        }
        return true;
    }
}

