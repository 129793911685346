import { Component, OnInit } from '@angular/core';
import { FuseConfigService } from 'app/shared/services/config.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'app/support/services/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalService } from 'app/support/services/auth/global.service';
import { User } from 'app/support/classes';
import { Permissions } from 'app/support/permissions/permissions';
import { SnackbarCongiration } from 'app/shared/components/snack-bar/SnackbarConfiguration';
import * as moment from 'moment';
import { LiabilityService } from 'app/support/services/liability.service';
import { PhoenixWebsocketService } from 'app/support/services/phoenix-websocket.service';

@Component({
  selector: 'app-device-acceptance',
  templateUrl: './device-acceptance.component.html',
  styleUrls: ['./device-acceptance.component.scss']
})
export class DeviceAcceptanceComponent implements OnInit {

  inProgress: any = false;
  screenFlag: any = 'accept';
  checkFlag: any = "N";
  hideProceedButton: boolean = false;
  isHipaaAccepted: boolean;
  isAuthLoggedIn: boolean;
  // isFromQuickLink: boolean = false;

  hipaaGroup: FormGroup
  last_login: any;
  user: User
  constructor(private _fuseConfigService: FuseConfigService,
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private globalService: GlobalService,
    private snackBar: SnackbarCongiration,
    private permission: Permissions,
    private liabilityservice: LiabilityService,
    private route: ActivatedRoute,
    private phoenixwsService: PhoenixWebsocketService) {

    var snapshot = route.snapshot;
    if (snapshot['_routerState'].url.includes('view') || snapshot['_routerState'].url == '/device-accept/view') {
      this.screenFlag = 'view';
    }

    this.getLastLogin()


    this.globalService.user$.subscribe(data => {
      this.user = data
      this.isAuthLoggedIn = this.user['is_auth_logged_in']
      if (this.isAuthLoggedIn && this.screenFlag != 'view') {
        if(localStorage.getItem("notification_view")){
          this.router.navigate([localStorage.getItem("notification_view")]);
          localStorage.removeItem('notification_view')
        }
        else if(localStorage.getItem("assessment_detail_view")){
          // Navigate to assessment details view screen
          let path = localStorage.getItem("assessment_detail_view").split('?');
          let params = path[1].replace(/runIds=/gi, "");
          params = params.replace(/\&/gi, ",");
          let run_ids = params.split(',')
          console.log(run_ids);
          this.router.navigate([path[0]], { queryParams: { runIds: run_ids } });
          localStorage.removeItem('assessment_detail_view');
      }
      else{
            this.permission.navigateToFirstPermissionList();
        }
      }
      else {
        this.setConfig()
      }

    })


    this.hipaaGroup = this.fb.group({
      "hipaa1": [{ value: true, disabled: true }, Validators.required],
      "hipaa2": [{ value: true, disabled: true }, Validators.required]
    })
  }


  ngOnInit() {



  }



  submitHipaa(event, value) {
    this.inProgress = true;
    this.globalService.geolocation$.subscribe(geolocation => {
      var nonce = this.globalService.generateRandomNonce(10)
      if (Object.keys(geolocation).length > 0) {
        value['geolocation'] = geolocation;
      }
      value['nonce'] = nonce
      this.authService.acceptNewDevice(value).subscribe(data => {
        if (data['status'] && data.hasOwnProperty('nonce')) {
          this.inProgress = false;
          this.user['is_auth_logged_in'] = true;
          this.globalService.addUserNonce(this.user.id, data['nonce'])
          this.snackBar.triggerSnackBar({
            message: 'Hipaa accepted successfully',
            type: 'success'
          })
          let role: Array<any> = this.user['roles'];
          if ((this.user['user_type']['name'] == "Clinic") && (role.includes('Director'))) {
            this.liabilityservice.getLiabilityData()
              .subscribe(
                (response) => {
                  if (response['all_accepted']) {
                    if(localStorage.getItem("notification_view")){
                      this.router.navigate([localStorage.getItem("notification_view")]);
                      localStorage.removeItem('notification_view')
                    }
                    else if(localStorage.getItem("assessment_detail_view")){
                      // Navigate to assessment details view screen
                      let path = localStorage.getItem("assessment_detail_view").split('?');
                      let params = path[1].replace(/runIds=/gi, "");
                      params = params.replace(/\&/gi, ",");
                      let run_ids = params.split(',')
                      console.log(run_ids);
                      this.router.navigate([path[0]], { queryParams: { runIds: run_ids } });
                      localStorage.removeItem('assessment_detail_view');
                  }
                  else{
                        this.permission.navigateToFirstPermissionList();
                    }
                  } else {
                    this.router.navigate(['/liability-accept']);
                  }
                },
                (error) => {
                  this.router.navigate(['/liability-accept']);
                });

          } else {
            if(localStorage.getItem("notification_view")){
              this.router.navigate([localStorage.getItem("notification_view")]);
              localStorage.removeItem('notification_view')
            }
            else if(localStorage.getItem("assessment_detail_view")){
              // Navigate to assessment details view screen
              let path = localStorage.getItem("assessment_detail_view").split('?');
              let params = path[1].replace(/runIds=/gi, "");
              params = params.replace(/\&/gi, ",");
              let run_ids = params.split(',')
              console.log(run_ids);
              this.router.navigate([path[0]], { queryParams: { runIds: run_ids } });
              localStorage.removeItem('assessment_detail_view');
          }
          else{
                this.permission.navigateToFirstPermissionList();
            } 
          }
        }
      },
        error => {
          this.snackBar.triggerSnackBar({
            message: 'There is issue in accepting hipaa',
            type: 'error'
          })

        })
    })
  }

  toggleHipaa() {
    if (this.screenFlag == 'view') {
      if (this.checkFlag == "FF") {
        this.checkFlag = "N";
        this.hipaaGroup.controls.hipaa1.setValue(true);
        this.hipaaGroup.controls.hipaa2.setValue(true);
        this.hideProceedButton = false;
        this.setConfig();
      }
      else {
        this.checkFlag = "FF";
        this.hipaaGroup.controls.hipaa1.setValue(false);
        this.hipaaGroup.controls.hipaa2.setValue(false);
        this.hideProceedButton = true;
        this.removeNonce()
        this.setConfig()
      }
    }
  }

  setConfig() {
    let nonce = this.globalService.getUserNonce(this.user.id)
    if ((nonce != '' && !this.isAuthLoggedIn) || nonce == '') {
      this._fuseConfigService.config = {
        layout: {
          navbar: {
            hidden: true
          },
          toolbar: {
            hidden: true
          },
          footer: {
            hidden: true
          },
          sidepanel: {
            hidden: true
          }
        }
      }
      this.isHipaaAccepted = false;

    }
    else {
      this._fuseConfigService.config = {
        layout: {
          navbar: {
            hidden: false
          },
          toolbar: {
            hidden: false
          },
          footer: {
            hidden: true
          },
          sidepanel: {
            hidden: true
          }
        }
      }

      this.isHipaaAccepted = true;
    }
  }

  removeNonce() {
    var removedNonce = this.globalService.removeUserNonce(this.user.id);
    if (Object.keys(removedNonce).length > 0) {
      this.user['is_auth_logged_in'] = false;
      this.authService.deleteExistingDeviceNonce(removedNonce).subscribe(data => {

      },
        error => {

        })
    }
  }

  logout(): void {
    this.phoenixwsService.closeConnection();
  }

  getLastLogin() {
    this.authService.getLastLoginDateTime().subscribe(data => {
      if (data['status']) {
        let date = moment(data['last_login'])
        var last_login = date.format('DD-MMM-YYYY') + ', ' + date.format('hh:mm A');
        this.last_login = last_login;
      }
      else {
        this.last_login = null;
      }

    },
      error => {

      })


  }

}
