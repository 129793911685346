import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

export interface CanComponentDeactivate {
  confirm(): any;
}

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuard implements  CanDeactivate<CanComponentDeactivate>{
  canDeactivate(
    component: CanComponentDeactivate,
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot){
      if(localStorage.getItem('token')){
        return component.confirm();
      } 
      else{
        return true;
      }
    }
}
