import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlimSearchComponent } from './slim-search/slim-search.component';
import { MatChipsModule, MatIconModule, MatButtonModule, MatAutocompleteModule, MatInputModule, MatCheckboxModule, MatProgressSpinnerModule, MatSelectModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FusePipesModule } from '../pipes/pipes.module';
import { DropSearchComponent } from './drop-search/drop-search.component';
import { ApplyfilterComponent } from './applyfilter/applyfilter.component';
import { FieldinputComponent } from './fieldinput/fieldinput.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
    declarations: [
        SlimSearchComponent,
        DropSearchComponent,
        ApplyfilterComponent,
        FieldinputComponent
    ],
    imports: [
        CommonModule,
        MatChipsModule,
        MatIconModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatInputModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        FusePipesModule,
        NgSelectModule,
        FontAwesomeModule
    ],
    exports: [
        SlimSearchComponent,
        DropSearchComponent,
        ApplyfilterComponent,
        FieldinputComponent
    ]
})
export class ExtendedSearchModule { }
