// angular imports
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

// third party imports
import { ImageCropperModule } from 'ngx-image-cropper';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import * as NoDataToDisplay from 'highcharts/modules/no-data-to-display';

// theme imports
import { FuseDirectivesModule } from 'app/shared/directives/directives';
import { FusePipesModule } from 'app/shared/pipes/pipes.module';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

// angular material imports
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import {
    MatTableModule, MatPaginatorModule, MatChipsModule, MatAutocompleteModule,
    MatRadioModule, MatTabsModule, MatListModule, MatTooltipModule, MatButtonToggleModule, MatCardModule,
    MatDatepickerModule, MatGridListModule, MatSortModule, MatToolbarModule, MatBadgeModule, MatProgressSpinnerModule, MatSnackBarModule, MatExpansionModule, MatSlideToggleModule, MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter,
    MatSelectModule
} from '@angular/material';
import { MatDialogModule } from '@angular/material/dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';


// project imports
// modules
import { DialogModule } from './components/dialog-box/dialog.module';

// import { ColorSliderModule } from 'ngx-color/slider';
import { ColorSketchModule } from 'ngx-color/sketch';
import { ColorSwatchesModule } from 'ngx-color/swatches';

// components
import { ExtendedSearchModule } from './extended-search/extended-search.module';
import { ControlMessagesComponent } from './components/control-messages/control-messages.component';
import { FuseWidgetModule, FuseNavigationModule, FuseCountdownModule } from './components';
import { GridDatatableModule } from './grid-datatable/grid-datatable.module';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { SnackBarModule } from './components/snack-bar/snack-bar.module';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { APP_FORMAT } from './services/date-adapter.service';
import { NgMaterialMultilevelMenuModule } from 'ng-material-multilevel-menu';
import { OverriddenControlsModule } from './components/overridden-controls/overridden-controls.module';

const ALL_MODULES = [
    // angular modules
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    ColorSketchModule,
    ColorSwatchesModule,

    // angular material modules
    MatButtonModule,
    MatCheckboxModule,
    MatMenuModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatStepperModule,
    MatTableModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatSelectModule,
    MatChipsModule,
    MatTabsModule,
    MatListModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatCardModule,
    MatDatepickerModule,
    MatGridListModule,
    MatSortModule,
    MatToolbarModule,
    MatBadgeModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatToolbarModule,
    MatDialogModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatSlideToggleModule,
    DragDropModule,
    ScrollingModule,

    // third party modules
    NgxMaterialTimepickerModule,
    ImageCropperModule,
    ChartModule,

    // theme modules
    FuseDirectivesModule,
    FusePipesModule,
    FuseWidgetModule,

    // project modules
    DialogModule,
    GridDatatableModule,
    ExtendedSearchModule,
    SnackBarModule,
    FuseNavigationModule,
    OverriddenControlsModule,

    // editor 
    AngularEditorModule,

    NgMultiSelectDropDownModule,
    FuseCountdownModule,
    NgMaterialMultilevelMenuModule
];

@NgModule({
    imports: ALL_MODULES,
    exports: [
        ALL_MODULES,
        ControlMessagesComponent,
    ],
    declarations: [
        ControlMessagesComponent
    ],
    providers: [
        { provide: HIGHCHARTS_MODULES, useFactory: () => [more, exporting, NoDataToDisplay] },
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        { provide: MAT_DATE_FORMATS, useValue: APP_FORMAT },
        // { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
        // { provide: DateAdapter, useClass: MomentUtcDateAdapter },
    ],
    entryComponents: [
    ]
})
export class SharedModule { }
