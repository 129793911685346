import { Component, OnInit, createPlatformFactory } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { AssessmentsService } from 'app/support/services/assessments.service';
import { SnackbarCongiration } from 'app/shared/components/snack-bar/SnackbarConfiguration';
@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit {
  assessment_input:any;
  input:any;
  result:Array<any>=[];
  answers:Array<any>=[];
  input_field:Boolean=false;
  languageForm: FormGroup;
  translated_data:any={};
  form_input:Boolean=false;
  section:Array<any>=[];
  section_dict:any={};
  section_value:Array<any>=[];
  subtitle:any;
  description:any;

  constructor(
    private router: Router,
    private route:ActivatedRoute, 
    private fb:FormBuilder,
    private assessmentService:AssessmentsService,
    private snackBar: SnackbarCongiration) {
    
    let ans_value:any={};
    this.assessment_input=window.history.state;
    // console.log(this.assessment_input);
    if (Object.keys(this.assessment_input).length > 1){
      this.input=this.assessment_input['section']
      this.subtitle=this.assessment_input['form']['assessment_subtitle']
      this.description=this.assessment_input['form']['description']
      if(this.input){
        if(this.input[0]['questions'][0].hasOwnProperty('question_languages')){
          this.form_input=true;
        }
      } 
    }   
    if(this.form_input){
      let count=0;
      if('assessment_languages' in this.assessment_input['form'] && this.assessment_input['form']['assessment_languages']!=null){
        if(this.subtitle!=null && this.subtitle!=""){
          if('subtitle' in this.assessment_input['form']['assessment_languages']['SP'])
            this.translated_data['subtitle']=this.assessment_input['form']['assessment_languages']['SP']['subtitle']
        }
        else{
          delete this.assessment_input['form']['assessment_languages']['SP']['subtitle'];
        }
        if(this.description!=null && this.description!=""){    
          if('description' in this.assessment_input['form']['assessment_languages']['SP'])  
            this.translated_data['description']=this.assessment_input['form']['assessment_languages']['SP']['description']
        }
        else{
          delete this.assessment_input['form']['assessment_languages']['SP']['description'];
        }    
      }    
      for(let temp in this.input){
        if('section_languages' in this.input[temp] && this.input[temp]['section_languages']!=null){
          this.translated_data['section_'+temp]=this.input[temp]['section_languages']['SP']['section_name']
          if('section_summary' in this.input[temp]['section_languages']['SP'])
            this.translated_data['section_summary_'+temp]=this.input[temp]['section_languages']['SP']['section_summary']
        }
        for(let val in this.input[temp]['questions']){
          if('question_languages' in this.input[temp]['questions'][val] && this.input[temp]['questions'][val]['question_languages']!=null){
            this.translated_data['question_'+temp+'_'+val]=this.input[temp]['questions'][val]['question_languages']['SP']['question_name']
            if('hint' in this.input[temp]['questions'][val]['question_languages']['SP'] ){
              this.translated_data['question_hint_'+temp+'_'+val]=this.input[temp]['questions'][val]['question_languages']['SP']['hint']  
            }
            if(this.input[temp]['questions'][val]['answers'].length){
              let ans=0
              for(let answer in this.input[temp]['questions'][val]['answers']){
                let check=0;
                for(let set in this.input[temp]['questions'][val]['answers'][answer]['answers']){
                  if(this.input[temp]['questions'][val]['answers'][answer]['answers'][set]['option_languages']!=null)
                    this.translated_data['answer_'+temp+'_'+val+"_"+(ans+1)+'_'+set]=this.input[temp]['questions'][val]['answers'][answer]['answers'][set]['option_languages']['SP']
                } 
                ans=ans+1;
              }
            }
          }  
          count=count+1;
        }
      } 
    }
    if(this.input){
      for(let temp in this.input){
        this.section=[]
        this.section_dict={}
        for(let val in this.input[temp]['questions']){
          let value:any={};
          let ans_value:any={};
          value['id']=this.input[temp]['questions'][val]['ques_id'];
          value['question']=this.input[temp]['questions'][val]['question_name'];
          if(this.input[temp]['questions'][val]['hint']!=null)
            value['question_hint']=this.input[temp]['questions'][val]['hint']
          if(this.input[temp]['questions'][val]['answers'].length){
            for(let answer in this.input[temp]['questions'][val]['answers']){
              let i=0;
              let ans_array=[];
              for(let set in this.input[temp]['questions'][val]['answers'][answer]['answers']){
                ans_array.push(this.input[temp]['questions'][val]['answers'][answer]['answers'][set]['option_value'])
              }
              ans_value[this.input[temp]['questions'][val]['answers'][answer]['answers'][0]['answer_set']]=ans_array;
            }
          value['answers']=ans_value;
          }

          this.section.push(value);
        }
        this.section_dict['section_name']= this.input[temp]['section_name']
        if(this.input[temp]['section_summary']!="") 
          this.section_dict['section_summary']=this.input[temp]['section_summary']
        this.section_dict['section']=this.section
        this.result.push(this.section_dict); 
      }
      // this.answers=this.result['answers'];
      this.createForm();
      if(this.form_input){
        this.languageForm.patchValue(this.translated_data)
      }
    }  
  }

  questionSplit(value, i, j, flag){
    const key = value.split("_");
    if(key.length == 3) {
      return parseInt(key[1]) === i && parseInt(key[2]) === j;
    }
    return value.includes(flag+i+'_'+j);
  }

  hintSplit(value, i, j, flag){
    const key = value.split("_");
    if(key.length == 4) {
      return parseInt(key[2]) === i && parseInt(key[3]) === j;
    }
    return value.includes(flag+i+'_'+j);
  }

  createForm(){
    this.languageForm=this.fb.group({
      id:[],
      // question:this.translated_data.question?this.translated_data.question:'',
      // answer:[this.translated_data.hasOwnProperty('answer')?this.translated_data.answer:''],
      // answer_set:[this.translated_data.hasOwnProperty('answer_set')?this.translated_data.answer_set:'']
      title:[this.translated_data.hasOwnProperty('title')?this.translated_data.title:''],
      // description:[this.translated_data.hasOwnProperty('description')?this.translated_data.description:'']
    });
    if(this.subtitle!="")
    this.languageForm.addControl('subtitle',this.fb.control(this.translated_data.hasOwnProperty('subtitle')?this.translated_data.subtitle:''));
    if(this.description!="")
      this.languageForm.addControl('description',this.fb.control(this.translated_data.hasOwnProperty('description')?this.translated_data.description:''));
    for(let temp in this.result){
      let que_no=0;
      let key_section='section_'+temp;
      this.languageForm.addControl('section_'+temp, this.fb.control(this.translated_data[key_section]?this.translated_data[key_section]:''));
      if('section_summary'in this.result[temp])
        this.languageForm.addControl('section_summary_'+temp, this.fb.control(this.translated_data[key_section]?this.translated_data[key_section]:''));
      for(let sec_data in this.result[temp]['section']){
        let key_ques='question_'+temp+"_"+sec_data;
        let key_ques_hint='question_hint_'+temp+"_"+sec_data;
        this.languageForm.addControl('question_'+temp+"_"+sec_data, this.fb.control(this.translated_data[key_ques]?this.translated_data[key_ques]:''));
        if('question_hint' in this.result[temp]['section'][sec_data])
          this.languageForm.addControl('question_hint_'+temp+"_"+sec_data, this.fb.control(this.translated_data[key_ques_hint]?this.translated_data[key_ques_hint]:''));

        for(let value in this.result[temp]['section'][sec_data]['answers']){
          let option_value=[]
          option_value=Object.values(this.result[temp]['section'][sec_data]['answers'][value])
          for(let option in option_value){
            let key_answer="answer_"+temp+"_"+sec_data+"_"+value+'_'+option;
            this.languageForm.addControl('answer_'+temp+'_'+sec_data+'_'+value+'_'+option, this.fb.control(this.translated_data[key_answer]?this.translated_data[key_answer]:''));
          }
        }
      }
    } 
  }

ngOnInit() {
}
setAsInput(){
  this.input_field=!this.input_field;
}
saveInput(){
  let assessment_dict={}
  let assessment_array=[]
  let data_array=[];
  let flag=false;
  this.input_field=!this.input_field;
  this.translated_data=this.languageForm.value;
  
  for(let key of Object.keys(this.translated_data)){
    if(this.translated_data[key]!=""&& key!='id')
      flag=true;
  }
  if(flag){
    if('subtitle'in this.translated_data)
      assessment_dict['subtitle']=this.translated_data['subtitle']
    
    if('description'in this.translated_data)  
      assessment_dict['description']=this.translated_data['description']

    for(let val in this.result ){
      let sec_dict={}
      // let section_val=[];
      let sec_array=[]
      let key_section='section_'+val;
      let key_section_summary='section_summary_'+val;
      // section_val=Object.values(this.result[val])
      sec_dict['section_name']=this.translated_data[key_section];
      if(key_section_summary in this.translated_data)
        sec_dict['section_summary']=this.translated_data[key_section_summary];
      for(let sec_data in this.result[val]['section']){
        let dict={}
        let ans_array=[]
        let key_ques='question_'+val+"_"+sec_data;
        dict['question']=this.translated_data[key_ques]
        let key_ques_hint='question_hint_'+val+"_"+sec_data;
        if( key_ques_hint in this.translated_data)
          dict['question_hint']=this.translated_data[key_ques_hint]

        for(let temp in this.result[val]['section'][sec_data]['answers']){
          let answer_array=[]
          let empty_array=[]
          let temp_dict={}
          let option_value=[]
          // console.log(this.result[val]['section'][sec_data]['answers'][temp]);
          option_value=Object.values(this.result[val]['section'][sec_data]['answers'][temp])
          for(let option in option_value){
            let key_answer="answer_"+val+"_"+sec_data+"_"+temp+"_"+option;
            let ans_dict={}
            ans_dict['option_value']=this.translated_data[key_answer];
            empty_array.push(ans_dict)
          }
          temp_dict['answers']=empty_array;
          ans_array.push(temp_dict)
        }
        dict['answers']=ans_array;
        sec_array.push(dict)
      }
      sec_dict['questions']=sec_array;  
      data_array.push(sec_dict)
      assessment_dict['section']=sec_dict
    }
    assessment_array.push(assessment_dict);
    let count=0;
    for(let inp in this.input ){
      let data={}
      data=this.input[inp]
      let language_sec={}
      let language_section={}
      language_sec['section_name']=data_array[inp]['section_name']
      if('section_summary' in data_array[inp] )
        language_sec['section_summary']=data_array[inp]['section_summary']
      language_section['SP']=language_sec
      this.input[inp]['section_languages']=language_section

      if(this.input[inp]['questions']){
        for(let j=0;j<(this.input[inp]['questions']).length;j++){
          let language_ques={};
          let ques_dict={};
          ques_dict['question_name']=data_array[inp]['questions'][j]['question']
          if('question_hint' in data_array[inp]['questions'][j]){
            ques_dict['hint']=data_array[inp]['questions'][j]['question_hint']
          }  
          language_ques['SP']=ques_dict;
          this.input[inp]['questions'][j]['question_languages']=language_ques;
          if(this.input[inp]['questions'][j]['answers'].length){
            for(let k=0;k<(this.input[inp]['questions'][j]['answers']).length;k++){
              for (let l=0;l<(this.input[inp]['questions'][j]['answers'][k]['answers']).length;l++){
                let language_ans={}
                language_ans['SP']=data_array[inp]['questions'][j]['answers'][k]['answers'][l]['option_value'];
                this.input[inp]['questions'][j]['answers'][k]['answers'][l]['option_languages']=language_ans
              }  
            }
          }
          count=count+1;
        }
      }
    }
    let assessment_language={};
    let language={};
    delete this.assessment_input['form']['assessment_languages'];
    if('subtitle' in assessment_array[0]&& assessment_array[0]['subtitle']!="" && assessment_array[0]['subtitle']!=null)
      language['subtitle']=assessment_array[0]['subtitle'];

    if('description' in assessment_array[0] && assessment_array[0]['description']!="" && assessment_array[0]['description']!=null)  
      language['description']=assessment_array[0]['description'];
    if(Object.keys(language).length){  
      assessment_language['SP']=language
      this.assessment_input['form']['assessment_languages']=assessment_language
    }  
  }
  else{
    for(let inp in this.input ){
      if(this.input[inp]['questions']){
        for(let j=0;j<(this.input[inp]['questions']).length;j++){
          if('question_languages' in this.input[inp]['questions'][j] ){
            delete this.input[inp]['questions'][j]['question_languages']
            if(this.input[inp]['questions'][j]['answers'].length){
              for(let k=0;k<(this.input[inp]['questions'][j]['answers']).length;k++){
                for (let l=0;l<(this.input[inp]['questions'][j]['answers'][k]['answers']).length;l++){
                  delete this.input[inp]['questions'][j]['answers'][k]['answers'][l]['option_languages']
                }
              }
            }
          }
        }
      }
    }            
  }
  
  this.createForm();
}

assesmentNavigate(){
  let data={}
  data['section']=this.input
  data['form']=this.assessment_input['form']
  data['settings']=this.assessment_input['settings']
  data['update']=true;
  data['assessment']=this.assessment_input['assessment'];
  data['overall_result']=this.assessment_input['overall_result'];
  data['isOverrallResult']=this.assessment_input['isOverrallResult'];
  data['sectionContinuity'] = this.assessment_input['sectionContinuity'];
  data['questionContinuity'] = this.assessment_input['questionContinuity'];
  if (this.assessment_input['page'] == 'update'||this.assessment_input['page'] == 'clone') 
  {
      let id = this.assessment_input['id'];
      data['update'] = false;
      data['assessment_name'] = this.assessment_input['form']['assessment_name']
      data['assessment_subtitle'] = this.assessment_input['form']['assessment_subtitle']
      data['sectionContinuityCopy'] = this.assessment_input['sectionContinuityCopy'];
      data['sectionCopy'] = this.assessment_input['sectionCopy'];
      data['formCopy'] = this.assessment_input['formCopy']
      data['overallItemsCopy'] = this.assessment_input['overallItemsCopy'];
      data['assessmentSettingsValueCopy'] = this.assessment_input['assessmentSettingsValueCopy'];
      if(this.assessment_input['page'] == 'clone')
      {
        this.router.navigate([`/assessments/clone/${id}`], { state: { ...data } }); 
      }
      else
      {
        this.router.navigate([`/assessments/edit/${id}`], { state: { ...data } });
      }
  }
  else
    this.router.navigate(['/assessments/create'],{state:{...data}});
}
}

