import { Component, OnInit, Input, Output, ViewChild, EventEmitter, Inject } from '@angular/core';
import { WorkFlowComponent } from 'app/features/main/smart-test/work-flow/work-flow.component';
@Component({
  selector: 'app-workflow-configure-layout',
  templateUrl: './configure-layout.component.html',
  styles: []
})
export class ConfigureLayoutComponent implements OnInit {

  @Input() menuItems: any[] = [];
  @Input() activeMenu: string = 'workflow_setup';
  @Output() activeMenuItem: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(WorkFlowComponent, { static: true }) workflowDiagram: WorkFlowComponent;

  data: any = null;
  mainObjectsFields = {};
  tagsList: any = [];
  constructor(
  ) { }

  ngOnInit() {
  }

  setSelectedData(data: any) {
    this.data = data;
  }

  async setActiveMenu(name) {
    if(this.activeMenu === 'workflow_setup') {
    }
    else {
        this.activeMenu = name;
        this.activeMenuItem.emit(name);
    }
  }

  setMainObjectsFields(fields: any) {
    this.mainObjectsFields = fields;
  }

  setTagsList(tags) {
    this.tagsList = tags;
  }

  getDiagramComponent() {
    return this.workflowDiagram;
  }
}
