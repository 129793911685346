import { Component, Inject } from "@angular/core";
import { CellRendererComponent } from "app/shared/grid-datatable/cell-renderer/cell-renderer.component";
import {
    Columns,
    GridConfig,
    Renderers,
    RowEvent,
} from "app/shared/grid-datatable/grid-datatable";
import {
    MatDialog,
    MatDialogConfig,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import * as moment from "moment";
import { CommonService } from "app/support/services/common.service";
import { TransferDyanicSmartestComponent } from "../transfer-dyanic-smartest/transfer-dyanic-smartest.component";

declare var require: any;
const FileSaver = require("file-saver");

@Component({
    selector: "app-transfer-logs",
    templateUrl: "./transfer-logs.component.html",
    styleUrls: ["./transfer-logs.component.scss"],
})
export class TransferLogsComponent {
    columns: Columns;
    config: GridConfig;
    renderers: Renderers;
    pdfFiles = [
        {
            name: "PDF File One",
            startPage: 2,
            path: "home/documents/text.pdf",
        },
    ];

    constructor(private dialog: MatDialog, private api: CommonService) {
        const dateComparator = (date1, date2) => {
            if (date1 == "- - - -") return -1;
            if (date2 == "- - - -") return 1;
            if (new Date(date1) > new Date(date2)) return 1;
            if (new Date(date1) < new Date(date2)) return -1;
            return 0;
        };

        let icons = {
            sortUnSort:
                '<img src="assets/images/icons/unsort.png" width="14" />',
            sortAscending:
                '<img src="assets/images/icons/upsort.png" width="12" />',
            sortDescending:
                '<img src="assets/images/icons/downsort.png" width="12" />',
        };

        this.config = {
            search: true,
            searchUrl: {
                searchValuesUrl: "audit-log/auditlog-search-values",
                searchResultUrl: "audit-log/auditlog-search-result",
            },
            searchList: [
                {
                    searchKey: "Clinic Name",
                    property: "clinic_name",
                    field: "clinic_name",
                    display: true,
                    searchValues: [],
                },
                {
                    searchKey: "API",
                    property: "api_type",
                    field: "api_type",
                    display: true,
                    searchValues: [],
                },
                {
                    searchKey: "Status",
                    property: "status",
                    field: "status",
                    display: true,
                    searchValues: [],
                },
                {
                    searchKey: "User Name",
                    property: "user_name",
                    field: "user_name",
                    display: true,
                    searchValues: [],
                },
                {
                    searchKey: "Patient Name",
                    property: "patient_name",
                    field: "patient_name",
                    display: true,
                    searchValues: [],
                },
                {
                    searchKey: "Patient MRN",
                    property: "patient_mrn",
                    field: "patient_mrn",
                    display: true,
                    searchValues: [],
                },
                {
                    searchKey: "Acknowledgement Key",
                    property: "dynamic_smartest_unique",
                    field: "dynamic_smartest_unique",
                    display: true,
                    searchValues: [],
                },
            ],

            sidepan: true,
            sidepanMenu: ["columns"],
            remoteUrl: "audit-log/auditlog-list",
            remoteParams: {},
        };

        this.renderers = {
            headerRenderer: [{ column: "Actions", renderer: ["create"] }],
            cellRenderer: [{ column: "Actions", renderer: ["status"] }],
            table: "transfer-logs",
        };

        this.columns = {
            columnDefs: [
                {
                    headerName: "Clinic Name",
                    headerTooltip: "Clinic Name",
                    field: "clinic_name",
                    minWidth: 100,
                    sortable: true,
                    unSortIcon: true,
                    suppressMovable: true,
                    tooltipField: "clinicName",
                    valueGetter:
                        'node.data.clinic_name!=null?node.data.clinic_name:"- - - - -"',
                    sortingOrder: ["asc", "desc"],
                    icons: icons,
                },
                {
                    headerName: "Patient Name",
                    headerTooltip: "Patient Name",
                    field: "patient_name",
                    minWidth: 100,
                    sortable: true,
                    unSortIcon: true,
                    suppressMovable: true,
                    tooltipField: "patientName",
                    valueGetter:
                        'node.data.patient_name!=""?node.data.patient_name:"- - - - -"',
                    sortingOrder: ["asc", "desc"],
                    icons: icons,
                },
                {
                    headerName: "Patient MRN",
                    headerTooltip: "patient mrn",
                    field: "patient_mrn",
                    minWidth: 100,
                    sortable: true,
                    unSortIcon: true,
                    suppressMovable: true,
                    tooltipField: "patient_mrn",
                    valueGetter:
                        'node.data.patient_mrn!=null?node.data.patient_mrn:"- - - - -"',
                    sortingOrder: ["asc", "desc"],
                    icons: icons,
                },
                {
                    headerName: "User Name",
                    headerTooltip: "User Email",
                    field: "user_name",
                    minWidth: 100,
                    sortable: true,
                    unSortIcon: true,
                    suppressMovable: true,
                    tooltipField: "user_name",
                    valueGetter:
                        'node.data.user_name!=null?node.data.user_name:"- - - - -"',
                    sortingOrder: ["asc", "desc"],
                    icons: icons,
                },
                {
                    headerName: "Date & Time",
                    headerTooltip: "Date & Time",
                    field: "created_date",
                    sortable: true,
                    unSortIcon: true,
                    comparator: dateComparator,
                    minWidth: 100,
                    tooltipField: "created_date",
                    suppressMovable: true,
                    sortingOrder: ["asc", "desc"],
                    icons: icons,
                    valueGetter: function (params) {
                        if (!params.data.created_date) {
                            return "- - - -";
                        } else {
                            return moment(params.data.created_date).format(
                                "MMM-DD-YYYY, hh:mm A"
                            );
                        }
                    },
                },
                {
                    headerName: "API",
                    headerTooltip: "API",
                    field: "api_type",
                    minWidth: 100,
                    sortable: true,
                    unSortIcon: true,
                    tooltipField: "api_type",
                    valueGetter:
                        'node.data.api_type!=null?node.data.api_type:"- - - - -"',
                    suppressMovable: true,
                    sortingOrder: ["asc", "desc"],
                    icons: icons,
                },
                {
                    headerName: "Status",
                    headerTooltip: "Status",
                    field: "status",
                    minWidth: 100,
                    suppressMovable: true,
                    sortable: true,
                    unSortIcon: true,
                    cellRendererFramework: CellRendererComponent,
                    sortingOrder: ["asc", "desc"],
                    icons: icons,
                },
                {
                    headerName: "JSON Output",
                    headerTooltip: "Output Link",
                    minWidth: 100,
                    sortable: false,
                    suppressMovable: true,
                    cellRendererFramework: CellRendererComponent,
                },
                {
                    headerName: "API Input",
                    headerTooltip: "Client File",
                    minWidth: 100,
                    sortable: false,
                    suppressMovable: true,
                    cellRendererFramework: CellRendererComponent,
                },
                {
                    headerName: "Acknowledgement File",
                    headerTooltip: "Acknowledgement File",
                    minWidth: 100,
                    sortable: false,
                    suppressMovable: true,
                    cellRendererFramework: CellRendererComponent,
                },
                {
                    headerName: "Acknowledgement Key",
                    headerTooltip: "Acknowledgement Key",
                    field: "dynamic_smartest_unique",
                    minWidth: 100,
                    sortable: true,
                    suppressMovable: true,
                    tooltipField: "Acknowledgement Key",
                    valueGetter:
                        'node.data.dynamic_smartest_unique != null ? node.data.dynamic_smartest_unique : "- - - - -"',
                    sortingOrder: ["asc", "desc"],
                    icons: icons,
                },
                {
                    headerName: "Dynamic SMARTest",
                    headerTooltip: "Dynamic SMARTest",
                    minWidth: 150,
                    sortable: false,
                    unSortIcon: true,
                    suppressMovable: true,
                    cellRendererFramework: CellRendererComponent,
                },
            ],
            columnTog: [
                {
                    columnName: "Clinic Name",
                    field: "clinic_name",
                    visible: true,
                    disabled: true,
                },
                {
                    columnName: "Date Time",
                    field: "created_date",
                    visible: true,
                },
                { columnName: "API", field: "api_type", visible: true },
                { columnName: "Status", field: "status", visible: true },
                { columnName: "User Name", field: "user_name", visible: true },
                {
                    columnName: "Patient MRN",
                    field: "patient_mrn",
                    visible: true,
                    disabled: true,
                },
                {
                    columnName: "Patient Name",
                    field: "patient_name",
                    visible: false,
                    disabled: false,
                },
                {
                    columnName: "Acknowledgement Key",
                    field: "dynamic_smartest_unique",
                    visible: false,
                    disabled: false,
                },
            ],
        };
    }

    rowEvent(event: RowEvent) {
        if (event.action == "download") {
            let data = { path: event["data"]["file_url"] };
            this.api.getDownloadAudit(data).subscribe((res) => {
                this.downloadfile(
                    res,
                    event["data"]["file_url"].split("/").pop()
                );
            });
        }

        if (event.action == "client_file_download") {
            let data = { path: event["data"]["client_file_url"] };
            this.api.getDownloadClientAudit(data).subscribe((res) => {
                this.downloadClientFile(
                    res.body,
                    event["data"]["client_file_url"].split("/").pop()
                );
            });
        }

        if (event.action == "acknowledgement_file_download") {
            let data = { path: event["data"]["acknowledgement_file_url"] };
            this.api.getDownloadClientAudit(data).subscribe((res) => {
                this.downloadClientFile(
                    res.body,
                    event["data"]["acknowledgement_file_url"].split("/").pop()
                );
            });
        }

        if (event.action == "log") {
            this.alertNavigation(event.data);
        }

        if (event.action == "dynamic_smartest") {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.data = event.data;
            let dialogRef = this.dialog.open(
                TransferDyanicSmartestComponent,
                dialogConfig
            );
        }
    }

    downloadfile(data, filename) {
        const blob = new Blob([JSON.stringify(data)], {
            type: "application/json",
        });
        FileSaver.saveAs(blob, filename);
    }

    downloadClientFile(data, filename) {
        const blob = new Blob([data], { type: "application/text" });
        FileSaver.saveAs(blob, filename);
    }

    alertNavigation(data) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.data = data;
        let dialogRef = this.dialog.open(LogFileModal, dialogConfig);
    }
}
//success ro failure log files
@Component({
    selector: "app-transfer-logs",
    templateUrl: "./log-file.component.html",
    styleUrls: ["./transfer-logs.component.scss"],
})
export class LogFileModal {
    constructor(
        public dialogRef: MatDialogRef<LogFileModal>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    checktype(data) {
        if (typeof data === "string") {
            return false;
        } else {
            return true;
        }
    }

    closeModal() {
        this.dialogRef.close();
    }
}
