import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { UsersService } from 'app/support/services/users.service';
import { GlobalService } from 'app/support/services/auth/global.service';
import { DeviceSettingsService } from 'app/support/services/device-settings.service';

import { SnackbarCongiration } from 'app/shared/components/snack-bar/SnackbarConfiguration';
import { ValidationService } from 'app/shared/services/validation.service';
import { MatDialog, MatDialogConfig, MatRadioChange, MatSelectChange } from '@angular/material';
import { DEFAULT_INTERRUPTSOURCES, EventTargetInterruptSource, Idle } from '@ng-idle/core';
import { PhoenixWebsocketService } from 'app/support/services/phoenix-websocket.service';
import { SessionLogoutComponent } from 'app/shared/components/dialog-box/session-logout/session-logout.component';
import { AuthService } from 'app/support/services/auth/auth.service';
import { ActivateDialogComponent } from 'app/shared/components/dialog-box/activate-dialog/activate-dialog.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop'; 

@Component({
	selector: 'app-device-settings',
	templateUrl: './device-settings.component.html',
	styleUrls: ['./device-settings.component.scss']
})
export class DeviceSettingsComponent implements OnInit {

	systemForm: FormGroup;
	clinicId: any;
	progress: boolean = false;
	path: any;
	system_data: any;
	initialFormValue: string = '';

	clinicsList: Array<any> = []
	formData: any = undefined;

	intervalDaysList: any = []
	intervalHrsList: any = []
	intervalMinsList: any = []
	retryCountList: any = []
	auditLogClearList: any = []
	durationHrsList: any = []
	durationMinsList: any = []
	durationDaysList: any = []
	amPm: Array<any> = []
	timeHrs:Array<any> = []
	roles: Array<any> = []
	isPatientToggleAvailable: boolean = false
	otpTimeoutList:any = [30,45,60,90,180,360]
	devicePreferences: Array<any> = [
		{ id: 1, name: 'Patient Email', isDisable: false, isSelected: false, key: 'Email' }, 
		{ id: 2, name: 'Patient Mobile', isDisable: false, isSelected: false, key: 'Mobile' }, 
		{ id: 3, name: 'In-Clinic Device', isDisable: false, isSelected: false, key: 'In-Clinic Device' },
		{ id: 4, name: 'Email&Mobile', isDisable: false, isSelected: false, key: 'Email&Mobile'},
		{ id: 5, name: 'All Three', isDisable: false, isSelected: false, key: 'All Three'}
	];
	selectedPreference: Array<any> = [];
	isDeviceSettings: boolean = false
	constructor(
		private fb: FormBuilder,
		private globalService: GlobalService,
		private userService: UsersService,
		private deviceService: DeviceSettingsService,
		private snackbar: SnackbarCongiration,
		private router: Router,
		private idle: Idle,
		private phoenixWebSocket: PhoenixWebsocketService,
        private dialog: MatDialog,
		private element: ElementRef,
		private authService: AuthService
	) {
		this.amPm = [
			{id: 1, value:'AM'},
			{id: 2, value:'PM'}
		];
	}

	ngOnInit() {
		// this.getUser();	
		if (this.router.url.includes('system-settings')) {
			this.path = "system_settings";
			this.isDeviceSettings = false
		}
		else {
			this.path = "device_settings";
			this.isDeviceSettings = true
		}

		if (this.path == "system_settings") {
			this.getSystemSettings();
		}
		else {
			this.getSettingsForClinic();
		}
		this.getRoles()
	}

	getRoles = () => {
		this.globalService.user$.subscribe(data => {
			this.roles = data.roles;
			if (this.roles.includes('Admin')) {
				this.isPatientToggleAvailable = true
			} else {
				this.isPatientToggleAvailable = false
			}
		})
	}

	// getUser() {
	// 	this.globalService.user$.subscribe((data: any) => {
	// 		this.userService.getUser(data['id']).subscribe(
	// 			userData => {
	// 				if (userData) {
	// 					this.clinicsList = userData['data']['user_data']['clinics'];
	// 					this.buildForm();
	// 					if (this.clinicsList.length > 0) {
	// 						this.deviceForm.get('clinic').setValue(this.clinicsList[0]['id']);
	// 						this.getSettingsForClinic();
	// 					}
	// 				}
	// 			}
	// 		)
	// 	});
	// }

	system_form() {
		this.systemForm = this.fb.group({
			'portal_notification': this.fb.group({
				'Email': [this.system_data['portal_notification'] && this.system_data['portal_notification'].includes('Email') ? true : false],
				'Web': [this.system_data['portal_notification'] && this.system_data['portal_notification'].includes('Web') ? true : false],
				'Mobile': [this.system_data['portal_notification'] && this.system_data['portal_notification'].includes('Mobile') ? true : false]
			}),
			'patient_run_assessment': this.fb.group({
				'Email': [this.system_data['assessment_notification'] && this.system_data['assessment_notification'].includes('Email') ? true : false],
				'Mobile': this.system_data['assessment_notification'] && [this.system_data['assessment_notification'].includes('Mobile') ? true : false],
				'In-Clinic Device': [this.system_data['assessment_notification'] && this.system_data['assessment_notification'].includes('In-Clinic Device') ? true : false]
			}),
			// 'expiration_time': [this.system_data['expiration_time'], [Validators.required, Validators.pattern('^([1-9]|[1-9][0-9]+)$')]],
			'expiration_days': [this.system_data['expiration_days'], [Validators.required,Validators.pattern('^([0-9]+)$')]],
			'expiration_hrs': [this.system_data['expiration_hrs'], [Validators.required]],
			'expiration_mins': [this.system_data['expiration_mins'], [Validators.required]],
			'two_factor_auth': [this.system_data['two_factor_auth']],	
			'device_launch_type': [this.system_data['device_launch_type'], Validators.required],
			// 'assessment_notification_reminder': [this.system_data['assessment_notification_reminder'], [Validators.required, Validators.pattern('^([1-9]|[1-9][0-9]+)$')]],
			'time_duration_days': [this.system_data['time_duration_days'], [Validators.required,Validators.pattern('^([0-9]+)$')]],
			'time_duration_hrs': [this.system_data['time_duration_hrs'], [Validators.required]],
			'time_duration_mins': [this.system_data['time_duration_mins'], [Validators.required]],
			'time_interval_days': [this.system_data['time_interval_days'], [Validators.required, Validators.pattern('^([0-9]+)$')]],
			'time_interval_hrs': [this.system_data['time_interval_hrs'],[Validators.required]],
			'time_interval_mins': [this.system_data['time_interval_mins'], [Validators.required]],
			'retry_count': [this.system_data['retry_count'], Validators.required],
			'otp_timeout': [this.system_data['otp_timeout'] ? this.system_data['otp_timeout'] : 90],
			'timeout': [this.system_data['timeout'], [Validators.required, Validators.min(10), Validators.max(600), Validators.pattern('^([1-9][0-9]+)$')]],
			'password_change': [this.system_data['password_change'], [Validators.required, Validators.min(30), Validators.max(365), Validators.pattern('^([1-9][0-9]+)$')]],
			'user_inactive': [this.system_data['user_inactive'], [Validators.required, Validators.min(30), Validators.max(365), Validators.pattern('^([1-9][0-9]+)$')]],
			'device_expiry_type': [this.system_data['device_expiry_type'], Validators.required],
			'user_activation_link': [this.system_data['user_activation_link']],
			'all_clinician': [this.system_data['all_clinician']],
			'notifications_on': [this.system_data['notifications_on']],
			// 'audit_log_clear': [this.system_data['audit_log_clear'], Validators.required],
			'fair_files': [this.system_data['fair_files']],
			'dump_threshold': [this.system_data['dump_threshold']],
			'maximum_load': [this.system_data['maximum_load']],
		})
		this.systemForm.get('portal_notification').setValidators([ValidationService.checkBoxValidator()])
		this.system_data['am_pm'] = this.amPm.find((element:any)=> element['value'] == this.system_data['am_pm']);
		// this.systemForm.get('am_pm').setValue(this.system_data['am_pm']) 
		if (this.systemForm.get('device_expiry_type').value == 'custom') {
			this.systemForm.addControl('device_expiry_time', new FormControl(this.system_data['device_expiry_time'], [Validators.required, Validators.min(1), Validators.pattern('^([1-9][0-9]*)$')]));
			this.systemForm.addControl('device_expiry_time_in', new FormControl(this.system_data['device_expiry_time_in'], Validators.required));
		}
		if (this.path == "device_settings") {
			this.systemForm.addControl('clinic', new FormControl(this.system_data['clinic'], Validators.required));
			this.systemForm.addControl('scheduling_hours', new FormControl(this.system_data['scheduling_hours'], Validators.required));
			this.systemForm.addControl('scheduling_mins', new FormControl(this.system_data['scheduling_mins'], Validators.required));
			this.systemForm.addControl('am_pm', new FormControl(this.system_data['am_pm'], Validators.required));
			if (this.isPatientToggleAvailable) {
				this.systemForm.addControl('patient_group', new FormControl(this.system_data['patient_group'], Validators.required));
			} else {
				this.systemForm.addControl('patient_group', new FormControl(false));
			}
		}

		if (this.path == "system_settings") {
			this.systemForm.addControl('audit_log_clear', new FormControl(this.system_data['audit_log_clear'], Validators.required));
			this.systemForm.get('patient_run_assessment').setValidators([ValidationService.checkBoxValidator()])
			this.checkboxDisable('patient_run_assessment');
			// this.systemForm.addControl('patient_group', new FormControl(this.system_data['patient_group'], Validators.required));
		}
		this.checkboxDisable('portal_notification');
		if (!this.systemForm.get('notifications_on').value)
			setTimeout(() => {
				this.systemForm.get('portal_notification').disable();
			}, 100);
		this.initialFormValue = JSON.stringify(this.systemForm.getRawValue())

		this.devicePreferences = [
			{ id: 1, name: 'Patient Email', isDisable: false, isSelected: false, key: 'Email' }, 
			{ id: 2, name: 'Patient Mobile', isDisable: false, isSelected: false, key: 'Mobile' }, 
			{ id: 3, name: 'In-Clinic Device', isDisable: false, isSelected: false, key: 'In-Clinic Device' },
			{ id: 4, name: 'Email&Mobile', isDisable: false, isSelected: false, key: 'Email&Mobile'},
			{ id: 5, name: 'All Three', isDisable: false, isSelected: false, key: 'All Three'}
		];
		let assessment_notification = this.system_data['assessment_notification']
		let predefinedNotification = ['Email', 'Mobile', 'In-Clinic Device', 'Email&Mobile', 'All Three']
		let ass_notification = assessment_notification.split(",")
		let apiArray = []
		ass_notification.map((item, index) => {
			if (predefinedNotification.includes(item)) {
				apiArray.push(item)
			}
		})
		apiArray.map((item, index) => {
			let foundIndex = this.devicePreferences.findIndex(x => x.key == item);
			this.devicePreferences[foundIndex].isDisable = true
			this.devicePreferences[foundIndex].isSelected = true
		})
		this.devicePreferences.sort((a, b) => ass_notification.indexOf(a.key) - ass_notification.indexOf(b.key));
		this.setSortingPreference()
		this.checkInDeviceCheck()

		if (ass_notification.includes('In-Clinic Device') || ass_notification.includes('All Three')) {
			let deviceType = this.system_data['device_launch_type']
			this.systemForm.get('device_launch_type').setValue(deviceType ? deviceType : null);
			setTimeout(() => {
				this.systemForm.get('device_launch_type').enable();
				this.systemForm.get('device_expiry_type').enable();
			}, 100);
		}
		else {
			this.systemForm.get('device_launch_type').setValue(null);
			setTimeout(() => {
				this.systemForm.get('device_launch_type').disable();
				this.systemForm.get('device_expiry_type').disable();
			}, 100);
		}
	}

	checkboxDisable(control_name) {
		let portal_notification_val = this.systemForm.getRawValue()[control_name];
		let portalKeys = Object.keys(portal_notification_val);
		// this.systemForm.get(control_name).get('Email').enable();
		// this.systemForm.get(control_name).get('Mobile').enable();
		// if (control_name = 'portal_notification') {
		// 	this.systemForm.get(control_name).get('Web').enable();
		// }
		// else {
		// 	this.systemForm.get(control_name).get('In-Clinic Device').enable();
		// }
		let portalgrp = [];
		portalKeys.forEach(element => {
			if (portal_notification_val[element]) {
				portalgrp.push(element);
			}
		});
		if (portalgrp.length == 1) {
			setTimeout(() => {
				this.systemForm.get(control_name).get(portalgrp[0]).disable();
				let other_keys = [...portalKeys];
				other_keys.splice(other_keys.indexOf(portalgrp[0]), 1)
				other_keys.forEach(element => {
					let ctrl = this.systemForm.get(control_name).get(element);
					if (ctrl.disabled) {
						ctrl.enable();
					}
				});
			}, 100);
		}
		else {
			setTimeout(() => {
				this.systemForm.get(control_name).get('Email').enable();
				this.systemForm.get(control_name).get('Mobile').enable();
				if (control_name == 'portal_notification') {
					this.systemForm.get(control_name).get('Web').enable();
				}
				else {
					this.systemForm.get(control_name).get('In-Clinic Device').enable();
				}
			}, 100);
		}
	}

	getSettingsForClinic() {
		this.deviceService.getClinicSettings(this.systemForm ? this.systemForm.get('clinic').value : null).subscribe(response => {
			this.clinicsList = response['clinic_list'];
			this.system_data = response['data'];
			this.fillRetriesConfig(this.system_data['retries_data']);
			this.systemForm ? this.systemForm.reset() : ''
			this.systemForm = new FormGroup({});
			this.system_form();
			// this.systemForm.patchValue(response['data']);
			// if (!response['status']) {
			// 	this.systemForm.patchValue(response['data']);
			// 	this.systemForm.get('device_launch_type').setValue(null);
			// 	this.systemForm.get('expiration_time').setValue(null);
			// }
			// this.setForm();
		})
	}

	getSystemSettings() {
		this.userService.getSystemSettings().subscribe(response => {
			this.system_data = response['data'][0];
			this.fillRetriesConfig(this.system_data['retries_data']);
			this.systemForm ? this.systemForm.reset() : ''
			this.systemForm = new FormGroup({});
			this.system_form();
			// this.systemForm.patchValue(response['data'][0]);
			// this.setForm();
		}
		)
	}

	fillRetriesConfig(retries_config) {
		if (retries_config != undefined && retries_config != null) {
			this.intervalHrsList = retries_config['time_interval_hrs'];
			this.intervalMinsList = retries_config['time_interval_mins'];
			this.durationHrsList = retries_config['time_duration_hrs'];
			this.durationMinsList = retries_config['time_duration_mins'];
			this.retryCountList = retries_config['max_retry_count_list'];
			this.timeHrs = retries_config['time_hours'];
			this.auditLogClearList = retries_config['audit_log_clear'];
		}
	}

	clinicDeviceChange(event) {
		this.checkboxDisable('patient_run_assessment');
		if (event.checked) {
			this.systemForm.get('device_launch_type').setValue(null);
			this.systemForm.get('device_launch_type').enable();
			this.systemForm.get('device_expiry_type').enable();
		}
		else {
			this.systemForm.get('device_launch_type').setValue(null);
			this.systemForm.get('device_launch_type').disable();
			this.systemForm.get('device_expiry_type').disable();
		}
	}

	preferenceChange(event, index) {
		if(event.checked) {
			this.devicePreferences[index].isDisable = event.checked
			this.devicePreferences[index].isSelected = event.checked
		} else {
			this.devicePreferences[index].isDisable = event.checked
			this.devicePreferences[index].isSelected = event.checked
		}
		this.setSortingPreference()
		this.checkInDeviceCheck()
	}

	checkInDeviceCheck() {
		let newArray = this.devicePreferences.filter(item => item.key === 'In-Clinic Device' && !item.isSelected)
		let newAllArray = this.devicePreferences.filter(item => item.key === 'All Three' && !item.isSelected)
		let selectedArray = this.devicePreferences.filter(item => item.isSelected)
		let selectedKeys = selectedArray.map(item => item.key)
		this.selectedPreference = selectedArray
		if (selectedKeys.includes('All Three') || selectedKeys.includes('In-Clinic Device')) {
			this.systemForm.get('device_launch_type').setValue(this.systemForm.get('device_launch_type').value ? this.systemForm.get('device_launch_type').value : null);
			this.systemForm.get('device_launch_type').enable();
			this.systemForm.get('device_expiry_type').enable();
		} else {
			this.systemForm.get('device_launch_type').setValue(null);
			this.systemForm.get('device_launch_type').disable();
			this.systemForm.get('device_expiry_type').disable();
		}
	}
 
	setSortingPreference() {
		this.devicePreferences.sort((a,b) => b.isDisable - a.isDisable)
	}

	isNumber(evt) {
        evt = (evt) ? evt : window.event;
        let keys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab']
        var charCode = evt.keyCode;
        if (((charCode >= 48 && charCode <= 57 || charCode >=96 && charCode <= 105)  && !evt.shiftKey) || keys.includes(evt.key)) {
            return true;
        }
        return false;
    }
    onPaste(event){
        let clipboardData = event.clipboardData;
        let pastedText = clipboardData.getData('text');
        var number = new RegExp("^[0-9]+$");
        if(number.test(pastedText)) {
            return true;
        }
        else {
            return false;
        }
	}
	
	checkFormvalueChange(){
		let formData = JSON.stringify(this.systemForm.getRawValue());
		if(formData != this.initialFormValue) return true;
		else return false;
		
	}

	systemform_submit() {
		this.progress = true;
		let data = this.systemForm.getRawValue();
		if(this.isDeviceSettings) {
			let payloadArray = []
			this.devicePreferences.map((item, index) => {
				if (item.isSelected) {
					payloadArray.push(item.key)
				}
			})
			data['patient_run_assessment'] = payloadArray
			if (payloadArray.length === 0) {
				this.snackbar.triggerSnackBar({
					message: 'Please Select Devices for Run Assessment',
					type: 'error'
				});
				this.progress = false;
				return
			}
		}
		let user: any;
		this.globalService.user$.subscribe(res => { user = res });
		if (this.path == "system_settings") {
			this.userService.updateSystemSettings(data).subscribe(
				response => {
					this.snackbar.triggerSnackBar({
						message: response['msg'],
						type: 'success'
					});
					this.progress = false;
					
					//if (user['timeout'] > data['timeout']) {
						user['timeout'] = data['timeout'];
						this.globalService.setUser(user);
						this.setTimeoutInterval(user);
					//}
					this.initialFormValue = JSON.stringify(this.systemForm.getRawValue())
				},
				error => {
					this.snackbar.triggerSnackBar({
						message: 'Failed to update MHT settings',
						type: 'error'
					});
					this.progress = false;
				}
			)
		}
		else if (this.path == "device_settings") {
			this.deviceService.setSettings(data).subscribe(
				response => {
					this.snackbar.triggerSnackBar({
						message: response['msg'],
						type: 'success'
					});
					this.progress = false;
					//if (user['timeout'] > response['user_timeout']) {

						user['timeout'] = response['user_timeout'];
						this.globalService.setUser(user);
						this.setTimeoutInterval(user);
						
                     //}
					
					this.initialFormValue = JSON.stringify(this.systemForm.getRawValue())
				},
				error => {
					this.snackbar.triggerSnackBar({
						message: 'Failed to update clinic settings',
						type: 'error'
					});
					this.progress = false;
				}
			)
		}
	}

	timeIntervalHrsChange() {
		if (this.systemForm.get('time_interval_days').value === 0 && this.systemForm.get('time_interval_hrs').value === 0 && this.systemForm.get('time_interval_mins').value === 0) {
			this.systemForm.get('time_interval_mins').setValue(15);
		}
	}

	timeDurationChange(){
		if (this.systemForm.get('time_duration_days').value === 0 && this.systemForm.get('time_duration_hrs').value === 0 && this.systemForm.get('time_duration_mins').value === 0) {
			this.systemForm.get('time_duration_mins').setValue(15);
		}
	}

	timeExpirationChange(){
		if (this.systemForm.get('expiration_days').value === 0 && this.systemForm.get('expiration_hrs').value === 0 && this.systemForm.get('expiration_mins').value === 0) {
			this.systemForm.get('expiration_mins').setValue(15);
		}
	}

	deviceExpiration(event: MatRadioChange) {
		if (event.value == 'custom') {
			this.systemForm.addControl('device_expiry_time', new FormControl(1, [Validators.required, Validators.min(1), Validators.max(365), Validators.pattern('^([1-9][0-9]*)$')]));
			this.systemForm.addControl('device_expiry_time_in', new FormControl('days', Validators.required));
		}
		else {
			this.systemForm.removeControl('device_expiry_time');
			this.systemForm.removeControl('device_expiry_time_in');
		}
	}

	deviceTimeIn(event: MatSelectChange) {
		if (event.value == 'days') {
			this.systemForm.get('device_expiry_time').setValidators([Validators.required, Validators.min(1), Validators.max(365), Validators.pattern('^([1-9][0-9]*)$')]);
		}
		else {
			this.systemForm.get('device_expiry_time').setValidators([Validators.required, Validators.min(1), Validators.max(12), Validators.pattern('^([1-9][0-2]*)$')]);
		}
		this.systemForm.get('device_expiry_time').updateValueAndValidity();
	}

	setTimeoutInterval(data: any) {
        let timeout = data['timeout'];
        this.idle.stop();
        this.idle.setIdle(1);
        this.idle.setIdle((timeout * 60) - 1);
        this.idle.setTimeout(1);
        this.idle.clearInterrupts();
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        let idleTiemout = this.idle.onTimeout.subscribe(() => {
            if (this.authService.authenticated()) {
                this.dialog.closeAll();
				let dialogConfig = new MatDialogConfig();
				dialogConfig.width = '600px';
				dialogConfig.disableClose = true;
				dialogConfig.restoreFocus = false;
				dialogConfig.data = { confirm: false };
				this.phoenixWebSocket.closeConnection();
				let dialogRef = this.dialog.open(SessionLogoutComponent, dialogConfig);
				//   dialogRef.afterClosed().subscribe(
				//       response => {
				//           if (response) {
				//               this.phoenixService.closeConnection();
				//               this.idle.stop();
				//           }
				//           else this.setTimeoutInterval({ timeout: timeout });
				//       }
				//   );
			}
			// idleTiemout.unsubscribe();
        });
        this.phoenixWebSocket.setSessionSubscription(idleTiemout, this.idle);
          
        // start watching or resets watching time
        this.idle.watch();
	}

	notificationsOn(event: any) {
		if (event['checked'])
			this.systemForm.get('portal_notification').enable();
		else
			this.systemForm.get('portal_notification').disable();
	}

	confirm(): Promise<any> {
		console.log(this.systemForm.value)
        return new Promise((resolve) => {
            if (this.checkFormvalueChange()) {
                const dialogConfig = new MatDialogConfig();
                // dialogConfig.position = { top: '70px' };
                dialogConfig.width = '500px';
                dialogConfig.disableClose = true;
                dialogConfig.data = { 'yes': null, 'no': null };
                let dialogRef = this.dialog.open(ActivateDialogComponent, dialogConfig);
                dialogRef.afterClosed().subscribe(
                    value => {
                        if (value == 'yes') {
                            if(localStorage.getItem('tokenData')) localStorage.removeItem('tokenData');
                            this.dialog.closeAll();
                            resolve(true);
                        } else {
                            if(localStorage.getItem('tokenData')){
                                let data = JSON.parse(localStorage.getItem('tokenData'))
                                localStorage.setItem("token", data['token']);
                                localStorage.setItem("firebase:host:phoenix-d7e6a.firebaseio.com", data['firebase:host:phoenix-d7e6a.firebaseio.com']);
                                localStorage.removeItem('tokenData');
                            }
                            this.dialog.closeAll();
                            resolve(false);
                        }
                    }
                );
            } else {
                resolve(true);
            }
        })
    }

	drop(event: CdkDragDrop<string[]>) {
		moveItemInArray(this.devicePreferences, event.previousIndex, event.currentIndex);
		this.setSortingPreference()
		this.checkInDeviceCheck()
	}
}