import { Component, EventEmitter, OnDestroy, OnInit, Output, Input, ElementRef, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from 'app/shared/services/config.service';
import { AuthService } from 'app/support/services/auth/auth.service';
import { Router } from '@angular/router';

@Component({
    selector   : 'fuse-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls  : ['./search-bar.component.scss']
})
export class FuseSearchBarComponent implements OnInit, OnDestroy
{
    collapsed: boolean;
    fuseConfig: any;
    SearchList: Array<any> = []

    @ViewChild('globalSearch', { static: false, }) globalSearch: ElementRef<HTMLInputElement>;


    @Output()
    searchValue: EventEmitter<any> = new EventEmitter();

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private authService: AuthService,
        private router:Router
    )
    {
        // Set the defaults
        this.collapsed = true;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (config) => {
                    this.fuseConfig = config;
                }
            );
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Collapse
     */
    collapse(): void
    {
        this.collapsed = true;
        this.globalSearch.nativeElement.value='';
        this.SearchList=[]
    }

    /**
     * Expand
     */
    expand(): void
    {
        this.collapsed = false;
    }

    /**
     * Search
     *
     * @param event
     */
    search(event): void
    {
        let value = event.target.value;
    
        if(value && value!='') {
            this.authService.getSearchList(value).subscribe(data => {
                this.SearchList = data['data']
            })
        } else {
            this.SearchList =[]
        }
    }

    viewScreen(options, data) {
        switch(options) {
            case 'Patients':
                this.router.navigate(['patients/view/', data['id']])
                break;
            case 'Clinics':
                this.router.navigate(['clinics/view/', data['id']])
                break;
            case 'Users':
                this.router.navigate(['users/view/', data['id']])
                break;
            case 'Insurers':
                this.router.navigate(['insurers/view/', data['id']])
                break;
            case 'Agents':
                this.router.navigate(['agency/view/', data['id']])
                break;
            
        }
    }

}
