import { Injectable } from '@angular/core';
import { getToken } from '../app-config';
import { InterfaceService } from './auth/interface.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DataImportService extends InterfaceService {

  constructor(
    private router: Router
  ) { 
    super()
  }

  public uploadData(filedata: File, selected0ption, flag) {
   let base_path = window.location.origin + this.router.createUrlTree([`/set-password`]).toString();
   let view_link = window.location.origin + this.router.createUrlTree([`users/view/`], {queryParams: { view_link: "user"}}).toString();
    const url = this.getApiUrl(`upload-data/`);
    return new Promise((resolve, reject) => {
        const formData: any = new FormData();
        const xhr = new XMLHttpRequest();
        if (filedata != undefined) {
            formData.append('file', filedata, filedata.name);
            formData.append('selectedOption', selected0ption);
            formData.append('flag', flag);
            formData.append('basePath', base_path);
            formData.append('view_link', view_link)
        }
        xhr.open('POST', url, true);
        xhr.setRequestHeader('Authorization', `JWT ${getToken()}`);
        xhr.send(formData);
        xhr.onreadystatechange = function () {
            if (xhr.readyState == XMLHttpRequest.DONE) {
                resolve(JSON.parse(xhr.responseText));
            }
        };
    }).catch(Error => Error)
}
}
