import { Injectable } from '@angular/core';
import { InterfaceService } from './auth/interface.service';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Subject, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PatientService extends InterfaceService {

    filterQuery: Array<any> = [];
    isSearchClicked: boolean = false;

    constructor(private http: HttpClient) {
        super()
    }
    public rowData: any;

    public rowEvent = new Subject<any>();
    rowData$ = this.rowEvent.asObservable()

    private patientRespondent = new BehaviorSubject(null);
    patientRespondent$ = this.patientRespondent.asObservable()

    private clinicData = new BehaviorSubject<string>('');
    clinicData$ = this.clinicData.asObservable();

    public refreshRows = new Subject<any>();
    newRowData$ = this.refreshRows.asObservable()

    public ClinicChange = new BehaviorSubject<any>('');
    clinicChange$ = this.ClinicChange.asObservable()

    public patientQuery = new BehaviorSubject<any>('');
    patientQueryData$ = this.patientQuery.asObservable()

    private view1 = new BehaviorSubject<string>('GroupDelete');
    screenview1$ = this.view1.asObservable();

    private view = new BehaviorSubject<string>('Patients');
    screenview$ = this.view.asObservable()

    public filterEvent = new BehaviorSubject<any>('');
    FilterEvent$ = this.filterEvent.asObservable()

    public checkQuery = new BehaviorSubject<any>('');
    checkQuery$ = this.checkQuery.asObservable();

    public filtergroupEvent = new BehaviorSubject<any>('');
    FilterGroupEvent$ = this.filtergroupEvent.asObservable()

    public selectAllRows = new Subject<any>();
    selectAll$ = this.selectAllRows.asObservable()

    public changeCheckBox = new Subject<any>();
    checkBox$ = this.changeCheckBox.asObservable()

    public patientGroupButton = new BehaviorSubject<any>(false);
    patientGroupButton$ = this.patientGroupButton.asObservable()

    public uncheckList = new Subject<any>();
    uncheckList$ = this.uncheckList.asObservable()

    public searchClicked = new Subject<any>();
    searchClicked$ = this.searchClicked.asObservable()

    public resetSelectall = new Subject<any>();
    resetSelectall$ = this.resetSelectall.asObservable()

    public clearPicker = new Subject<any>();
    clearPicker$ = this.clearPicker.asObservable()

    public GroupSearch;
    public checkAllValue;
    public selectedData;

    clinicDataTransfer(data) {
        this.clinicData.next(data)
    }

    patientGroupvalidate(data) {
        this.patientGroupButton.next(data)
    }

    ClinicIdTransfer() {
        return this.clinicData
    }

    refreshRowData(data) {
        this.refreshRows.next(data);
    }

    selectRowAllData(data) {
        this.selectedData = data
        this.selectAllRows.next(data);
    }

    getSelectedRowdata(){
        return this.selectedData
    }

    changeCheckBoxText(data) {
        this.changeCheckBox.next(data)
    }

    ClinicSelectionChange(data) {
        this.ClinicChange.next(data)
    }

    assignPatientsQuery(data) {
        this.patientQuery.next(data);
    }

    resetSelectCheckbox(data) {
        this.resetSelectall.next(data)
    }

    clearYearPicker(data) {
        this.clearPicker.next(data)
    }

    DeletePatientorGroup(view) {
        this.view1.next(view);
    }

    PatientViewChange(view) {
        this.view.next(view);
    }

    patientGroupDetails() {
        return this.rowData
    }

    viewEditDataFunction(data) {
        this.rowData = data;
    }

    clearOrFilterPatients(view) {
        this.filterEvent.next(view);
    }

    checkFilterQueryLength(data) {
        this.checkQuery.next(data)
    }

    clearOrFilterPatientGroup(view) {
        this.filtergroupEvent.next(view);
    }

    groupSearch(data) {
        this.GroupSearch = data;
    }
    searchData() {
        return this.GroupSearch
    }

    checkAll(data) {
        this.checkAllValue = data
    }

    getCheckBoxValue() {
        return this.checkAllValue
    }

    assignPatientsDataFunction(data: any) {
        this.filterQuery = data;
        this.rowEvent.next(data);
    }

    setSearchClicked(data: any) {
        this.isSearchClicked = data
        this.searchClicked.next(data)
    }

    clearSearchClicked() {
        this.isSearchClicked = false
    }

    getSearchClicked () {
        return this.isSearchClicked
    }

    getFilterQuery() {
        return this.filterQuery
    }

    filterQueryDataFunction() {
        return of(this.filterQuery)
    }

    emptyFilterQueryDataFunction() {
        this.filterQuery = []
    }

    setUnCheckedData(data: any) {
        this.uncheckList.next(data)
    }

    getUnCheckedData() {
        return this.uncheckList
    }

    newPatientsGetColumns(data) {
        return this.http.post(this.getApiUrl(`get_patient_filter/get-columns/`), { model: data }, this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    groupNameSearch(data) {
        return this.http.post(this.getApiUrl(`patient_group/search-patient/`), data, this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getPatientCreateData() {
        return this.http.get(this.getApiUrl(`patients/get-patient-create-data/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    createPatientGroup(data) {
        return this.http.post(this.getApiUrl(`patient_group/`), data, this.getHttpOptions('json', true)).pipe(
            map(Response => Response),
            catchError(this.handleError)
        );
    }

    updatePatientGroupQuery(data) {
        let groupId = data.group_id
        if (groupId) {
            return this.http.put(this.getApiUrl(`patient_group/${groupId}/`), data, this.getHttpOptions('json', true)).pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
        }
    }

    updatPatientGroup(data) {
        let groupId = data.group_id
        if (groupId) {
            return this.http.put(this.getApiUrl(`patient_group/${groupId}/`), data, this.getHttpOptions('json', true)).pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
        }
    }

    patientGroupDelete(data) {
        let groupId = data.group_id
        if (groupId) {
            return this.http.delete(this.getApiUrl(`patient_group/${groupId}`), this.getHttpOptions('json', true)).pipe(
                map(response => response),
                catchError(this.handleError)
            );
        }
    }

    getPatientsInGroupDetails(data) {
        return this.http.post(this.getApiUrl(`patient_group/patient-from-patient-group/`), data, this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    deletePatientsFromGroup(data) {
        return this.http.post(this.getApiUrl(`patient_group/patient-delete-from-patient-group/`), data, this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getGroupDetails(data) {
        if(data){
            return this.http.post(this.getApiUrl(`patient_group/static-patient-group-list/`), data, this.getHttpOptions('json', true)).pipe(
                map(response => response),
                catchError(this.handleError)
            );
        }
    }

    getClinicianForClinics(clinic_id) {
        return this.http.get(this.getApiUrl(`patients/${clinic_id}/get-clinician/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    createPatient(data) {
        return this.http.post(this.getApiUrl(`patients/`), data, this.getHttpOptions('json', true)).pipe(
            map(Response => Response),
            catchError(this.handleError)
        );
    }

    getPatientData(patientId) {
        return this.http.get(this.getApiUrl(`patients/${patientId}/get-patient-data/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getPatientHistory(patientId, start_date=null, end_date=null) {
        return this.http.get(this.getApiUrl(`patients/${patientId}/get-patient-history/`, { start_date: start_date, end_date: end_date}), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }
    getPatientViewData(patientId) {
        return this.http.get(this.getApiUrl(`patients/${patientId}/get-patient-view-data/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getPatAssmtHistory(patientId, start_date, end_date, assessment, responder, version) {
        console.log(version)
        return this.http.get(this.getApiUrl(`patients/${patientId}/get-patient-assessment-history`, { start_date: start_date, end_date: end_date, assessment: assessment, responder: responder, version: version }), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getPatQuestionHistory(patientId, data) {
        return this.http.get(this.getApiUrl(`patients/${patientId}/get-patient-question-history`, data), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }


    updatePatient(patientId: string, data: any, responder: any, deleted: any) {
        return this.http.put(this.getApiUrl(`patients/${patientId}/`), { update_fields: data, responder: responder, deleted: deleted }, this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    deactivatePatient(patientId: string) {
        return this.http.get(this.getApiUrl(`patients/${patientId}/deactivate-patient/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    activatePatient(patientId: string) {
        return this.http.get(this.getApiUrl(`patients/${patientId}/activate-patient/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getStateInsurers(state) {
        return this.http.get(this.getApiUrl(`patients/get-insurers/`, { 'state': state }), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    public checkEmail(email) {
        let data = { "email": email };
        return this.http.post(this.getApiUrl(`patients/check-email/`), data, this.getHttpOptions('json', true)).pipe(
            map(Response => Response),
            catchError(this.handleError)
        );
    }


    mrnExist(clinic_id: any, value: any) {
        return this.http.get(this.getApiUrl(`patients/${clinic_id}/mrn-exist`, { mrn: value }), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    //Respondent service

    createRespondent(data) {
        return this.http.post(this.getApiUrl(`responder/`), data, this.getHttpOptions('json', true)).pipe(
            map(Response => Response),
            catchError(this.handleError)
        );
    }
    getRespondentList(id?: any, params?: object) {
        return this.http.get(this.getApiUrl(`responder?patient_id=${id}`, params), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    deleteRespondent(respondentId) {
        return this.http.get(this.getApiUrl(`responder/${respondentId}/delete-respondent/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }
    updateRespondent(id, data) {
        return this.http.put(this.getApiUrl(`responder/${id}/`), { update_fields: data }, this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );

    }
    getRespondent(id) {
        return this.http.get(this.getApiUrl(`responder/${id}/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );

    }
    setpatientRespondent(data) {
        this.patientRespondent.next(data)
    }

    getClinicList() {
        return this.http.get(this.getApiUrl(`patients/get-clinic-list/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    autocompletFilterValuesListGet(value){
        return this.http.post(this.getApiUrl(`get_patient_filter/get-autocomplete-values/`), {value}, this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }
}
