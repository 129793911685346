
import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { faCoffee, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FormGroup, FormControl } from '@angular/forms';
import { MatInputModule } from '@angular/material';
import { Observable } from 'rxjs';
import { map, startWith, debounceTime, distinctUntilChanged, switchMap, filter } from 'rxjs/operators';
import { ReportsService } from 'app/support/services/reports.service';
import * as moment from 'moment';
import { PatientService } from 'app/support/services/patient.service';

@Component({
  selector: 'app-field-input1',
  templateUrl: './fieldinput.component.html',
  styleUrls: ['./fieldinput.component.scss']
})
export class FieldinputComponent implements OnInit {




  @Input() keyIndex: number = -1;
  @Input() fieldRule: any = {};
  @Input() clinic: any;
  @Input() masterFields: any;
  @Input('ModelType')
  set ModelType(ModelType: String) {
    this.Model = ModelType;
  }
  @Input('GroupId')
  set GroupId(GroupId: Number) {
    this.groupId = GroupId;

  }
  @Output() removeRuleCallback: EventEmitter<any> = new EventEmitter();
  @Output() detectChanges: EventEmitter<any> = new EventEmitter();

  textAutocomplete = new FormControl();
  options: string[] = [];
  filterField: string;
  filteredOptions: Observable<string[]>;
  Model: any;
  groupId: Number;

  type: string = 'text';
  qualifiers: Object = {};
  faTimesCircle = faTimesCircle;
  allowInput: boolean = true;
  //this will assign When type is dropdown
  dropdown_value: Array<string>

  public defaultOperatorMap: { [key: string]: Object } = {
    text: {
      "Equals": 'equals', "Not Equals": 'not_equals', "Starts With": 'startswith',
      "Ends With": 'endswith', "Contains": 'contains', "Doesn't Contains": "doesnot_contains",
      "Empty": 'empty', "Not Empty": 'not_empty'
    },
    number: { "=": 'equals', "!=": 'not_equals', ">": 'gt', ">=": 'gte', "<": 'lt', "<=": 'lte' },
    // date: {
    //   "Date Range": 'date_range', 'Yesterday': "yesterday", "Today": 'today', "Tommorow": 'tomorrow', 
    //   "Last Week": 'previous_week', 'This Week': 'this_week', "Next Week": 'next_week', 
    //   "Last Month": 'last_month', "This Month": 'this_month', "Next Month": 'next_month', 
    //   "Last 3 Months": 'last_3_month', "Next 3 Months": 'next_3_month',
    // },

    date: {
      "Date Range": 'date_range', 'Yesterday': "yesterday", "Today": 'today', "Tomorrow": 'tomorrow',
      "Last Week": 'previous_week', 'This Week': 'this_week', "Next Week": 'next_week',
      "Last Month": 'last_month', "This Month": 'this_month', "Next Month": 'next_month',
      "Past 30 Days": 'last_30_days', "Next 30 Days": 'next_30_days',
      "Past 90 Days": 'last_90_days', "Next 90 Days": 'next_90_days',
    },
    boolean: { 'Yes': 'true', 'No': 'false' },
    dropdown: { "Equals": 'equals', "Not Equals": 'not_equals' },
    dictionary: { "Equals": 'equals', "Not Equals": 'not_equals' },
    single_qualifier: { "Equals": 'equals' },
    phone_number: { "Equals": 'equals', "Not Equals": 'not_equals' }

  };

  public allowInputEntryTypes: Array<string> = ["equals", "not equals", "startswith", "endswith",
    "contains", "gt", "gte", "lt", "lte",
    "doesnot_contains", "not_equals", "date_range"]

  constructor(private reportService: ReportsService,
    private patientService: PatientService) {
    this.filteredOptions = this.textAutocomplete.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(value => this._filter(value))
    );
  }

  ngOnInit(): void {
    this.type = this.fieldRule['type'];
    this.qualifiers = this.defaultOperatorMap[this.type];

    //When type is dropdown,then values will be asssign here
    if (this.type === "dropdown" || "dictionary" || "single_qualifier") {
      this.dropdown_value = this.fieldRule.dropdown_value
    }

    // this condition working when we choose boolean field, That time qualifier recieve
    // value as 'true'.So boolean field we have assign value manualy. Other type this below
    // condition no need.
    if (this.fieldRule.qualifier == 'true') {
      this.fieldRule.value = true;
    }

    //return values from saved reports, check the qualifier to visible or hide the input
    if (!this.allowInputEntryTypes.includes(this.fieldRule.qualifier)) {
      this.allowInput = false;
    }
  }

  private _filter(value: string): Observable<any[]> {

    if (value) {
      if (this.Model == 'Patients') {
        let autocomplete_config = {
          value: value,
          filter_field: this.fieldRule.col_name,
          clinic: this.clinic.map(clinic => clinic.clinic_id),
          model: this.Model
        }
        return this.patientService.autocompletFilterValuesListGet(autocomplete_config).pipe(
          map(response => { return response["data"] })
        )
      }
      if (this.Model == 'PatientGroup') {
        let autocomplete_config = {
          value:value,
          filter_field:this.fieldRule.col_name,
          clinic: this.clinic.map(clinic => clinic.clinic_id),
          model: this.Model,
          group_id: this.groupId
        }
        return this.patientService.autocompletFilterValuesListGet(autocomplete_config).pipe(
          map(response =>{return response["data"]})
        )
      }

    }
  }

  removeRule() {

    this.removeRuleCallback.emit(this.keyIndex);


  }


  fromDateSet(event) {
    if (!this.fieldRule.value) {
      this.fieldRule.value = []
    }
    this.fieldRule.value[0] = event.target.value
    if (moment(event.target.value).isAfter(moment(this.fieldRule.value[1]))) {
      this.fieldRule.value[1] = "";
    }
  }

  toDateSet(event) {
    if (!this.fieldRule.value) {
      this.fieldRule.value = []
    }
    this.fieldRule.value[1] = event.target.value
  }

  changeDetection() {
    this.detectChanges.emit(false);
  }
  value
  changeFn() {
    this.changeDetection();
    if (this.allowInputEntryTypes.includes(this.fieldRule.qualifier)) {
      this.allowInput = true;
    }
    else {
      this.allowInput = false;
      if (this.fieldRule.qualifier === "true") {
        this.fieldRule.value = true
      }
      else if (this.fieldRule.qualifier === "false") {
        this.fieldRule.value = false
      }
      else {
        this.fieldRule.value = ""
      }
    }
  }
  isNumber(evt) {
    evt = (evt) ? evt : window.event;
    let keys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab']
    var charCode = evt.keyCode;
    if (((charCode >= 48 && charCode <= 57 || charCode >= 96 && charCode <= 105) && !evt.shiftKey) ||
      keys.includes(evt.key) || (evt.ctrlKey && evt.code == 'KeyA')) {
      return true;
    }
    return false;
  }

}
