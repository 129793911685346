import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { GlobalService } from '../services/auth/global.service';
import { User } from '../classes';
import { FuseNavigationService } from 'app/shared/components/navigation/navigation.service';
import { PhoenixWebsocketService } from '../services/phoenix-websocket.service';


@Injectable({
    providedIn: 'root'
})
export class Permissions {
    user: User

    // view accesses
    // static dashboard: Array<String> = ['view#MhtDashboard', 'view#ClinicDashboard']

    constructor(private router: Router,
        private globalService: GlobalService,
        private navigation: FuseNavigationService,
        private phoenixWebSocket: PhoenixWebsocketService) {
        this.globalService.user$.subscribe(user => {
            this.user = user;
        })
    }

    setNavigationMenusAccesses(navigation, userData): any {
        var navigations: any = navigation
        if (userData['accesses']) {
            let access = userData['accesses'];
            navigations.map(function (nav) {
                if (nav.id.includes('dashboard')) {
                    if (access.includes('view#MhtDashboard') || access.includes('view#ClinicDashboard')) {
                        nav['hidden'] = false;
                    }
                    else {
                        nav['hidden'] = true;
                    }
                }

                if (nav.id.includes('clinics')) {
                    if (access.includes('view#Clinic')) {
                        nav['hidden'] = false
                    }
                    else {
                        nav['hidden'] = true
                    }
                }

                if (nav.id.includes('agencies')) {
                    if (access.includes('view#Agencies')) {
                        nav['hidden'] = false
                    }
                    else {
                        nav['hidden'] = true
                    }
                }

                if (nav.id.includes('assessments')) {
                    if (access.includes('view#Assessments')) {
                        nav['hidden'] = false
                    }
                    else {
                        nav['hidden'] = true
                    }
                }

                if (nav.id.includes('assessmentqueue')) {
                    if (access.includes('view#Assessmentqueue')) {
                        nav['hidden'] = false
                    }
                    else {
                        nav['hidden'] = true
                    }
                }

                if (nav.id.includes('users')) {
                    if (access.includes('view#ClinicUsers') || access.includes('view#MhtUsers')) {
                        nav['hidden'] = false
                    }
                    else {
                        nav['hidden'] = true
                    }
                }

                if (nav.id.includes('insurers')) {
                    if (access.includes('view#Insurers')) {
                        nav['hidden'] = false
                    }
                    else {
                        nav['hidden'] = true
                    }
                }

                if (nav.id.includes('runassessment')) {
                    if (access.includes('change#RunAssessment')) {
                        nav['hidden'] = false
                    }
                    else {
                        nav['hidden'] = true
                    }
                }

                if (nav.id.includes('assessmentview')) {
                    if (access.includes('view#AssessmentDetailView')) {
                        nav['hidden'] = false
                    }
                    else {
                        nav['hidden'] = true
                    }
                }

                if (nav.id.includes('activateassessments')) {
                    if (access.includes('assign#ActivateAssessment')) {
                        nav['hidden'] = false
                    }
                    else {
                        nav['hidden'] = true
                    }
                }

                if (nav.id.includes('clinicsettings')) {
                    if (userData['user_type'] && userData['user_type']['name'] == 'Clinic' && (userData['system_admin'] || access.includes('enable#DeviceSettings'))) {
                        nav['hidden'] = false
                    }
                    else {
                        nav['hidden'] = true
                    }
                }

                if (nav.id.includes('importtool')) {
                    if (access.includes('add#ImportTool')) {
                        nav['hidden'] = false
                    }
                    else {
                        nav['hidden'] = true
                    }
                }

                // if (nav.url == '/results') {
                //     if (access.includes('')) {
                //         nav['hidden'] = false
                //     }
                //     else
                //     {
                //         nav['hidden'] = true
                //     }
                // }

                if (nav.id.includes('reports')) {
                    if (access.includes('view#MhtSummaryReports') ||
                        access.includes('view#MhtDetailReports') ||
                        access.includes('view#ClinicSummaryReports') ||
                        access.includes('view#ClinicDetailReports')
                    ) {
                        nav['hidden'] = false
                    }
                    else {
                        nav['hidden'] = true
                    }
                }

                if (nav.id.includes('patients')) {
                    if (access.includes('view#Patients')) {
                        nav['hidden'] = false
                    }
                    else {
                        nav['hidden'] = true
                    }
                }

                if (nav.id.includes('devicelaunch')) {
                    if (access.includes('view#scheduledPatientAssessmentRun')) {
                        nav['hidden'] = false
                    }
                    else {
                        nav['hidden'] = true
                    }
                }

                if (nav.id.includes('admin') ) {
                    if (userData['user_type'] && userData['user_type']['name'] == 'MHT' && (userData['is_superuser'])) {
                        nav['hidden'] = false;
                        nav.children.forEach(children => {
                            if (children.id == 'systemsettings') {
                                access.includes('change#MHTSystemSettings') && userData['is_superuser'] ? children['hidden'] = false : children['hidden'] = true;
                            }
                            else if(children.id == 'liability') {
                                access.includes('add#Liability') && userData['is_superuser'] ? children['hidden'] = false : children['hidden'] = true;
                            }
                            else if(children.id == 'audits') {
                                access.includes('view#Audits') && userData['is_superuser'] ? children['hidden'] = false : children['hidden'] = true;
                            }
                            else if(children.id == 'transferlogs') {
                                access.includes('view#Audits') && userData['is_superuser'] ? children['hidden'] = false : children['hidden'] = true;
                            }
                            else if(children.id == 'email-template'){
                                access.includes('add#EmailTemplate') && userData['is_superuser'] ? children['hidden'] = false : children['hidden'] = true;
                            }
                            else if(children.id == 'registerdevice'){
                                access.includes('view#registerdevice') && userData['is_superuser'] ? children['hidden'] = false : children['hidden'] = true;
                            }else if(children.id == 'custom-groups') {
                                access.includes('add#ListManagement') && userData['is_superuser'] ? children['hidden'] = false : children['hidden'] = true;
                            } else if (children.id == 'api-users-generate') {
                                access.includes('add#ApiUserGenerate') && userData['is_superuser'] ? children['hidden'] = false : children['hidden'] = true;
                            }
                        });
                    }
                    else {
                        nav['hidden'] = true;
                    }
                }

                if (nav.id.includes('smarttest')) {
                    if (access.includes('view#DynamicSmartTest')) {
                        nav['hidden'] = false
                    }
                    else {
                        nav['hidden'] = true
                    }
                }

                // if(nav.id.includes('custom-groups')){
                //     if(access.includes('add#ListManagement')){
                //         nav['hidden'] = false
                //     }else{
                //         nav['hidden'] = true
                //     }
                // }

            })
        }
        return navigations
    }

    navigateToFirstPermissionList() {
        // this.phoenixWebSocket.initializePhoenixWebSocket();
        var navigation = this.navigation.getCurrentNavigation();
        var access = this.user['accesses']
        var permissonList = []
        this.setNavigationMenusAccesses(navigation, this.user).map(function (permission) {
            if (permission.hasOwnProperty("hidden") && !permission.hidden) {
                permissonList.push(permission);
            }
        })
        if(this.user.roles.includes('Clinician') && this.user.roles.length == 1){
            this.router.navigate(['assessments/assessment-queue'])    
        }
        else{
            this.router.navigate([permissonList[0].url])
        }
    }
}