import { Inject, Injectable, forwardRef } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { timer, BehaviorSubject, Observable } from 'rxjs';
import { catchError, tap, first, map } from 'rxjs/operators';


// -- Configurations --
import { isTokenExpired, setToken, getTokenExpiration, GetApiurl } from '../../app-config';

// -- Services --
import { GlobalService } from './global.service';
import { InterfaceService } from './interface.service';

// -- Classes --
import { User } from '../../classes';
import { Permissions } from 'app/support/permissions/permissions';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { CommonService } from '../common.service';
import { AssessmentsService } from '../assessments.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthService extends InterfaceService {

    user: User;
    userPermissions: Array<string> = [];

    // used for transferring messages
    socketSubject = new BehaviorSubject<any>(null);
    message$: Observable<any> = this.socketSubject.asObservable();

    // used to get the websocket connection
    socketConnectionSubject: BehaviorSubject<any> = new BehaviorSubject(null);
    ws$: Observable<ReconnectingWebSocket> = this.socketConnectionSubject.asObservable();

    constructor(
        private http: HttpClient,
        private router: Router,
        private permissions: Permissions,
        @Inject(forwardRef(() => GlobalService)) private globals,
        private commonService: CommonService,
        private asmService: AssessmentsService,
        private cookieService: CookieService
    ) {
        super();
        this.subscriptions['user'] = this.globals.user$.subscribe(u => this.user = u);
        this.subscriptions['userPermissions'] = this.globals.userPermissions$.subscribe(ps => this.userPermissions = ps);
    }

    // Checks is authenticated
    public authenticated() {
        // Check if there's an unexpired JWT
        return !isTokenExpired();
    }

    // Checks is user has roles
    public permitted(roles: Array<string>, userTypes?: Array<string>) {
        // Checking for user type permission
        if (userTypes && (!(userTypes instanceof Array) || (userTypes.indexOf(this.user.user_type['name']) < 0))) {
            return false;
        }

        if (!(this.user instanceof Object) || !(this.userPermissions instanceof Array)) {
            return false;
        } else {
            if (intersect(this.userPermissions, roles).length != 0) {
                return true;
            } else {
                return false;
            }
        }


        function intersect(a, b) {
            let t;
            if (b.length > a.length) {
                t = b, b = a, a = t; // indexOf to loop over shorter
            }
            return a.filter(e => b.indexOf(e) > -1).filter((e, i, c) => c.indexOf(e) === i); // extra step to remove duplicates
        }
    }

    // Login action
    public login(credentials: any) {

        // const credentials = `{"email":"${email}","password":"${password}"}`;

        return this.http.post(this.getApiUrl('api-token-auth/'), credentials, this.getHttpOptions('json', false))
            .pipe(
                tap(response => this.handleAuthResponse(response)),
                catchError(this.handleError)
            );
    }

    // Handle response and schedule token auto refresh
    public handleAuthResponse(response: any): void {

        if ('token' in response) {
            setToken(response['token']);
            this.cookieService.set( 'session', 'verification', 0 , '/');
            this.globals.setUser(response['user']);
            if (!response['user']['device']) {
                const now = new Date().valueOf();
                const exp = <Date>getTokenExpiration();
                const delay = exp.valueOf() - now;
                const _timer = timer(delay - 60);

                _timer.subscribe(() => {
                    this.refreshToken().subscribe();
                });
            }
        }
    }

    // Refreshing token
    public refreshToken() {
        // console.log('Token expired refreshing : ', new Date());
        return this.http.get(this.getApiUrl('api-token-refresh/'), this.getHttpOptions('json', false))
            .pipe(
                tap(response => this.handleAuthResponse(response[0])),
                catchError(error => {
                    error = this.handleError(error);
                    this.router.navigate(['/login']);
                    return error;
                })
            );
    }

    // Redirecting to respective homepages for the recpective user types
    redirectToHome() {
        // this.router.navigate(['/dashboard']);
        this.permissions.navigateToFirstPermissionList()
    }

    // Logout action
    public logout(nav: boolean = true, device: boolean = false) {
        // updating last login
        // this.updateLastLogin().subscribe(data => {
        // },
        // error => {
        //     console.log('error in updating last login date time')
        // })

        // Clearing local storage

        // Redirecting to login homepages
        if (device) {
            // Clearing local storage
            localStorage.removeItem("token");
            localStorage.removeItem("firebase:host:phoenix-d7e6a.firebaseio.com");

            // device logout navigate to device login page
            if (device) this.router.navigate(['/device']);
        }
        else {
            if (this.authenticated()) {
                /* removes the user tracking on logging out (only for user login) */
                this.unsetUserTrack().subscribe(response => {
    
                    // Clearing local storage
                    localStorage.removeItem("token");
                    localStorage.removeItem("firebase:host:phoenix-d7e6a.firebaseio.com");
                    this.cookieService.delete('session');
                    // this.logoutSubject.next(true)
                    
                    // clear datatable cache
                    this.commonService.clearDatatableCache();
                    // this.asmService.clearQueueCache();

                    // Redirecting to login homepages
                    // if (nav) this.router.navigate(['/login']);
                    if (nav)
                        this.router.navigate(['/login']);
                    this.ws$.pipe(first()).subscribe(ws => {
                        if (ws) {
                            ws.send(JSON.stringify({ message: 'loggedoff' }));
                        }
                    })
                });
            }
        }

    }


    unsetUserTrack() {
        return this.http.get(this.getApiUrl('users/unset-user-track/'), this.getHttpOptions()).pipe(
            map(response => response),
            catchError(error => {
                this.handleError(error);
                return error;
            })
        );
    }

    changePasswordVerify(value) {
        const url: string = GetApiurl('users/change-password-verify/');
        return this.http.post(url, value, this.getHttpOptions('json', true))
            .pipe(
                // this.handleAuthResponse(response[0])
                tap(response => response),
                catchError(error => {
                    error = this.handleError(error);
                    return error;
                })
            );

    }

    changePassword(value) {
        const url: string = GetApiurl('users/change-passwords/');
        return this.http.post(url, value, this.getHttpOptions('json', true))
            .pipe(
                // this.handleAuthResponse(response[0])
                tap(response => response),
                catchError(error => {
                    error = this.handleError(error);
                    return error;
                })
            );

    }

    resendOtp(data) {
        const url: string = GetApiurl('users/resend-otp/');
        return this.http.post(url, data, this.getHttpOptions('json', true))
            .pipe(
                tap(response => response),
                catchError(error => {
                    error = this.handleError(error);
                    return error;
                })
            );
    }

    // API call to validate user details and generate password reset link
    generatePasswordResetLink(details: string) {
        const url: string = GetApiurl('api-password-reset/');
        const data = {
            username: details['username'],
            email: details['email'],
            base_path: window.location.origin + this.router.createUrlTree(['/password/reset/confirm/']).toString()
        };

        return this.http.post(url, data, this.getHttpOptions('json', true))
            .pipe(
                tap(response => response),
                catchError(error => {
                    error = this.handleError(error);
                    return error;
                })
            );
    }

    // API call to verify password reset key
    verifyPasswordResetToken(token: string) {

        const data = {
            'token': token,
        };

        return this.http
            .post(this.getApiUrl(`api_password_reset_verify/`), JSON.stringify(data), this.getHttpOptions())
            .pipe(catchError(this.handleError));
    }

    // API call to reset password
    resetPasswordConfirm(token: string, password: string) {
        const data = {
            'new_password': password,
            'token': token
        };

        return this.http
            .post(this.getApiUrl(`api_password_reset_confirm/`), JSON.stringify(data), this.getHttpOptions())
            .pipe(catchError(this.handleError));
    }
    sendOTP(value) {
        const url: string = GetApiurl('send_otp/phone-verification/');
        return this.http.post(url, value, this.getHttpOptions('json', true))
            .pipe(
                tap(response => response),
                catchError(error => {
                    error = this.handleError(error);
                    return error;
                })
            );
    }
    verifyOTP(value) {
        const url: string = GetApiurl('send_otp/verify/');
        return this.http.post(url, value, this.getHttpOptions('json', true))
            .pipe(
                tap(response => response),
                catchError(error => {
                    error = this.handleError(error);
                    return error;
                })
            );
    }

    vendorRegistration(value) {
        const url: string = GetApiurl('requester/service-create/');
        return this.http.post(url, value, this.getHttpOptions('json', true))
            .pipe(
                tap(response => response),
                catchError(error => {
                    error = this.handleError(error);
                    return error;
                })
            );

    }

    getLastLoginDateTime() {

        return this.http.get(this.getApiUrl(`users/get-last-login/`), this.getHttpOptions('json', true)).pipe(
            tap(response => response),
            catchError(this.handleError)
        );

    }

    readNotification(data) {
        return this.http.post(this.getApiUrl(`users/read-notification/`), data, this.getHttpOptions('json', true)).pipe(
            tap(response => response),
            catchError(this.handleError)
        );

    }

    hideNotification(data) {
        return this.http.post(this.getApiUrl(`users/hide-notification/`), data, this.getHttpOptions('json', true)).pipe(
            tap(response => response),
            catchError(this.handleError)
        );

    }
    

    acceptNewDevice(data) {
        return this.http.post(this.getApiUrl('users/device-accept/'), data, this.getHttpOptions('json', true))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            )
    }

    deleteExistingDeviceNonce(data) {
        return this.http.post(this.getApiUrl('users/delete-trusted-device/'), data, this.getHttpOptions('json', true))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            )
    }


    updateLastLogin() {
        let data = {
            nonce: this.globals.getOnlyNonce()
        }
        return this.http.post(this.getApiUrl('users/update-last-login/'), data, this.getHttpOptions('json', true))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            )
    }

    public getSearchList(search_text) {
        return this.http.get(this.getApiUrl(`users/get-search-list/`, { 'search_text': search_text }), this.getHttpOptions('json', true))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

}

