
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { InterfaceService } from './auth/interface.service';

@Injectable({
    providedIn: 'root'
})
export class ClinicsService extends InterfaceService {
    constructor(private http: HttpClient, private router: Router) {
        super()
    }

    public createClinic(data: any) {
        data['view_link'] = window.location.origin + this.router.createUrlTree([`clinics/view/`],{queryParams: { view_link: "clinic"}}).toString();
        return this.http.post(this.getApiUrl(`clinics/`), data, this.getHttpOptions('json', true)).pipe(
            map(Response => Response),
            catchError(this.handleError)
        );
    }
    public nameExistCheck(data: any){
        return this.http.post(this.getApiUrl(`clinics/location-name-exist/`), data, this.getHttpOptions('json', true)).pipe(
          map(Response => Response),
          catchError(err => this.handleError(err))
        );
      }

    getClinicsList() {
        return this.http.get(this.getApiUrl('clinics/'), this.getHttpOptions('json', true)).pipe(
            map(Response => Response),
            catchError(this.handleError)
        );
    }

    public checkRule(data:any){
        return this.http.post(this.getApiUrl(`clinics/location-rule-exist/`),data, this.getHttpOptions('json', true)).pipe(
            map(Response => Response),
            catchError(err => this.handleError(err))
          );
    }

    getClinic(clinic_id, region = false) {
        return this.http.get(this.getApiUrl(`clinics/${ clinic_id }/get-clinic`, { region: region }), this.getHttpOptions('json', true)).pipe(
            map(Response => Response),
            catchError(this.handleError)
        );
    }

    getClinicItems() {
        return this.http.get(this.getApiUrl(`clinics/get-clinic-items`), this.getHttpOptions('json', true)).pipe(
            map(Response => Response),
            catchError(this.handleError)
        );
    }

    getParentClinics(clinicTypeOrder: any, clinicId: any) {
        return this.http.get(this.getApiUrl(`clinics/${clinicTypeOrder}/get-parent-clinics`, { clinic_id: clinicId }), this.getHttpOptions('json', true)).pipe(
            map(Response => Response),
            catchError(this.handleError)
        );
    }

    checkClinicDirector(clinicId: any) {
        return this.http.get(this.getApiUrl(`clinics/${clinicId}/check-clinic-directors`), this.getHttpOptions('json', true)).pipe(
            map(Response => Response),
            catchError(this.handleError)
        );
    }

    getSalesRep(agencyId: any) {
        return this.http.get(this.getApiUrl(`clinics/${agencyId}/get-sales-rep`), this.getHttpOptions('json', true)).pipe(
            map(Response => Response),
            catchError(this.handleError)
        );
    }

    changeClinicStatus(clinicId: any) {
        return this.http.get(this.getApiUrl(`clinics/${clinicId}/change-clinic-status/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getAssociatedClinics() {
        return this.http.get(this.getApiUrl(`clinics/get-associated-clinics/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    setClinicTwofactor(data: any) {
        return this.http.post(this.getApiUrl(`clinics/set-clinic-two-factor/`), data, this.getHttpOptions('json', true)).pipe(
            map(Response => Response),
            catchError(this.handleError)
        );
    }

    updateClinic(clinicId: string, data: any) {
        return this.http.put(this.getApiUrl(`clinics/${clinicId}/`), data, this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    updateContact(clinicId: string, data: any) {
        return this.http.put(this.getApiUrl(`clinics/${clinicId}/update-contact/`), { update_fields: data }, this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getClinicAssessments(clinicId: any) {
        return this.http.get(this.getApiUrl(`clinics/${clinicId}/get-clinic-assessments/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    setClinicAssessments(clinicId: any, data: any) {
        return this.http.post(this.getApiUrl(`clinics/${clinicId}/set-clinic-assessments/`), data, this.getHttpOptions('json', true)).pipe(
            map(Response => Response),
            catchError(this.handleError)
        );
    }

    updateClinicAssessments(clinicId: any, data: any) {
        return this.http.put(this.getApiUrl(`clinics/${clinicId}/update-clinic-assessments/`), data, this.getHttpOptions('json', true)).pipe(
            map(Response => Response),
            catchError(this.handleError)
        );
    }

    updateClinicNotification(clinicId: any, preference: any) {
        return this.http.post(this.getApiUrl(`clinics/${clinicId}/update-clinic-notifications/`), { preference : preference }, this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getClinicDirectors(clinicId){
        return this.http.get(this.getApiUrl(`clinics/${clinicId}/get-clinic-directors/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    mapClinicDirector(user_id, clinicId){
        return this.http.get(this.getApiUrl(`clinics/${clinicId}/map-clinic-director`, { user_id: user_id }), this.getHttpOptions('json', true)).pipe(
            map(Response => Response),
            catchError(this.handleError)
        );
    }


    getClinicCreated(clinicId: any) {
        return this.http.get(this.getApiUrl(`clinics/${clinicId}/get-clinic-created/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getAssessmentUsage(clinicId: any, data: any) {
        return this.http.post(this.getApiUrl(`clinics/${clinicId}/get-assessment-usage/`), data, this.getHttpOptions('json', true)).pipe(
            map(Response => Response),
            catchError(this.handleError)
        );
    }

    clinicMobileUsers(clinicId: any) {
        return this.http.get(this.getApiUrl(`clinics/${clinicId}/clinic-mobile-users/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    clinicExist(value: any) {
        return this.http.get(this.getApiUrl(`clinics/clinic-exist`, { clinic: value}), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    clinicEmailVerify(clinicId: any) {
        return this.http.get(this.getApiUrl(`clinics/${clinicId}/check-clinic-email-verified`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

}
