import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { SnackbarCongiration } from 'app/shared/components/snack-bar/SnackbarConfiguration';
import { SmartTestService } from 'app/support/services/smart-test.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-edit-smart-test',
    templateUrl: './edit-smart-test.component.html',
    styles: ['./edit-smart-test.component.scss'],
})
export class EditSmartTestComponent {

    smartForm: FormGroup;
    dataLoad: boolean = false;
    /**
     * Constructor
     */
    constructor(
        private fb: FormBuilder,
        private router: Router,
        private snackbar: SnackbarCongiration,
        private smartTestService: SmartTestService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<EditSmartTestComponent>,

    ) {
        this.smartForm = this.fb.group({
            name: [this.data['name'] ? this.data['name'] : '',[Validators.maxLength(45)]],
            description: [this.data['description'] ? this.data['description'] : '']
        })
    }

    ngOnInit() {

    }

    updateSmartTest() {
        this.dataLoad = true;
        let smartTestData = this.smartForm.value;
        smartTestData['id'] = this.data['id'];
        this.smartTestService.updateSmartTest(smartTestData).subscribe(respone => {
            this.reloadCurrentPage();
            this.snackbar.triggerSnackBar({
                message: respone['message'],
                type: 'success'
            });
            this.dialogRef.close()
            this.dataLoad = false;
        })

    }

    reloadCurrentPage() {
        this.smartTestService.refreshRowData('refresh');
    }

    cancelSmartTest() {
        this.dialogRef.close()
    }

    nameExist(): Promise<any> {
        return new Promise((resolve) => {
          let value = this.smartForm.get('name').value;
          let name = this.smartForm.controls['name'];
          if(value == ''){
            name.setErrors({'required' : true});
          }else if (value != this.data['name']){
            this.smartTestService.nameExistCheck({"name" : value.trim()}).subscribe( res => {
              if(res['status'] == 'warning'){
                resolve(true);
                name.setErrors({'Exist' : true });
                name.markAsTouched();
              }else{
                resolve(false);
                name.setErrors(null);
                name.updateValueAndValidity();
              }
            })
          }
          
        })
        
      }

    updateConfigureSmartTest() {
        this.dataLoad = true;
        let smartTestData = this.smartForm.value;
        smartTestData['id'] = this.data['id'];
        this.smartTestService.updateSmartTest(smartTestData).subscribe(response => {
            // this.snackbar.triggerSnackBar({
            //     message: response['message'],
            //     type: 'success'
            // });
            this.dialogRef.close(response)
            this.dataLoad = false;
        })
    }

  descCheck(evt) {
    evt = (evt) ? evt : window.event;
    const pattern = /[^A-Za-z0-9]/;
    let eventKey = evt.key
    var charCode = evt.keyCode;
    let keys = [191, 219, 220, 221, 222]
    if (keys.includes(charCode)) {
      return false;
    } else if (charCode !=32 && pattern.test(eventKey)) {
      evt.preventDefault();
    } else {
      return true;
    }
  }
}