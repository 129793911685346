import { Component, OnInit, Output, EventEmitter, Input, ElementRef, Optional, Self } from '@angular/core';
import { MatFormFieldControl } from '@angular/material';
import { ControlValueAccessor, FormGroup, FormBuilder, NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { FocusMonitor } from '@angular/cdk/a11y';
import { ValidationService } from 'app/shared/services/validation.service';

@Component({
  selector: 'height-input',
  templateUrl: './height-input.component.html',
  styleUrls: ['./height-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: HeightInputComponent }],
})
export class HeightInputComponent implements OnInit, MatFormFieldControl<Height>, ControlValueAccessor {

  parts: FormGroup;
  stateChanges = new Subject<void>();
  static nextId = 0;
  focused = false;
  // errorState = false;
  controlType = 'height-input';
  id = `height-input-${HeightInputComponent.nextId++}`;
  describedBy = '';
  onChange = (_: any) => { };
  onTouched = () => { };
  private _placeholder: string;

  @Output() heightEmitter = new EventEmitter<any>();

  @Input() valueChange: Subject<boolean>;

  get empty() {
    const { value: { feet, inches } } = this.parts;
    return !feet && !inches;
  }

  get shouldLabelFloat() { return this.focused || !this.empty; }

  @Input()
  get value(): Height | null {
    let n = this.parts.value;
    if ((n.feet && n.feet.length == 1) && (n.inches && n.inches.length == 1)) {
      return new Height(n.feet, n.inches);
    }
    return null;
  }
  set value(height: Height | null) {
    height = height || new Height(null, null);
    this.parts.setValue({ feet: height.feet, inches: height.inches });
    // console.log(this.parts.value);
    this.stateChanges.next();
  }


  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }
  private _disabled = false;


  get errorState() {
    if (this.parts) {
      return this.parts.errors !== null && !!this.parts.touched;
    }
    return null
  }


  constructor(fb: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl) {
    this.parts = fb.group({
      'feet': [null, ValidationService.heightFeetValidator],
      'inches': [null, ValidationService.heightInchesValidator],
    });

    this.parts.valueChanges.subscribe(data => {
      if (this.parts.get('feet').hasError('invalidFeetHeight')) {
        this.heightEmitter.emit({ 'invalidFeetHeight': true });
      }
      else if (this.parts.get('inches').hasError('invalidInchesHeight')) {
        this.heightEmitter.emit({ 'invalidInchesHeight': true });
      }
      else if ((this.parts.get('feet').value != null && this.parts.get('feet').value != '') || (this.parts.get('inches').value != null && this.parts.get('inches').value != '')) {
        let height = '';
        if (this.parts.get('feet').value) {
          height = height + this.parts.get('feet').value + "'";
        }

        if (this.parts.get('inches').value) {
          height = height + this.parts.get('inches').value + '"';
        }
        this.heightEmitter.emit({ 'height': height });
      }
      else {
        this.heightEmitter.emit({ 'height': null });
      }
    });

    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    if (this.valueChange) {
      this.valueChange.subscribe((data: any) => {
        if (data) {
          this.parts.setValue(data)
        }
      })
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this._elementRef.nativeElement.querySelector('input')!.focus();
    }
  }

  writeValue(height: Height | null): void {
    this.value = height;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(): void {
    this.onChange(this.value);
  }

  static ngAcceptInputType_disabled: boolean | string | null | undefined;
  static ngAcceptInputType_required: boolean | string | null | undefined;



}

class Height {
  constructor(public feet: number, public inches: number) {

  }
}
