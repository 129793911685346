import { Component, OnInit } from '@angular/core';
import { ClinicsService } from 'app/support/services/clinics.service';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { SnackbarCongiration } from 'app/shared/components/snack-bar/SnackbarConfiguration';
import { GlobalService } from 'app/support/services/auth/global.service';
import { UsersService } from 'app/support/services/users.service';
import { CanComponentDeactivate } from 'app/support/services/auth/can-deactivate.guard';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivateDialogComponent } from 'app/shared/components/dialog-box/activate-dialog/activate-dialog.component';

@Component({
    selector: 'app-two-factor',
    templateUrl: './two-factor.component.html',
    styleUrls: ['./two-factor.component.scss']
})
export class TwoFactorComponent implements CanComponentDeactivate {

    clinicId: any;
    clinicList: Array<any> = [];
    clinicArray: FormArray;
    clinicTwofactorForm: FormGroup;
    mhtTwofactorForm: FormGroup;
    formLoad: boolean = false;
    loginUser: any;
    mhtList: any;
    inProgress: boolean = false;
    submitChange: boolean = false;
    initialMHTValues: string;
    initialClinicValues: string;

    constructor(
        private clinicsService: ClinicsService,
        private userService: UsersService,
        private snackBar: SnackbarCongiration,
        private fb: FormBuilder,
        private dialog: MatDialog,
        private globalService: GlobalService
    ) {
        this.getData()
    }

    getData() {
        this.globalService.user$.subscribe(user => this.loginUser = user);
        this.formLoad = false;
        this.clinicsService.getAssociatedClinics().subscribe(
            (response: any) => {
                if (this.loginUser['user_type']['name'] == 'MHT') {
                    this.mhtList = response['data'][0];
                    this.createMHTForm();
                }
                else {
                    this.clinicList = response['data'].concat([]);
                    this.createClinicForm();
                }
                this.formLoad = true;
            }
        );
    }

    ngOnInit() {
    }

    createMHTForm() {
        this.mhtTwofactorForm = this.fb.group({
            // need_auth: this.mhtList['need_auth'],
            email: this.mhtList['email'],
            mobile: this.mhtList['mobile'],
            web: this.mhtList['web'],
            turn_off_notifications: this.mhtList['turn_off_notifications']
            // email: {value: this.mhtList['email'], disabled: !this.mhtList['mobile']},
            // mobile: {value: this.mhtList['mobile'], disabled: !this.mhtList['email']},
            // web: {value: this.mhtList['web'], disabled: !this.mhtList['email']},
        });
        this.initialMHTValues = JSON.stringify(this.mhtTwofactorForm.value);
        this.turnOffNotificationsChange();
    }

    createClinicForm() {
        this.clinicTwofactorForm = this.fb.group({
            'clinics': this.fb.array([])
        });
        this.clinicArray = this.clinicTwofactorForm.get('clinics') as FormArray;
        this.clinicList.forEach(
            (element: any, index) => {
                this.clinicArray.push(this.fb.group({
                    id: element['clinic_id'],
                    clinic_name: element['clinic_name'],
                    // need_auth_code: element['need_auth_code'],
                    email_auth: element['email_auth'],
                    mobile_auth: element['mobile_auth'],
                    // email_auth: {value: element['email_auth'], disabled: !element['mobile_auth']},
                    // mobile_auth: {value: element['mobile_auth'], disabled: !element['email_auth']},
                    web_auth: element['web_auth'],
                    turn_off_notifications: element['turn_off_notifications']
                }));
            }
        );
        this.initialClinicValues = JSON.stringify(this.clinicTwofactorForm.value);
        this.clinicArray.controls.forEach((element: any, index) => {
            this.turnOffNotificationsChange(index);
        });
    }

    mhtTwofactor() {
        this.submitChange = true;
        let value = new Object(this.mhtTwofactorForm.getRawValue());
        // value['need_auth'] == this.mhtList['need_auth'] ? delete value['need_auth'] : '';
        // value['email'] == this.mhtList['email'] ? delete value['email'] : '';
        // value['mobile'] == this.mhtList['mobile'] ? delete value['mobile'] : '';

        if (Object.keys(value).length) {
            this.inProgress = true;
            this.clinicsService.setClinicTwofactor({ clinic_list: value }).subscribe(
                // this.userService.setMHTTwofactor(value).subscribe(
                (response: any) => {
                    this.snackBar.triggerSnackBar({
                        message: 'My settings updated',
                        type: 'success'
                    });
                    // this.mhtList = new Object(response['data']);
                    this.getData();
                    this.inProgress = false;
                },
                (error: any) => {
                    this.inProgress = false;
                    this.snackBar.triggerSnackBar({
                        message: 'Failed to update',
                        type: 'error'
                    });
                }
            );
        }
        else
            this.snackBar.triggerSnackBar({
                message: 'My settings updated',
                type: 'success'
            });
    }

    clinicTwofactor() {
        this.submitChange = true;
        let values = new Object(this.clinicTwofactorForm.getRawValue());
        // let tempValues = values['clinics'].concat([]);
        // this.clinicList.forEach((el, idx) => {
        //     let index = values['clinics'].indexOf(tempValues[idx]);
        //     if(el['need_auth_code'] == tempValues[idx]['need_auth_code'] &&
        //     el['email_auth'] == tempValues[idx]['email_auth'] && 
        //     el['mobile_auth'] == tempValues[idx]['mobile_auth']&&el['web_auth'] == tempValues[idx]['web_auth'] ) 
        //         values['clinics'].splice(index,1);
        //     else {
        //         if (el['need_auth_code'] == tempValues[idx]['need_auth_code'])
        //             delete values['clinics'][index]['need_auth_code']
        //         if (el['email_auth'] == tempValues[idx]['email_auth'])
        //             delete values['clinics'][index]['email_auth']
        //         if (el['mobile_auth'] == tempValues[idx]['mobile_auth'])
        //             delete values['clinics'][index]['mobile_auth']
        //         if (el['web_auth'] == tempValues[idx]['web_auth'])
        //             delete values['clinics'][index]['web_auth']    
        //     }
        // });
        if (values['clinics'].length)
            this.clinicsService.setClinicTwofactor({ clinic_list: values['clinics'] }).subscribe(
                (response: any) => {
                    this.snackBar.triggerSnackBar({
                        message: 'My settings updated',
                        type: 'success'
                    });
                    this.getData()
                    // if(response['data'].length)
                    //     this.clinicList = response['data'].concat([]);
                },
                (error: any) => {
                    this.snackBar.triggerSnackBar({
                        message: 'Failed to update',
                        type: 'error'
                    });
                }
            );
        else
            this.snackBar.triggerSnackBar({
                message: 'My settings updated',
                type: 'success'
            });
    }

    mhtChange(control: string, checked: boolean) {
        if (checked) {
            if (control == 'email') {
                this.inProgress = true;
                this.userService.mhtMobileUsers().subscribe(
                    (response: any) => {
                        if (response['status'] == 'warning') {
                            this.snackBar.triggerSnackBar({
                                message: 'Users without mobile number exist. Please update mobile number for them',
                                type: 'warning'
                            });
                            this.mhtTwofactorForm.get('mobile').setValue(false);
                        }
                        else
                            this.mhtTwofactorForm.get('email').enable();
                        this.inProgress = false;
                    }
                );
            }
            else
                this.mhtTwofactorForm.get(control).enable();
        }
        else
            this.mhtTwofactorForm.get(control).disable();
    }

    clinicChange(index: number, control: string, checked: boolean) {
        if (checked) {
            if (control == 'email_auth') {
                this.inProgress = true;
                this.clinicsService.clinicMobileUsers(this.clinicTwofactorForm.get('clinics')['controls'][index]['controls']['id'].value)
                    .subscribe(
                        (response: any) => {
                            if (response['status'] == 'warning') {
                                this.snackBar.triggerSnackBar({
                                    message: 'Users without mobile number exist. Please update mobile number for them',
                                    type: 'warning'
                                });
                                this.clinicTwofactorForm.get('clinics')['controls'][index]['controls']['mobile_auth'].setValue(false);
                            }
                            else
                                this.clinicTwofactorForm.get('clinics')['controls'][index]['controls']['email_auth'].enable();
                            this.inProgress = false;
                        }
                    );
            }
            else
                this.clinicTwofactorForm.get('clinics')['controls'][index]['controls'][control].enable();
        }
        else
            this.clinicTwofactorForm.get('clinics')['controls'][index]['controls'][control].disable();
    }

    checkTwofactorChange() {
        if (this.loginUser['user_type']['name'] == 'MHT') {
            let formValue = JSON.stringify(this.mhtTwofactorForm.getRawValue());
            if (formValue != this.initialMHTValues) return true;
            else return false;
        } else {
            let formValue = JSON.stringify(this.clinicTwofactorForm.getRawValue());
            if (formValue != this.initialClinicValues) return true;
            else return false;
        }
    }

    turnOffNotificationsChange(index?: any) {
        if (this.loginUser['user_type']['name'] == 'MHT') {
            if (this.mhtTwofactorForm.get('turn_off_notifications').value) {
                // this.mhtTwofactorForm.get('need_auth').disable()
                this.mhtTwofactorForm.get('email').disable()
                this.mhtTwofactorForm.get('mobile').disable()
                this.mhtTwofactorForm.get('web').disable()
            }
            else {
                // this.mhtTwofactorForm.get('need_auth').enable()
                this.mhtTwofactorForm.get('email').enable()
                this.mhtTwofactorForm.get('mobile').enable()
                this.mhtTwofactorForm.get('web').enable()
            }
        }
        else {
            let notificationArray = this.clinicTwofactorForm.get('clinics') as FormArray;
            let notificationControl = notificationArray.controls[index]
            if (notificationControl.get('turn_off_notifications').value) {
                // notificationControl.get('need_auth_code').disable();
                notificationControl.get('email_auth').disable();
                notificationControl.get('mobile_auth').disable();
                notificationControl.get('web_auth').disable();
            }
            else {
                // notificationControl.get('need_auth_code').enable();
                notificationControl.get('email_auth').enable();
                notificationControl.get('mobile_auth').enable();
                notificationControl.get('web_auth').enable();
            }
        }
    }

    confirm(): Promise<any> {
        return new Promise((resolve) => {
            // console.log(this.checkTwofactorChange())
            if (!this.submitChange && this.checkTwofactorChange()) {
                const dialogConfig = new MatDialogConfig();
                // dialogConfig.position = { top: '70px' };
                dialogConfig.width = '500px';
                dialogConfig.disableClose = true;
                dialogConfig.data = { 'yes': null, 'no': null };
                let dialogRef = this.dialog.open(ActivateDialogComponent, dialogConfig);
                dialogRef.afterClosed().subscribe(
                    value => {
                        if (value == 'yes') {
                            if (localStorage.getItem('tokenData')) localStorage.removeItem('tokenData');
                            this.dialog.closeAll();
                            resolve(true);
                        } else {
                            if (localStorage.getItem('tokenData')) {
                                let data = JSON.parse(localStorage.getItem('tokenData'))
                                localStorage.setItem("token", data['token']);
                                localStorage.setItem("firebase:host:phoenix-d7e6a.firebaseio.com", data['firebase:host:phoenix-d7e6a.firebaseio.com']);
                                localStorage.removeItem('tokenData');
                            }
                            this.dialog.closeAll();
                            resolve(false);
                        }
                    }
                );
            } else {
                resolve(true);
            }
        })
    }
}
