import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './auth/auth.service';
import { AuthGuardService, AuthRedirectorService, PermissionService, DeviceAcceptanceGuard } from './auth/auth-guard.service';
import { GlobalService } from './auth/global.service';
import { InterfaceService } from './auth/interface.service';
import { UsersService } from './users.service';
import { ClinicsService } from './clinics.service';
import { AgencyService } from './agency.service';
import { CanDeactivateGuard } from './auth/can-deactivate.guard' 
import { PatientAssessmentService } from './patient-assessment.service';
import { DashboardService } from './dashboard.service';
import { DeviceDetectorService } from 'ngx-device-detector';

function globalServiceFactory(global: GlobalService) {
    return () => global.loadProject();
}

const appInitializer = {
    provide: APP_INITIALIZER,
    useFactory: globalServiceFactory,
    deps: [GlobalService],
    multi: true
};

@NgModule({
    imports: [
        CommonModule
    ],
    providers: [
        // app services
        appInitializer,
        GlobalService,
        InterfaceService,

        // guards
        AuthGuardService,
        AuthRedirectorService,
        PermissionService,
        DeviceAcceptanceGuard,
        CanDeactivateGuard,

        // services
        AuthService,
        UsersService,
        ClinicsService,
        AgencyService,
        PatientAssessmentService,
        DashboardService,
        DeviceDetectorService
    ]
})
export class ServicesModule { }
