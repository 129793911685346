import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map, take, tap } from 'rxjs/operators';
import { Observable, timer } from 'rxjs';
// import { Subscription } from 'rxjs';

// -- Configurations --
import { isTokenExpired, setToken, unsetToken, AUTH_TOKEN } from '../../app-config';

// -- Classes --
import { User } from '../../classes';

// -- Services --
import { InterfaceService } from './interface.service';
import { async } from 'q';
import { Router } from '@angular/router';
import { Permissions } from 'app/support/permissions/permissions';
declare var findMyIP: any;

@Injectable()
export class GlobalService extends InterfaceService {
    
    // app version to auto refresh browser(on login)
    version_updated_at = null;

    user: User
    // getting ip address
    public ipAddressSource = new BehaviorSubject<any>(null)
    ip$: Observable<any> = this.ipAddressSource.asObservable()

    public profile_image_source = new BehaviorSubject<any>(null)
    profile_image$: Observable<any> = this.profile_image_source.asObservable()

    public geolocation = new BehaviorSubject<object>({})
    geolocation$: Observable<object> = this.geolocation.asObservable()


    private userSource = new BehaviorSubject<User>(new User());
    user$: Observable<User> = this.userSource.asObservable();
    private userPermissionsSource = new BehaviorSubject<Array<string>>([]);
    userPermissions$: Observable<Array<String>> = this.userPermissionsSource.asObservable();
    private stateSource = new BehaviorSubject<any>(undefined);
    state$: Observable<any> = this.stateSource.asObservable();

    private get _router() { return this._injector.get(Router); }
    // private get _permission() { return this._injector.get(Permissions); }

    constructor(
        private http: HttpClient,
        private _injector: Injector,
    ) {
        super();
        // findMyIP().then((ip) => this.ipAddressSource.next(ip))

        // this.getGeolocation()

        this.user$.subscribe(user => this.user = user)

    }

    // Loading project
    loadProject(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {

            if (isTokenExpired()) {
                this.setUser();
                resolve(true);
            } else {
                this.http.get(this.getApiUrl('device/device-refresh/'), this.getHttpOptions()).subscribe(
                    (response) => {
                        if (!this.version_updated_at)
                            this.version_updated_at = response['version_updated_at'];
                        if (response['device']) {
                            setToken(response['token']);
                            this.setUser(response['user']);
                            resolve();
                        }
                        else {
                            this.getUserdata().subscribe(
                                response => {
                                    setToken(response['token']);
                                    this.setUser(response['user']);
                                    let userData = response['user'];
                                    var nonce = {
                                        "nonce": this.getUserNonce(response['user'].id)
                                    }
                                    this.checkTrustedDevice(nonce).subscribe(
                                        response => {
                                            if (response['is_auth_logged_in']) {
                                                this.user['is_auth_logged_in'] = true;
                                                if ('all_liability_accepted' in userData && !userData['all_liability_accepted']) {
                                                    this._router.navigate(['/liability-accept'])
                                                }
                                                // this._permission.navigateToFirstPermissionList()
                                            }
                                            else {
                                                this.user['is_auth_logged_in'] = false;
                                                this._router.navigate(['/device-accept'])
                                            }
                                        }
                                    )
                                    resolve();
                                },
                                error => {
                                    unsetToken();
                                    resolve(error);
                                }
                            );
                        }
                    },
                    error => {
                        unsetToken();
                        resolve(error);
                    }
                );

                // changed code for getting ip address
                // this.ip$.subscribe(ip => {
                //     if(ip)
                //     {
                // actual code segment

                //     }
                // })
            }
        });
    }

    // Get user data & new token
    // changed params for getting ip address
    getUserdata() {
        const credentials = `{"token":"${localStorage.getItem(AUTH_TOKEN)}"}`;
        return this.http
            .post(this.getApiUrl('api-token-refresh/'), credentials, this.getHttpOptions())
            .pipe(
                map(response => response),
                catchError(this.handleError)
            );
    }

    // Set or Change user value
    setUser(details?: Object) {
        if (!(details instanceof Object)) {
            details = {};
        }

        this.userPermissionsSource.next(details['accesses'] || []);
        delete details['permissions'];

        this.userSource.next(new User(details));
    }
    // Toast message
    private toastMessageSource = new BehaviorSubject<Array<string>>(['', 'success']);
    toastMessage = this.toastMessageSource.asObservable();
    setToastMessage(message: string, time: number, state?: string) {
        this.toastMessageSource.next([message, state || 'success']);
        time = time < 10000 && time != 0 ? 10000 : time;
        let timers = timer(time);

        timers.subscribe(() => {
            this.toastMessageSource.next(['', 'success']);
        });
    }

    //  Selected State
    stateSelected(state) {
        this.stateSource.next(state);
    }

    getIpAddress() {
        return this.http
            .get('https://api.ipify.org?format=json').pipe(
                map(response => response || {}),
                catchError(this.handleError)
            )
    }

    getGeolocation() {
        var that = this;
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                that.geolocation.next(
                    {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    }
                )
            });
        }
    }

    generateRandomNonce(length): string {
        var result = '';
        var chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        for (var i = 0; i < length; i++) {
            result += chars[Math.floor(Math.random() * chars.length)];
        }
        return result;
    }

    getUserNonce(userId) {
        var nonceArr = JSON.parse(localStorage.getItem("phoenix_nonce"));
        var nonce = "";
        if (nonceArr) {
            nonceArr.forEach(element => {
                if (element.id == userId) {
                    nonce = element.nonce;
                }
            });
            return nonce;
        }
        return nonce;
    }

    getOnlyNonce() {
        var onlyNonce = []
        var nonceArr = JSON.parse(localStorage.getItem("phoenix_nonce"));
        if (nonceArr != null || nonceArr != undefined) {
            nonceArr.map(function (elem) {
                onlyNonce.push(elem.nonce)
            })
            return onlyNonce;
        }
        return []
    }

    addUserNonce(userId, nonce) {
        var nonceArr = JSON.parse(localStorage.getItem("phoenix_nonce"));
        for (const nonceObj of nonceArr) {
            if (nonceObj.id == userId) {
                nonceObj['nonce'] = nonce;
                localStorage.setItem("phoenix_nonce", JSON.stringify(nonceArr))
                return;
            }
        }
        nonceArr.push({
            id: userId,
            nonce: nonce
        })


        localStorage.setItem("phoenix_nonce", JSON.stringify(nonceArr))
    }

    removeUserNonce(userId) {
        var nonceArr = JSON.parse(localStorage.getItem("phoenix_nonce"));
        if (nonceArr) {
            var index = 0;
            for (const nonceObj of nonceArr) {
                if (nonceObj.id == userId) {
                    var removedNonce = nonceArr.splice(index, 1)
                    localStorage.setItem('phoenix_nonce', JSON.stringify(nonceArr))
                    return removedNonce[0];
                }
                index = index + 1;
            }
            return '';
        }

        return '';

    }

    checkTrustedDevice(data) {
        return this.http.post(this.getApiUrl('users/check-trusted-device/'), data, this.getHttpOptions('json', true))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            )
    }

    redirectUrl(data) {
        return this.http.post(this.getApiUrl('sms/redirect-url/'), data, this.getHttpOptions('json', false)).pipe(
            map(Response => Response),
            catchError(this.handleError)
        );
    }

}
