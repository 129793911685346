import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatCheckboxChange, MatDialogConfig, MatDialog } from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { AssessmentsService } from 'app/support/services/assessments.service';
import { SnackbarCongiration } from 'app/shared/components/snack-bar/SnackbarConfiguration';
@Component({
  selector: 'app-list-dialog',
  templateUrl: './list-dialog.component.html',
  styleUrls: ['./list-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ListDialogComponent>,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private snackBar: SnackbarCongiration,
    private assessmentService: AssessmentsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }
  ngOnInit(): void {
      
  }

  closeModal(){
    this.dialogRef.close();
  }
}