export class Clinic {
    public id?: number = -1;
    public clinic_name: string = '';
    public clinic_type: Object = null;
    public parent?: Object = null;
    public website?: string = '';
    public clinic_sepeciality?: Array<any> = [];
    public liability: Object = null;
    public patient_liability: Object = null;
    public patient_spanish_liability: Object = null;
    public service_type?: Object = null;
    public account_type?: Object = null;
    public payment_terms?: Object = null;
    public payment_type?: Object = null;
    public agency: Object = null;
    // public email_template: Object = null;
    public en_email_template: Object = null;
    public en_sms_template: Object = null;
    public sp_email_template: Object = null;
    public sp_sms_template: Object = null;
    public en_resp_email_template: Object = null;
    public en_resp_sms_template: Object = null;
    public sp_resp_email_template: Object = null;
    public sp_resp_sms_template: Object = null;
    public en_alert_email_template: Object = null;
    public en_alert_sms_template: Object = null;
    public sp_alert_email_template: Object = null;
    public sp_alert_sms_template: Object = null;
    public sales_rep: Object = null;
    public is_active: boolean = false;
    public speciality: Array<any> = [];
    public created_at: any = '';
    public is_liability_accepted: boolean = false;
    public notifications: string = '';
    public directors: Array<any> = [];
    public staffs?: number = 0;
    public patients?: number = 0;
    public email_auth: boolean = true;
    public mobile_auth: boolean = false;
    public responder: boolean = false;
    public api_integration: boolean = false;

    constructor(data?: Object) {
        if (data) { 
            this.id = data['id'] || this.id;
            this.clinic_name = data['clinic_name'] || this.clinic_name;
            this.clinic_type = data['clinic_type'] || this.clinic_type;
            this.parent = data['parent'] || this.parent;
            this.website = data['website'] || this.website;
            this.clinic_sepeciality = data['clinic_sepeciality'] || this.clinic_sepeciality;
            this.liability = data['liability'] || this.liability;
            this.patient_liability = data['patient_liability'] || this.patient_liability;
            this.service_type = data['service_type'] || this.service_type;
            this.account_type = data['account_type'] || this.account_type;
            this.payment_terms = data['payment_terms'] || this.payment_terms;
            this.payment_type = data['payment_type'] || this.payment_type;
            this.agency = data['agency'] || this.agency;
            // this.email_template = data['email_template'] || this.email_template;
            this.en_email_template = data['en_email_template'] || this.en_email_template;
            this.en_sms_template = data['en_sms_template'] || this.en_sms_template;
            this.sp_email_template = data['sp_email_template'] || this.sp_email_template;
            this.sp_sms_template = data['sp_sms_template'] || this.sp_sms_template;
            this.en_resp_email_template = data['en_resp_email_template'] || this.en_resp_email_template;
            this.en_resp_sms_template = data['en_resp_sms_template'] || this.en_resp_sms_template;
            this.sp_resp_email_template = data['sp_resp_email_template'] || this.sp_resp_email_template;
            this.sp_resp_sms_template = data['sp_resp_sms_template'] || this.sp_resp_sms_template;
            this.en_alert_email_template = data['en_alert_email_template'] || this.en_alert_email_template;
            this.en_alert_sms_template = data['en_alert_sms_template'] || this.en_alert_sms_template;
            this.sp_alert_email_template = data['sp_alert_email_template'] || this.sp_alert_email_template;
            this.sp_alert_sms_template = data['sp_alert_sms_template'] || this.sp_alert_sms_template;
            this.sales_rep = data['sales_rep'] || this.sales_rep;
            this.speciality = data['speciality'] || this.speciality;
            this.is_active = data['is_active'] || this.is_active;
            this.created_at = data['created_at'] || this.created_at;
            this.is_liability_accepted = data['is_liability_accepted'] || this.is_liability_accepted;
            this.notifications = data['notifications'] || this.notifications;
            this.directors = data['directors'] || this.directors;
            this.staffs = data['staffs'] || this.staffs;
            this.patients = data['patients'] || this.patients;
            this.email_auth = data['email_auth'] || this.email_auth;
            this.mobile_auth = data['mobile_auth'] || this.mobile_auth;
            this.responder = data['responder'] || this.responder;
            this.api_integration = data['api_integartion'] || this.api_integration;
        }
    }
}