import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

import { DialogComponent } from './dialog.component';
import { CommonModule } from '@angular/common';
import {
  MatIconModule,
  MatCardModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatTabsModule,
  MatCheckboxModule,
  MatChipsModule,
  MatTooltipModule,
  MatSlideToggleModule,
  MatSliderModule,
} from '@angular/material';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { ActivateDialogComponent } from './activate-dialog/activate-dialog.component';
import { StatusChangeDialogComponent } from './status-change-dialog/status-change-dialog.component';
import { NoNavigationComponent } from './no-navigation/no-navigation.component';
import { ZipOverwriteComponent } from './zip-overwrite/zip-overwrite.component';
import { ConfimerComponent } from './confimer/confimer.component';
import { AssessmentGeneralSettingsComponent } from './assessment-general-settings/assessment-general-settings.component';
import { SessionLogoutComponent } from './session-logout/session-logout.component';
import { ReloadComponent } from './reload/reload.component';
import { ListDialogComponent } from './list-dialog/list-dialog.component';
import { CustomSliderComponent } from '../custom-slider/custom-slider.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

@NgModule({
  declarations: [
    DialogComponent,
    DeleteDialogComponent,
    ActivateDialogComponent,
    StatusChangeDialogComponent,
    NoNavigationComponent,
    ZipOverwriteComponent,
    CustomSliderComponent,
    ConfimerComponent,
    AssessmentGeneralSettingsComponent,
    SessionLogoutComponent,
    ReloadComponent,
    ListDialogComponent,
  ],
  imports: [
    NgxSliderModule,
    MatChipsModule,
    MatTooltipModule,
    CommonModule,
    MatDialogModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatIconModule,
    MatTabsModule,
    MatCheckboxModule,
    MatCardModule,
    MatInputModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    FlexLayoutModule,
    ReactiveFormsModule,
  ],
  entryComponents: [
    DialogComponent,
    ActivateDialogComponent,
    StatusChangeDialogComponent,
    DeleteDialogComponent,
    NoNavigationComponent,
    ZipOverwriteComponent,
    AssessmentGeneralSettingsComponent,
    ReloadComponent,
    ListDialogComponent,
  ],
})
export class DialogModule {}
