import {
  Component,
  OnInit,
  ViewEncapsulation,
  Inject,
  ViewChildren,
  ViewChild,
  ChangeDetectorRef,
  EventEmitter,
} from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogConfig,
  MatDialog,
  MatSliderChange,
} from '@angular/material';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
} from '@angular/forms';
import { AssessmentsService } from 'app/support/services/assessments.service';
import { SnackbarCongiration } from 'app/shared/components/snack-bar/SnackbarConfiguration';
import { ActivateDialogComponent } from '../activate-dialog/activate-dialog.component';
import { isEqual } from 'lodash';
import { ConfimerComponent } from '../confimer/confimer.component';

enum ListType {
  CLINICIANS = 'clinicians',
  LOCATIONS = 'locations',
}

@Component({
  selector: 'app-assessment-general-settings',
  templateUrl: './assessment-general-settings.component.html',
  styleUrls: ['./assessment-general-settings.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AssessmentGeneralSettingsComponent implements OnInit {
  @ViewChildren('matSelect_j') matSelect_j;
  @ViewChildren('alertmatSelect_j') alertmatSelect_j;

  listType = ListType;
  settingsForm: FormGroup;
  initialSettingsForm: FormGroup;
  alertForm: FormGroup;
  alertRuleForm: FormGroup;
  selectedRules: Array<any> = [];
  deletedRules: Array<any> = [];
  intervalHrsList: any = [];
  intervalMinsList: any = [];
  retryCountList: any = [];
  rulesList: any = [];
  alertRulesListForm: FormGroup;
  clinicAlerts: Array<any> = [];
  durationHrsList: any = [];
  durationMinsList: any = [];
  settings: boolean = true;
  is_update: boolean = false;
  checkAlert: Array<any> = [];
  clinicianList: Array<any> = [];
  valueChange: boolean = false;
  genderDependencyList = [
    { value: 'both', name: 'All', id: 1 },
    { value: 'female', name: 'Female', id: 2 },
    { value: 'male', name: 'Male', id: 3 },
  ];
  maxVisibleItems: number = 2;
  locationList: Array<any> = [];
  event: any;
  assessmentId: any;
  manualRefresh: boolean = false;

  constructor(
    private cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<AssessmentGeneralSettingsComponent>,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private snackBar: SnackbarCongiration,
    private assessmentService: AssessmentsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    if (this.data && this.data.hasOwnProperty('update_data')) {
      this.is_update = true;
    }
    this.handleData();
    this.buildForm();
    this.populateAlertRulesList();
  }

  formatLabel(value: number): string {
    return `Retry count(s) ${value}`;
  }

  handleData() {
    if (this.data.type !== 'assessment') {
      this.intervalHrsList =
        this.data['general_settings_data']['time_interval_hrs'];
      this.intervalMinsList =
        this.data['general_settings_data']['time_interval_mins'];
      this.durationHrsList =
        this.data['general_settings_data']['time_duration_hrs'];
      this.durationMinsList =
        this.data['general_settings_data']['time_duration_mins'];
      this.retryCountList =
        this.data['general_settings_data']['max_retry_count_list'];
      this.rulesList = this.data['alert_data']['alert_rule'].filter(
        (alert) => !alert.url_navigation
      );

      if (this.data['alert_data']['assessment_scoring'] == false) {
        if (this.rulesList.length > 0) {
          for (var i = 0; i < this.rulesList.length; i++) {
            this.rulesList[i]['value'] =
              this.rulesList[i]['value'].split('.')[0];
          }
        }
      }

      this.clinicAlerts = this.data['alert_data']['clinic_alerts'];
      this.clinicAlerts.forEach((alert) => {
        this.checkAlert.push(alert['id']);
      });
      const assignedClinician = {
        id: -1,
        name: 'Assigned Clinician',
      };

      this.clinicianList = [assignedClinician, ...this.data['clinicians']];
      this.locationList = this.data['locations'];
    }
  }

  buildForm() {
    this.settingsForm = this.fb.group({});
    this.alertRuleForm = this.fb.group({
      rule: [null, Validators.required],
      selectAllClinicians: false,
      selectAllLocations: true,
      clinicians: new FormControl([this.clinicianList[0]], Validators.required),
      locations: [this.locationList],
    });

    this.alertRulesListForm = this.fb.group({
      rules: this.fb.array([]),
    });

    if (this.data.type == 'assessment') {
      this.settingsForm.addControl(
        'gender_dependency',
        new FormControl(
          this.is_update
            ? this.data['update_data']['gender_dependency']
            : 'both',
          Validators.required
        )
      );
    }

    if (this.data.type !== 'assessment') {
      this.settingsForm.addControl(
        'time_interval_days',
        new FormControl(
          this.is_update ? this.data['update_data']['time_interval_days'] : 0,
          Validators.required
        )
      );
      this.settingsForm.addControl(
        'time_interval_hrs',
        new FormControl(
          this.is_update ? this.data['update_data']['time_interval_hrs'] : 0,
          Validators.required
        )
      );
      this.settingsForm.addControl(
        'time_interval_mins',
        new FormControl(
          this.is_update ? this.data['update_data']['time_interval_mins'] : 15,
          Validators.required
        )
      );
      this.settingsForm.addControl(
        'retry_count',
        new FormControl(
          this.is_update ? this.data['update_data']['retry_count'] : 0,
          Validators.required
        )
      );

      if (
        this.settingsForm.get('time_interval_hrs').value == 0 &&
        this.settingsForm.get('time_interval_mins').value == 0 &&
        this.settingsForm.get('time_interval_days').value == 0
      ) {
        this.settingsForm.get('time_interval_mins').setValue(15);
      }

      if ('id' in this.data['general_settings']) {
        this.settingsForm.controls['retry_count'].setValue(
          this.data['general_settings']['retry_count']
        );
        this.settingsForm.controls['time_interval_days'].setValue(
          this.data['general_settings']['time_interval_days']
        );
        this.settingsForm.controls['time_interval_hrs'].setValue(
          this.data['general_settings']['time_interval_hrs']
        );
        this.settingsForm.controls['time_interval_mins'].setValue(
          this.data['general_settings']['time_interval_mins']
        );
      }
    }
  }

  get alertRulesList() {
    return this.alertRulesListForm.get('rules') as FormArray;
  }

  populateAlertRulesList() {
    this.clinicAlerts.forEach((alert) => {
      const alertRules = alert['alert_rules'] || alert;
      const rule = this.fb.group({
        isActive: [alert['is_alert'] || false],
        values: {
          alert_id: alert['id'],
          id: alertRules.id,
          name: alertRules['rule_name'] || null,
          type_in_word: alertRules['type_in_word'] || null,
          operator_in_word: alertRules['operator_in_word'] || null,
          value: alertRules['value'] || null,
          selectAllClinicians: alert['all_clinician'] || false,
          selectAllLocations: alert['assigned_location'] || false,
          clinicians: alert['user_list'] || [],
          locations: alert['location_list'] || [],
        },
      });

      this.alertRulesList.push(rule);
    });
  }

  getVisibleItems(ruleIndex: number, type: ListType): string[] {
    const values = { ...this.alertRulesList.value[ruleIndex].values };

    return values[type].slice(0, this.maxVisibleItems);
  }

  getExtraItems(ruleIndex: number, type: ListType): string[] {
    const values = { ...this.alertRulesList.value[ruleIndex].values };

    if (type === ListType.CLINICIANS) {
      return values[type]
        .map((clinician) => clinician.name)
        .slice(this.maxVisibleItems);
    }

    if (type === ListType.LOCATIONS) {
      return values[type]
        .map((location) => location.clinic_location)
        .slice(this.maxVisibleItems);
    }
  }

  getTooltipText(ruleIndex: number, type: ListType): string {
    return this.getExtraItems(ruleIndex, type).join(', ');
  }

  onSliderChange(event: MatSliderChange) {
    this.settingsForm.get('retry_count').setValue(event.value);
  }

  deleteRule(ruleIndex: number) {
    const rule = this.alertRulesList.at(ruleIndex).value;

    if (rule.values.alert_id) {
      const deletedRule = {
        id: rule.values.id,
        alert_id: rule.values.alert_id,
      };
      this.deletedRules.push(deletedRule);
    }

    this.alertRulesList.removeAt(ruleIndex);
  }

  createAlertRule() {
    const rule = this.alertRuleForm.get('rule').value;
    const allClinicians = this.alertRuleForm.get('selectAllClinicians').value;
    const allLocations = this.alertRuleForm.get('selectAllLocations').value;
    const clinicians = this.alertRuleForm.get('clinicians').value;
    const locations = this.alertRuleForm.get('locations').value;
    const ruleName = rule.rule_name;
    const ruleAlreadyExist = this.alertRulesList.value.some((rule) => {
      const cliniciansIsEqual = isEqual(rule.values.clinicians, clinicians);
      const locationsIsEqual = isEqual(rule.values.locations, locations);
      const ruleNameIsEqual = rule.values.name === ruleName;

      return cliniciansIsEqual && locationsIsEqual && ruleNameIsEqual;
    });

    if (ruleAlreadyExist) {
      let dialogConfig = new MatDialogConfig();
      dialogConfig.width = '500px';
      dialogConfig.disableClose = false;
      dialogConfig.data = {
        type: 'admin_logout',
        message: 'This alert rule already exist on the list',
      };

      this.dialog.open(ConfimerComponent, dialogConfig);
    }

    if (!ruleAlreadyExist) {
      const newRule = this.fb.group({
        isActive: [rule.isActive || false],
        values: {
          id: rule.id,
          alert_id: null,
          clinicians: clinicians,
          name: ruleName,
          type_in_word: rule.type_in_word,
          operator_in_word: rule.operator_in_word,
          value: rule.value,
          locations: locations,
          selectAllClinicians: allClinicians,
          selectAllLocations: allLocations,
        },
      });

      this.alertRulesList.push(newRule);

      Object.keys(this.alertRuleForm.controls).forEach((key) => {
        this.alertRuleForm.get(key).clearValidators();
        this.alertRuleForm.get(key).updateValueAndValidity();
      });
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  isAllSelected(type: ListType) {
    let listItems = [];

    if (type === ListType.CLINICIANS) {
      listItems = this.clinicianList;
    }

    if (type === ListType.LOCATIONS) {
      listItems = this.locationList;
    }

    const numSelected = this.alertRuleForm.get(type).value.length;
    const numOptions = listItems.length;

    return numSelected === numOptions;
  }

  selectAll(event: any, type: ListType) {
    let listItems = [];

    if (type === ListType.CLINICIANS) {
      listItems = this.clinicianList;
    }

    if (type === ListType.LOCATIONS) {
      listItems = this.locationList;
    }

    if (event.checked) {
      this.alertRuleForm.controls[type].patchValue(listItems);
    } else {
      this.alertRuleForm.controls[type].setValue([]);
    }
  }

  onSelectChange(type: ListType) {
    if (this.isAllSelected(type)) {
      if (type === ListType.CLINICIANS) {
        this.alertRuleForm.get('selectAllClinicians').setValue(true);
      }
      if (type === ListType.LOCATIONS) {
        this.alertRuleForm.get('selectAllLocations').setValue(true);
      }
    } else {
      if (type === ListType.CLINICIANS) {
        this.alertRuleForm.get('selectAllClinicians').setValue(false);
      }
      if (type === ListType.LOCATIONS) {
        this.alertRuleForm.get('selectAllLocations').setValue(false);
      }
    }
  }

  saveGeneralSettings() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.disableClose = true;
    dialogConfig.data = { yes: null, no: null };
    let dialogRef = this.dialog.open(ActivateDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((value) => {
      if (value == 'yes') {
        this.dialogRef.close();
      }
    });
  }

  saveAlertForm() {
    this.alertRulesList.value.forEach((rule) => {
      const ruleBody = {
        is_alert: rule.isActive,
        alert_id: rule.values['alert_id'] || null,
        id: rule.values['id'],
        users: rule.values['clinicians'],
        locations: rule.values['locations'],
        allLocations: rule.values['selectAllLocations'],
        allClinician: rule.values['selectAllClinicians'],
      };
      this.selectedRules.push(ruleBody);
    });

    const data = {
      selected_rule: this.selectedRules,
      deleted_rules: this.deletedRules,
      add_settings_form: this.settingsForm.value,
    };

    this.assessmentService
      .createAlertSettings(data, this.data['clinic_assessment_id'])
      .subscribe(
        (response) => {
          this.snackBar.triggerSnackBar({
            message: 'Assessment General Settings Updated',
            type: 'success',
          });
          this.dialogRef.close();
        },
        () => {
          this.snackBar.triggerSnackBar({
            message: 'Failed to update Assessment General Settings',
            type: 'error',
          });
          this.dialogRef.close();
        }
      );
  }

  timeIntervalHrsChange() {
    if (
      this.settingsForm.get('time_interval_hrs').value == 0 &&
      this.settingsForm.get('time_interval_mins').value == 0 &&
      this.settingsForm.get('time_interval_days').value == 0
    ) {
      this.settingsForm.get('time_interval_mins').setValue(15);
    }
  }
  saveGenderDependency(value) {
    if (this.is_update == true) {
      this.data.update_data['gender_dependency'] = value.gender_dependency;
    } else {
      this.settingsForm.controls['gender_dependency'].setValue(
        value['gender_dependency']
      );
    }
    this.dialogRef.close(value);
  }
}
