import { Component, OnInit, ChangeDetectorRef, AfterViewInit, ViewEncapsulation, Output, EventEmitter, Inject } from '@angular/core';
import { Subject } from "rxjs/index";
import { mxgraph } from "mxgraph-factory";
import { MxGraphService } from 'app/support/services/mx-graph.service';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConditionalBlockPanelComponent } from '../conditional-block-panel/conditional-block-panel.component';
import { AddTasksPanelComponent } from '../add-tasks-panel/add-tasks-panel.component';
import { MulticonditionPanelComponent } from '../multicondition-panel/multicondition-panel.component';
import { CreatePatientPanelComponent } from '../create-patient-panel/create-patient-panel.component';
import { UpdatePatientPanelComponent } from '../update-patient-panel/update-patient-panel.component';
import { CancelAppointmentPanelComponent } from '../cancel-appointment-panel/cancel-appointment-panel.component';
import { LaunchAssessmentPanelComponent } from '../launch-assessment-panel/launch-assessment-panel.component';
import { ReschedulePanelComponent } from '../reschedule-panel/reschedule-panel.component';
import { ReassignPanelComponent } from '../reassign-panel/reassign-panel.component';
import { GotoPanelComponent } from '../goto-panel/goto-panel.component';
import { CreateClinicianPanelComponent } from '../create-clinician-panel/create-clinician-panel.component';
import { AssignClinicianPanelComponent } from '../assign-clinician-panel/assign-clinician-panel.component';
import { IncludePanelComponent } from '../include-panel/include-panel.component';
import { ScheduleBlockComponent } from '../schedule-block/schedule-block.component';
import { LaunchIncludePanelComponent } from '../launch-include-panel/launch-include-panel.component';
import { UpdateClinicianPanelComponent } from '../update-clinician-panel/update-clinician-panel.component';
import { RescheduleBlockComponent } from '../reschedule-block/reschedule-block.component';
import { ReassignBlockComponent } from '../reassign-block/reassign-block.component';

declare var _;
@Component({
  selector: 'add-blocks-panel',
  templateUrl: './add-blocks-panel.component.html',
  styleUrls: ['./add-blocks-panel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddBlocksPanelComponent implements OnInit, AfterViewInit {

  public onClose: Subject<any>;
  callAlways: boolean = true;
  showBlockConfig: string = '';
  selectedBlock: string = '';
  isConditional: boolean = false;
  activeField: any = {};
  selectedBlockModel: any = {};
  operators: any;
  cellModel: any;
  parentModel: any;
  //sidepanel data
  cell: mxgraph.mxCell;
  mxGraph: mxgraph.mxGraph;
  sender: any;
  event: any;
  title: string;
  selectedTriggerFields: any = null;
  isEdit: boolean = false;
  isMarketPlaceView: boolean = false;
  actionBlocks: any[] = [];
  options: any;
  mainObjectName: string = '';
  hasChild: boolean = false;
  showDeleteBtn: boolean;
  show_trigger: Array<any> = [];
  trigger_objects = {
    "create_patient" : ['createPatientBlock', 'gotoBlock', 'createClinicianBlock', 'updateClinicianBlock','assignClinicianBlock','updatePatientBlock'],
    "update_patient" : ['createPatientBlock', 'updatePatientBlock', 'gotoBlock', 'createClinicianBlock', 'updateClinicianBlock', 'assignClinicianBlock'],
    "create_appointment" : ['createPatientBlock', 'updatePatientBlock', 'gotoBlock', 'createClinicianBlock', 'updateClinicianBlock', 'assignClinicianBlock', 'createAppointmentBlock', 'includeBlock', 'scheduleBlock'],
    "reschedule_appointment": ['createPatientBlock', 'updatePatientBlock', 'gotoBlock', 'createClinicianBlock', 'updateClinicianBlock', 'assignClinicianBlock', 'createAppointmentBlock',
                               'includeBlock', 'scheduleBlock', 'rescheduleBlock', 'includeReschedule','cancelAppointmentBlock'],
    "modify_appointment": ['createPatientBlock', 'updatePatientBlock', 'gotoBlock', 'createClinicianBlock', 'updateClinicianBlock', 'assignClinicianBlock', 'createAppointmentBlock', 'includeBlock', 'scheduleBlock', 
                           'reassignBlock', 'includeReassign','cancelAppointmentBlock'],
    "cancel_appointment" : ['cancelAppointmentBlock'],
    "run_assessment" : ['launchAssessmentBlock', 'gotoBlock', 'launchBlock', 'includeBlock']
  };
  @Output() inputData = new EventEmitter<any>();
  @Output() conditionBoolean = new EventEmitter<any>();

  constructor(
    private cd: ChangeDetectorRef,
    private mx_graphService: MxGraphService,
    private sidePanelService: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddBlocksPanelComponent>
  ) {
    this.selectedTriggerFields = data['selectedTriggerFields']
    this.isEdit = data['isEdit'];
  }

  ngOnInit() {
    this.showDeleteBtn = this.isMarketPlaceView ? false : this.isEdit;
    this.onClose = new Subject();
    this.hasChild = this.data.cell.getEdgeCount() > 1;
    const mainObjectCell: mxgraph.mxCell = this.data.mxGraph.getModel().getCell('cs_initializer');
    const { mainObject } = mainObjectCell.getValue();
    this.mainObjectName = mainObject;
    this.show_trigger = this.trigger_objects[mainObject];
    let cellValue: any = this.data.cell.getValue();
    let cellModel = cellValue['model'];
    this.cellModel = cellModel;
    let parent = this.data.mxGraph.getModel().getCell(this.data.cell['parentId']);
    if(parent && parent['value']){
      this.parentModel = parent['value']['model'];
    }else{
      this.parentModel = ''
    }
    if (cellValue['name'] === 'conditionalBlock' || cellValue['name'] === 'ifconditionalBlock' || cellValue['name'] === 'multiconditionalBlock') {
      this.isConditional = true;
    } else if(this.isEdit && (parent['block_name'] == 'conditionalBlock' || parent['block_name'] == 'multiconditionalBlock')){
      this.isConditional = true;
    }
    else{
      this.isConditional = false;
    }

    if (this.isConditional) {
      if(cellModel != null && cellModel['col_name'] != undefined){
        this.activeField = this.selectedTriggerFields[cellModel['col_name']];
      }else if(parent && parent['value']['model']){
        this.activeField = this.selectedTriggerFields[parent['value']['model']['col_name']];
      }else{
        this.activeField = '';
      }
    }
    //set dummy structure
    this.selectedBlockModel = this.prepareBlockModel(this.isConditional);
    if (this.isEdit) {
      this.openCloseBlockConfig(cellValue['name'], cellModel);
      
    }
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  saveBlock() {
    if (this.selectedBlock === 'delayBlock') {
      if (this.selectedBlockModel['model']['type'] === 'conditional') {
        this.selectedBlockModel['model']['fieldLabel'] = this.selectedTriggerFields[this.selectedBlockModel['model']['col_name']]['text'];
      }
    }
    this.onClose.next(this.selectedBlockModel);
    this.dialogRef.close(this.selectedBlockModel);
  }

  addBlockConfiguration(event: any = null) {
    this.saveBlock();
  }

  closeBlockModal(closeAll) {
    if (closeAll === 'remove') {
      this.onClose.next('deleteBlock');
    }
    if (closeAll) {
      // this.modalRef.hide();
    }
    else {
      this.selectedBlock = this.showBlockConfig = '';
    }
  }

  openCloseBlockConfig(blockName: string = '', modelValue: any = null) {
    if (this.isConditional && (blockName === 'conditionalBlock' || blockName === 'ifconditionalBlock') && !this.isEdit) {
      this.data['activeField'] = this.activeField;
    }
    if (blockName === '') {
      this.selectedBlockModel = this.prepareBlockModel(this.isConditional);
    }
    else {
      if (this.isConditional) {
        this.data['activeField'] = this.activeField;
      }
      this.selectedBlockModel['name'] = blockName;
      this.selectedBlockModel['config'] = this.getBlockConfiguration(blockName);
      this.selectedBlockModel['model'] = this.getBlockModel(blockName, modelValue);
      if (modelValue && modelValue['condition']) {
        this.selectedBlockModel['condition'] = modelValue;
      }
    }
    this.showBlockConfig = blockName;
    this.data['selectedBlockModal'] = this.selectedBlockModel;
    this.selectedBlock = blockName;
    if(this.isEdit){
      let cellValue: any = this.data.cell.getValue();
      let parent = this.data.mxGraph.getModel().getCell(this.data.cell['parentId']);
      if (cellValue['condition'] && cellValue['condition']['col_name'] != '') {
        this.isConditional = true;
        if(cellValue['condition']['value'] != undefined && cellValue['condition']['value'] != ''){
          if(cellValue['condition']['value']['condition'] != undefined){
            this.selectedBlockModel['condition'] = cellValue['condition']['value'];
          }else{
            this.selectedBlockModel['condition'] = cellValue['condition'];
          }
        }
        else if(cellValue['model'] && cellValue['model']['condition'] != undefined){
          this.selectedBlockModel['condition'] = cellValue['model'];
        }else{
          this.selectedBlockModel['condition'] = cellValue['condition'];
        }
        this.selectedBlockModel['isConditional'] = this.isConditional;
        if(cellValue['condition']['col_name']){
          this.data['activeField'] = this.selectedTriggerFields[cellValue['condition']['col_name']];
        }
      }else if(cellValue['model'] && cellValue['model']['condition'] != undefined){
        this.isConditional = true;
        this.selectedBlockModel['condition'] = cellValue['model'];
        this.selectedBlockModel['isConditional'] = this.isConditional;
      }else{
        this.selectedBlockModel['isConditional'] = false;
      }
      if(parent != undefined){
        if(parent['block_name'] == 'conditionalBlock' || parent['block_name'] == 'multiconditionalBlock'){
          this.selectedBlockModel['isConditional'] = this.isConditional;
        }
        if(parent['block_name'] == 'multiconditionalBlock' && cellValue['condition'] == undefined){
          this.selectedBlockModel['condition'] = cellValue['model']
        }
      }
    }
    if (blockName === 'conditionalBlock' || blockName === 'ifconditionalBlock') {
      const dialogConfig = new MatDialogConfig();
      let data = this.data;
      dialogConfig.maxWidth = '1000px';
      dialogConfig.width = '1000px';
      dialogConfig.height = '100%';
      dialogConfig.data = data;
      dialogConfig.disableClose = true;
      dialogConfig.position = {
        right: "0"
      }
      const fsModal = this.sidePanelService.open(ConditionalBlockPanelComponent, dialogConfig);
      fsModal.afterClosed().subscribe(result => {
        if (result != 'close') {
          if(result == 'remove'){
            this.dialogRef.close('remove');
          }else{
            this.addBlockConfiguration();
          }
        }else{
          if(this.isEdit){
            this.cancel();
          }
        }
      })

    }
    if (blockName === 'createAppointmentBlock') {
      const dialogConfig = new MatDialogConfig();
      let data = this.data;
      dialogConfig.maxWidth = '1000px';
      dialogConfig.width = '1000px';
      dialogConfig.height = '100%';
      dialogConfig.data = data;
      dialogConfig.disableClose = true;
      dialogConfig.position = {
        right: "0"
      }
      const fsModal = this.sidePanelService.open(AddTasksPanelComponent, dialogConfig);
      fsModal.afterClosed().subscribe(result => {
        if (result != 'close') {
          if(result === 'remove'){
            this.dialogRef.close('remove');
          }else if(result === 'particular'){
            this.dialogRef.close('particular');
          }else{
            this.addBlockConfiguration();
          }
        }else{
          if(this.isEdit){
            this.cancel();
          }
        }
        
      })
    }
    if( blockName == 'createPatientBlock'){
      const dialogConfig = new MatDialogConfig();
      let data = this.data;
      dialogConfig.maxWidth = '1000px';
      dialogConfig.width = '1000px';
      dialogConfig.height = '100%';
      dialogConfig.data = data;
      dialogConfig.disableClose = true;
      dialogConfig.position = {
        right: "0"
      }
      const fsModal = this.sidePanelService.open(CreatePatientPanelComponent, dialogConfig);
      fsModal.afterClosed().subscribe(result => {
        if (result != 'close') {
          if(result === 'remove'){
            this.dialogRef.close('remove');
          }else if(result === 'particular'){
            this.dialogRef.close('particular');
          }else{
            this.addBlockConfiguration();
          }
        }else{
          if(this.isEdit){
            this.cancel();
          }
        }
      })
    }
    if(blockName === 'multiconditionalBlock'){
      const dialogConfig = new MatDialogConfig();
      let data = this.data;
      dialogConfig.maxWidth = '1000px';
      dialogConfig.width = '1000px';
      dialogConfig.height = '100%';
      dialogConfig.data = data;
      dialogConfig.disableClose = true;
      dialogConfig.position = {
        right: "0"
      }
      if(this.isEdit || this.isConditional == true){
        const fsModal = this.sidePanelService.open(MulticonditionPanelComponent, dialogConfig);
        fsModal.afterClosed().subscribe(result => {
          if (result != 'close') {
            if(result === 'remove'){
              this.dialogRef.close('remove');
            }else{
              this.addBlockConfiguration();
            }
          }else{
            if(this.isEdit){
              this.cancel();
            }
          }
        })
      }else{
        this.addBlockConfiguration();
      }
      
    }

    if( blockName == 'updatePatientBlock'){
      const dialogConfig = new MatDialogConfig();
      let data = this.data;
      dialogConfig.maxWidth = '1000px';
      dialogConfig.width = '1000px';
      dialogConfig.height = '100%';
      dialogConfig.data = data;
      dialogConfig.disableClose = true;
      dialogConfig.position = {
        right: "0"
      }
      const fsModal = this.sidePanelService.open(UpdatePatientPanelComponent, dialogConfig);
      fsModal.afterClosed().subscribe(result => {
        if (result != 'close') {
          if(result === 'remove'){
            this.dialogRef.close('remove');
          }else if(result === 'particular'){
            this.dialogRef.close('particular');
          }else{
            this.addBlockConfiguration();
          }
        }else{
          if(this.isEdit){
            this.cancel();
          }
        }
      })
    }

    if( blockName == 'reassignBlock'){
      const dialogConfig = new MatDialogConfig();
      let data = this.data;
      dialogConfig.maxWidth = '1000px';
      dialogConfig.width = '1000px';
      dialogConfig.height = '100%';
      dialogConfig.data = data;
      dialogConfig.disableClose = true;
      dialogConfig.position = {
        right: "0"
      }
      const fsModal = this.sidePanelService.open(ReassignPanelComponent, dialogConfig);
      fsModal.afterClosed().subscribe(result => {
        if (result != 'close') {
          if(result === 'remove'){
            this.dialogRef.close('remove');
          }else if(result === 'particular'){
            this.dialogRef.close('particular');
          }else{
            this.addBlockConfiguration();
          }
        }else{
          if(this.isEdit){
            this.cancel();
          }
        }
      })
    }
    if( blockName == 'cancelAppointmentBlock'){
      const dialogConfig = new MatDialogConfig();
      let data = this.data;
      dialogConfig.maxWidth = '1000px';
      dialogConfig.width = '1000px';
      dialogConfig.height = '100%';
      dialogConfig.data = data;
      dialogConfig.disableClose = true;
      dialogConfig.position = {
        right: "0"
      }
      if(this.isEdit || this.isConditional == true){
        const fsModal = this.sidePanelService.open(CancelAppointmentPanelComponent, dialogConfig);
        fsModal.afterClosed().subscribe(result => {
          if (result != 'close') {
            if(result === 'remove'){
              this.dialogRef.close('remove');
            }else if(result === 'particular'){
              this.dialogRef.close('particular');
            }else{
              this.addBlockConfiguration();
            }
          }else{
            if(this.isEdit){
              this.cancel();
            }
          }
        })
      }else{
        this.addBlockConfiguration();
      }
    }
    if( blockName == 'rescheduleBlock'){
      const dialogConfig = new MatDialogConfig();
      let data = this.data;
      dialogConfig.maxWidth = '1000px';
      dialogConfig.width = '1000px';
      dialogConfig.height = '100%';
      dialogConfig.data = data;
      dialogConfig.disableClose = true;
      dialogConfig.position = {
        right: "0"
      }
      const fsModal = this.sidePanelService.open(ReschedulePanelComponent, dialogConfig);
        fsModal.afterClosed().subscribe(result => {
          if (result != 'close') {
            if(result === 'remove'){
              this.dialogRef.close('remove');
            }else if(result === 'particular'){
              this.dialogRef.close('particular');
            }else{
              this.addBlockConfiguration();
            }
          }else{
            if(this.isEdit){
              this.cancel();
            }
          }
        })
    }
    if( blockName == 'launchAssessmentBlock'){
      const dialogConfig = new MatDialogConfig();
      let data = this.data;
      dialogConfig.maxWidth = '1000px';
      dialogConfig.width = '1000px';
      dialogConfig.height = '100%';
      dialogConfig.data = data;
      dialogConfig.disableClose = true;
      dialogConfig.position = {
        right: "0"
      }
      const fsModal = this.sidePanelService.open(LaunchAssessmentPanelComponent, dialogConfig);
        fsModal.afterClosed().subscribe(result => {
          if (result != 'close') {
            if(result === 'remove'){
              this.dialogRef.close('remove');
            }else if(result === 'particular'){
              this.dialogRef.close('particular');
            }else{
              this.addBlockConfiguration();
            }
          }else{
            if(this.isEdit){
              this.cancel();
            }
          }
        })
    }
    if( blockName == 'gotoBlock'){
      const dialogConfig = new MatDialogConfig();
      let data = this.data;
      dialogConfig.maxWidth = '1000px';
      dialogConfig.width = '1000px';
      dialogConfig.height = '100%';
      dialogConfig.data = data;
      dialogConfig.disableClose = true;
      dialogConfig.position = {
        right: "0"
      }
      if(this.isEdit || this.isConditional == true){
        const fsModal = this.sidePanelService.open(GotoPanelComponent, dialogConfig);
        fsModal.afterClosed().subscribe(result => {
          if (result != 'close') {
            if(result === 'remove'){
              this.dialogRef.close('remove');
            }else if(result === 'particular'){
              this.dialogRef.close('particular');
            }else{
              this.addBlockConfiguration();
            }
          }else{
            if(this.isEdit){
              this.cancel();
            }
          }
        })
      }else{
        this.addBlockConfiguration();
      }
    }
    if( blockName == 'createClinicianBlock'){
      const dialogConfig = new MatDialogConfig();
      let data = this.data;
      dialogConfig.maxWidth = '1000px';
      dialogConfig.width = '1000px';
      dialogConfig.height = '100%';
      dialogConfig.data = data;
      dialogConfig.disableClose = true;
      dialogConfig.position = {
        right: "0"
      }
      if(this.isEdit || this.isConditional == true){
        const fsModal = this.sidePanelService.open(CreateClinicianPanelComponent, dialogConfig);
        fsModal.afterClosed().subscribe(result => {
          if (result != 'close') {
            if(result === 'remove'){
              this.dialogRef.close('remove');
            }else if(result === 'particular'){
              this.dialogRef.close('particular');
            }else{
              this.addBlockConfiguration();
            }
          }else{
            if(this.isEdit){
              this.cancel();
            }
          }
        })
      }else{
        this.addBlockConfiguration();
      }
    }
    
    if( blockName == 'updateClinicianBlock'){
      const dialogConfig = new MatDialogConfig();
      let data = this.data;
      dialogConfig.maxWidth = '1000px';
      dialogConfig.width = '1000px';
      dialogConfig.height = '100%';
      dialogConfig.data = data;
      dialogConfig.disableClose = true;
      dialogConfig.position = {
        right: "0"
      }
      if(this.isEdit || this.isConditional == true){
        const fsModal = this.sidePanelService.open(UpdateClinicianPanelComponent, dialogConfig);
        fsModal.afterClosed().subscribe(result => {
          if (result != 'close') {
            if(result === 'remove'){
              this.dialogRef.close('remove');
            }else if(result === 'particular'){
              this.dialogRef.close('particular');
            }else{
              this.addBlockConfiguration();
            }
          }else{
            if(this.isEdit){
              this.cancel();
            }
          }
        })
      }else{
        this.addBlockConfiguration();
      }
    }

    if( blockName == 'assignClinicianBlock'){
      const dialogConfig = new MatDialogConfig();
      let data = this.data;
      dialogConfig.maxWidth = '1000px';
      dialogConfig.width = '1000px';
      dialogConfig.height = '100%';
      dialogConfig.data = data;
      dialogConfig.disableClose = true;
      dialogConfig.position = {
        right: "0"
      }
      if(this.isEdit || this.isConditional == true){
        const fsModal = this.sidePanelService.open(AssignClinicianPanelComponent, dialogConfig);
        fsModal.afterClosed().subscribe(result => {
          if (result != 'close') {
            if(result === 'remove'){
              this.dialogRef.close('remove');
            }else if(result === 'particular'){
              this.dialogRef.close('particular');
            }else{
              this.addBlockConfiguration();
            }
          }else{
            if(this.isEdit){
              this.cancel();
            }
          }
        })
      }else{
        this.addBlockConfiguration();
      }
    }
    if( blockName == 'includeBlock'){
      const dialogConfig = new MatDialogConfig();
      let data = this.data;
      dialogConfig.maxWidth = '1000px';
      dialogConfig.width = '1000px';
      dialogConfig.height = '100%';
      dialogConfig.data = data;
      dialogConfig.disableClose = true;
      dialogConfig.position = {
        right: "0"
      }
      const fsModal = this.sidePanelService.open(IncludePanelComponent, dialogConfig);
        fsModal.afterClosed().subscribe(result => {
          if (result != 'close') {
            if(result === 'remove'){
              this.dialogRef.close('remove');
            }else if(result === 'particular'){
              this.dialogRef.close('particular');
            }else{
              this.addBlockConfiguration();
            }
          }else{
            if(this.isEdit){
              this.cancel();
            }
          }
        })
    }
    if (blockName === 'scheduleBlock') {
      const dialogConfig = new MatDialogConfig();
      let data = this.data;
      dialogConfig.maxWidth = '1000px';
      dialogConfig.width = '1000px';
      dialogConfig.height = '100%';
      dialogConfig.data = data;
      dialogConfig.disableClose = true;
      dialogConfig.position = {
        right: "0"
      }
      const fsModal = this.sidePanelService.open(ScheduleBlockComponent, dialogConfig);
      fsModal.afterClosed().subscribe(result => {
        if (result != 'close') {
          if(result === 'remove'){
            this.dialogRef.close('remove');
          }else if(result === 'particular'){
            this.dialogRef.close('particular');
          }else{
            this.addBlockConfiguration();
          }
        }else{
          if(this.isEdit){
            this.cancel();
          }
        }
        
      })
    }
    if( blockName == 'launchBlock'){
      const dialogConfig = new MatDialogConfig();
      let data = this.data;
      dialogConfig.maxWidth = '1000px';
      dialogConfig.width = '1000px';
      dialogConfig.height = '100%';
      dialogConfig.data = data;
      dialogConfig.disableClose = true;
      dialogConfig.position = {
        right: "0"
      }
      const fsModal = this.sidePanelService.open(LaunchIncludePanelComponent, dialogConfig);
      fsModal.afterClosed().subscribe(result => {
        if (result != 'close') {
          if(result === 'remove'){
            this.dialogRef.close('remove');
          }else if(result === 'particular'){
            this.dialogRef.close('particular');
          }else{
            this.addBlockConfiguration();
          }
        }else{
          if(this.isEdit){
            this.cancel();
          }
        }
      })
    }
    if( blockName == 'includeReassign'){
      const dialogConfig = new MatDialogConfig();
      let data = this.data;
      dialogConfig.maxWidth = '1000px';
      dialogConfig.width = '1000px';
      dialogConfig.height = '100%';
      dialogConfig.data = data;
      dialogConfig.disableClose = true;
      dialogConfig.position = {
        right: "0"
      }
      const fsModal = this.sidePanelService.open(ReassignBlockComponent, dialogConfig);
      fsModal.afterClosed().subscribe(result => {
        if (result != 'close') {
          if(result === 'remove'){
            this.dialogRef.close('remove');
          }else if(result === 'particular'){
            this.dialogRef.close('particular');
          }else{
            this.addBlockConfiguration();
          }
        }else{
          if(this.isEdit){
            this.cancel();
          }
        }
      })
    }
    if( blockName == 'includeReschedule'){
      const dialogConfig = new MatDialogConfig();
      let data = this.data;
      dialogConfig.maxWidth = '1000px';
      dialogConfig.width = '1000px';
      dialogConfig.height = '100%';
      dialogConfig.data = data;
      dialogConfig.disableClose = true;
      dialogConfig.position = {
        right: "0"
      }
      const fsModal = this.sidePanelService.open(RescheduleBlockComponent, dialogConfig);
      fsModal.afterClosed().subscribe(result => {
        if (result != 'close') {
          if(result === 'remove'){
            this.dialogRef.close('remove');
          }else if(result === 'particular'){
            this.dialogRef.close('particular');
          }else{
            this.addBlockConfiguration();
          }
        }else{
          if(this.isEdit){
            this.cancel();
          }
        }
      })
    }
  }
  
  getBlockConfiguration(blockName: string) {
    let config;
    if(blockName === 'ifconditionalBlock') {
      config = {
        ifconditionalBlock: {
          fields: this.getFields()
        },
  
      };
    } else if (blockName === 'conditionalBlock') {
      config = {
        conditionalBlock: {
          fields: this.getFields()
        },
  
      };
    } else if(blockName === 'multiconditionalBlock'){
      config = {
        multiconditionalBlock: {
          field: this.getFields()
        }
      }
    }
    return config !== undefined ? config[blockName] : null;
  }

  prepareBlockModel(isConditional: boolean = false) {
    let inputs = {
      name: '',
      config: {},
      model: {},
      isConditional: this.isConditional
    };
    // forming model of conditional type field
    if (isConditional) {
      inputs['condition'] = {
        operator: '',
        value: '',
        col_name: this.cellModel ? this.cellModel['col_name']: this.parentModel ? this.parentModel['col_name'] : '',
        text: this.activeField ? this.activeField.text : '',
        type: this.activeField ? this.activeField.type : '',
        // fieldType: this.activeField.hasOwnProperty('fieldType') ? this.activeField['fieldType'] : '',
        // pageType: this.activeField.hasOwnProperty('pageType') ? this.activeField['pageType'] : '',
      }
    }
    return inputs;
  }

  getBlockModel(blockName: string, value: any = null) {
    let config;
    if(blockName === 'conditionalBlock'){
      config = {
        conditionalBlock: {
          col_name: value === null ? '' : value.hasOwnProperty('col_name') ? value['col_name'] : ''
        },
      };
    } else if(blockName === 'ifconditionalBlock'){
      config = {
        ifconditionalBlock: {
          col_name: value === null ? '' : value.hasOwnProperty('col_name') ? value['col_name'] : ''
        },
      };
    }
    return config !== undefined ? config[blockName] : null;
  }

  getFields() {
    let fields = [];
    _.forEach(this.selectedTriggerFields, (field: any, colName: any) => {
      fields.push({ id: colName, text: field.text, type: field.type });
    });
    return fields;
  }

  isValid() {
    let isValid = [];
    if (this.selectedBlock === 'conditionalBlock' || this.selectedBlock === 'ifconditionalBlock') {
      isValid.push(this.selectedBlockModel.model['col_name'] === '' ? false : true);
    }
    else if (this.selectedBlock === 'delayBlock') {
      isValid.push(this.selectedBlockModel.model['interval'] === '' ? false : true);
    }
    else if (this.selectedBlock === 'emailBlock') {
      isValid.push(true);
    } else if (this.selectedBlock === 'smsBlock') {
      isValid.push(true);
    }
    else {
      isValid.push(false);
    }

    if (this.isConditional) {
      let valid = this.selectedBlockModel.condition['operator'] != ''
        && this.selectedBlockModel.condition['value'] != '';
      isValid.push(valid);
    }

    if (_.contains(isValid, false)) {
      return false;
    }
    else {
      return isValid.length === 0 ? false : true;
    }

  }

  cancel() {
    this.dialogRef.close('close');
    this.mx_graphService.setGraphLoadingTrue()
  }

}
