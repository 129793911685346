import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InterfaceService } from './auth/interface.service';
import { map, catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ContactService extends InterfaceService {

    constructor(
        private http: HttpClient,
        private router: Router
    ) {
        super();
    }

    getZipCode(value: any) {
        return this.http.get(this.getApiUrl(`contacts/${value}/get-region-data/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getCityZip(city: any, state: any, country: any) {
        return this.http.get(this.getApiUrl(`contacts/${country}/get-city-zip/`, { city: city, state: state }), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getCityList(stateId: any) {
        return this.http.get(this.getApiUrl(`contacts/${stateId}/get-city-list/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getStateList(countryId: any) {
        return this.http.get(this.getApiUrl(`contacts/${countryId}/get-state-list/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getCountryData(){
        return this.http.get(this.getApiUrl(`contacts/get-country-data/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }
}
