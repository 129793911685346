import { Component, ViewEncapsulation} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ValidationService } from 'app/shared/services/validation.service';
import { UsersService } from 'app/support/services/users.service';
import { DeviceSettingsService } from 'app/support/services/device-settings.service';
import { SnackbarCongiration } from 'app/shared/components/snack-bar/SnackbarConfiguration';
import { GlobalService } from 'app/support/services/auth/global.service';
import { Clinic } from 'app/support/classes';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';

@Component({
    selector     : 'quick-panel',
    templateUrl  : './quick-panel.component.html',
    styleUrls    : ['./quick-panel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class QuickPanelComponent
{
    date: Date;
    events: any[];
    notes: any[];
    settings: any;
    enable_mail_form: boolean = true;
    helpForm: FormGroup;
    clinicsList: Array<any> = []
    clinic_drop: boolean = false;
    clinic_value = "";
    /**
     * Constructor
     */
    constructor(
        private fb: FormBuilder,
        private usersService: UsersService,
        private globalService: GlobalService,
        private deviceService: DeviceSettingsService,
        private snackbar: SnackbarCongiration,
    ){
        // Set the defaults
        this.date = new Date();
        this.settings = {
            notify: true,
            cloud : false,
            retro : true
        };
        this.helpForm = this.fb.group({
            help_text: [""],
        })
        this.globalService.user$.subscribe(res => { 
            if (res['user_type']){
                this.clinic_value = res['user_type']['name'];
                if(this.clinic_value == 'Clinic'){
                    this.helpForm.addControl('clinic', new FormControl(Validators.required))
                    this.deviceService.getClinicSettings().subscribe(response =>{
                        this.clinicsList = response['clinic_list'];
                        if(this.clinicsList.length == 1){
                            this.helpForm.controls['clinic'].setValue(this.clinicsList[0]['c_id']);
                        }
                    })
                }
            }
         });       
    }
    
    enableMailForm(){
        this.enable_mail_form = true;
    }
    disable(){
        this.enable_mail_form = false;
    }
    getSettingsForClinic(){
        
    }
    submitHelpForm(event, data){
        this.usersService.sendHelpMail(data)
            .subscribe(
                response => {
                    this.helpForm.reset();
                    // this.enable_mail_form = false;
                    if(response['status'] == "success"){
                        this.snackbar.triggerSnackBar({
                            message:response['message'],
                            type: 'success'
                          });
                    }
                    else{
                        this.snackbar.triggerSnackBar({
                            message:response['message'],
                            type: 'warning'
                          });
                    }
                },
                error => {
                   
                }
            )
    }
    
}
