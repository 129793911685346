import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-session-logout',
    templateUrl: './session-logout.component.html',
    styleUrls: ['./session-logout.component.scss']
})
export class SessionLogoutComponent implements OnInit {

    /**
     * Constructor
     *
     * @param {MatDialogRef<SessionLogoutComponent>} dialogRef
    */
    constructor(
        public dialogRef: MatDialogRef<SessionLogoutComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit() {
    }

    closeModal(param: boolean): void {
    }

    resetSession() {}

}
