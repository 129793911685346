import { Component, OnInit, NgZone } from '@angular/core';
import { Columns, GridConfig, Renderers, RowEvent, HeaderEvent } from 'app/shared/grid-datatable/grid-datatable';
import { HeaderRendererComponent } from 'app/shared/grid-datatable/header-renderer/header-renderer.component';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CellRendererComponent } from 'app/shared/grid-datatable/cell-renderer/cell-renderer.component';
import { AuthService } from 'app/support/services/auth/auth.service';
import { GlobalService } from 'app/support/services/auth/global.service';
import { PatientService } from 'app/support/services/patient.service';
import { CommonService } from 'app/support/services/common.service';
import { SnackbarCongiration } from 'app/shared/components/snack-bar/SnackbarConfiguration';
import { PatientGroupDeleteComponent } from 'app/features/main/patients/patient-group-delete/patient-group-delete.component';
import { MatDialogConfig, MatDialog, MatExpansionPanelHeader } from '@angular/material';
import { Subscription } from 'rxjs/internal/Subscription';


@Component({
    selector: 'app-patient-group',
    templateUrl: './patient-group.component.html',
    styleUrls: ['./patient-group.component.scss']
})
export class PatientGroupComponent implements OnInit {
    dialogbox;
    columns: Columns;
    config: GridConfig;
    renderers: Renderers;
    accesses: any;
    dataArray;
    public rowData1: any;
    groupName: String;
    patientGroupList: Array<any> = [];
    patientGroupId: object;
    individualDelete: object = {
        group_id: '',
        patient_list: ''
    }
    masterSelected: boolean;
    checklist: any;
    checkedList: any;
    patientList: Array<any> = [];
    idArray: Array<any> = [];
    ShowView: boolean;
    Loading: boolean = true;
    groupData: any;
    noresults: boolean = false;
    subscription1: Subscription;
    remainingList: Array<any> = [];
    totalLength: any;

    constructor(private router: Router,
        private globalService: GlobalService,
        private ngZone: NgZone,
        private patientService: PatientService,
        private cs: CommonService,
        private snackbar: SnackbarCongiration,
        private dialog: MatDialog,
    ) {


        globalService.user$.subscribe(data => {
            this.accesses = data.accesses;
        })
        this.groupData = this.patientService.patientGroupDetails();


    }
    ngOnInit() {
        let data = {
            'value': this.groupData['clinic_id']
        }
        this.patientService.clinicDataTransfer(data);
        this.groupName = this.groupData['group_name'];
        this.patientGroupId = this.groupData['group_id'];
        this.subscription1 = this.patientService.FilterGroupEvent$.subscribe(data => {
            if (data == true) {
                this.cs.patientViewEditFilterResult().subscribe(data => {
                    this.Loading = false;
                    let filterValue = data;
                    this.patientList = [];
                    this.patientList = filterValue['data'];
                    this.idArray = []
                    this.patientList.forEach(el => {
                        if (this.idArray.lastIndexOf(el['id']) == -1) {
                            this.idArray.push(el['id'])
                        }
                    })
                })
            }
            if (data == false) {
                this.patientList = [];
                this.Loading = true;
                this.patientDetailsList();
            }
        })

        this.patientDetailsList();

    }

    patientDetailsList() {
        this.patientService.getPatientsInGroupDetails(this.groupData).subscribe((datalist: Array<any>) => {
            this.Loading = true;
            if (datalist.length != 0) {
                this.totalLength = datalist['data'].length;
                if(datalist['data']) {
                    this.patientList = datalist['data'].slice(0, 150);
                    this.remainingList = datalist['data'].slice(150, this.totalLength);
                    datalist['data'].forEach(el => {
                        if (this.idArray.lastIndexOf(el['id']) == -1) {
                            this.idArray.push(el['id'])
                        }
                    })
                }
            } else {
                this.patientList = [];
                this.Loading = false;
            }
            this.Loading = false;
        })
        setTimeout(() => {
            this.setRemainingData()
        }, 500);    
    }
    reloadCurrentPage() {
        this.patientService.refreshRowData('refresh');
    }

    // set another remaining data
    setRemainingData() {
        if (this.patientList.length === this.totalLength) {
            return
        } else {
            let copyOfList = [...this.patientList, ...this.remainingList]
            this.patientList = [...new Set(copyOfList)]
        }
    }

    checkUncheckAll(checked) {
        this.idArray.forEach(el => {
            if (checked == true) {
                if (this.patientGroupList.lastIndexOf(el) == -1) {
                    this.patientGroupList.push(el)
                }
            }
            else {
                this.patientGroupList.forEach((ele, ind) => {
                    if (ele == el) {
                        this.patientGroupList.splice(ind, 1);
                    }
                })

            }
        })
        this.individualDelete['group_id'] = this.patientGroupId;
        this.individualDelete['patient_list'] = this.patientGroupList;
    }


    isAllSelected(checked, value) {
        if (checked == true) {
            if (this.patientGroupList.lastIndexOf(value['id']) == -1) {
                this.patientGroupList.push(value['id'])
            }
        }
        else {
            this.patientGroupList.forEach((ele, ind) => {
                if (ele == value['id']) {
                    this.patientGroupList.splice(ind, 1);
                }
            })
        }
        this.individualDelete['group_id'] = this.patientGroupId;
        this.individualDelete['patient_list'] = this.patientGroupList;
    }

    selectedAll() {
        let AllData = this.idArray;
        let SelectedData = this.patientGroupList;
        return AllData.length > 0 ? AllData.length == SelectedData.length : false
    }

    selectedRow(value) {
        let index = this.patientGroupList.findIndex(x => x == value['id'])
        return index > -1;
    }

    checkCondition() {
        if (this.idArray.length == this.patientGroupList.length) {
            return true
        }
    }

    DeletePatients() {
        this.patientService.DeletePatientorGroup('DeletePatient');
        let dialogConfig = new MatDialogConfig();
        // dialogConfig.position = { top: '70px' };
        dialogConfig.width = '600px';
        dialogConfig.scrollStrategy;
        dialogConfig.disableClose = true;
        dialogConfig.restoreFocus = false;
        dialogConfig.data = {
            data: this.individualDelete
        }
        let dialogbox = this.dialog.open(PatientGroupDeleteComponent, dialogConfig);
        dialogbox.afterClosed().subscribe(
            value => {
                if (value == 'yes') {
                    this.patientService.deletePatientsFromGroup(this.individualDelete).subscribe(data => {
                        let data1 = {
                            group_id: this.patientGroupId,
                            group_type: this.groupData['group_type']
                        }
                        this.patientService.getPatientsInGroupDetails(data1).subscribe((datalist: Array<any>) => {
                            if (datalist.length != 0) {
                                this.patientList = [];
                                this.Loading = true;
                                this.ShowView = false;
                                this.idArray = [];
                                this.patientList = datalist['data'];
                                this.Loading = false;
                                this.masterSelected = false;
                                this.patientList.forEach(el => {
                                    this.idArray.push(el['id'])
                                })
                            }

                            if (datalist.length == 0) {
                                this.patientList = [];
                                this.Loading = false;
                                this.masterSelected = false;


                            }

                        })

                        this.snackbar.triggerSnackBar({
                            message: 'Patient(s) Deleted from group successfully',
                            type: 'success'

                        });

                        this.reloadCurrentPage();

                        error => {
                            this.snackbar.triggerSnackBar({
                                message: 'Patient does not exist in the group',
                                type: 'error'
                            });
                        }
                    })

                    this.patientGroupList = [];

                }

            })
    }

    refreshGroup() {
        if (this.groupData['group_type'] == 'Dynamic') {
            this.patientService.refreshRowData('refresh');
        }
    }

    ngOnDestroy() {
        this.patientService.clearOrFilterPatientGroup(false);
        this.subscription1.unsubscribe()
    }

}