import {
    Component,
    ElementRef, OnInit,
    Renderer2,
    HostListener,
    Inject,
    ViewEncapsulation
} from '@angular/core';
import {ModalContainerComponent} from "ngx-bootstrap/modal";
import { TranslateService } from '@ngx-translate/core';
import { SidepanelOptions } from '../services/sidepanel-options';

// noinspection JSAnnotator
@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'sidepanel-modal-container',
    template: `
    <div [class]="'modal-dialog' + (config.class ? ' ' + config.class : '')" role="document">
      <div class="modal-content">
        <ng-content></ng-content>
      </div>
    </div>
  `,
   host: {
    class: 'modal cs-sidepanel-v2',
    role: 'dialog',
    tabindex: '-1',
    '[attr.aria-modal]': 'true',
    '[attr.aria-labelledby]': 'config.ariaLabelledBy',
    '[attr.aria-describedby]': 'config.ariaDescribedby'
   },
    styleUrls: ['./sidepanel.component.scss']
})

export class SidepanelComponent extends ModalContainerComponent implements OnInit{

    config: SidepanelOptions;

    private clickStartedInContent = false;

    constructor(options: SidepanelOptions,
      protected element: ElementRef,
      @Inject('confirmationBoxHelper') private confirmationBoxHelper,
      @Inject('$scope') private ajsScope,
      private translate: TranslateService,
      private renderer: Renderer2) {
        super(options, element, renderer);
    }

    ngOnInit() {
        super.ngOnInit();
        if(this.config.size != '')
            this.renderer.addClass(this.element.nativeElement, this.config.size);
    }

    @HostListener('body:click', ['$event'])
    onClickStarted(event: any): void {
        this.clickStartedInContent = event.target !== this.element.nativeElement;
        if(event.target.tagName==='BS-MODAL-BACKDROP') {
          // this.confirmationBoxHelper
          //   .getConfirmation(this.translate.instant('Are.you.sure.you.want.to.close.side.panel?'), this.ajsScope)
          //   .then(() => {
          //       this.hide();
          // });
        }
    }

    @HostListener('document:keydown.escape', ['$event'])
    onEsc(event: KeyboardEvent): void {
      if (event.keyCode === 27 || event.key === 'Escape') {
            event.preventDefault();
      }
      if(this.isShown) {
        // this.confirmationBoxHelper
        //   .getConfirmation(this.translate.instant('Are.you.sure.you.want.to.close.side.panel?'), this.ajsScope)
        //   .then(() => {
        //       this.hide();
        //   });
        }
    }
}