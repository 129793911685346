import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError, shareReplay } from 'rxjs/operators';
import { PatientService } from './patient.service';
import { InterfaceService } from './auth/interface.service';
import { GridConfig } from 'app/shared/grid-datatable/grid-datatable';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class CommonService extends InterfaceService {
    private cachePatient$: any;
    cacheRefresh: boolean = false;
    public filterResult: any = [];

    constructor(private http: HttpClient,
        private patientService: PatientService) {
        super();
    }

    updateUserState(data: any) {
        return this.http.post(this.getApiUrl(`user_state/`), data, this.getHttpOptions('json', true)).pipe(
            map(Response => Response),
            catchError(err => this.handleError(err))
        );
    }

    getUserState(screen: String) {
        return this.http.get(this.getApiUrl(`user_state/?screen=${screen}`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getPatientRecords(config: GridConfig) {
        if (this.cacheRefresh || !this.cachePatient$) {
            this.cachePatient$ = this.http.get<Object[]>(this.getApiUrl(config.remoteUrl, config.remoteParams), this.getHttpOptions('json', true)).pipe(
                shareReplay(1),
                catchError(err => this.handleError(err))
            );
            this.cacheRefresh = false;
        }
        return this.cachePatient$;
    }

    patientViewEditFilterResult() {
        let filter_details: Object = {};
        filter_details = this.filterResult
        return this.http.post(this.getApiUrl(`get_patient_filter/get-filter-result/`), { filter_details }, this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    setPatientFilterResullts(data) {
        this.filterResult = data;
    }

    getPatientFilterResullts() {
        return this.filterResult;
    }

    getRecords(config: GridConfig) {

        if (config.remoteUrl == 'patient_group_view') {
            return this.http.get(this.getApiUrl(`patient_group/`), this.getHttpOptions('json', true)).pipe(
                map(response => response),
                catchError(this.handleError)
            );
        }

        if (config.remoteUrl == 'get_filter_results') {
            let filter_details: Object = {};
            filter_details = this.filterResult
            return this.http.post(this.getApiUrl(`get_patient_filter/get-filter-result/`), { filter_details }, this.getHttpOptions('json', true)).pipe(
                map(response => response),
                catchError(this.handleError)
            );
        }

        if (config.remoteUrl == 'group_name_search') {
            let search_value = this.patientService.searchData()
            if (search_value) {
                return this.http.post(this.getApiUrl(`patient_group/patient-group-search/`), { search_value }, this.getHttpOptions('json', true)).pipe(
                    map(response => response),
                    catchError(this.handleError)
                );
            } else {
                return this.http.get(this.getApiUrl(`patient_group/`), this.getHttpOptions('json', true)).pipe(
                    map(response => response),
                    catchError(this.handleError)
                );
            }
        }

        // if (config.remoteUrl == 'patient_clinic_list') {
        //     if (data.clinicData) {
        //         return this.http.post(this.getApiUrl(`patients/search-patient/`), data.clinicData, this.getHttpOptions('json', true)).pipe(
        //             map(response => response),
        //             catchError(this.handleError)
        //         );
        //     }
        // }

        if (config.remoteUrl == 'patients') return this.getPatientRecords(config);
        else
            if (config.remoteUrl == 'audit-log/auditlog-list') {
                return this.http.get<Object[]>(this.getApiUrl(config.remoteUrl + '/', config.remoteParams, true), this.getHttpOptions('json', true)).pipe(
                    map(response => response),
                    catchError(err => this.handleError(err))
                );
            }
            else {
                return this.http.get<Object[]>(this.getApiUrl(config.remoteUrl + '/', config.remoteParams), this.getHttpOptions('json', true)).pipe(
                    map(response => response),
                    catchError(err => this.handleError(err))
                );
            }

    }

    getDownloadAudit(data) {
        console.log(data)
        return this.http.post(this.getApiUrl(`audit-log/auditlog-download-file/`, '', true), data, this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getDownloadClientAudit(data) {
        console.log(data)
        return this.http.post(this.getApiUrl(`audit-log/auditlog-download-file/`, '', true), data, { observe: 'response', responseType: 'blob' }).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }
    getdatabydatefiltered(data){
        return this.http.post(this.getApiUrl(`audit-log/auditlog-daterange-filter/`,'',true),data, this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getdynamicSmarTest(data) {
        console.log(data)
        return this.http.post(this.getApiUrl(`dynamic-task-status/`), data, this.getHttpOptions('json', true)).pipe(
            map(Response => Response),
            catchError(err => this.handleError(err))
        );
    }

    clearDatatableCache() {
        this.cachePatient$ = undefined;
    }

    chosenYearHandler(control) {
        if (control) {
            if (control.value.isAfter(moment(), 'year')) {
                control.value.set('year', control.value.get('year') - 100);
                control.setValue(control.value);
            }
        }
    }
}
