import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'app-zip-overwrite',
    templateUrl: './zip-overwrite.component.html',
    styleUrls: ['./zip-overwrite.component.scss']
})
export class ZipOverwriteComponent implements OnInit {

    zipControl: FormControl;

    /**
      * Constructor
      *
      * @param {MatDialogRef<ZipOverwriteComponent>} dialogRef
      */
    constructor(
        public dialogRef: MatDialogRef<ZipOverwriteComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.zipControl = new FormControl(null, Validators.required);
    }

    ngOnInit() {
    }

    closeModal(): void {
        this.dialogRef.close({ type: true, zip: this.zipControl.value });
    }
}
