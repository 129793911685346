import { Injectable } from '@angular/core';
import { ValidatorFn, FormGroup, AbstractControl, ValidationErrors } from '@angular/forms';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  static getValidatorErrorMessage(validatorName: string, validatorValue?: any, controlName?: string) {
    let parent
    let config = {
      'required': typeof validatorValue !== 'boolean' ? `Please enter ${ValidationService.formatControlName(controlName)}`
        : `Please select ${ValidationService.formatControlName(controlName)}`,
      'invalidCreditCard': 'Please enter valid credit card number',
      'invalidEmailAddress': 'Please enter valid email address',
      'invalidPassword': 'Please enter valid password',
      'invalidAzureWindowsPassword': 'Password must be 12 characters long and must have 3 of the following: 1 lower case, 1 upper case, 1 number, 1 special character',
      'notEqual': `Entered ${controlName} doesn\'t match`,
      'minlength': `Minimum length ${validatorValue.requiredLength}`,
      'invalidPercentage': 'Value should be inbetween 0-100',
      'invalidEmailRecipients': 'Please enter valid email address. Multiple emails should be seperated by comma.',
      'invalidUrl': 'Please enter valid url.',
      'invalidPhoneNumber': 'Mobile number must be 10 digits.',
      'invalidPincode': 'Zip code must be 5 digits.',
      'invalidNonEmptyList': 'Please select atleast one value.',
      'invalidPanNumber': 'PAN number must contain 10 digits',
      'invalidUserName': 'Please avoid space in username',
      'maxlength': `Maximum length ${validatorValue.requiredLength}`,
      'invalidInstanceName': 'Please enter valid name without space & special characters',
      'invalidMinimumLength': 'Name must be at least 4 characters long',
      'invalidBucketName': 'Bucket name must be in lowercase letters',
      'invalidAccesstoken': 'Please enter valid access token',
      'equalTo': 'Password doesn\'t match',
      'requireOneCheckboxToBeChecked': 'Please check atleast one role',
      'whitespace': 'Empty spaces are not alowed'
    };

    return config[validatorName];
  }

  static formatControlName(name: string) {
    if (name) return name.replace(/_/g, ' ').trim().toLocaleLowerCase();
    else return "input";
  }

  static noWhitespaceValidator(control){
    return (control.value || '').trim().length? null : { 'whitespace': true};       
  }


  static creditCardValidator(control) {
    // Visa, MasterCard, American Express, Diners Club, Discover, JCB
    if (control.value.match(/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/)) {
      return null;
    } else {
      return { 'invalidCreditCard': true };
    }
  }

  static emailMobileValidator(control) {
    if ((!control.value) || control.value.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,}))$/))
      return null;
    else {
      if (!control.value || control.value.match(/^[1-9][0-9]{9}$/))
        return null;
      else return { 'invalid': true };
    }
  }

  static emailValidator(control) {
    // RFC 2822 compliant regex
    if ((!control.value) || control.value.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,}))$/)) {
      return null;
    } else {
      return { 'invalidEmailAddress': true };
    }
  }

  static passwordValidator(control) {
    // {8,100}           - Assert password is between 8 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    // (?=.*[A-Z])       - Assert a password should have atleast one capital letter 

    if (control.value) {
      if (control.value.match(/^(?=.*[0-9])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{8,100}$/)) {
        return null;
      } else {
        return { 'invalidPassword': true };
      }
    }
  }

  static azureWindowsPasswordValidator(control) {
    // {6,100}           - Assert password is between 12 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    // (?=.*[!@#$%^&*])  - Assert a special character 
    if (control.value.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{12,100}$/)) {
      return null;
    } else {
      return { 'invalidAzureWindowsPassword': true };
    }
  }

  static nameWithoutSpaceValidator(control) {
    if (control.value.match(/^[a-zA-Z0-9\-_]{0,40}$/)) {
      return null;
    } else {
      return { 'invalidInstanceName': true };
    }
  }

  static upperCaseValidator(control) {
    if (control.value.match(/^[a-z0-9\-_]{0,40}$/)) {
      return null;
    } else {
      return { 'invalidBucketName': true };
    }
  }

  static minimumLengthValidator(control) {
    if (control.value.match(/^.{4,40}$/)) {
      return null;
    } else {
      return { 'invalidMinimumLength': true };
    }
  }

  static percentageValidator(control) {
    // 0-100 percentage value is between 0 to 100
    if (control.value >= 0 && control.value <= 100) {
      return null;
    } else {
      return { 'invalidPercentage': true };
    }
  }

  static multipleEmailValidator(control) {
    // Multiple emails seperated by comma regex
    if (control.value.match(/(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*,\s*|\s*$))+/)) {
      return null;
    }
    else {
      return { 'invalidEmailRecipients': true };
    }
  }

  static urlValidator(control) {
    // Url regex
    if (!control.value || control.value.match(/(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/)) {
      return null;
    }
    else {
      return { 'invalidUrl': true };
    } 
  }
    
  static urlvalidatorstr(control){
    var custom_validate = require('url-validation');
    let value =  control.value ? control.value.toString():"";
    
    if (!value) {
      return null;
    }
    
    if(value && !value.startsWith("http")){
      value = "http://"+value;
    }

    if(custom_validate(value)){
      return null;
    }
    else {
      return { 'invalidUrl': true };
    }
  }

  static numberValidator(control) {
    var number = new RegExp("^[0-9]{1,3}$")

    if (!control.value || control.value && number.test(control.value)) {
      return null;
    }
    else {
      return { 'invalidNumber': "Invalid number" };
    }
  }

  static phoneValidator(control) {
    var phoneNumber = new RegExp("^[1-9][0-9]{9}$")

    if (!control.value || control.value && phoneNumber.test(control.value)) {
      return null;
    }
    else {
      return { 'invalidPhoneNumber': true };
    }
  }
  static pincodeValidator(control) {
    var pincode = new RegExp("^[0-9]{5}$");
    if (!control.value || control.value && pincode.test(control.value)) {
      return null;
    }
    else {
      return { 'invalidPincode': true };
    }
  }

  static pancardValidator(control) {
    var pan_number = /^[a-zA-Z0-9_.-]*$/;
    if (control.value.match(pan_number) || null) {
      return null;
    }
    else {
      return { 'invalidPanNumber': true };
    }


  }

  static nonEmptylistValidator(control) {
    // Checks is array & has Minimum one item in array
    if (control.value instanceof Array && (control.value.length > 0)) {
      return null;
    }
    else {
      return { 'invalidNonEmptyList': true };
    }
  }

  static equalValidator(group) {
    // Checks all values of controls in group are equal
    let controlNames: Array<string> = Object.keys(group.value);
    let first: string = controlNames.splice(0, 1)[0];

    for (let controlName of controlNames) {
      if (group.controls[first].touched && group.controls[controlName].touched && group.value[controlName] != group.value[first]) {
        return { 'notEqual': true };
      }
    }
    return null;
  }

  static userNameValidator(control) {
    var regex = /[^A-Za-z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g; //_@./#&+-
    if (control.value != null) {
      if (!control.value.match(regex)) {
        return null;
      }
      else {
        return { 'invalidUserName': true };
      }
    }
  }

  static accessTokenvalidator(control) {
    if (control.value.length == 32) {
      return null;
    }
    else {
      return { 'invalidAccesstoken': true };
    }
  }

  static lbsValidator(control) {
    let val = control.value;
    if (val != '' && val != null) {
      val = val + '';
      if (val.match(/^[0-9]{1,3}$|^[0-9]{1,3}\.[0-9]{1,2}$/)) {
        return null;
      }
      else {
        return { 'invalidLbs': true };
      }
    }
    else {
      return null;
    }
  }

  static heightFeetValidator(control) {
    if (control.value != '' && control.value != null) {
      if (control.value && control.value.match(/^[0-9]{1}$/)) {
        return null;
      }
      else {
        return { 'invalidFeetHeight': true };
      }
    }
    else {
      return null;
    }
  }

  static heightInchesValidator(control) {
    if (control.value != '' && control.value != null) {
      if (control.value && control.value.match(/^[0-9]{1,2}$/)) {
        return null;
      }
      else {
        return { 'invalidInchesHeight': true };
      }
    }
    else {
      return null;
    }
  }

  static heightValidator(control) {
    if (control.value != '' && control.value != null) {
      if (control.value && control.value.match(/^[0-9]{1}$|^[0-9]{1,3}\.[0-9]{1,2}$/)) {
        return null;
      }
      else {
        return { 'invalidHeight': true };
      }
    }
    else {
      return null;
    }
  }

  static bloodPressureValidator(control) {
    if (control.value != '' && control.value != null) {
      if (control.value && control.value.match(/^[0-9]{1,3}$/)) {
        return null;
      }
      else {
        return { 'invalidBP': true };
      }
    }
    else {
      return null;
    }
  }

  static checkBoxValidator(minRequired = 1): ValidatorFn {
    return function validate(formGroup: FormGroup) {
      let checked = 0;

      Object.keys(formGroup.controls).forEach(key => {
        const control = formGroup.controls[key];

        if (control.value === true) {
          checked++;
        }
      });

      if (checked < minRequired) {
        return {
          requireOneCheckboxToBeChecked: true,
        };
      }

      return null;
    };
  }

  /**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
  static confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    if (!control.parent || !control) {
      return null;
    }

    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('passwordConfirm');

    if (!password || !passwordConfirm) {
      return null;
    }

    if (passwordConfirm.value === '') {
      return null;
    }

    if (password.value === passwordConfirm.value) {
      return null;
    }

    return { passwordsNotMatching: true };
  };

  /**
* Check days, hours and minutes for assessment queue expiration time
*
* @param {AbstractControl} control
* @returns {ValidationErrors | null}
*/
  static checkDayTime: ValidatorFn = (control): ValidationErrors | null => {

    if (!control.parent || !control) {
      return null;
    }
    const expiration_day = control.parent.get('expiration_days');
    const expiration_hour = control.parent.get('expiration_hrs');
    const expiration_min = control.parent.get('expiration_mins');
    if (expiration_day.value == "0" && Number(expiration_hour.value) == 0 && Number(expiration_min.value) == 0) {
      if (expiration_day['errors'] != null && expiration_day['errors']['invalidDayTime'] == true) {
        control.parent.get('expiration_days').setErrors(null);
      }
      if (expiration_hour['errors'] != null && expiration_hour['errors']['invalidDayTime'] == true) {
        control.parent.get('expiration_hrs').setErrors(null)
      }
      if (expiration_min['errors'] != null && expiration_min['errors']['invalidDayTime'] == true) {
        control.parent.get('expiration_mins').setErrors(null)
      }
      control.parent.updateValueAndValidity();
      return { invalidDayTime: true };
    }
    else {
      return null;
    }
  };

  /* Check days, hours and minutes for patient assessment link expiration time
   *
   * @param {AbstractControl} control
   * @returns {ValidationErrors | null}
   */
  static checkDayTimeForLink: ValidatorFn = (control): ValidationErrors | null => {

    if (!control.parent || !control) {
      return null;
    }
    const time_duration_day = control.parent.get('time_duration_days');
    const time_duration_hour = control.parent.get('time_duration_hrs');
    const time_duration_min = control.parent.get('time_duration_mins');

    if (time_duration_day.value == "0" && Number(time_duration_hour.value) == 0 && Number(time_duration_min.value) == 0) {
      if (time_duration_day['errors'] != null && time_duration_day['errors']['invalidDayTime'] == true) {
        control.parent.get('time_duration_days').setErrors(null);
      }
      if (time_duration_hour['errors'] != null && time_duration_hour['errors']['invalidDayTime'] == true) {
        control.parent.get('time_duration_hrs').setErrors(null)
      }
      if (time_duration_min['errors'] != null && time_duration_min['errors']['invalidDayTime'] == true) {
        control.parent.get('time_duration_mins').setErrors(null)
      }
      control.parent.updateValueAndValidity();
      return { invalidDayTime: true };
    }
    else {
      return null;
    }
  };

  static legendValidator(legendArr: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.parent || !control) {
        return null;
      }

      const start_range = control.parent.get('start_range');
      const end_range = control.parent.get('end_range');

      let start_range_val = start_range.value
      let end_range_val = end_range.value

      if (end_range_val && end_range_val > 99999) return { 'end_max': true };
      else if (end_range.hasError('end_max')) end_range.setErrors(null);

      if (start_range_val !== '') {
        if (start_range_val == 0 || (start_range_val > 0 && start_range_val !== null)) {
          if (legendArr.length == 0) {
            if (end_range_val !== null && end_range_val !== undefined && end_range_val !== '') {
              if (start_range_val > end_range_val) {
                return { 'start_range_error': true }
              }
              else {
                end_range.setErrors(null)
              }
            }
          }
          else {
            if (end_range_val != null && end_range_val != undefined && end_range_val != '') {
              if (start_range_val > end_range_val || start_range_val <= legendArr[legendArr.length - 1].end_range) {
                return { 'start_range_error': true }
              }
              else {
                start_range.setErrors(null);
                end_range.setErrors(null);
              }
            }
            else {
              if (start_range_val <= legendArr[legendArr.length - 1].end_range) {
                return { 'start_range_error': true }
              }
            }
          }
        }
      }

      return null
    }
  }

  static timeValidation: ValidatorFn = (control): ValidationErrors | null => {
    if (!control.parent || !control) {
      return null;
    }
    if (control.value) {
      let hour = control.value['hours'];
      let minutes = control.value['minutes'];
      let am_pm = control.value['am_pm'];
      let currentTime = moment();
      let run_date = control.parent.get('run_date').value;
      let enteredTime = moment(run_date.format('YYYY-MM-DD') + ' ' + hour + ':' + minutes + ' ' + am_pm, 'YYYY-MM-DD hh:mm a')
      if (enteredTime.isBefore(currentTime)) {
        // console.log('error')
        return { 'past_time': true }
      }
    }
    return null
  }

  static deleteValidator(matchingWord='DELETE'): ValidatorFn {
    return (control: AbstractControl) => {
      if (control && control.value && control.value == matchingWord) {
        return null;
      } else {
        return { 'notMatching': true };
      }
    }
  }

}
