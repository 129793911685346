import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { IHeaderAngularComp } from "ag-grid-angular";
import { Subscription } from "rxjs/internal/Subscription";
import { PatientService } from "app/support/services/patient.service";
import { GlobalService } from "app/support/services/auth/global.service";

@Component({
    selector: "app-header-renderer",
    templateUrl: "./header-renderer.component.html",
    styleUrls: ["./header-renderer.component.scss"],
})
export class HeaderRendererComponent implements OnInit, IHeaderAngularComp {
    public params: any;
    header: any;
    templateRef: Array<string>;
    table: string;
    type: string;
    fullScreen: boolean = true;
    user: any;
    isSelectAll: boolean = false;
    unCheckedList: Array<any> = [];

    checkboxSubscription: Subscription;
    uncheckSubscriber: Subscription;

    constructor(
        private patientservice: PatientService,
        private globalService: GlobalService
    ) {}

    ngOnInit() {
        this.uncheckSubscriber = this.patientservice.uncheckList$.subscribe(
            (data) => {
                this.unCheckedList = data;
                if (data.length > 0) {
                    this.isSelectAll = false;
                } else {
                    this.isSelectAll = true;
                }
            }
        );
    }

    agInit(params: any): void {
        this.globalService.user$.subscribe((user) => (this.user = user));
        this.params = params;
        this.header = params.column.colDef.headerName;
        if (params.context.headerRenderer) {
            params.context.headerRenderer.forEach((element) => {
                if (element.column == this.header) {
                    this.templateRef = element.renderer;
                }
            });
        }
        this.table = params.context.table;
    }

    headerEvent(action: string) {
        let event = { action: action };
        if (action == "fullscreen") {
            event["fullScreen"] = this.fullScreen;
            this.fullScreen = !this.fullScreen;
        }
        if (action == "Selectall") {
            event["Selectall"] = this.isSelectAll;
            this.isSelectAll = !this.isSelectAll;
        }
        this.params.context.gridRenderer.emitHeaderEvent(event);
    }
}
