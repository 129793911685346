import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'MHT',
    pure: false
})
export class MHTPipe implements PipeTransform {

    transform(value: any, needed: boolean = true): any {
        // if(needed) {
        //     // value = value.replace('Predefined/', '').replace(/([A-Z])/g, ' $1').trim().toLowerCase().replace('mht', 'MHT');
        //     return value.replace('Predefined/', '').replace(/([A-Z])/g, ' $1').trim().toLowerCase().replace('mht', 'MHT');
        // }
        // else 
        return value;
    }

}
