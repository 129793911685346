import { Component, OnInit, ViewEncapsulation, Inject, ViewChild, ElementRef, ViewChildren, QueryList, HostListener } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { DashboardService } from 'app/support/services/dashboard.service';
import { FormControl } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import $ from 'jquery';
import { GlobalService } from 'app/support/services/auth/global.service';
import { User } from 'app/support/classes';
const moment = _moment

export const MY_FORMATS = {
	parse: {
		dateInput: 'll',
	},
	display: {
		dateInput: 'll',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
	encapsulation: ViewEncapsulation.None,
	// providers: [
	// 	{
	// 		provide: DateAdapter,
	// 		useClass: MomentDateAdapter,
	// 		deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
	// 	},
	// 	{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
	// ],
})
export class DashboardComponent implements OnInit {
	current_date = new Date()
	selectedWeek: any;
	current_year: any = new Date().getFullYear();
	showMultipleClinic: boolean = false;
	// Date Form Control
	start = new Date("1/1/" + this.current_year);
	Start_date = new FormControl(moment().startOf('day').subtract(6, 'days')); //new FormControl(moment(this.start.valueOf()));
	End_date = new FormControl(moment());

	formLoad: boolean;
	chartLoad: boolean;
	snapshotLoad: boolean;
	clinicLoad: boolean;
	show_field: boolean = true;
	dashboardData = []
	ChartData: any = []
	// clinics: any = [];
	weekList: any = [];
	selectOption: any = 'dashboard';
	selectType: any = 'Monthly';
	chart_title = undefined;
	select_text: any = 'UnSelect All';
	assessmentData = []
	dropdownSettings: any = {}
	dropdownList: any = []
	selectedItems: any = []
	widgetWidth: any = 100;
	assessmentOption: any = 'daterange';
	dropdown_text: any = 'All Assessment'

	average_chart_load: boolean = false;

	@ViewChildren('clinicPane') clinicPane: QueryList<any>;
	@ViewChild('clinicOverallSearch', { static: false }) clinicOverallSearch: ElementRef<HTMLInputElement>;
	@ViewChildren('assessmentSearch') assessmentSearch: QueryList<HTMLInputElement>;
	@ViewChildren('clinicSearch') clinicSearch: QueryList<HTMLInputElement>;
	// Clinic_list: any = [];
	assessment_list: any = [];
	clinicname: any;
	defaultClinic: any = -1;
	clinicItems: any = []
	config = {
		paddingAtStart: true,
		classname: 'my-custom-class',
		fontColor: 'rgb(8, 54, 71)',
		backgroundColor: 'white',
		selectedListFontColor: 'rgb(78, 146, 217)',
	};
	showClinicsList: boolean = false;
	selectedClinicName: any = 'All clinics';
	clinicOutFocus: boolean = false;
	selectedClinic: any = null;
	user: User;

	assessmentVersions: Object = {};

	constructor(
		private dashboardService: DashboardService,
		private dialog: MatDialog,
		private eRef: ElementRef,
		private globalService: GlobalService
	) {
		this.globalService.user$.subscribe(user => {
			this.user = user;
		})
	}

	ngOnInit() {
		console.log(this.End_date);
		this.getDropdownList();
		// this.Start_date.valueChanges.subscribe(date => {
		// 	this.selectassessmentOption()
		// })

		// this.End_date.valueChanges.subscribe(date => {
		// 	this.selectassessmentOption()
		// })
		this.dashboardService.getParentBasedClinics().subscribe(data => {
			if (data) {
				this.clinicItems = data;
				if (this.clinicItems.length > 1) {
					this.clinicItems.unshift({ 'id': -1, 'label': 'All Clinics' })
				}
				else {
					this.selectedClinicName = this.clinicItems[0]['label'];
				}
			}
		})
	}

	@HostListener('document:click', ['$event'])
	outFocus() {
		if (this.eRef.nativeElement.contains(event.target)) {
			if (!this.clinicOutFocus) {
				this.showClinicsList = false;
			}
		}
		this.clinicOutFocus = false;
	}


	showClinics() {
		if (this.showClinicsList) {
			this.showClinicsList = false;
		} else {
			this.showClinicsList = true;
			this.clinicOutFocus = true;
		}
	}

	selectedLabel(event) {
		if (event.target.textContent != ' keyboard_arrow_down ') {
			this.selectedClinicName = event.target.textContent;
			this.showClinicsList = false;
			if (event.target.textContent == 'All Clinics') {
				this.SelectedClinicChange({ clinic_id: -1 })
			}
			else {
				var selected_clinic = this.clinicItems.filter(i => i.label == event.target.textContent)
				this.SelectedClinicChange(selected_clinic[0])
			}
		}
		else {
			this.clinicOutFocus = true;
		}
	}

	onSearchChange(text, data) {
		if (text) {
			data['dropdownList'] = data['dataList'].filter(x => (x['name'].toLowerCase()).match(text.toLowerCase()))
		} else {
			data['dropdownList'] = data['dataList']
		}

	}

	getDropdownList() {
		this.dashboardService.runAssessmentList().subscribe(data => {
			if (data) {
				this.assessment_list = data['assessment_list'];
				this.setVersions();
				this.setAssessment();
				this.getInitialChartData();
			}
		})
		// this.getClinicList();

	}

	getChecked(list, id) {
		let index = list.findIndex(i => i.id == id)
		if (index == -1) {
			return false;
		} else {
			return true;
		}
	}
	dashboardWidget(params) {
		// this.formLoad = false
		this.dashboardService.getClinicianWidgetData(params).subscribe(res => {
			if (res['clinicians_widget'].length > 0) {
				this.formLoad = true
				this.dashboardData.push(res['clinicians_widget'][0])
				this.dashboardData.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
			}
		}),
			this.dashboardService.getClinicsWidgetData(params).subscribe(res => {
				if (res['clinics_widget'].length > 0) {
					this.formLoad = true
					this.dashboardData.push(res['clinics_widget'][0])
					this.dashboardData.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
				}
			}),
			this.dashboardService.getPatientWidgetData(params).subscribe(res => {
				if (res['patient_widget'].length > 0) {
					this.formLoad = true
					this.dashboardData.push(res['patient_widget'][0])
					this.dashboardData.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
				}
			}),
			this.dashboardService.getAssessmentWidgetData(params).subscribe(res => {
				if (res['assessments_widget'].length > 0) {
					this.formLoad = true
					this.dashboardData.push(res['assessments_widget'][0])
					this.dashboardData.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
				}
			})
	}

	getInitialChartData() {
		this.ChartData = []
		let params = {
			'assessmentOption': this.assessmentOption,
			'current_date': moment().endOf('day').toISOString(),
			'timezone': new Date().getTimezoneOffset()
		}
		if (this.assessmentOption == 'daterange') {
			let tzoffset = (new Date()).getTimezoneOffset() * 60000;
			params['start_date'] = (new Date(this.Start_date.value.startOf('day') - tzoffset)).toISOString();
			params['end_date'] = (new Date(this.End_date.value.endOf('day') - tzoffset)).toISOString();
		}
		this.dashboardWidget(params);
		this.chartLoad = false
		this.dashboardService.getDashboardChartData({ timezone: new Date().getTimezoneOffset(), assessmentVersions: this.assessmentVersions }).subscribe(res => {
			if (res) {
				this.chartLoad = true
				let week = res['weekly_data'].reverse();
				if (res['chart_data'].length > 0) {
					res['chart_data'].forEach(charts => {
						charts.series.forEach(elt => {
							elt['type'] = undefined
						})
						charts.legend['useHTML'] = true
						charts.legend['labelFormatter'] = function () {
							return '<div title="' + this.name + '" style="color:' + this.color + ';white-space:no-wrap;overflow:hidden;text-overflow:ellipsis;">' + this.name + '</div>'
						}
						this.ChartData.push(
							{
								'title': charts.chart_name,
								'period': [
									{
										'name': 'Monthly',
										'type': 'button',
										'width': 30,
										'active': false
									},
									{
										'name': 'Weekly',
										'type': 'dropdown',
										'selectedWeek': week[0]['value'],
										'dropdownData': week,
										'width': 15,
										'active': true
									}

								],
								'chartDetails': this.chartOptionSet(charts)

							}
						)
					})
					this.ChartData.forEach(elt => {
						elt['clinics'] = this.clinicItems;
						// elt['Clinic_list'] = this.clinics.map(x => Object.assign({}, x));
						elt['Clinic_list'] = [...this.clinicItems];
						elt['selectedClinic'] = [];
						elt['clinicname'] = ''
					})
					this.setAssessment();
				}
			}
		}, error => {
			this.chartLoad = true
		})
		// if (this.user.user_type['name'] != 'MHT') {
		// 	this.average_chart_load = true;
		// 	this.dashboardService.getAverageAssessmentScore().subscribe((data: any) => {
		// 		let chart_data = data['chart_data'];
		// 		let week = data['weekly_data'].reverse();
		// 		chart_data.series.forEach(elt => {
		// 			elt['type'] = undefined
		// 		})
		// 		chart_data.legend['useHTML'] = true
		// 		chart_data.legend['labelFormatter'] = function () {
		// 			return '<div title="' + this.name + '" style="color:' + this.color + ';white-space:no-wrap;overflow:hidden;text-overflow:ellipsis;">' + this.name + '</div>'
		// 		}
		// 		chart_data['clinics'] = this.clinicItems;
		// 		// elt['Clinic_list'] = this.clinics.map(x => Object.assign({}, x));
		// 		chart_data['Clinic_list'] = [...this.clinicItems];
		// 		chart_data['selectedClinic'] = [];
		// 		chart_data['clinicname'] = ''

		// 		chart_data['dataList'] = this.assessment_list;
		// 		chart_data['dropdownList'] = this.assessment_list;
		// 		chart_data['selectedItems'] = chart_data['dropdownList'].map(x => Object.assign({}, x));
		// 		chart_data['dropdown_text'] = 'All Assessments'
		// 		chart_data['dropdown_name'] = ''
		// 		this.ChartData.push(
		// 			{
		// 				'title': chart_data.chart_name,
		// 				'period': [
		// 					{
		// 						'name': 'Monthly',
		// 						'type': 'button',
		// 						'width': 30,
		// 						'active': false
		// 					},
		// 					{
		// 						'name': 'Weekly',
		// 						'type': 'dropdown',
		// 						'selectedWeek': week[0]['value'],
		// 						'dropdownData': week,
		// 						'width': 15,
		// 						'active': true
		// 					}

		// 				],
		// 				'chartDetails': this.chartOptionSet(chart_data)
		// 			}
		// 		)
		// 		this.average_chart_load = false;
		// 	})
		// }

	}

	setAssessment() {
		this.ChartData.forEach(elt => {
			if (elt.title == 'Assessment Details' || elt.title == 'Average Assessment Score') {
				elt['dataList'] = this.assessment_list;
				elt['dropdownList'] = this.assessment_list;
				elt['selectedItems'] = elt['dropdownList'].map(x => Object.assign({}, x));
				elt['dropdown_text'] = 'All Assessments'
				elt['dropdown_name'] = ''
			}
		})
	}

	setVersions() {
		this.assessment_list.forEach(assElem => {
			assElem['version'].forEach(varElem => {
				if (!(varElem in this.assessmentVersions)) {
					this.assessmentVersions[varElem] = {
						'version': assElem['version'],
						'name': assElem['name']
					}
				}
			});
		});
	}

	// getClinicList() {
	// 	this.dashboardService.getClinics().subscribe(data => {
	// 		if (data) {
	// 			this.clinics = data['clinic_data'].map(x => Object.assign({}, x));
	// 			this.Clinic_list = this.clinics.map(x => Object.assign({}, x));
	// 			this.getInitialChartData();
	// 		}
	// 	})
	// }

	getChartByClinic(data) {
		data['assessmentVersions'] = this.assessmentVersions;
		// to get chart data by clinic
		this.dashboardService.filterClinicData(data).subscribe(
			res => {
				this.chartLoad = true
				if (res) {
					if (res['data'].length > 0) {
						res['data'].forEach(charts => {
							let index = this.ChartData.findIndex(i => i.title == charts.chart_name)
							charts.legend['useHTML'] = true
							charts.legend['labelFormatter'] = function () {
								return '<div title="' + this.name + '" style="color:' + this.color + ';white-space:no-wrap;overflow:hidden;text-overflow:ellipsis;">' + this.name + '</div>'
							}
							if (index != -1) {
								this.ChartData[index]['chartDetails'] = this.chartOptionSet(charts)
							}
						})
					}
				}
			})

		// if (this.user.user_type['name'] != 'MHT') {
		// 	let index = this.ChartData.findIndex(i => i.title == 'Average Assessment Score');
		// 	let old_avarage_graph = { ...this.ChartData[index] };
		// 	this.ChartData.splice(index, 1);
		// 	data['chart'] = average_score_chart;
		// 	this.average_chart_load = true;
		// 	this.dashboardService.getAverageAssessmentScore(data).subscribe((data: any) => {
		// 		old_avarage_graph['useHTML'] = true
		// 		old_avarage_graph['labelFormatter'] = function () {
		// 			return '<div title="' + this.name + '" style="color:' + this.color + ';white-space:no-wrap;overflow:hidden;text-overflow:ellipsis;">' + this.name + '</div>'
		// 		}
		// 		if (index != -1) {
		// 			old_avarage_graph['chartDetails'] = this.chartOptionSet(data['chart_data'])
		// 		}
		// 		this.ChartData.push(old_avarage_graph);
		// 		this.average_chart_load = false;
		// 	})
		// }
	}

	SelectedClinicChange(event) {
		this.clearChartfilter();
		let clinic;
		if (event.clinic_id != -1) {
			this.showMultipleClinic = true;
			clinic = event.clinic_id;
		} else {
			clinic = null
			this.showMultipleClinic = false
		}
		this.selectedClinic = clinic
		this.defaultClinic = event.clinic_id;
		this.clinicLoad = true
		this.chartLoad = false
		let paramsData = {
			'clinic': clinic,
			'current_date': moment().endOf('day').toISOString(),
			'timezone': new Date().getTimezoneOffset()
		}
		this.dashboardData = []
		this.dashboardWidget(paramsData);
		this.clinicLoad = false
		let snapshotParams = {
			'assessmentOption': this.assessmentOption,
			'clinic_id': clinic,
			'timezone': new Date().getTimezoneOffset()
		}
		if (this.assessmentOption == 'daterange') {
			// snapshotParams['start_date'] = (new Date(this.Start_date.value.startOf('day') - tzoffset)).toISOString(),
			// snapshotParams['end_date'] = (new Date(this.End_date.value.endOf('day') - tzoffset)).toISOString()
			// snapshotParams['start_date'] = new Date(this.Start_date.value).toLocaleDateString();;
			// snapshotParams['end_date'] = new Date(this.End_date.value).toLocaleDateString();;
			snapshotParams['start_date'] = this.Start_date.value.format('MM/DD/YYYY');
			snapshotParams['end_date'] = this.End_date.value.format('MM/DD/YYYY');
		}
		else if (this.assessmentOption == 'current_month') {
			snapshotParams['start_date'] = moment().startOf('month').format('MM/DD/YYYY');
			snapshotParams['end_date'] = moment().endOf('month').format('MM/DD/YYYY');
			// params['start_date'] = new Date(<any>moment().startOf('month')).toLocaleDateString();
			// params['end_date'] = new Date(<any>moment().endOf('month')).toLocaleDateString();
		}
		else if (this.assessmentOption == 'current_year') {
			snapshotParams['start_date'] = moment().startOf('year').format('MM/DD/YYYY');
			snapshotParams['end_date'] = moment().endOf('year').format('MM/DD/YYYY');
			// params['start_date'] = new Date(<any>moment().startOf('year')).toLocaleDateString();
			// params['end_date'] = new Date(<any>moment().endOf('year')).toLocaleDateString();
		}
		else if (this.assessmentOption == 'total') {
			snapshotParams['start_date'] = null;
			snapshotParams['end_date'] = null;
		}
		this.snapshotLoad = false

		// to get assessment list by clinic
		this.dashboardService.runAssessmentList({ 'clinic_id': clinic }).subscribe(element => {
			if (element) {
				this.assessment_list = element['assessment_list'];
				this.setAssessment();
				let chart_details: any = [];
				this.ChartData.forEach(data => {
					let obj: any = {}
					obj['title'] = data['title'];
					if ('selectedItems' in data) { obj['assessment'] = data['selectedItems'].map(x => x = x.id) };
					data['period'].forEach(period => {
						if (period['active']) {
							obj['period'] = period['name']
							if ('selectedWeek' in period) { obj['week'] = period['selectedWeek'] };
						}
					})
					chart_details.push(obj)
				})
				let data = {
					'clinic': clinic,
					'chart': chart_details,
					'current_date': moment().endOf('day').toISOString()
				}
				this.getChartByClinic(data);
			}
		})
		// to get snapshot data by clinic 
		this.dashboardService.getAssessmentData(snapshotParams).subscribe(
			res => {
				if (res) {
					this.snapshotLoad = true
					this.assessmentData = res['total_assessments']
				}
			}, error => {
				this.snapshotLoad = true
			})
	}

	returnStyle() {
		if (this.showMultipleClinic) {
			return 0.6;
		} else {
			return 1;
		}
	}

	clearChartfilter() {
		this.ChartData.forEach(data => {
			if (data.title == 'Assessment Details' || data.title == 'Average Assessment Score' || data.title == 'Active Patients') {
				data['selectedClinic'] = [];
				data['clinicname'] = ''
			}
		})
	}

	// showFields() {
	// 	if (!this.clinics.length) return false;
	// 	if (this.clinicOverallSearch) {
	// 		let value = this.clinicOverallSearch.nativeElement.value
	// 		if (value) {
	// 			return false;
	// 		} else {
	// 			return true;
	// 		}
	// 	} else {
	// 		return true;
	// 	}
	// }

	changeReportData(filter?: any, clinic_selected?: any) {
		let data: any = {};
		if (this.selectType == 'Weekly') {
			let chart_val = this.ChartData.filter(i => i.title == this.chart_title)[0];
			let week_data = chart_val['period'].filter(i => i.name == 'Weekly')[0];
			if (week_data) {
				this.selectedWeek = week_data['selectedWeek'];
			}
		} else {
			this.selectedWeek = undefined
		}

		let clinic_list_id = []

		if (this.defaultClinic && this.defaultClinic != -1) {
			clinic_selected = []
			clinic_selected.push(this.defaultClinic)
		} else {
			clinic_list_id = clinic_selected
		}

		data = {
			type: this.selectType,
			title: this.chart_title,
			filter: filter,
			clinic: clinic_selected,
			week: this.selectedWeek,
			assessmentVersions: this.assessmentVersions
		}

		this.dashboardService.getChartData(data).subscribe(res => {
			if (res) {
				let charts = res['chart']
				let index = this.ChartData.findIndex(i => i.title == charts.chart_name)
				if (index != -1) {
					this.ChartData[index]['chartDetails'] = this.chartOptionSet(charts)
					this.ChartData[index]['selectedClinic'] = clinic_list_id
				}
				charts.legend['useHTML'] = true
				charts.legend['labelFormatter'] = function () {
					return '<div title="' + this.name + '" style="color:' + this.color + ';white-space:no-wrap;overflow:hidden;text-overflow:ellipsis;">' + this.name + '</div>'
				}
			}
		})
	}

	chartOptionSet(charts) {
		let option = new Chart({
			chart: {
				height: "550px",
				type: charts.chart_type,
				events: {
					load: function () {
						$(".highcharts-legend-item path").attr('stroke-width', 10);
					},
					redraw: function () {
						$(".highcharts-legend-item path").attr('stroke-width', 10);
					}
				}
			},
			title: {
				text: charts.title,
				style: {
					color: 'grey',
					fontWeight: '600',
					fontFamily: "Muli"
				}
			},
			lang: {
				noData: "No Data Available"
			},
			noData: {
				style: {
					fontWeight: 'bold',
					fontSize: '15px',
					color: '#303030',
					fontFamily: 'Muli'
				}
			},
			legend: charts.legend,
			plotOptions: {
				series: {
					stickyTracking: false,
					events: {
						legendItemClick: function (event) {
							if (!this.visible)
								return true;

							var seriesIndex = this.index;
							var series = this.chart.series;

							for (var i = 0; i < series.length; i++) {
								if (series[i].index != seriesIndex) {

									series[i].visible ? series[i].hide() : series[i].show();
								}
							}

							return false;
						},
					},
				},
				column: {
					pointWidth: charts.width,
					dataLabels: {
						inside: false,
						enabled: charts.dataLabels,
						style: {
							color: '#856B3C',
							fontFamily: 'muli',
							fontWeight: '600'
						},
						formatter: function () {
							return (this.y != 0) ? this.y : "";
						}
					},
				}
			},
			xAxis: {
				min: 0,
				title: {
					text: charts.xAxis.title,
					style: {
						fontSize: '13px',
						fontWeight: '800',
						fontFamily: "Muli",
					},
					margin: 15
				},
				categories: charts.xAxis.categories,
				labels: {
					style: {
						fontSize: '10px',
						fontWeight: '400',
						fontFamily: "Muli",
					}
				},
				crosshair: false,
				gridLineColor: '#ffffff',
				lineColor: 'lightgrey',
			},
			yAxis: {
				tickInterval: charts.yAxis.interval,
				min: 0,
				allowDecimals: true,
				title: {
					text: charts.yAxis.title,
					style: {
						fontSize: '13px',
						fontWeight: '800',
						fontFamily: "Muli",
					},
				},
				gridLineColor: '#ffffff',
				lineColor: 'lightgrey',
				lineWidth: 1
			},
			credits: {
				enabled: false
			},
			exporting: {
				enabled: false
			},
			series: charts.series,
			tooltip: {
				shape: charts.shape,
				stickOnContact: true,
				padding: 0,
				borderColor: '#FFFFFF',
				backgroundColor: '#FFFFFF',
				style: {
					fontFamily: 'muli'
				},
				useHTML: true,
				formatter() {
					if (charts.chart_name == 'Active Patients') {
						return '<div style="padding:5px">' + this.y + '\t' + this.series.name + '</div>'
					} else if (charts.chart_name == 'Average Assessment Score') {
						var d = '<table>' +
							'<tr> <td style="padding:5px">' + 'Total Assessments' + '</td><td style="text-align:right">' + this.point['assessments'].toString() + '</td></tr>' +
							'<tr> <td style="padding:5px">' + 'Average Score' + '</td><td style="text-align:right">' + this.point['y'].toString() + '</td></tr>'
							+ '</table>'
						return d;
					} else if (charts.chart_name == 'Assessment Details') {
						var s;
						if (this.point['y'] != 0) {
							s = '<div style="display:block;max-height:200px;overflow-y:auto;"><table style="border-collapse:collapse;table-layout: fixed;width: 200px;">'
							this.point['type_data'].forEach(elt => {
								s += '<tr style="width:200px">' +
									'<td style="border-bottom:1px solid #dddddd;padding:8px;width:70%;height:auto;word-break:break-word;white-space:pre-wrap;">' + elt['clinic_assessment__assessment__assessment_name'] + '</td>' +
									'<td style="border-bottom:1px solid #dddddd;padding:8px;width:30%;height:auto;text-align:right">' + elt['total'].toString() + '</td>' +
									'</tr>'

							})
							s += '</table></div>'
							return s
						} else {
							s = '<span style="font-size: 10px;padding:5px">No assessments taken</span>'
							return s
						}
					}
				},
			},
			responsive: {
				rules: [{
					condition: {
						maxWidth: 500
					},
					chartOptions: {
						legend: {
							x: 50,
							y: -10,
							floating: false
						},
					}
				}]
			}

		})
		return option;
	}

	selectedOption(option) {
		this.selectOption = option;
		if (this.selectOption == 'assessments') {
			this.assessmentOption = 'daterange';
			this.snapshotLoad = false;
			let params = {
				'assessmentOption': this.assessmentOption,
				'current_date': moment().endOf('day').toISOString(),
				'timezone': new Date().getTimezoneOffset()
			}
			params['start_date'] = this.Start_date.value.format('MM/DD/YYYY');
			params['end_date'] = this.End_date.value.format('MM/DD/YYYY');
			if (this.selectedClinic) {
				params['clinic_id'] = this.selectedClinic;
			}
			this.dashboardService.getAssessmentData(params).subscribe(res => {
				if (res) {
					this.snapshotLoad = true;
					this.assessmentData = res['total_assessments'];
				}
			}, error => {
				this.snapshotLoad = true;
			})
		}
	}

	selectedType(option, index1, index2, week) {
		let filter: any = []
		this.selectType = option
		this.selectedWeek = week
		let data = this.ChartData[index1];
		this.chart_title = data['title']
		data['period'].map((val, i) => {
			if (i == index2) {
				val['active'] = true
			} else {
				val['active'] = false
			}
		})
		// filtering unselected assessment version list
		if (data['dataList']) {
			data['dataList'].map(elem => {
				if (data['selectedItems'].findIndex(i => i.id == elem['id']) == -1) {
					filter = filter.concat(elem['version']);
				}
			})
		}
		this.changeReportData(filter.length > 0 ? filter : undefined, data['selectedClinic']);
	}

	getGraphs(event, value, index) {
		if (event.isUserInput) {
			if (event.source.selected) { value['selectedClinic'].push(event.source.value) }
			else {
				let idxx = value['selectedClinic'].findIndex(e => e == event.source.value)
				value['selectedClinic'].splice(idxx, 1);
			}
			let filter: any = [];
			this.chart_title = value['title']
			value['period'].map(data => {
				if (data['active']) {
					this.selectType = data['name']
				}
			})
			if (value['selectedItems']) {
				value['selectedItems'].map(x => {
					filter.push(x.id)
				})
			}

			var result = value['Clinic_list'].filter(function (item) {
				return value['selectedClinic'].indexOf(item.id) !== -1;
			});

			value['clinicname'] = result.map(e => e = e.clinic_name).join(", ")

			if (value['selectedClinic'].length == 0) {
				this.clinicSearch['_results'][index].nativeElement.value = '';
				value['clinics'] = value['Clinic_list'].map(x => Object.assign({}, x));
				this.clinicSearch['_results'][index].nativeElement.focus();
			}
			this.changeReportData(filter, value['selectedClinic'])
		}
	}

	ClearClinic(data, index) {
		this.chart_title = data['title']
		data['period'].map(data => {
			if (data['active']) {
				this.selectType = data['name']
			}
		})
		let filter: any = [];
		if (data['selectedItems']) {
			data['selectedItems'].map(x => { filter.push(x.id) })
		}
		this.clinicSearch['_results'][index].nativeElement.value = '';
		data['clinics'] = data['Clinic_list'].concat([]);
		data['selectedClinic'] = [];
		data['clinicname'] = '';
		this.changeReportData(filter, data['selectedClinic'])
	}

	clinicSelectReset(isOpened: boolean, data, index) {
		$('.cdk-overlay-pane').addClass('clinic-pane-overlay')
		if (isOpened) this.clinicSearch['_results'][index].nativeElement.focus();
		this.clinicPane['_results'][index]._scrollTop = 0;
		this.clinicSearch['_results'][index].nativeElement.value = '';
		data['clinics'] = data['Clinic_list'].concat([]);
	}

	// OpenClinicPanel(event) {
	// 	$('.cdk-overlay-pane').addClass('pane-overlay')
	// 	this.clinics = this.Clinic_list;
	// 	this.clinicOverallSearch.nativeElement.focus();
	// 	this.clinicOverallSearch.nativeElement.value = ''
	// }

	menuOpened(event, data, index) {
		this.assessmentSearch['_results'][index].nativeElement.focus()
		this.assessmentSearch['_results'][index].nativeElement.value = '';
		data['dropdownList'] = data['dataList']
	}

	displayName(key) {
		return key ? (key.clinic_name) : undefined;
	}

	filtered_clinics(event, data) {
		if (event) {
			data['clinics'] = data['Clinic_list'].filter(x => x.clinic_name.toLowerCase().match(event.target.value.toLowerCase()))
		} else {
			data['clinics'] = data['Clinic_list'].map(x => Object.assign({}, x));;
		}
	}

	// search_clinics(event) {
	// 	if (event) {
	// 		this.clinics = this.Clinic_list.filter(x => x.clinic_name.toLowerCase().match(event.target.value.toLowerCase()))
	// 	} else {
	// 		this.clinics = this.Clinic_list;
	// 	}
	// }

	selectItem(event, data, list, index) {
		let value: any = []
		let checked = data['selectedItems'].filter(i => i.id == list.id)
		if (checked.length == 0) {
			let index = data['dropdownList'].findIndex(i => i.id == list.id)
			if (index != -1) {
				data['selectedItems'].splice(index, 0, list)
			}
		} else {
			let idx = data['selectedItems'].findIndex(i => i.id == list.id)
			if (idx != -1) {
				data['selectedItems'].splice(idx, 1)
			}
		}
		// Dropdown text
		if (data['selectedItems'].length == data['dataList'].length) {
			data['dropdown_text'] = 'All Assessments';
			data['dropdown_name'] = ''
		}
		else if (data['selectedItems'].length == 0) {
			data['dropdown_text'] = 'No Assessments';
			data['dropdown_name'] = ''
		}
		else {
			data['dropdown_text'] = data['selectedItems'].map(e => e.name).join(", ");
			data['dropdown_name'] = data['dropdown_text']
		}

		// filtering unselected assessment version list
		data['dataList'].map(elem => {
			if (data['selectedItems'].findIndex(i => i.id == elem['id']) == -1) {
				value = value.concat(elem['version']);
			}
		})
		// data['selectedItems'].map(x => {
		// 	value.push(x.id)
		// })
		this.chart_title = data['title']
		data['period'].map(data => {
			if (data['active']) {
				this.selectType = data['name']
			}
		})

		if (data['selectedItems'].length == 0) {
			this.assessmentSearch['_results'][index].nativeElement.focus()
			this.assessmentSearch['_results'][index].nativeElement.value = '';
			data['dropdownList'] = data['dataList']
		}

		this.changeReportData(value, data['selectedClinic'])
	}

	selectAll(data, text, index) {
		let value: any = []
		if (text == 'UnSelect All') {
			data['selectedItems'] = [];
			data['dropdown_text'] = 'No Assessments'
			data['dropdown_name'] = ''
			this.select_text = 'Select All'
			// data['chartDetails']['options']['series'] = [];
		} else {
			data['selectedItems'] = JSON.parse(JSON.stringify(data['dropdownList']));
			data['dropdown_text'] = 'All Assessments'
			data['dropdown_name'] = ''
			this.select_text = 'UnSelect All'
		}
		this.chart_title = data['title']
		data['period'].map(data => {
			if (data['active']) {
				this.selectType = data['name']
			}
		})

		if (data['selectedItems'].length == 0) {
			this.assessmentSearch['_results'][index].nativeElement.focus()
			this.assessmentSearch['_results'][index].nativeElement.value = '';
			data['dropdownList'] = data['dataList']
		}

		// filtering unselected assessment version list
		data['dataList'].map(elem => {
			if (data['selectedItems'].findIndex(i => i.id == elem['id']) == -1) {
				value = value.concat(elem['version']);
			}
		})

		this.changeReportData(value, data['selectedClinic']);
	}

	selectassessmentOption() {
		let params = {
			'assessmentOption': this.assessmentOption,
			'clinic_id': this.selectedClinic,
			'timezone': new Date().getTimezoneOffset()
		}
		if (this.assessmentOption == 'daterange') {
			params['start_date'] = this.Start_date.value.format('MM/DD/YYYY');
			params['end_date'] = this.End_date.value.format('MM/DD/YYYY');
			// params['start_date'] = new Date(this.Start_date.value).toLocaleDateString();
			// params['end_date'] = new Date(this.End_date.value).toLocaleDateString();
		}
		else if (this.assessmentOption == 'current_month') {
			params['start_date'] = moment().startOf('month').format('MM/DD/YYYY');
			params['end_date'] = moment().endOf('month').format('MM/DD/YYYY');
			// params['start_date'] = new Date(<any>moment().startOf('month')).toLocaleDateString();
			// params['end_date'] = new Date(<any>moment().endOf('month')).toLocaleDateString();
		}
		else if (this.assessmentOption == 'current_year') {
			params['start_date'] = moment().startOf('year').format('MM/DD/YYYY');
			params['end_date'] = moment().endOf('year').format('MM/DD/YYYY');
			// params['start_date'] = new Date(<any>moment().startOf('year')).toLocaleDateString();
			// params['end_date'] = new Date(<any>moment().endOf('year')).toLocaleDateString();
		}
		else if (this.assessmentOption == 'total') {
			params['start_date'] = null;
			params['end_date'] = null;
		}
		this.snapshotLoad = false
		this.dashboardService.getAssessmentData(params).subscribe(
			res => {
				if (res) {
					this.snapshotLoad = true
					this.assessmentData = res['total_assessments']
				}
			}, error => {
				this.snapshotLoad = true
			})

	}
	OpenCustomrange() {
		const dialogRef = this.dialog.open(CustomDateRange,
			{
				width: '90vw',
				maxWidth: '100vw',
				height: 'auto',
				data: {
					data: this.dashboardData
				}
			}
		)

	}

}


@Component({
	selector: 'custom-daterange-modal',
	templateUrl: './custom-daterange-modal.html',
	styleUrls: ['./dashboard.component.scss'],
	encapsulation: ViewEncapsulation.None,
})

export class CustomDateRange {
	current_date = new Date()
	current_range = []
	previous_range = []
	Start_date = new FormControl(moment().startOf('day').subtract(29, 'days'));
	End_date = new FormControl(moment().endOf('day'));
	previous_end = this.Start_date.value.clone().subtract(1, 'days').endOf('day');
	previous_start = this.previous_end.clone().subtract(29, 'days').startOf('day');
	past_end = this.previous_start.clone().subtract(1, 'days').endOf('day');
	past_start = this.past_end.clone().subtract(29, 'days').startOf('day');
	formLoad: boolean;

	constructor(
		public dialogRef: MatDialogRef<CustomDateRange>,
		public dashboardService: DashboardService,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
	}

	ngOnInit() {
		this.getDateRangeValue();
	}

	closeModal() {
		this.dialogRef.close();
	}

	getDateRangeValue() {
		let tzoffset = (new Date()).getTimezoneOffset() * 60000;
		let data = {
			'start_date': (new Date(this.Start_date.value.startOf('day') - tzoffset)).toISOString(),
			'end_date': (new Date(this.End_date.value.endOf('day') - tzoffset)).toISOString(),
			'previous_start': (new Date(this.previous_start.startOf('day') - tzoffset)).toISOString(),
			'previous_end': (new Date(this.previous_end.endOf('day') - tzoffset)).toISOString(),
			'past_start': (new Date(this.past_start.startOf('day') - tzoffset)).toISOString(),
			'past_end': (new Date(this.past_end.endOf('day') - tzoffset)).toISOString()
		}
		this.formLoad = false
		this.dashboardService.getCustomdata(data).subscribe(res => {
			this.formLoad = true
			this.current_range = res['current'];
			this.previous_range = res['previous'];
		})

	}

	format(date) {
		let date_string;
		if (date) {
			date_string = date.format('MMM DD')
		}
		return date_string
	}

	getDateRangeData() {
		let date1 = this.Start_date.value
		let date2 = this.End_date.value
		let diff_days = date2.diff(date1, 'days')
		this.previous_end = date1.clone().subtract(1, 'days')
		this.previous_start = this.previous_end.clone().subtract(diff_days, 'days');
		this.past_end = this.previous_start.clone().subtract(1, 'days');
		this.past_start = this.past_end.clone().subtract(diff_days, 'days');
		this.getDateRangeValue();
	}
}
