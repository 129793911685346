import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { InterfaceService } from './auth/interface.service';

@Injectable({
  providedIn: 'root'
})
export class DeviceSettingsService extends InterfaceService {

  constructor(private http: HttpClient, private router: Router) {
    super()
  }

  public setSettings(data: any) {
    return this.http.post(this.getApiUrl(`device-settings/`), data, this.getHttpOptions('json', true)).pipe(
      map(Response => Response),
      catchError(this.handleError)
    );
  }

  public getClinicSettings(clinicId?: any) {
    return this.http.get(this.getApiUrl(`device-settings/get-clinic-settings/`, clinicId ? {clinic_id: clinicId} : null), this.getHttpOptions('json', true)).pipe(
      map(response => response),
      catchError(this.handleError)
    );
  }

  public getDeviceClinicSettings() {
    return this.http.get(this.getApiUrl(`device-settings/get-device-clinic-settings/`), this.getHttpOptions('json', true)).pipe(
      map(response => response),
      catchError(this.handleError)
    );
  }

  public getDateRangeAudits(start:any,end:any){
    let start_date=start;
    let end_date=end;
    let params={}
    params['start_date']=start;
    params['end_date']=end;
    return this.http.get(this.getApiUrl(`audits/date-range-data`, params), this.getHttpOptions('json', true)).pipe(
      map(response => response),
      catchError(this.handleError)
    );

  }

  // public getClinicDeviceSettings() {
  //   return this.http.get(this.getApiUrl(`device-settings/get-device-clinic-settings`), this.getHttpOptions('json', true)).pipe(
  //     map(response => response),
  //     catchError(this.handleError)
  //   );
  // }

}
