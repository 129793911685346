import { Injectable } from '@angular/core';
import { InterfaceService } from './auth/interface.service';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ReportsService extends InterfaceService {

    constructor(private http: HttpClient) {
        super();
    }

    getCategory(query: any, type: string) {
        return this.http.post(this.getApiUrl(`reports/get-category/`), { query: query, type: type }, this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getCategoryFields(category: string) {
        return this.http.post(this.getApiUrl(`reports/get-category-fields/`), { category: category }, this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getFieldValues(field: any) {
        return this.http.post(this.getApiUrl(`reports/get-field-values/`), field, this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getResult(query: any) {
        return this.http.post(this.getApiUrl(`reports/get-result/`), { result: query, timezone: new Date().getTimezoneOffset() }, this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    deleteReport(queryId: any) {
        return this.http.get(this.getApiUrl(`reports/${queryId}/delete-report-queue/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getReportResult(queryId: any) {
        return this.http.get(this.getApiUrl(`reports/${queryId}/get-report-result/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    saveQuery(details: any) {
        return this.http.post(this.getApiUrl(`reports/`), { details: details }, this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    updateQuery(queryId: any, details: any) {
        return this.http.put(this.getApiUrl(`reports/${queryId}/`), { details: details }, this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getQuery() {
        return this.http.get(this.getApiUrl(`reports/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getColumns(category: any) {
        return this.http.post(this.getApiUrl(`reports/get-columns/`), { category: category }, this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    reportExists(name: string) {
        return this.http.get(this.getApiUrl(`reports/${name}/report-exists/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }
    getReportQueue() {
        return this.http.get(this.getApiUrl(`reports/get-report-queue/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }
    
    getReportCount() {
        return this.http.get(this.getApiUrl(`reports/get-report-count/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    // New filter query
    newReportsGetColumns(entity: any) {
        return this.http.post(this.getApiUrl(`filtered-reports/get-columns/`), { model:entity }, this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        ).toPromise();
    }

    newReportssaveQuery(details: any) {
        return this.http.post(this.getApiUrl(`new-reports/`), { details: details }, this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    newReportsgetQuery() {
        return this.http.get(this.getApiUrl(`new-reports/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        ).toPromise();
    }

    newReportsFilterQuery(filter_details) {
        return this.http.post(this.getApiUrl(`filtered-reports/filtering/`), { filter_details }, this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    newReportExists(name: string) {
        return this.http.get(this.getApiUrl(`new-reports/${name}/report-exists/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    newReportQueueSave(value: any) {
        return this.http.post(this.getApiUrl(`new-reports/report-queue-save/`), {value}, this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    newGetReportQueue() {
        return this.http.get(this.getApiUrl(`new-reports/get-report-queue/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    newGetReportResult(value: any) {
        return this.http.post(this.getApiUrl(`new-reports/get-report-result/`), {value}, this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    newDeleteReport(queryId: any) {
        return this.http.get(this.getApiUrl(`new-reports/${queryId}/delete-report-queue/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    autocompletFilterValuesListGet(value){
        return this.http.post(this.getApiUrl(`new-reports/get-autocomplete-values/`), {value}, this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    saveColumnOrder(value){
        return this.http.post(this.getApiUrl(`new-reports/save-column-order/`), {value}, this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

}
