import { Component, OnDestroy, OnInit, ViewEncapsulation, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { Subject, of } from 'rxjs';
import { takeUntil, first, last } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { GlobalService } from 'app/support/services/auth/global.service';
import { FuseConfigService } from 'app/shared/services/config.service';
import { FuseSidebarService } from 'app/shared/components/sidebar/sidebar.service';
import { navigation } from 'app/support/nav-menu/navigation';
import { AuthService } from 'app/support/services/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from 'app/support/classes';
import * as moment from 'moment';
import { AssessmentsService } from 'app/support/services/assessments.service';
import { PhoenixWebsocketService } from 'app/support/services/phoenix-websocket.service';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy {
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    user: User;
    notificationCount = 0;
    profile_image: any;
    profile_tooltip: any;

    lastLogin: any = '';
    first_login: any;

    notifications: any;
    showToolbar: boolean = false;
    // Private
    private _unsubscribeAll: Subject<any>;

    isNavBarHidden: boolean = false;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private renderer: Renderer2,
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private globalService: GlobalService,
        private authService: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        private assessmentService: AssessmentsService,
        private phoenixService: PhoenixWebsocketService
    ) {

        this.globalService.user$.subscribe((data: any) => {
            this.user = data;
            this.profile_tooltip = this.user['last_name'] + ' ' + this.user['first_name'] + ' / ' + this.user['roles'].join(', ');
            this.globalService.profile_image_source.next(data['profile_image']);
            // if (this.authService.authenticated())
            //     this.getLastLoginData();
        });
        this.globalService.profile_image$.subscribe(data => {
            if (data) {
                this.profile_image = data + "?" + Math.floor(Math.random() * 100) + 1;
            } else {
                this.profile_image = null;
            }
        });



        if (this.authService.authenticated()) {
            setTimeout(() => {
                this.getLastLoginData();
            }, 500);

        }

        // Set the defaults
        this.userStatusOptions = [
            {
                title: 'Online',
                icon: 'icon-checkbox-marked-circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                icon: 'icon-clock',
                color: '#FFC107'
            },
            {
                title: 'Do not Disturb',
                icon: 'icon-minus-circle',
                color: '#F44336'
            },
            {
                title: 'Invisible',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD'
            },
            {
                title: 'Offline',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#616161'
            }
        ];

        this.languages = [
            {
                id: 'en',
                title: 'English',
                flag: 'us'
            },
            {
                id: 'tr',
                title: 'Turkish',
                flag: 'tr'
            }
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // subscribe to notifications
        // this.firebaseService.currentMessage.subscribe((data) => {
        //     if (data) {
        //         this.notificationCount = data.data['gcm.notification.count']
        //     }
        // })
        this.authService.message$.subscribe(data => {
            // console.log(data);
            if (data && data == 'get-notifications') {
                this.getLastLoginData();
            }
            else if (data == 'logout') {
                // console.log('tool')
                this.authService.message$ = of([]);
                this.authService.logout();
            }
        })
        // this.phoenixService.socketSubject.subscribe((data) => {

        // })

        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                settings['layout']['navbar']['hidden'] ? this.isNavBarHidden = true : this.isNavBarHidden = false;
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, { id: this._translateService.currentLang });
    }

    getLastLoginData() {
        this.authService.getLastLoginDateTime().subscribe(data => {
            this.getNotificationList(data)
        },
            error => {

            })
    }

    getNotificationList(data) {
        this.notifications = data['notification_data'];
        this.notifications.forEach(element => {
            if (element['content'].includes('Assessment c')) element['icon'] = "assessment";
            if (element['content'].includes('User created')) element['icon'] = "account_circle";
            if (element['content'].includes('Clinic created')) element['icon'] = "local_hospital";
        });
       
        this.notificationCount = this.notifications.length;
        console.log(this.notificationCount)
        if (data['status']) {
            this.first_login = data['first_login'];
            let date = moment(data['last_login']);
            this.lastLogin = date.format('MMM-DD-YYYY').toUpperCase() + ', ' + date.format('hh:mm A');
        }
        else {
            this.lastLogin = '';
        }
        this.showToolbar = true;
        console.log(this.notifications)

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Toggle sidebar folded status
     */
    toggleSidebarFolded(): void {
        this._fuseSidebarService.getSidebar('navbar').toggleFold();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }

    logout(): void {
        if (this.router.url.includes('create') || this.router.url.includes('dashboard-settings') || this.router.url.includes('assign') || this.router.url.includes('set-assessment') || this.router.url.includes('my-settings') || this.router.url.includes('profile') || this.router.url.includes('director') || this.router.url.includes('edit')) {
            let tokenData = {
                "token": localStorage.getItem('token'),
                "firebase:host:phoenix-d7e6a.firebaseio.com": localStorage.getItem('firebase:host:phoenix-d7e6a.firebaseio.com')
            }
            localStorage.setItem("tokenData", JSON.stringify(tokenData));
        }
        this.phoenixService.closeConnection();
    }

    navigateNotification(data) {
        if (data['type'] == 'AC') {
            this.router.navigate(['/assessments/detail-view', data['pk_id']], { queryParams: { view: 'pdf' } })
            // this.assessmentService.updateAssessmentQueueViewStatus(data['pk_id']).subscribe(data => {
            // })
        } else if (data['type'] == 'CC') {
            this.router.navigate(['/clinics/view', data['pk_id']])
        } else if (data['type'] == 'UC') {
            this.router.navigate(['/users/view', data['pk_id']])
        }

        // this.authService.readNotification({ ids: [data['id']] }).subscribe(
        //     data => {
        //         this.getNotificationList(data)
        //     }
        // )
        let notificationIds = this.notifications.map(function (elem) { return elem['id'] })
        this.authService.readNotification({ ids: notificationIds }).subscribe(
            data => {
                this.getNotificationList(data)
            }
        )
        console.log(notificationIds)
    }

    readAllNotifications() {
        let notificationIds = this.notifications.map(function (elem) { return elem['id'] })
        this.authService.readNotification({ ids: notificationIds }).subscribe(
            data => {
                this.getNotificationList(data)
            }
        )
    }
    hideNotifications() {
        let notificationIds = this.notifications.map(function (elem) { return elem['id'] })
        this.authService.hideNotification({ ids: notificationIds }).subscribe(
            data => {
                this.getNotificationList(data)
            }
        )
    }
    // change_password(): void {
    //     this.router.navigate(['/change-password']);
    // }
}
