import { Injectable } from "@angular/core";
import { InterfaceService } from "./auth/interface.service";
import { HttpClient } from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { Subject, of } from "rxjs";
import { Router } from "@angular/router";

@Injectable({
    providedIn: "root",
})
export class SmartTestService extends InterfaceService {
    constructor(private http: HttpClient, private router: Router) {
        super();
    }

    public refreshRows = new Subject<any>();
    newRowData$ = this.refreshRows.asObservable();

    public loadConfig = new Subject<any>();
    loadData$ = this.loadConfig.asObservable();

    public getGroupRules(clinicId: number) {
        return this.http
            .get(
                this.getApiUrl(`abnormal-flag/get-rules/${clinicId}/`),
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((response) => response),
                catchError((err) => this.handleError(err))
            );
    }

    public createGroupRules(clinicId: number, groupRules: any) {
        return this.http
            .post(
                this.getApiUrl(`abnormal-flag/rules/${clinicId}/`),
                groupRules,
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((response) => response),
                catchError((err) => this.handleError(err))
            );
    }

    public createSmartTest(data: any) {
        return this.http
            .post(
                this.getApiUrl(`dynamic-smart-test/`),
                data,
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((Response) => Response),
                catchError((err) => this.handleError(err))
            );
    }

    public nameExistCheck(data: any) {
        return this.http
            .post(
                this.getApiUrl(
                    `dynamic-smart-test/dynamic-smartest-name-exist/`
                ),
                data,
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((Response) => Response),
                catchError((err) => this.handleError(err))
            );
    }

    public getSmartTestList() {
        return this.http
            .get(
                this.getApiUrl(`dynamic-smart-test/`),
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    public updateXml(data: object) {
        return this.http
            .post(
                this.getApiUrl(`dynamic-smart-test/add-xml/`),
                data,
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((Response) => Response),
                catchError(this.handleError)
            );
    }

    public exportWorkflow(data: object) {
        let options = this.getHttpOptions("json", true);
        options["responseType"] = "text";
        return this.http
            .post(this.getApiUrl(`dynamic-smart-test/export/`), data, options)
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    public workflowStatusUpdate(data) {
        return this.http
            .post(
                this.getApiUrl(
                    `dynamic-smart-test/assign-clinic-workflow-status/${data["id"]}/`
                ),
                data,
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    public workflowActiveStatus(data) {
        return this.http
            .post(
                this.getApiUrl(
                    `dynamic-smart-test/activate-workflow-status/${data["id"]}/`
                ),
                data,
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    public getActiveClinic(data) {
        return this.http
            .post(
                this.getApiUrl(
                    `dynamic-smart-test/get-active-clinic/${data["id"]}/`
                ),
                data,
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    public assignClinic(data: object, workflowId) {
        return this.http
            .post(
                this.getApiUrl(
                    `dynamic-smart-test/assign-clinic/${workflowId}/`
                ),
                data,
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((Response) => Response),
                catchError(this.handleError)
            );
    }

    public deleteWorkflow(data) {
        return this.http
            .delete(
                this.getApiUrl(
                    `dynamic-smart-test/${data["id"]}?warning_accepted=${data["warning_accepted"]}`
                ),
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    public getWorkflowList(data) {
        return this.http
            .get(
                this.getApiUrl(`dynamic-smart-test/${data["id"]}`),
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    refreshRowData(data) {
        this.refreshRows.next(data);
    }

    loadConfigure(data) {
        this.loadConfig.next(data);
    }

    public updateSmartTest(data: any) {
        return this.http
            .put(
                this.getApiUrl(`dynamic-smart-test/${data["id"]}/`),
                data,
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((Response) => Response),
                catchError((err) => this.handleError(err))
            );
    }

    public workflowConfigureSave(data) {
        return this.http
            .post(
                this.getApiUrl(
                    `dynamic-smart-test/workflow-configuration/${data["smartTestId"]}/`
                ),
                data,
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    public workflowClone(data) {
        return this.http
            .get(
                this.getApiUrl(
                    `dynamic-smart-test/workflow-clone/${data["id"]}`,
                    data
                ),
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    public getConfigure(data) {
        return this.http
            .post(
                this.getApiUrl(`dynamic-smart-test/get-config-object/`),
                data,
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }

    public getClinic(data) {
        return this.http
            .post(
                this.getApiUrl(`dynamic-smart-test/get-assign-clinic/`),
                data,
                this.getHttpOptions("json", true)
            )
            .pipe(
                map((response) => response),
                catchError(this.handleError)
            );
    }
}
