import { CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { Component, EventEmitter, Inject, OnInit, Output, ViewChild, ViewChildren } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { DeleteDialogComponent } from "app/shared/components/dialog-box/delete-dialog/delete-dialog.component";
import { ValidationService } from "app/shared/services/validation.service";
import { MxGraphService } from "app/support/services/mx-graph.service";
import { fromEvent } from 'rxjs';
import { debounceTime, map } from "rxjs/operators";

@Component({
    selector: 'app-include-panel',
    templateUrl: './include-panel.component.html',
    styleUrls: ['./include-panel.component.scss']
  })
  export class IncludePanelComponent implements OnInit {
    @Output() outputModel: EventEmitter<any> = new EventEmitter<any>();
    @Output() edit: EventEmitter<any> = new EventEmitter<any>();
    @ViewChildren('matSelect_j') matSelect_j;
    taskForm: FormGroup = new FormGroup({});
    assmnt: boolean = false;
    condition_val: any;
    selectedAssessment: Array<any> = [];
    selected_input: any;
    isEdit: boolean = false;
    activeField: any = {};
    assessmentList: any;
    show_operator: boolean = false;
    temp_data: any;
    activeMetaDatas: any;
    activeInputs: any = {};
    deleteModalContent: any = {
        title: '',
        message: ''
    };
    selectedBlock: string = '';
    deleteConfirmTextInput: string = '';
    openDeleteConfirmation: boolean = false;
    isMarketPlaceView: boolean = false;
    activateCondtions: boolean = false;
    grp_rule: boolean = false;
    valuesArr: Array<any> = []
    operatorValuesList: any;
    grpNameList: any;
    duplicatelist: any;
    assessmentlist: any =[];
    dayList = [
        {id:0, value:'Days'},
        {id:1, value:'Weeks'},
        {id:2, value:'Months'},
        {id:3, value:'Years'}
    ];
    scoreList = [
        {id:0, value:'Overall'},
        {id:1, value:'Latest'},
    ];
    newDuplicateList: any;
    copyOfAssessmentList: Array<any> = [];
    searchassessmentvalue: any;
    enableSaveBtn = false;
    constructor(
        private fb: FormBuilder,
        private dialog: MatDialog,
        private mx_graphService: MxGraphService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<IncludePanelComponent>,
    ){
        this.isEdit = this.data['isEdit'];
        this.grpNameList = this.data['grp_name_list'];
        this.activeMetaDatas = this.data['triggerData'];
        this.taskForm = this.fb.group({
            operator: [''],
            code: [''],
            code2: [''],
            unit: [''],
            assessments: [[], Validators.required],
            all_assessment: [false],
            all_operators: false
        })
        this.assessmentList = JSON.parse(JSON.stringify(this.data['assessment_list']['options']));
        let asmtList = this.assessmentList
        const key = 'value';
        const arrayUniqueByKey = [...new Map(asmtList.map(item => [item[key], item])).values()];
        this.duplicatelist = arrayUniqueByKey;
        this.newDuplicateList = arrayUniqueByKey;
        this.copyOfAssessmentList = JSON.parse(JSON.stringify(this.data['assessment_list']['options']));
        if (this.data['selectedBlockModal'].isConditional == true && this.data['cell']['value']['name'] == 'conditionalBlock' && !this.isEdit) {
            this.selectedBlock = 'conditionalBlock';
            this.activeField = this.data['activeField'];
            this.taskForm.get('operator').setValidators(Validators.required);
            this.taskForm.get('code').setValidators(Validators.required);
            if(this.activeField.text == 'Total Score' || this.activeField.input == 'unit'){
                this.taskForm.get('unit').setValidators(Validators.required);
            }
        }
        if(this.data['selectedBlockModal'].isConditional == true && this.data['cell']['value']['name'] == 'multiconditionalBlock' && !this.isEdit){
            this.selectedBlock = 'multiconditionalBlock';
        }
        this.taskForm.valueChanges.subscribe({
            next: (res) => {
                this.checkCondition();
            }
        })
    }
    ngOnInit() {
        if (this.isEdit) {
            let parent = this.data.mxGraph.getModel().getCell(this.data.cell['parentId']);
            this.assessmentList = JSON.parse(JSON.stringify(this.data['assessment_list']['options'].concat([])));
            let asmtList = this.assessmentList
            const key = 'value';
            const arrayUniqueByKey = [...new Map(asmtList.map(item => [item[key], item])).values()];
            this.duplicatelist = arrayUniqueByKey;
            this.newDuplicateList = arrayUniqueByKey;
            this.copyOfAssessmentList = this.data['assessment_list']['options'].concat([]);
            let selected_assessment = [];
            this.data['cell']['value']['model'].forEach((e,i)=>{
              this.duplicatelist.forEach((ele,ind) => {
                  if(e['value'] == ele['value']){
                      selected_assessment.push(e);
                      this.duplicatelist.splice(ind,1);
                  }
              });
          })
            this.taskForm.controls['assessments'].setValue(selected_assessment);
            this.assessmentlist = selected_assessment;
            if(this.data['selectedBlockModal'].isConditional == true && parent['block_name'] == 'conditionalBlock' ){
                this.selectedBlock = 'conditionalBlock';
                this.activeField = this.data['activeField'];
                if(this.activeField.text == 'Total Score' || this.activeField.input == 'unit'){
                    this.taskForm.get('unit').setValidators(Validators.required);
                }
                this.taskForm.get('operator').setValidators(Validators.required);
                this.taskForm.get('code').setValidators(Validators.required);
                this.taskForm.get('operator').setValue(this.data['selectedBlockModal']['condition']['operator']);
                this.taskForm.get('code').setValue(this.data['selectedBlockModal']['condition']['value']);
                this.taskForm.get('code2').setValue(this.data['selectedBlockModal']['condition']['value2']);
                this.taskForm.get('unit').setValue(this.data['selectedBlockModal']['condition']['unit']);
                this.temp_data = this.data['selectedBlockModal']['condition']['value'];
                if(this.temp_data == 'English' && !['contains', 'does not contains'].includes(this.data['selectedBlockModal']['condition']['operator'])) this.taskForm.get('code').setValue('EN');
                if(this.temp_data == 'Spanish' && !['contains', 'does not contains'].includes(this.data['selectedBlockModal']['condition']['operator'])) this.taskForm.get('code').setValue('SP');
                this.outputModel.emit(this.temp_data);
                this.edit.emit(this.isEdit);
            }
            if(this.data['selectedBlockModal'].isConditional == true && parent['block_name'] == 'multiconditionalBlock'){
                this.selectedBlock = 'multiconditionalBlock';
                this.edit.emit(this.isEdit);
            }
        }
    }

    @ViewChild('searchassessment', {static: false}) searchassessment: any;
    ngAfterViewInit(): void {
        fromEvent(this.searchassessment.nativeElement, 'keydown')
            .pipe(
                debounceTime(550),
                map(x => x['target']['value'])
            )
            .subscribe(value => {
                this.updateFilter(value);
                if (value) {
                    this.searchassessmentvalue = value
                } else {
                    this.searchassessmentvalue = ''
                }
            })
    }

    updateFilter(val: any) {
        this.duplicatelist = [];
        this.duplicatelist = this.newDuplicateList.filter(v => v.value.toLowerCase().indexOf(val.toLowerCase()) >= 0);
    }

    selectConditionCode(evt,i){
        if(evt.value == 'EN') this.taskForm.value['code'] = 'English';
        if(evt.value == 'SP') this.taskForm.value['code'] = 'Spanish';
    }

    clearSearch () {
        this.duplicatelist = [];
        this.duplicatelist = this.newDuplicateList
        this.searchassessmentvalue = ''
        this.searchassessment.nativeElement.value = ''
    }

    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
          moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
          transferArrayItem(
            event.previousContainer.data,
            event.container.data,
            event.previousIndex,
            event.currentIndex,
          );
        }
        let emptyArray = []
        const ids = this.assessmentlist.map(item => item.assessment_id)
        const values = this.assessmentlist.map(item => item.value)
        this.copyOfAssessmentList.map((item, index) => {
            if(!ids.includes(item.assessment_id)) {
                emptyArray.push(item)
            }
        })
        let asmtList = emptyArray
        const key = 'value';
        const arrayUniqueByKey = [...new Map(asmtList.map(item => [item[key], item])).values()];
        let arrayToPush = []
        arrayUniqueByKey.map((item, index) => {
            if(!values.includes(item.value)) {
                arrayToPush.push(item)
            }
        })
        this.duplicatelist = arrayToPush
        this.newDuplicateList = arrayToPush
        this.clearSearch()
        this.taskForm.get('assessments').setValue(this.assessmentlist)
    }

    setOperator(){
        this.taskForm.get('code').setValue('');
        this.taskForm.get('code2').setValue('');
        this.taskForm.get('unit').setValue('');
        if((this.taskForm.get('operator').value === 'equal' || this.taskForm.get('operator').value === 'not equal') && 
        (this.activeField.text == 'Clinician Email (EMR)' || this.activeField.text == 'Patient Email' || this.activeField.text == 'Patient Email (EMR)')){
            this.taskForm.get('code').setValidators([Validators.required, ValidationService.emailValidator]);
        }else{
            this.taskForm.get('code').setValidators([Validators.required]);
        }
        if(this.taskForm.get('operator').value === 'exist' || this.taskForm.get('operator').value === 'not exist' ||
        this.taskForm.get('operator').value === 'empty' || this.taskForm.get('operator').value === 'not empty' ||
        this.taskForm.get('operator').value === 'Yes' || this.taskForm.get('operator').value === 'No'){
            this.taskForm.get('code').setValue(['?']);
        }else if(this.taskForm.get('operator').value === 'between'){
            this.taskForm.get('code2').setValidators(Validators.required);
        }else{
            this.taskForm.get('code2').clearValidators();
        }
        this.taskForm.get('code').updateValueAndValidity();
        this.taskForm.get('code2').updateValueAndValidity();
        this.taskForm.get('unit').updateValueAndValidity();
        this.taskForm.get('all_operators').setValue(false);
    }

    selectAllAssessment(event) {
        if (event.checked) {
            this.taskForm.get('all_assessment').setValue(true);
            this.taskForm.get('assessments').setValue(this.assessmentList.concat([]));
        } else {
            this.taskForm.get('all_assessment').setValue(false);
            this.taskForm.get('assessments').setValue([]);
        }
    }

    checkAssessments(){
        let count = this.taskForm.controls['assessments'].value;
        if(count.length == this.assessmentList.length){
            this.taskForm.get('all_assessment').setValue(true);
        }else{
            this.taskForm.get('all_assessment').setValue(false);
        }
    }

    removeCell() {
        let dialogConfig = new MatDialogConfig();
        dialogConfig.width = '40%';
        dialogConfig.data = { 'yes': null, 'no': null, 'option': null };
        dialogConfig.position = { top: '70px' };
        dialogConfig.disableClose = true;
        if(this.data['cell']['edges'].length > 1 ){
            dialogConfig.data = {
                title: 'Delete Flow?',
                sub_title: 'Are you sure? You want to delete this block/flow?',
                no_button: 'No, keep it',
                yes_button: 'Delete flow',
                option_button:'Delete block',
                option:true
            }
        }else{
            dialogConfig.data = {
                title: 'Delete Flow?',
                sub_title: 'Are you sure? You want to delete this flow?',
                no_button: 'No, keep it',
                yes_button: 'Yes, Delete'
            }
        }
        let deleteRef = this.dialog.open(DeleteDialogComponent, dialogConfig);
        deleteRef.afterClosed().subscribe(
            value => {
                this.mx_graphService.setGraphLoadingTrue()
                if (value == 'yes') {
                    this.dialogRef.close('remove');
                }
                if(value == 'option'){
                    this,this.dialogRef.close('particular');
                }
            }
        );
    }

    checkCondition() {
        if (this.data['selectedBlockModal'].isConditional == true && this.selectedBlock == 'multiconditionalBlock') {
            if (this.taskForm.controls.rules) {
                if(this.taskForm.controls.rules.value.length == 0){
                    this.enableSaveBtn = false;
                    return;
                } 
                if (this.taskForm.controls.rules.value && this.taskForm.controls.rules.value.length) {
                    const all_check = this.taskForm.controls.rules.value.map((e, i) => {
                        const sub_rule_check = e.rules.map((se, j) => {
                            if (se.rules) {
                                if (se.rules[j] && se.rules[j]['condition']) {
                                    const sub_check = se.rules.map((el) => {
                                        const check_sub_length = el.rules.length > 0;
                                        return check_sub_length ? this.taskForm.valid : check_sub_length;
                                    });
                                    return sub_check.every(u => !!u);
                                }
                                const check_sub_length = se.rules.length > 0;
                                return check_sub_length ? this.taskForm.valid : check_sub_length;
                            }
                            return this.taskForm.valid;
                        });
                        return sub_rule_check.length > 0 ? sub_rule_check.every(u => !!u) && this.taskForm.valid : false;
                    });
                    this.enableSaveBtn = all_check.every(v => !!v);
                    return;
                }
            }
        }else{
            this.enableSaveBtn = this.taskForm.valid;
            return
        }
    }


    addValue() {
        this.mx_graphService.setGraphLoadingTrue()
        this.data['selectedBlockModal']['model'] = this.taskForm.value['assessments'];
        if(this.isEdit){
            let parent = this.data.mxGraph.getModel().getCell(this.data.cell['parentId']);
            if (this.data['selectedBlockModal'].isConditional == true && parent['block_name'] == 'conditionalBlock' ) {
              this.data['selectedBlockModal']['condition']['operator'] = this.taskForm.value['operator'];
              this.data['selectedBlockModal']['condition']['value'] = this.taskForm.value['code'];
              this.data['selectedBlockModal']['condition']['value2'] = this.taskForm.value['code2'];
              this.data['selectedBlockModal']['condition']['unit'] = this.taskForm.value['unit'];
            }
            if(this.data['selectedBlockModal'].isConditional == true && parent['block_name'] == 'multiconditionalBlock' ) {
                if(this.data['selectedBlockModal']['condition']['rules'] == undefined){
                    this.data['selectedBlockModal']['condition'] = this.taskForm.value;
                }else{
                    this.data['selectedBlockModal']['condition']['value'] = this.taskForm.value;
                }
            }
          }else{
            if (this.data['selectedBlockModal'].isConditional == true && this.data['cell']['value']['name'] == 'conditionalBlock') {
              this.data['selectedBlockModal']['condition']['operator'] = this.taskForm.value['operator'];
              this.data['selectedBlockModal']['condition']['value'] = this.taskForm.value['code'];
              this.data['selectedBlockModal']['condition']['value2'] = this.taskForm.value['code2'];
              this.data['selectedBlockModal']['condition']['unit'] = this.taskForm.value['unit'];
            }
            if(this.data['selectedBlockModal'].isConditional == true && this.data['cell']['value']['name'] == 'multiconditionalBlock') {
              this.data['selectedBlockModal']['condition'] = this.taskForm.value;
            }
          }
        console.log("Condition---",this.taskForm.value)
        this.dialogRef.close(this.data);

    }

    closeModal() {
        this.mx_graphService.setGraphLoadingTrue()
        this.dialogRef.close('close');
    }

    isNumber(evt) {
        evt = (evt) ? evt : window.event;
        let keys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab']
        var charCode = evt.keyCode;
        if (((charCode >= 48 && charCode <= 57 || charCode >= 96 && charCode <= 105) && !evt.shiftKey) || keys.includes(evt.key)) {
          return true;
        }
        return false;
      }

    onPaste(event) {
        let clipboardData = event.clipboardData;
        let pastedText = clipboardData.getData('text');
        var number = new RegExp("^[0-9]+$");
        if (number.test(pastedText)) {
            return true;
        }
        else {
            return false;
        }
    }

    selectCondtionAllOperators(event) {
        try {
            if (event.checked) {
      
              this.taskForm.get('all_operators').setValue(true);
              this.operatorValuesList = this.activeField.options;
              this.operatorValuesList.forEach(e => {
                  if (e){
                  this.valuesArr.push(e.id);
                  }
              })
              this.taskForm.get('code').setValue(this.valuesArr.concat([]))
              this.matSelect_j._results[0].options._results.forEach((x,y) => {
                x.select();
              });
            } else {
                this.matSelect_j._results[0].options._results.forEach((x,y) => {
                    x.deselect();
                  });
              this.taskForm.get('all_operators').setValue(false);
              this.taskForm.get('code').setValue([]);
            }
          }
          finally {
            this.valuesArr = [];
          }
    }

    checkConditionOperators(i) {
        let count;
        count = this.taskForm.controls['code'].value;
        if (count.length == this.activeField.options.length) {
            this.taskForm.get('all_operators').setValue(true);
        } else {
            this.taskForm.get('all_operators').setValue(false);
        }
    }

}