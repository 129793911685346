import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';
import { InterfaceService } from './auth/interface.service';

@Injectable({
  providedIn: 'root'
})
export class SyncDataService extends InterfaceService {


  private _value: any = false;

  set setValue(newValue: any){
    this._value = newValue;
  }

  get getNewValue(): any{
    return this._value;
  }

  constructor() {
    super()
  }

}
