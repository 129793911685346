import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { AuthService } from './auth/auth.service';
import { InterfaceService } from './auth/interface.service';
import { Idle } from '@ng-idle/core';
import { FuseSidebarService } from 'app/shared/components/sidebar/sidebar.service';

@Injectable({
  providedIn: 'root',
})
export class PhoenixWebsocketService {

  socketConnection: ReconnectingWebSocket = null;

  options = {
    connectionTimeout: 1000,
    maxRetries: 5,
  };

  subscriptions: Object = {};
  sessionTrack: Idle;

  constructor(
    private _fuseSidebarService: FuseSidebarService,
    private authService: AuthService) {
    // this.authService.logout$.subscribe(data => {
    //   console.log(data)
    //   console.log(this.config)
    // })
  }

  // get config(): any | ReconnectingWebSocket {
  //   return this.socketConnectionSubject.asObservable();
  // }

  // set config(value){
  //   this.config = value;
  // }

  initializePhoenixWebSocket() {
    if (this.authService.authenticated()) {
      // console.log('service constructor')
      let url = '';
      window.location.protocol == 'http:' ? url = url + 'ws:' : url = url + 'wss:';
      url = url + environment.baseUrl.replace(window.location.protocol, '');
      this.socketConnection = new ReconnectingWebSocket(url + 'messages/sample/?token=' + window.localStorage.token, null, this.options);
      // this.authService.soc.next(this.socketConnection);
      this.authService.socketConnectionSubject.next(this.socketConnection);
      this.socketConnection.onopen = (event) => {
        // console.log('Phoenix websocket opened!...')
        this.sendMessage('get-notifications');
      }

      this.socketConnection.onclose = (event) => {
        // console.log('Phoenix websocket closed!...')
      }

      this.socketConnection.onerror = (event) => {
        if (!this.authService.authenticated()) {
          this.socketConnection.close()
        }
        // console.log('error', event)
      }
      this.socketConnection.onmessage = (event) => {
        // console.log('mesage', event);
        this.authService.socketSubject.next(event['data'])
        // console.log(this.authService.message$);
      }
      // this.socketConnectionSubject.next(this.socketConnection);
    }
  }

  getSocketConnection() {
    return this.socketConnection;
  }

  sendMessage(message: string) {
    this.socketConnection.send(JSON.stringify({ 'message': message }))
  }

  closeConnection() {
    // this.socketConnection.close();

    // close right sidebar if opened
    if (this._fuseSidebarService.getSidebar('quickPanel')['opened'])
      this._fuseSidebarService.getSidebar('quickPanel').toggleOpen();

    // unsubscribe event subscriptions (session)
    Object.entries(this.subscriptions).forEach(
      ([name, subscription]) => subscription.unsubscribe()
    );
    if (this.sessionTrack) {
      this.sessionTrack.stop();
      this.sessionTrack.clearInterrupts();
    }
    this.authService.logout();
  }

  setSessionSubscription(observable: any, idle: any) {
    this.sessionTrack = idle;
    this.subscriptions['session'] = observable;
  }
}
