import { ENTER } from '@angular/cdk/keycodes';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger, MatSelectChange } from '@angular/material';
import { InterfaceService } from 'app/support/services/auth/interface.service';
import { Observable } from 'rxjs';
import { map, startWith, last } from 'rxjs/operators';
import { SearchList, SearchUrl } from '../slim-search/slim-search';
import * as _ from 'lodash';

@Component({
    selector: 'drop-search',
    templateUrl: './drop-search.component.html',
    styleUrls: ['./drop-search.component.scss']
})
export class DropSearchComponent extends InterfaceService implements OnInit {
    removable: boolean = true;
    loading: boolean = false;
    separatorKeyCodes: number[] = [ENTER];
    searchControl: FormControl = new FormControl();
    subscriptions: Object = {};

    // filteredOptions: Observable<string[]>;
    filteredOptions: Array<string> = [];
    selectedOptions: string[] = [];
    currentOption: Object = {};
    isKey = true
    allOptions: Array<any> = [];
    tempOptions: Array<any> = [];

    searchQuery: Object = {};

    searchUrl: SearchUrl = {
        searchResultUrl: '',
        searchValuesUrl: '',
        defaultsTo: true
    };

    valueList: Array<any> = [];
    tempValueList: Array<any> = [];
    defaultsTo: any = null;

    @ViewChild('searchInput', { static: false }) searchInput: ElementRef<HTMLInputElement>;
    @ViewChild('autoComplete', { static: false }) matAutocomplete: MatAutocomplete;
    @ViewChild('trigger', { static: false, read: MatAutocompleteTrigger }) trigger: MatAutocompleteTrigger;

    @Output('searchResult') searchResult: EventEmitter<any> = new EventEmitter();
    @Output('clearResult') clearResult: EventEmitter<any> = new EventEmitter();

    @Input('searchList')
    set setOptions(options: SearchList[]) {
        this.tempOptions = options;
    }

    @Input('searchUrl')
    set setUrl(url: SearchUrl) {
        this.searchUrl = url;
        if (this.selectedOptions.length) {
            this.selectedOptions = [];
            this.clearResult.emit();
            this.tempOptions.forEach((element, index) => {
                this.tempOptions[index].display = true;
                if ('checked' in this.tempOptions[index])
                    this.tempOptions[index].checked = false;
            });
            this.tempValueList = this.valueList.concat([]);
            this.searchInput.nativeElement.value = '';
            this.searchControl.reset();
            this.searchQuery = {};
            // this.searchInput.nativeElement.focus();
            this.trigger.closePanel();
        }
    }

    @Input('valueList')
    set setValue(value: Array<any>) {
        if (value != undefined) {
            this.valueList = value.concat([]);
            this.tempValueList = value.concat([]);
        }
    }

    @Input('activeOptions')
    set setActiveOptions(value: Array<any>) {
        value.forEach((element, index) => {
            let idx = this.tempOptions.findIndex(el => el['field'] == element['field']);
            if (idx > -1) {
                this.tempOptions[idx]['active'] = element['visible'];
                // if (this.isKey) {
                //     this.allOptions[this.allOptions.findIndex(el => el['field'] == element['field'])]['active'] = element['visible'];
                //     this.setFilter(this.searchControl.value);
                // }
                if (!element['visible'])
                    for (let option of this.selectedOptions)
                        if (option.split(':')[0] == this.tempOptions[idx]['searchKey']) {
                            // this.remove(option);
                            break;
                        }
            }
        });
        if (this.searchUrl.defaultsTo && !this.defaultsTo && value.length) {
            let idx = 0;
            for (let el of this.tempOptions) {
                if (el['active']) {
                    this.defaultsTo = this.tempOptions[idx];
                    this.currentOption['key'] = this.tempOptions[idx]['field'].split('.');
                    if ('replace' in this.tempOptions[idx])
                        this.currentOption['option'] = this.tempOptions[idx];
                    else if ('option' in this.currentOption)
                        delete this.currentOption['option'];
                    // this.selectedOptions.push(this.tempOptions[idx]['searchKey'] + ':');
                    // this.tempOptions[idx].display = false;
                    this.retrieveValue(this.tempOptions[idx]['property'], false);
                    // this.searchControl.reset();
                    break;
                }
                idx++;
            }
        }
    }


    constructor(private http: HttpClient) {
        super();
        this.loading = false;
        // this.setFilter();
    }

    ngOnInit() {
        this._filter = _.debounce(this._filter, 400);
        this.searchControl.valueChanges.subscribe(val => {
            val ? this._filter(val) : this.filteredOptions = [];
            // val ? this._filter(val) : this.filteredOptions = [...this.allOptions];
        })
    }

    // setFilter(initialValue: any = null) {
    // if (!initialValue) {
    //     this.filteredOptions = [...this.allOptions];
    // }
    // this.filteredOptions = this.searchControl.valueChanges.pipe(
    //     startWith(initialValue),
    //     map((options: any) => options ? this._filter(options) : this.allOptions)
    // );
    // }

    private _filter(value: any) {
        if (value) {
            let filterValue: any = value.toString().toLowerCase();
            console.log(filterValue);
            let filtered = this.allOptions.filter(
                option => {
                    if (option.includes('*/*')) {
                        return option.split(' */* ')[1].toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
                    }
                    else {
                        option = option.toString();
                        return (this.currentOption['key'][0] == 'gender' && filterValue == 'male') ? option.toLowerCase() == 'male' :
                            option.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
                    }
                }
            ).map(option => option);
            this.filteredOptions = filtered;
        }
    }

    retrieveValue(value: any, openPanel: boolean = true) {
        this.allOptions = [];
        this.removable = false;
        this.loading = true;
        // this.searchInput.nativeElement.focus();
        this.subscriptions['value'] = this.http.post(this.getApiUrl(`${this.searchUrl.searchValuesUrl}/`), { searchKey: value, searchQuery: this.searchQuery },
            this.getHttpOptions('json', true)).subscribe((response: string[]) => {
                this.allOptions = response.concat([]);
                // this.setFilter();
                this.trigger.closePanel();
                if (openPanel) {
                    setTimeout(() => {
                        this.trigger.openPanel();
                    }, 0);
                    this.searchInput.nativeElement.focus();
                }
                this.searchInput.nativeElement.value = '';
                this.searchControl.reset();
                this.removable = true;
                this.loading = false;
            }
            );
    }

    selectKey(event: MatSelectChange) {
        // this.selectedOptions =  event.value['property']
        this.searchQuery = {};
        this.retrieveValue(event.value['property']);
        this.searchControl.reset();
        // this.clearResult.emit();
    }

    selectValue(event: MatAutocompleteSelectedEvent) {
        this.searchQuery[this.defaultsTo['property']] = event.option.value;
        this.searchControl.setValue(event.option.viewValue);
        this.retrieveData();
    }

    retrieveData() {
        if (!this.searchUrl.needQueryEmit) {
            this.subscriptions['data'] = this.http.post(this.getApiUrl(`${this.searchUrl.searchResultUrl}/`), JSON.stringify({ searchQuery: this.searchQuery }),
                this.getHttpOptions('json', true)).subscribe(
                    (response: any) => {
                        this.searchResult.emit(response);
                        this.tempValueList = response['data'].concat([]);
                        if (this.removable)
                            this.loading = false;
                    },
                    (error: any) => {
                        this.loading = false;
                    }
                );
        }
        else {
            this.searchResult.emit({ url: this.searchUrl.searchResultUrl, query: this.searchQuery })
        }
    }

    clearSearch() {
        if (this.searchControl.value) {
            if (Object.keys(this.searchQuery).length)
                this.clearResult.emit();
            // this.tempOptions.forEach((element, index) => {
            //     this.tempOptions[index].display = true;
            //     if ('checked' in this.tempOptions[index])
            //         this.tempOptions[index].checked = false;
            // });
            this.searchInput.nativeElement.value = '';
            this.searchControl.reset();
            this.searchQuery = {};
            // this.searchInput.nativeElement.focus();
            this.trigger.closePanel();
            // event.stopPropagation();
            // triggger.openPanel();
        }
    }

}
