import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { SnackbarCongiration } from 'app/shared/components/snack-bar/SnackbarConfiguration';
import { UsersService } from 'app/support/services/users.service';
// import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
// import { MatFormFieldControl } from '@angular/material';
import * as moment from 'moment';


@Component({
  selector: 'app-migration',
  templateUrl: './migration.component.html',
  styleUrls: ['./migration.component.scss']
})
export class MigrationComponent implements OnInit {

  migrationCreateForm: FormGroup;
  securityCreateForm: FormGroup;
  token: Boolean = false;
  screenType: string = '';

  result_type: any = undefined;
  result_arr: Array<any> = []

  progress: boolean = false;
  clinicsList: Array<any> = [];

  constructor(private fb: FormBuilder,
    private UsersService: UsersService,
    private snackbar: SnackbarCongiration,
  ) {
    this.securityForm();
  }

  securityForm() {
    this.securityCreateForm = this.fb.group({
      security_key: ['', [Validators.required]]
    })
  }

  createForm() {
    this.migrationCreateForm = this.fb.group({
      db_url: ['', [Validators.required]],
      user_name: ['', [Validators.required]],
      password: ['', [Validators.required]],
      db_name: ['', [Validators.required]]
    });
  }

  checkKey(event, key) {
    this.UsersService.checkMigrationKey(key).subscribe(data => {
      if (data['status']) {
        this.snackbar.triggerSnackBar({
          message: data['message'],
          type: 'success'
        });
        this.createForm();
        this.token = true;
      }
    },
      error => {
        this.snackbar.triggerSnackBar({
          message: 'Security Key is not valid',
          type: 'error'
        });
      });

  }

  createMigration() {
    this.progress = true;
    let value = { ...this.migrationCreateForm.value };
    this.result_arr = [];
    this.result_type = undefined;
    this.UsersService.migrationData(value).subscribe(data => {
      this.result_type = data['status'];
      if ('result' in data) {
        this.result_arr = data['result'];
      }
      if (data['status']) {
        this.progress = false;
        this.snackbar.triggerSnackBar({
          message: data['message'],
          type: 'success'
        });
        if ('needed' in data && data['needed'] == 'clinic') {
          this.clinicsList = data['clinic_list'];
          this.migrationCreateForm.addControl('clinic', new FormControl(null, Validators.required));
        }
      }
      else {
        this.progress = false;
        this.snackbar.triggerSnackBar({
          message: data['message'],
          type: 'error'
        });
      }
    },
      error => {
        this.progress = false;
        this.snackbar.triggerSnackBar({
          message: 'Error in migration',
          type: 'error'
        });
      });
  }

  downloadPatientCsv() {
    this.progress = true;
    this.UsersService.downloadPatientCSV(this.migrationCreateForm.value).then((res: any) => {
      this.progress = false;
      let responseHeader = res.getResponseHeader('content-type')
      let result = res.responseText;
      if (responseHeader == 'application/json') {
        result = JSON.parse(result);
        // console.log(result);
        if (result['status']) {
          if ('needed' in result && result['needed'] == 'clinic') {
            this.clinicsList = result['clinic_list'];
            this.migrationCreateForm.addControl('clinic', new FormControl(null, Validators.required));
          }
        }
        else {
          this.snackbar.triggerSnackBar({
            message: result['message'],
            type: 'error'
          });
        }
      }
      else if (responseHeader == 'text/csv') {
          var blob = new Blob([res.responseText]);
          let filename = 'patient_data_export_' + moment().format('MMM_D_YYYY')
          if (window.navigator.msSaveOrOpenBlob)  // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
            window.navigator.msSaveBlob(blob, filename + ".csv");
          else {
            var a = window.document.createElement("a");
            a.href = window.URL.createObjectURL(blob);
            a.download = filename + ".csv";
            document.body.appendChild(a);
            a.click();  // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
            document.body.removeChild(a);
          }
      }
    }).catch(res => {
      this.progress = false;
      try {
        let error_response = JSON.parse(res);
        if ('status' in error_response) {
          this.snackbar.triggerSnackBar({
            message: error_response['message'],
            type: 'error'
          });
        }
      }
      catch (err) {
        this.snackbar.triggerSnackBar({
          message: 'Error in patient data download',
          type: 'error'
        });
      }
    })
  }

  ngOnInit() {
  }

}
