import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridDatatableComponent } from './grid-datatable.component';
import { CellRendererComponent } from './cell-renderer/cell-renderer.component';
import { HeaderRendererComponent } from './header-renderer/header-renderer.component';
import { AgGridModule } from 'ag-grid-angular';
import { MatCheckboxModule, MatIconModule, MatButtonModule, MatProgressSpinnerModule, MatDatepicker, MatDatepickerModule, MatFormFieldModule, MatInputModule, MatMenuModule, MatBadgeModule, MatSlideToggleModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ExtendedSearchModule } from '../extended-search/extended-search.module';



@NgModule({
    declarations: [
        GridDatatableComponent,
        CellRendererComponent,
        HeaderRendererComponent
    ],
    imports: [
        CommonModule,
        AgGridModule.withComponents([CellRendererComponent, HeaderRendererComponent]),
        MatCheckboxModule,
        MatIconModule,
        MatButtonModule,
        MatDatepickerModule,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatInputModule,
        FormsModule,
        FlexLayoutModule,
        ExtendedSearchModule,
        MatMenuModule,
        MatBadgeModule,
        MatSlideToggleModule,
    ],
    exports: [
        GridDatatableComponent
    ]
})
export class GridDatatableModule { }
