import { E } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DeleteDialogComponent } from 'app/shared/components/dialog-box/delete-dialog/delete-dialog.component';
import { GlobalService } from 'app/support/services/auth/global.service';
import { ConfigureLayoutComponent } from 'app/workflow-setup/configure-layout/configure-layout.component';
import { WorkFlowComponent } from '../work-flow/work-flow.component';
import { WorkflowSideNavComponent } from '../workflow-side-nav/workflow-side-nav.component';
import { MxGraphService } from 'app/support/services/mx-graph.service';


@Component({
    selector: 'reference',
    templateUrl: './reference.component.html',
    styleUrls: ['./reference.component.scss']
})
export class ReferenceComponent implements OnInit {
    @ViewChild(WorkflowSideNavComponent, { static: false }) workflowSideNav: WorkflowSideNavComponent;
    @ViewChild(WorkFlowComponent, { static: false }) workflowDiagram: WorkFlowComponent;
    private _diagramInstance: any;
    public configureLayout: ConfigureLayoutComponent;
    configureStatus: FormGroup;
    menuItems: any[] = [
        {
            id: 'workflow_setup',
            title: 'Work Flow'
        },
    ];
    dagGenerate: boolean = false;
    dialogModalContent: any = {
        title: '',
        message: ''
    };
    user:any;
    loading: any;
    saveLoading: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private globalService: GlobalService,
        private diagramService: MxGraphService,
        private dialog: MatDialog,
        public dialogRef: MatDialogRef<ReferenceComponent>,
    ) {
        this.configureStatus = this.fb.group({
            'configure_active': [this.data['is_active'] ? this.data['is_active'] : false]
        })
        this.dagGenerate = this.data['is_active'] ? this.data['is_active'] : false;
    }

    ngOnInit() {
        this.globalService.user$.subscribe(user => this.user = user)
        this.loading = this.diagramService.getGraphLoading()
    }

    getDiagramComponent() {
        return this.workflowDiagram;
    }

    close() {
        if(this.user['user_type']['type']=='1'){
            let dialogConfig = new MatDialogConfig();
            dialogConfig.width = '450px';
            dialogConfig.data = { 'yes': null, 'no': null };
            dialogConfig.position = { top: '370px' };
            dialogConfig.disableClose = true,
                dialogConfig.data = {
                    title: 'Close this workflow?',
                    sub_title: 'Are you sure you want to close this workflow?',
                    no_button: 'Close without save',
                    yes_button: 'Save & Close'
                }
            let dialogRef = this.dialog.open(DeleteDialogComponent, dialogConfig);
            dialogRef.afterClosed().subscribe(
                value => {
                    if (value == 'yes') {
                        this.save();
                    }else{
                        this.dialogRef.close(ReferenceComponent); 
                    }
                }
            );
        }else{
            this.dialogRef.close(ReferenceComponent);
        }
    }

    changeActivation(event) {
        this.configureStatus.value['configure_active'] = event.checked;
        if (event.checked) {
            this.dagGenerate = true;
        } else {
            this.dagGenerate = false;
        }
    }

    save() {
        this.saveLoading = true
        this.data['is_active'] = this.configureStatus.value['configure_active'];
        this._diagramInstance = this.getDiagramComponent();
        this._diagramInstance.saveXml(this.data);
        setTimeout(() => {
            this.saveLoading = false
        }, 2000);  
        // this.dialogRef.close(ReferenceComponent);
    }

    undo() {
        this._diagramInstance = this.getDiagramComponent();
        this._diagramInstance.undoAction(this.data);
        // this.dialogRef.close(ReferenceComponent);
    }
}
