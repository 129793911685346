import { Injectable } from "@angular/core";
import { MatSnackBar } from '@angular/material';
import { SnackBarComponent } from './snack-bar.component';


@Injectable({
    providedIn: 'root'
})
export class SnackbarCongiration {

    private config: any = {
        data: {},
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: []
    };

    constructor(private snackbar: MatSnackBar) {

    }

    triggerSnackBar(data, fixed=false ,duration=4000) {
        this.config.data = data;
        this.config.data['intoMark'] = true;
        if(!fixed) {
            this.config['duration'] = duration;
        }
        if (data['type'] == 'success') {
            this.config.panelClass.push('success-snackbar');
        }
        else if (data['type'] == 'error') {
            this.config.panelClass.push('error-snackbar');
        }
        else {
            this.config.panelClass.push('warn-snackbar');
        }
        this.snackbar.openFromComponent(SnackBarComponent, this.config)
        this.resetConfig();
    }

    private resetConfig() {
        this.config.panelClass = []
        // this.config.duration = 4000;
    }
}