import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'custom-slider',
  templateUrl: './custom-slider.component.html',
  styleUrls: ['./custom-slider.component.scss'],
})
export class CustomSliderComponent implements OnInit {
  @ViewChild('sliderContainer', { static: false })
  sliderContainer: ElementRef<HTMLDivElement>;
  @Input() formControlSlider!: FormControl;
  @Input() min: number;
  @Input() max: number;
  @Input() step: number;

  options: Options;
  startPoint: number;
  dimension: number;

  ngOnInit() {
    this.options = {
      floor: this.min,
      ceil: this.max,
      step: this.step,
      hidePointerLabels: true,
      hideLimitLabels: true,
      animate: true,
    };
  }

  ngAfterViewInit() {
    const rect = this.sliderContainer.nativeElement.getBoundingClientRect();
    this.dimension = rect.right - rect.left;

    this.options = {
      ...this.options,
      barDimension: this.dimension,
    };
  }
}
