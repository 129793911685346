import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InterfaceService } from './auth/interface.service';
import { map, catchError, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DashboardService extends InterfaceService {


    constructor(
        private http: HttpClient,
    ) {
        super();
    }

    getDashboardData(params) {
        return this.http.post(this.getApiUrl('dashboard/get-dashboard-data/'), params, this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }
    getAssessmentWidgetData(params) {
        return this.http.get(this.getApiUrl('dashboard/get-assessment-widget-data/', params), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }
    getPatientWidgetData(params) {
        return this.http.get(this.getApiUrl('dashboard/get-patient-widget-data/', params), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }
    getClinicianWidgetData(params) {
        return this.http.get(this.getApiUrl('dashboard/get-clinicians-widget-data/', params), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }
    getClinicsWidgetData(params) {
        return this.http.get(this.getApiUrl('dashboard/get-clinics-widget-data/', params), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }
    getDashboardChartData(data){
        return this.http.post(this.getApiUrl('dashboard/get-initial-dashboard-chart/'), data, this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getAverageAssessmentScore(data?:any) {
        let params = {};
        if (data) {
            params = data
        }
        else {
            params['timezone'] = new Date().getTimezoneOffset();
        }
        return this.http.post(this.getApiUrl('dashboard/get-average-assessment-score-chart/'), params, this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getChartData(data) {
        data['timezone'] = new Date().getTimezoneOffset();
        return this.http.post(this.getApiUrl(`dashboard/get-chart-data/`), data, this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }
    public runAssessmentList(params?:object) {
        return this.http.get(this.getApiUrl(`dashboard/run-assessment-list/`, params), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }
    getChartSettings() {
        return this.http.get(this.getApiUrl(`dashboard/get_chart_settings/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    changeChartSettings(data) {
        return this.http.post(this.getApiUrl(`dashboard/change_chart_settings/`), data, this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }
    getClinics() {
        return this.http.get(this.getApiUrl(`dashboard/get-clinics/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getAssessmentData(params) {
        return this.http.post(this.getApiUrl('dashboard/get-assessment-data/'), params, this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getCustomdata(data) {
        data['timezone'] = new Date().getTimezoneOffset();
        return this.http.post(this.getApiUrl(`dashboard/get-custom-date/`), data, this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }
    filterClinicData(data) {
        data['timezone'] = new Date().getTimezoneOffset();
        return this.http.post(this.getApiUrl(`dashboard/get-overall-chart/`), data, this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getParentBasedClinics() {
        return this.http.get(this.getApiUrl(`dashboard/get-parent-based-clinics/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }
}
