import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  HostListener,
  OnDestroy,
  Inject
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SnackbarCongiration } from 'app/shared/components/snack-bar/SnackbarConfiguration';
import { MxGraphService } from 'app/support/services/mx-graph.service';
import { SmartTestService } from 'app/support/services/smart-test.service';
import { MatDialogRef } from '@angular/material';
import { ReferenceComponent } from '../reference/reference.component';

import { mxgraph, mxgraphFactory } from "mxgraph-factory";
const { mxGraph } = mxgraphFactory({
  mxLoadResources: false,
  mxLoadStylesheets: true,
});

declare var _;

const {
  mxCodec,
  mxImage,
  mxUtils,
  mxCellOverlay,
  mxConstants,
  mxPoint,
  mxCell,
  mxEvent,
  mxKeyHandler,
  mxHierarchicalLayout,
  mxMorphing,
  mxEdgeStyle,
  mxCompactTreeLayout,
  mxLayoutManager,
  mxOutline,
  mxDivResizer,
  mxCellTracker,
  mxCellHighlight,
  mxRubberband,
} = mxgraphFactory({
  mxLoadResources: false,
  mxLoadStylesheets: true,
});
@Component({
  selector: 'app-work-flow',
  templateUrl: './work-flow.component.html',
  styleUrls: ['./work-flow.component.scss']
})
export class WorkFlowComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() data: any;
  @Input() mainObjectsFields: any;
  @Input() tags: any[] = [];
  sourceXml: string = null;
  mxGraph: mxgraph.mxGraph;
  assessmentInclude: any =[];
  assessmentIncludeId:any =[];

  @ViewChild('mx_container', { static: false }) container: ElementRef<HTMLElement>;
  @ViewChild('outlineContainer', { static: false }) outlineContainer: ElementRef<HTMLElement>;
  mainObjectsField: any;
  constructor(
    private diagramService: MxGraphService,
    private route: ActivatedRoute,
    private mx_graphService: MxGraphService,
    private smartTestService: SmartTestService,
    private snackBar: SnackbarCongiration,
    public dialogRef: MatDialogRef<ReferenceComponent>
  ) {
    this.mx_graphService.getmainObj().subscribe(res => {
      this.mainObjectsField = res;
    })
  }
  ngOnInit() {
    this.route.snapshot.params
  }

  ngAfterViewInit() {
    this.setContainerHeight(); 
    this.diagramService.initialize(this.container, {
      xmlSource: this.data.xml_source,
      isScheduler: 0,
      outlineContainer: this.outlineContainer,
      mainObjectsFields: this.mainObjectsField,
      workflowAction:this.data.action,
      viewMode: this.data.viewMode,
      // isMarketPlaceView: this.isMarketPlaceView,
      // tags: this.tags
    });
    this.diagramService.setDiagramComponent(this);
    this.mxGraph = this.diagramService.getGraph();
    this.diagramService.render();
  }


  setContainerHeight() {
    setTimeout(() => {
      const height = window.innerHeight - (this.container.nativeElement.offsetTop + 50);
      this.container.nativeElement.style.minHeight = height + 'px';
    }, 100);
  }

  xmlValidator() {
    return true;
  }

  prepareDag() {
    let mxGraphModel = this.diagramService.getGraph().getModel();
    let rootCell = mxGraphModel.getCell('1');
    /*let dag:any = {
        'cs_initializer': []
    };
    let firstLevel = _.where(rootCell.children, {parentId: "cs_initializer"});
    if(firstLevel.length) {
        _.forEach(firstLevel, (c:mxgraph.mxCell) => {
            dag['cs_initializer'].push(c.getId());
        })
    }*/
    const getBlockObject = () => {
      if (!rootCell.children.length) {
        return [];
      }
      let vertex: any = {};
      _.chain(rootCell.children)
        .filter((cell: mxgraph.mxCell) => {
          return cell.isVertex();
        })
        .map((cell: mxgraph.mxCell) => {
          if(_.has(cell,'block_name') && cell['block_name'] === 'elseConditionalBlock') {
            const value = {
              name: 'elseConditionalBlock',
              model: cell.getValue(),
              parentId: "" + cell.getId(),
              ifBlockId: "" + cell['value']['ifBlockId'],
              id: "" + cell.getId(),
            };
            vertex[cell.getId()] = value;
          }
          _.forEach(cell.edges, (e: mxgraph.mxCell) => {
            if (_.has(e, 'block_name') && e['block_name'] === "condition_edge" && e['source']['id'] == cell.getId()) {
              const value = {
                name: 'conditionEdge',
                model: e.getValue(),
                parentId: "" + cell.getId(),
                targetId: "" + e['target']['id'],
                id: "" + e.getId(),
              };
              vertex[e.getId()] = value;
            }
          })
          
          if(cell['block_name'] == 'gotoBlock'){
            return vertex[cell.getId()] = {
              ...cell.getValue(),
              parentId: "" + cell['parentId'],
              targetId: "" + cell['goto_child'] && cell['goto_child'] != undefined ? (cell['goto_child']).toString() : "",
              id: "" + cell.getId()
            }
          }else if(cell['block_name'] == 'scheduleBlock' || cell['block_name'] == 'launchAssessmentBlock' ||
           cell['block_name'] == 'includeReschedule' || cell['block_name'] == 'includeReassign'){
            return vertex[cell.getId()] = {
              ...cell.getValue(),
              parentIds: cell['parentIds'],
              parentId: "" + cell['parentId'],
              id: "" + cell.getId()
            }
          }else if(cell['block_name'] == 'includeBlock'){
            cell['value']['model'].forEach(e => {
              this.assessmentInclude.push(e);
            })
            return vertex[cell.getId()] = {
              ...cell.getValue(),
              targetId: "" + cell['targetId'] && cell['targetId'] != undefined ? (cell['targetId']).toString() : "",
              targetIds: cell['targetIds'],
              parentId: "" + cell['parentId'],
              id: "" + cell.getId()
            }
          }
          else{
            return vertex[cell.getId()] = {
              ...cell.getValue(),
              parentId: "" + cell['parentId'],
              id: cell.getId() === 'cs_initializer' ? 'cs_initializer' : "" + cell.getId()
            }
          } 
        })
        .value();
      let transformVertex = _.clone(vertex);
      _.chain(transformVertex)
        .filter((item) => _.has(item, 'targetId'))
        .map((item) => {
          vertex[item['targetId']]['parentId'] = "" + item.id;
          return item;
        });
      return vertex;
    };
    const processEndBlock = () => {
      if (!_.size(_dag)) {
        return [];
      }
      let endBlocks: any[] = [];
      let arrayValues: any[] = _.flatten(Object.values(_dag));
      let arrayKeys: any[] = _.flatten(Object.keys(_dag));
      endBlocks = _.filter(arrayValues, (id) => {
        return !_.includes(arrayKeys, id);
      });
      return endBlocks;
    };

    let _dag: any = {
      'cs_initializer': []
    };
    const blocksList = getBlockObject();
    let firstChildren = _.filter(blocksList, { parentId: "cs_initializer" });
    if (firstChildren) {
      _.forEach(firstChildren, (item: any) => {
        _dag['cs_initializer'].push(item.id);
      })
    }

    const _generateDag = (blockId: string) => {
      if (_dag[blockId].length) {
        _.forEach(_dag[blockId], (_id) => {
          let children = _.filter(Object.values(blocksList), { parentId: _id });
          if (children.length) {
            _dag[_id] = [];
            _.forEach(children, (child: any) => {
              _dag[_id].push(child.id);
            });
            _generateDag(_id);
          }
          else {
            return;
          }
        });
      }
    };
    _generateDag('cs_initializer');
    let goto = _.filter(blocksList, {name:"gotoBlock"});
    if(goto){
      _.forEach(goto, (item: any) => {
        _dag[item.id] = [];
        if(item['targetId']){
          _dag[item.id].push(item['targetId']);
        }
      })
    }
    let include = _.filter(blocksList, {name:"includeBlock"});
    if(include){
      _.forEach(include, (item: any) => {
        if(item['targetIds']){
          _.forEach(item['targetIds'], (target: any) => {
            if(_dag[item.id] == undefined){
              _dag[item.id] = [];
            } 
            if(!_dag[item.id].includes(target)){
              _dag[item.id].push(target);
            }
          })
        }
      })
    }
    /*const dagProgess = (blockId:string) => {
      if(dag[blockId].length) {
        _.forEach(dag[blockId], (_id) => {
          let child = _.where(rootCell.children, {parentId: _id});
          if(child.length) {
            dag[_id] = [];
            _.forEach(child, (c:mxgraph.mxCell) => {
              dag[_id].push(c.getId());
            });
            dagProgess(_id);
          }
          else {
            return;
          }
        });
      }
      return;
    };
    dagProgess('cs_initializer');*/
    let airObject: any = {
      dagObject: _dag,
      blockObjects: getBlockObject(),
      endBlocks: processEndBlock()
    };
    let mainObjectName = '';
    let finalairDagMessage: any = {}, finalairEndDagMessage: any[] = [], finalairBlocksMessage: any = {};
    _.forEach(airObject['dagObject'], (children, id) => {
      let dagName = id === 'cs_initializer' ? 'cs_initializer' : airObject['blockObjects'][id]['name'] + '_' + id;
      finalairDagMessage[dagName] = [];
      _.forEach(children, (child_id) => {
        finalairDagMessage[dagName].push(airObject['blockObjects'][child_id]['name'] + '_' + child_id);
      });
    });
    _.forEach(airObject['endBlocks'], (endId) => {
      finalairEndDagMessage.push(airObject['blockObjects'][endId]['name'] + '_' + endId);
    });
    _.forEach(airObject['blockObjects'], (block, id) => {
      let _blockName = id === 'cs_initializer' ? 'cs_initializer' : block['name'] + '_' + id;
      if (id === 'cs_initializer') {
        mainObjectName = block.mainObject;
      }
      finalairBlocksMessage[_blockName] = block;
    });
    return {
      dagObject: finalairDagMessage,
      blockObjects: finalairBlocksMessage,
      endBlocks: finalairEndDagMessage,
      mainObjectName: mainObjectName
    };
  }

  public saveWorkflow(close: boolean = true) {
    if (this.diagramService.unsaveConfirm) {
      this.saveXml(close);
    }
    else {
      // this.closeModal();
    }
  }

  public getDiagramService() {
    return this.diagramService;
  }

  public undoAction(data) {
    let previousXml = this.diagramService.getPreviousXmlSource()
    if (previousXml) {
      this.diagramService.destroyMxGraph()
      this.setContainerHeight();
      this.diagramService.initialize(this.container, {
        xmlSource: previousXml,
        isScheduler: 0,
        outlineContainer: this.outlineContainer,
        mainObjectsFields: this.mainObjectsField,
        workflowAction: this.data.action,
        viewMode: this.data.viewMode
      });
      this.diagramService.setDiagramComponent(this);
      this.mxGraph = this.diagramService.getGraph();
      this.diagramService.render();
      this.diagramService.clearPreviousXmlSource()
    } else {
      this.snackBar.triggerSnackBar({
        message: 'no new changes detected',
        type: 'error'
      });
    }
  }

  public saveXml(data, uriEncode?: boolean) {
    if (!this.xmlValidator()) {
      return;
    }
    let dagSource = this.prepareDag();
    // try {
    //   let isInclude = "includeBlock"
    //   let assessmentData = []
    //   let includeData = []
    //   Object.keys(dagSource.dagObject).forEach(element => {
    //     if (element.includes(isInclude)) {
    //       includeData.push(element)
    //       assessmentData.push(dagSource.dagObject[element])
    //     }
    //   });
    //   if (includeData.length > 0) {
    //     let isSchedule = []
    //     assessmentData.map((item, index) => {
    //       Object.keys(item).forEach(element => {
    //         isSchedule.push(item[element])
    //       });
    //     })
    //     if (isSchedule.length === 0) {
    //       this.snackBar.triggerSnackBar({
    //         message: 'The workflow cannot be saved without the Schedule/Include Run block',
    //         type: 'error'
    //       });
    //       return
    //     }
    //   }
    // } catch (e) {
    // }
    
    // this.data.xmlSource = this.diagramService.getXml();
    // const codec = new mxCodec();
    // let result = codec.encode(this.mxGraph.getModel());
    // let xml = mxUtils.getXml(result);
    let xml = this.mx_graphService.getXmlData();
    if (uriEncode) {
      return encodeURIComponent(xml);
    }
    if(dagSource.mainObjectName && dagSource.dagObject.cs_initializer.length > 0) {
      this.smartTestService.workflowConfigureSave({
        smartTestId:data['id'],
        xmlSource: xml,
        dagSource: dagSource,
        objectName: dagSource.mainObjectName,
        doRegenerateDag: data['is_active'],
        assessmentInclude: this.assessmentInclude
      }).subscribe((resp) => {
      // this.reloadCurrentPage();
      this.snackBar.triggerSnackBar({
        message: resp['message'],
        type: 'success'
      });
      this.dialogRef.close();
      this.diagramService.unsaveConfirm = false;
      // this.diagramService.doRegenerateDag = false;
    });
    }else {
      this.snackBar.triggerSnackBar({
        message: 'cannot save without block (or) flow',
        type: 'error'
      });
    }
  }

  reloadCurrentPage() {
    this.smartTestService.refreshRowData('refresh');
  }

  closeModal() {
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setContainerHeight();
  }

  ngOnDestroy() {
    this.mxGraph.destroy();
    this.data = {};
  }
}
