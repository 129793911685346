import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-workflow-side-nav',
    templateUrl: './workflow-side-nav.component.html',
    styles: ['./workflow-side-nav.component.scss'],
})
export class WorkflowSideNavComponent {
    @Input() data: any;
    @Input() menuItems: Array<any> = [];
    constructor() {

    }

    ngOnInit() {
        
    }
}