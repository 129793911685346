import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-reload',
  templateUrl: './reload.component.html',
  styleUrls: ['./reload.component.scss']
})
export class ReloadComponent implements OnInit {
  yes: any;
  /**
     * Constructor
     *
     * @param {MatDialogRef<DialogComponent>} dialogRef
     * 
     */
  constructor(
    public dialogRef: MatDialogRef<ReloadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  ngOnInit() {
  }

  close() {
    console.log(this.data);
    this.dialogRef.close();
    if(this.data.is_tab_close) {
      window.location.reload();
      // window.close();
    }
  }
  closeModal(param): void {
    this.dialogRef.close(param);
  }
}
