import { Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from 'app/shared/services/config.service';
import { FuseNavigationService } from 'app/shared/components/navigation/navigation.service';
import { FuseSidebarService } from 'app/shared/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from 'app/shared/services/splash-screen.service';
import { FuseTranslationLoaderService } from 'app/shared/services/translation-loader.service';

import { navigation } from 'app/support/nav-menu/navigation';
import { locale as navigationEnglish } from 'app/support/nav-menu/i18n/en';
import { locale as navigationTurkish } from 'app/support/nav-menu/i18n/tr';
import { GlobalService } from './support/services/auth/global.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GetApiurl } from './support/app-config';
import { PhoenixWebsocketService } from './support/services/phoenix-websocket.service';
import {Idle, EventTargetInterruptSource, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
import { AuthService } from './support/services/auth/auth.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { SessionLogoutComponent } from './shared/components/dialog-box/session-logout/session-logout.component';
import { UsersService } from './support/services/users.service';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;
    message: any;
    showAssessment: boolean = false
    subscriptions: Object = {};


    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private globalService: GlobalService,
        private router: Router,
        private phoenixWebSocket: PhoenixWebsocketService,
        private idle: Idle,
        private authService: AuthService,
        private element: ElementRef,
        private dialog: MatDialog,
        private userService: UsersService
    ) {
        // Get default navigation
        this.navigation = navigation;

        // Register the navigation to the service
        this._fuseNavigationService.register('main', this.navigation);

        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation('main');

        // Add languages
        this._translateService.addLangs(['en', 'tr']);

        // Set the default language
        this._translateService.setDefaultLang('en');

        // Set the navigation translations
        this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationTurkish);

        // Use a language
        this._translateService.use('en');

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix Start
         * ----------------------------------------------------------------------------------------------------
         */

        /**
         * If you are using a language other than the default one, i.e. Turkish in this case,
         * you may encounter an issue where some of the components are not actually being
         * translated when your app first initialized.
         *
         * This is related to ngxTranslate module and below there is a temporary fix while we
         * are moving the multi language implementation over to the Angular's core language
         * service.
         **/

        // Set the default language to 'en' and then back to 'tr'.
        // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
        // been selected and there is no way to force it, so we overcome the issue by switching
        // the default language back and forth.
        /**
         setTimeout(() => {
            this._translateService.setDefaultLang('en');
            this._translateService.setDefaultLang('tr');
         });
         */

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix End
         * ----------------------------------------------------------------------------------------------------
         */

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        /* idle timeout */
        if (authService.authenticated()) {
            let timeout = globalService.user$.subscribe(data => {
                if (!data['device']) {
                    this.setTimeoutInterval(data);
                    setTimeout(() => timeout.unsubscribe())
                }
            });
        }
        else idle.stop();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Redirect Url Api
        if (window.location.pathname == '/secret_key') {
            let url_array = (window.location.search).split('k=')
            if (url_array.length > 1) {
                this.globalService.redirectUrl({ 'token': url_array[1] }).subscribe((url: any) => {
                    window.location.href = url
                })
            }
        }


        if (window.location.pathname == '/assessment-link' || window.location.pathname == '/assessment-link/view') {
            this.showAssessment = true
        } else {
            this.showAssessment = false
        }
        // const userId = 'user001';
        // this.firebaseService.configureFirebaseNotifications(userId)   
        // this.phoenixWebSocket.initializePhoenixWebSocket();

        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {

                this.fuseConfig = config;

                // Boxed
                if (this.fuseConfig.layout.width === 'boxed') {
                    this.document.body.classList.add('boxed');
                }
                else {
                    this.document.body.classList.remove('boxed');
                }

                // Color theme - Use normal for loop for IE11 compatibility
                for (let i = 0; i < this.document.body.classList.length; i++) {
                    const className = this.document.body.classList[i];

                    if (className.startsWith('theme-')) {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.fuseConfig.colorTheme);
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        Object.entries(this.subscriptions).forEach(
            ([name, subscription]) => subscription.unsubscribe()
        );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    setTimeoutInterval(data: any) {
        let timeout = data['timeout'];
        this.idle.stop();
        this.idle.setIdle(1);
        this.idle.setIdle((timeout * 60) - 1);
        this.idle.setTimeout(1);
        this.idle.clearInterrupts();
        
        // let x = 'keydown DOMMouseScroll mousewheel mousedown mousemove touchstart touchmove scroll';
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); 

        let idleTiemout = this.idle.onTimeout.subscribe(() => {
            if (this.authService.authenticated()) {
                this.dialog.closeAll();
                let dialogConfig = new MatDialogConfig();
                dialogConfig.width = '600px';
                dialogConfig.disableClose = true;
                dialogConfig.restoreFocus = false;
                dialogConfig.data = { confirm: false };
                this.phoenixWebSocket.closeConnection();
                let dialogRef = this.dialog.open(SessionLogoutComponent, dialogConfig);
                //   dialogRef.afterClosed().subscribe(
                //       response => {
                //           if (response) {
                //               this.phoenixWebSocket.closeConnection();
                //               this.idle.stop();
                //           }
                //           else this.setTimeoutInterval({ timeout: timeout });
                //       }
                //   );
            }
            // idleTiemout.unsubscribe();
        });
        this.phoenixWebSocket.setSessionSubscription(idleTiemout, this.idle);
        
        // start watching or resets watching time
        this.idle.watch();
    }

}
