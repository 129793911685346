import { Component, OnInit, NgZone, Inject } from '@angular/core';
import { PatientService } from 'app/support/services/patient.service';
import { InterfaceService } from 'app/support/services/auth/interface.service';
import { HttpClient } from '@angular/common/http';
import { SnackbarCongiration } from 'app/shared/components/snack-bar/SnackbarConfiguration';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Columns, GridConfig, Renderers, RowEvent, HeaderEvent } from 'app/shared/grid-datatable/grid-datatable';




@Component({
    selector: 'app-patient-group-delete',
    templateUrl: './patient-group-delete.component.html',
    styleUrls: ['./patient-group-delete.component.scss']
})
export class PatientGroupDeleteComponent implements OnInit {
    rowData: any;
    view: any;


    constructor(private patientService: PatientService,
        private http: HttpClient,
        private snackbar: SnackbarCongiration,
        private router: Router,
        public dialogRef: MatDialogRef<PatientGroupDeleteComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,) {

    }
    ngOnInit() {

        this.patientService.screenview1$.subscribe(data => {
            this.view = data;
        })

        this.rowData = this.patientService.patientGroupDetails();
    }

    closeModal(params: any) {
        this.dialogRef.close(params);
    }
    DeletePatients(value) {
        this.closeModal(value);
    }
    DeleteGroup() {

        this.patientService.patientGroupDelete(this.rowData).subscribe(data => {

            this.snackbar.triggerSnackBar({
                message: data['message'],
                type: 'success'

            });
            // this.patientService.PatientViewChange('Patients'); 
            this.patientService.refreshRowData('refresh');

            error => {
                this.snackbar.triggerSnackBar({
                    message: data['message'],
                    type: 'error'
                });
            }
        })
    }

}