import { Component, EventEmitter, Inject, OnInit, Output, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig } from '@angular/material';
import { DeleteDialogComponent } from 'app/shared/components/dialog-box/delete-dialog/delete-dialog.component';
import { ValidationService } from 'app/shared/services/validation.service';
import { MxGraphService } from 'app/support/services/mx-graph.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

@Component({
    selector: 'app-launch-include-panel',
    templateUrl: './launch-include-panel.component.html',
    styleUrls: ['./launch-include-panel.component.scss']
})
export class LaunchIncludePanelComponent implements OnInit {

    @Output() outputModel: EventEmitter<any> = new EventEmitter<any>();
    @Output() edit: EventEmitter<any> = new EventEmitter<any>();
    @ViewChildren('matSelect_j') matSelect_j;
    taskForm: FormGroup = new FormGroup({});
    assmnt: boolean = false;
    condition_val: any;
    selected_input: any;
    isEdit: boolean = false;
    activeField: any = {};
    show_operator: boolean = false;
    temp_data: any;
    activeMetaDatas: any;
    activeInputs: any = {};
    deleteModalContent: any = {
        title: '',
        message: ''
    };
    selectedBlock: string = '';
    deleteConfirmTextInput: string = '';
    openDeleteConfirmation: boolean = false;
    isMarketPlaceView: boolean = false;
    activateCondtions: boolean = false;
    grp_rule: boolean = false;
    valuesArr: Array<any> = []
    operatorValuesList: any;
    grpNameList: any;
    dayList = [
        {id:0, value:'Days'},
        {id:1, value:'Weeks'},
        {id:2, value:'Months'},
        {id:3, value:'Years'}
    ];
    scoreList = [
        {id:0, value:'Overall'},
        {id:1, value:'Latest'},
    ];
    expiration_settings: boolean = false;
    intervalHrsList = [
        {'hr': 0, 'value': '00'},
        {'hr': 1, 'value': '01'},
        {'hr': 2, 'value': '02'},
        {'hr': 3, 'value': '03'},
        {'hr': 4, 'value': '04'},
        {'hr': 5, 'value': '05'},
        {'hr': 6, 'value': '06'},
        {'hr': 7, 'value': '07'},
        {'hr': 8, 'value': '08'},
        {'hr': 9, 'value': '09'},
        {'hr': 10, 'value': '10'},
        {'hr': 11, 'value': '11'},
        {'hr': 12, 'value': '12'},
        {'hr': 13, 'value': '13'},
        {'hr': 14, 'value': '14'},
        {'hr': 15, 'value': '15'},
        {'hr': 16, 'value': '16'},
        {'hr': 17, 'value': '17'},
        {'hr': 18, 'value': '18'},
        {'hr': 19, 'value': '19'},
        {'hr': 20, 'value': '20'},
        {'hr': 21, 'value': '21'},
        {'hr': 22, 'value': '22'},
        {'hr': 23, 'value': '23'},
    ]
    intervalMinsList = [
        {'min': 0, 'value': '00'},
        {'min': 5, 'value': '05'},
        {'min': 10, 'value': '10'},
        {'min': 15, 'value': '15'},
        {'min': 20, 'value': '20'},
        {'min': 25, 'value': '25'},
        {'min': 30, 'value': '30'},
        {'min': 35, 'value': '35'},
        {'min': 40, 'value': '40'},
        {'min': 45, 'value': '45'},
        {'min': 50, 'value': '50'},
        {'min': 55, 'value': '55'},
    ]
    assessmentList: any = [];
    expTimeList = [
        { id: 1, value: 'Before' },
        { id: 2, value: 'After' }
    ];
    selectedExpiryOption:any;
    list_color:boolean = false;
    enableSaveBtn = true;

    constructor(
        private fb: FormBuilder,
        private mx_graphService: MxGraphService,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<LaunchIncludePanelComponent>,
    ) {
        this.isEdit = this.data['isEdit'];
        this.grpNameList = this.data['grp_name_list'];
        this.activeMetaDatas = this.data['triggerData'];
        this.taskForm = this.fb.group({
            operator: [''],
            assessments: [[]],
            code: [''],
            code2: [''],
            unit: [''],
            all_operators: false,
            assessment_expire_link_settings:[false],
            expire_days:[1, Validators.required],
            expire_hrs:[0, Validators.required],
            expire_mins:[0, Validators.required],
        })
        if (this.data['selectedBlockModal'].isConditional == true && this.data['cell']['value']['name'] == 'conditionalBlock' && !this.isEdit) {
            this.selectedBlock = 'conditionalBlock';
            this.activeField = this.data['activeField'];
            this.taskForm.get('operator').setValidators(Validators.required);
            this.taskForm.get('code').setValidators(Validators.required);
            if(this.activeField.text == 'Total Score' || this.activeField.input == 'unit'){
                this.taskForm.get('unit').setValidators(Validators.required);
            }
        }
        if (this.data['selectedBlockModal'].isConditional == true && this.data['cell']['value']['name'] == 'multiconditionalBlock' && !this.isEdit) {
            this.selectedBlock = 'multiconditionalBlock';
        }
        let model = this.data['mxGraph'].getModel();
        let schedule = this.data['cell']['id'];
        let arr = [];
        let ids = [];
        Object.values(model['cells']).forEach((e,i) => {
            if(e['block_name'] == 'includeBlock'){
                if(this.isEdit && (e['targetId'] == schedule || e['targetIds'].includes(schedule) || e['id'] == schedule)){
                    e['value']['model'].forEach(el => {
                        if(!ids.includes(el.assessment_id)){
                            ids.push(el.assessment_id);
                            arr.push(el);
                        }
                    })
                }else if(e['id'] == schedule){
                    e['value']['model'].forEach(el => {
                        if(!ids.includes(el.assessment_id)){
                            ids.push(el.assessment_id);
                            arr.push(el);
                        }
                    })
                }
            }
        })
        if(this.isEdit){
            let selected_assessment = [];
            let selected_ids = [];
            if(this.data['cell']['value']['model']){
                this.data['cell']['value']['model'].forEach((e,i)=>{
                    if(ids.includes(e.assessment_id)){
                        selected_assessment.push(e);
                        selected_ids.push(e.assessment_id);
                    }
                });
            }
            this.assessmentList = selected_assessment;
            if(arr.length > this.assessmentList.length){
               this.list_color = true;
            }
            arr.forEach((e,i)=>{
                if(!selected_ids.includes(e.assessment_id)){
                    this.assessmentList.push(e);
                }
            })
            this.taskForm.get('assessments').setValue(this.assessmentList);
        }else{
            this.assessmentList = arr;
            this.taskForm.get('assessments').setValue(this.assessmentList);
        }
        this.taskForm.valueChanges.subscribe({
            next: (res) => {
                this.checkCondition();
            }
        })
    }

    ngOnInit() {
        if (this.isEdit) {
            let parent = this.data.mxGraph.getModel().getCell(this.data.cell['parentId']);
            this.expiration_settings = this.data['cell']['value']['assessment_data']['assessment_expire_link_settings'];
            this.taskForm.controls['assessment_expire_link_settings'].setValue(this.data['cell']['value']['assessment_data']['assessment_expire_link_settings']);
            this.taskForm.get('expire_days').setValue(this.data['cell']['value']['assessment_data']['expire_days']);
            this.taskForm.get('expire_hrs').setValue(this.data['cell']['value']['assessment_data']['expire_hrs']);
            this.taskForm.get('expire_mins').setValue(this.data['cell']['value']['assessment_data']['expire_mins']);
            if (this.data['selectedBlockModal'].isConditional == true && parent['block_name'] == 'conditionalBlock') {
                this.selectedBlock = 'conditionalBlock';
                this.activeField = this.data['activeField'];
                if(this.activeField.text == 'Total Score' || this.activeField.input == 'unit'){
                    this.taskForm.get('unit').setValidators(Validators.required);
                }
                this.taskForm.get('operator').setValidators(Validators.required);
                this.taskForm.get('code').setValidators(Validators.required);
                this.taskForm.get('operator').setValue(this.data['selectedBlockModal']['condition']['operator']);
                this.taskForm.get('code').setValue(this.data['selectedBlockModal']['condition']['value']);
                this.taskForm.get('code2').setValue(this.data['selectedBlockModal']['condition']['value2']);
                this.taskForm.get('unit').setValue(this.data['selectedBlockModal']['condition']['unit']);
                this.temp_data = this.data['selectedBlockModal']['condition']['value'];
                if(this.temp_data == 'English' && !['contains', 'does not contains'].includes(this.data['selectedBlockModal']['condition']['operator'])) this.taskForm.get('code').setValue('EN');
                if(this.temp_data == 'Spanish' && !['contains', 'does not contains'].includes(this.data['selectedBlockModal']['condition']['operator'])) this.taskForm.get('code').setValue('SP');
                this.outputModel.emit(this.temp_data);
                this.edit.emit(this.isEdit);
            }
            if (this.data['selectedBlockModal'].isConditional == true && parent['block_name'] == 'multiconditionalBlock') {
                this.selectedBlock = 'multiconditionalBlock';
                this.edit.emit(this.isEdit);
            }
        }
    }

    selectConditionCode(evt,i){
        if(evt.value == 'EN') this.taskForm.value['code'] = 'English';
        if(evt.value == 'SP') this.taskForm.value['code'] = 'Spanish';
    }

    setOperator(){
        this.taskForm.get('code').setValue('');
        this.taskForm.get('code2').setValue('');
        this.taskForm.get('unit').setValue('');
        if((this.taskForm.get('operator').value === 'equal' || this.taskForm.get('operator').value === 'not equal') && 
        (this.activeField.text == 'Clinician Email (EMR)' || this.activeField.text == 'Patient Email' || this.activeField.text == 'Patient Email (EMR)')){
            this.taskForm.get('code').setValidators([Validators.required, ValidationService.emailValidator]);
        }else{
            this.taskForm.get('code').setValidators([Validators.required]);
        }
        if(this.taskForm.get('operator').value === 'exist' || this.taskForm.get('operator').value === 'not exist' ||
        this.taskForm.get('operator').value === 'empty' || this.taskForm.get('operator').value === 'not empty' ||
        this.taskForm.get('operator').value === 'Yes' || this.taskForm.get('operator').value === 'No'){
            this.taskForm.get('code').setValue(['?']);
        }else if(this.taskForm.get('operator').value === 'between'){
            this.taskForm.get('code2').setValidators(Validators.required);
        }else{
            this.taskForm.get('code2').clearValidators();
        }
        this.taskForm.get('code').updateValueAndValidity();
        this.taskForm.get('code2').updateValueAndValidity();
        this.taskForm.get('unit').updateValueAndValidity();
        this.taskForm.get('all_operators').setValue(false);
    }

    removeCell() {
        let dialogConfig = new MatDialogConfig();
        dialogConfig.width = '40%';
        dialogConfig.data = { 'yes': null, 'no': null, 'option': null };
        dialogConfig.position = { top: '70px' };
        dialogConfig.disableClose = true;
        dialogConfig.data = {
            title: 'Delete Flow?',
            sub_title: 'Are you sure? You want to delete this flow?',
            no_button: 'No, keep it',
            yes_button: 'Yes, Delete'
        }

        let deleteRef = this.dialog.open(DeleteDialogComponent, dialogConfig);
        deleteRef.afterClosed().subscribe(
            value => {
                this.mx_graphService.setGraphLoadingTrue()
                if (value == 'yes') {
                    this.dialogRef.close('remove');
                }
                if(value == 'option'){
                    this,this.dialogRef.close('particular');
                }
            }
        );
    }

    addValue() {
        this.mx_graphService.setGraphLoadingTrue()
        this.data['selectedBlockModal']['model'] = this.taskForm.value['assessments'];
        this.data['selectedBlockModal']['assessment_data'] = {
            'assessment_expire_link_settings':this.taskForm.controls['assessment_expire_link_settings'].value,
            'expire_days': this.taskForm.controls['expire_days'].value,
            'expire_hrs': this.taskForm.controls['expire_hrs'].value,
            'expire_mins': this.taskForm.controls['expire_mins'].value,
        }
        if (this.isEdit) {
            let parent = this.data.mxGraph.getModel().getCell(this.data.cell['parentId']);
            if (this.data['selectedBlockModal'].isConditional == true && parent['block_name'] == 'conditionalBlock') {
                this.data['selectedBlockModal']['condition']['operator'] = this.taskForm.value['operator'];
                this.data['selectedBlockModal']['condition']['value'] = this.taskForm.value['code'];
                this.data['selectedBlockModal']['condition']['value2'] = this.taskForm.value['code2'];
                this.data['selectedBlockModal']['condition']['unit'] = this.taskForm.value['unit'];
            }
            if (this.data['selectedBlockModal'].isConditional == true && parent['block_name'] == 'multiconditionalBlock') {
                if(this.data['selectedBlockModal']['condition'] == null){
                    this.data['selectedBlockModal']['condition'] = this.taskForm.value;
                }else{
                    this.data['selectedBlockModal']['condition']['value'] = this.taskForm.value;
                }
            }
        } else {
            if (this.data['selectedBlockModal'].isConditional == true && this.data['cell']['value']['name'] == 'conditionalBlock') {
                this.data['selectedBlockModal']['condition']['operator'] = this.taskForm.value['operator'];
                this.data['selectedBlockModal']['condition']['value'] = this.taskForm.value['code'];
                this.data['selectedBlockModal']['condition']['value2'] = this.taskForm.value['code2'];
                this.data['selectedBlockModal']['condition']['unit'] = this.taskForm.value['unit'];
            }
            if (this.data['selectedBlockModal'].isConditional == true && this.data['cell']['value']['name'] == 'multiconditionalBlock') {
                this.data['selectedBlockModal']['condition'] = this.taskForm.value;
            }
        }
        this.dialogRef.close(this.data);
    }

    closeModal() {
        this.mx_graphService.setGraphLoadingTrue()
        this.dialogRef.close('close');
    }

    isNumber(evt) {
        evt = (evt) ? evt : window.event;
        let keys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab']
        var charCode = evt.keyCode;
        if (((charCode >= 48 && charCode <= 57 || charCode >= 96 && charCode <= 105) && !evt.shiftKey) || keys.includes(evt.key)) {
          return true;
        }
        return false;
      }

    onPaste(event) {
        let clipboardData = event.clipboardData;
        let pastedText = clipboardData.getData('text');
        var number = new RegExp("^[0-9]+$");
        if (number.test(pastedText)) {
            return true;
        }
        else {
            return false;
        }
    }

    selectCondtionAllOperators(event) {
        try {
            if (event.checked) {

                this.taskForm.get('all_operators').setValue(true);
                this.operatorValuesList = this.activeField.options;
                this.operatorValuesList.forEach(e => {
                    if (e) {
                        this.valuesArr.push(e.id);
                    }
                })
                this.taskForm.get('code').setValue(this.valuesArr.concat([]))
                this.matSelect_j._results[0].options._results.forEach((x,y) => {
                    x.select();
                  });
            } else {
                this.matSelect_j._results[0].options._results.forEach((x,y) => {
                    x.deselect();
                  });
                this.taskForm.get('all_operators').setValue(false);
                this.taskForm.get('code').setValue([]);
            }
        }
        finally {
            this.valuesArr = [];
        }
    }

    checkConditionOperators(i) {
        let count;
        count = this.taskForm.controls['code'].value;
        if (count.length == this.activeField.options.length) {
            this.taskForm.get('all_operators').setValue(true);
        } else {
            this.taskForm.get('all_operators').setValue(false);
        }
    }

    checkCondition() {
        if (this.data['selectedBlockModal'].isConditional == true && this.selectedBlock == 'multiconditionalBlock') {
            if (this.taskForm.controls.rules) {
                if(this.taskForm.controls.rules.value.length == 0){
                    this.enableSaveBtn = false;
                    return;
                } 
                if (this.taskForm.controls.rules.value && this.taskForm.controls.rules.value.length) {
                    const all_check = this.taskForm.controls.rules.value.map((e, i) => {
                        const sub_rule_check = e.rules.map((se, j) => {
                            if (se.rules) {
                                if (se.rules[j] && se.rules[j]['condition']) {
                                    const sub_check = se.rules.map((el) => {
                                        const check_sub_length = el.rules.length > 0;
                                        return check_sub_length ? this.taskForm.valid : check_sub_length;
                                    });
                                    return sub_check.every(u => !!u);
                                }
                                const check_sub_length = se.rules.length > 0;
                                return check_sub_length ? this.taskForm.valid : check_sub_length;
                            }
                            return this.taskForm.valid;
                        });
                        return sub_rule_check.length > 0 ? sub_rule_check.every(u => !!u) && this.taskForm.valid : false;
                    });
                    this.enableSaveBtn = all_check.every(v => !!v);
                    return;
                }
            }
        }else{
            this.enableSaveBtn = this.taskForm.valid;
            return
        }
    }

    expireSettings(event){
        if (event.checked == true) {
            this.taskForm.get('assessment_expire_link_settings').setValue(true);
            this.expiration_settings = true;
            this.taskForm.get('expire_days').setValidators(Validators.required);
        } else {
            this.taskForm.get('assessment_expire_link_settings').setValue(false);
            this.expiration_settings = false;
            this.taskForm.get('expire_days').clearValidators();
            this.taskForm.get('expire_hrs').clearValidators();
            this.taskForm.get('expire_mins').clearValidators();
            this.taskForm.get('expire_hrs').updateValueAndValidity();
            this.taskForm.get('expire_mins').updateValueAndValidity();
        }
        this.taskForm.get('expire_days').updateValueAndValidity();
    }

    expireChange(){
        if (parseInt(this.taskForm.get('expire_days').value) === 0 && this.taskForm.get('expire_hrs').value === 0 && this.taskForm.get('expire_mins').value === 0) {
			this.taskForm.get('expire_mins').setValue(15);
		}
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        this.taskForm.get('assessments').setValue(this.assessmentList)
    }
}