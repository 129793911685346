import { Component, EventEmitter, Inject, OnInit, Output, ViewChildren } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { DeleteDialogComponent } from "app/shared/components/dialog-box/delete-dialog/delete-dialog.component";
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MxGraphService } from "app/support/services/mx-graph.service";
import { ValidationService } from "app/shared/services/validation.service";

@Component({
    selector: 'app-reschedule-block',
    templateUrl: './reschedule-block.component.html',
    styleUrls: ['./reschedule-block.component.scss']
  })
  export class RescheduleBlockComponent implements OnInit {
    @Output() outputModel: EventEmitter<any> = new EventEmitter<any>();
    @Output() edit: EventEmitter<any> = new EventEmitter<any>();
    @ViewChildren('matSelect_j') matSelect_j;
    taskForm: FormGroup = new FormGroup({});
    assmnt: boolean = false;
    condition_val: any;
    selected_input: any;
    isEdit: boolean = false;
    activeField: any = {};
    assessmentList: any;
    show_operator: boolean = false;
    temp_data: any;
    activeMetaDatas: any;
    activeInputs: any = {};
    deleteModalContent: any = {
        title: '',
        message: ''
    };
    selectedBlock: string = '';
    deleteConfirmTextInput: string = '';
    openDeleteConfirmation: boolean = false;
    isMarketPlaceView: boolean = false;
    activateCondtions: boolean = false;
    grp_rule: boolean = false;
    valuesArr: Array<any> = []
    operatorValuesList: any;
    defaultSetting:boolean = false;
    notification_setting:boolean = false;
    expiration_settings:boolean=false;
    notificationDeviceList = ['Email', 'Mobile', 'In-Clinic Device', 'Email & Mobile', 'All Three'];
    deviceList: any = [];
    respondentList = [
        { id: -1, value: 'Patient' },
        { id: 1, value: 'R1' },
        { id: 2, value: 'R2' },
        { id: 3, value: 'R3' },
        { id: 4, value: 'R4' },
        { id: 5, value: 'R5' }
    ];
    retryCountList: any = [
        // { id: 0, value: '0' },
        { id: 1, value: '1' },
        { id: 2, value: '2' },
        { id: 3, value: '3' },
        { id: 4, value: '4' },
        { id: 5, value: '5' },
        { id: 6, value: '6' },
        { id: 7, value: '7' },
        { id: 8, value: '8' },
        { id: 9, value: '9' },
        { id: 10, value: '10' }
      ];
      intervalHrsList = [
          {'hr': 0, 'value': '00'},
          {'hr': 1, 'value': '01'},
          {'hr': 2, 'value': '02'},
          {'hr': 3, 'value': '03'},
          {'hr': 4, 'value': '04'},
          {'hr': 5, 'value': '05'},
          {'hr': 6, 'value': '06'},
          {'hr': 7, 'value': '07'},
          {'hr': 8, 'value': '08'},
          {'hr': 9, 'value': '09'},
          {'hr': 10, 'value': '10'},
          {'hr': 11, 'value': '11'},
          {'hr': 12, 'value': '12'},
          {'hr': 13, 'value': '13'},
          {'hr': 14, 'value': '14'},
          {'hr': 15, 'value': '15'},
          {'hr': 16, 'value': '16'},
          {'hr': 17, 'value': '17'},
          {'hr': 18, 'value': '18'},
          {'hr': 19, 'value': '19'},
          {'hr': 20, 'value': '20'},
          {'hr': 21, 'value': '21'},
          {'hr': 22, 'value': '22'},
          {'hr': 23, 'value': '23'},
      ]
      intervalMinsList = [
        {'min': 0, 'value': '00'},
        {'min': 5, 'value': '05'},
        {'min': 10, 'value': '10'},
        {'min': 15, 'value': '15'},
        {'min': 20, 'value': '20'},
        {'min': 25, 'value': '25'},
        {'min': 30, 'value': '30'},
        {'min': 35, 'value': '35'},
        {'min': 40, 'value': '40'},
        {'min': 45, 'value': '45'},
        {'min': 50, 'value': '50'},
        {'min': 55, 'value': '55'},
    ]
      intervalSecsList = [
        {'sec': 0, 'value': '00'},
        {'sec': 15, 'value': '15'},
        {'sec': 30, 'value': '30'},
        {'sec': 45, 'value': '45'}
      ]
      periodsList = [
        { "id" : 1, 'value': 'Current', 'name': 'Current'},
        { "id" : 2, 'value': 'Before', 'name': 'Before'},
        { "id" : 3, 'value': 'After', 'name': 'After'}
      ]
    grpNameList: any;
    dayList = [
        {id:0, value:'Days'},
        {id:1, value:'Weeks'},
        {id:2, value:'Months'},
        {id:3, value:'Years'}
    ];
    scoreList = [
        {id:0, value:'Overall'},
        {id:1, value:'Latest'},
    ];
    operatorList = [
        {"id":'Equal to', value:'Equal To'},
        {"id":'Greater than or Equal To', value:'Greater than or Equal To'}
    ];
    expTimeList = [
        { "id": 'Before', value: 'Before' },
        { "id": 'After', value: 'After' }
    ];
    addDaysList = [
        {'day': 0, 'value': '00'},
        {'day': 1, 'value': '01'},
        {'day': 2, 'value': '02'},
        {'day': 3, 'value': '03'},
        {'day': 4, 'value': '04'},
        {'day': 5, 'value': '05'},
        {'day': 6, 'value': '06'},
        {'day': 7, 'value': '07'},
        {'day': 8, 'value': '08'},
        {'day': 9, 'value': '09'},
        {'day': 10, 'value': '10'}
    ]
    selectedRetryList: any = [];
    enableLaunch: boolean = false
    selectedExpiryOption: any
    selectedRemainderList: any = [];
    selectedLaunchList: any = [];
    list_color:boolean = false;
    enableSaveBtn = true;
  
    constructor(
        private fb: FormBuilder,
        private dialog: MatDialog,
        private mx_graphService: MxGraphService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<RescheduleBlockComponent>,
    ){
        this.isEdit = this.data['isEdit'];
        this.grpNameList = this.data['grp_name_list'];
        this.activeMetaDatas = this.data['triggerData'];
        this.taskForm = this.fb.group({
            operator: [''],
            code: [''],
            code2: [''],
            unit: [''],
            assessments: [[]],
            assessment_settings:[false],
            assessment_expire_link_settings:[false],
            assessment_expiration_option:[1],
            assessment_expire_hrs:[''],
            assessment_expire_mins:[''],
            assessment_expire_operator:[''],
            assessment_expire_appointment_days_hrs:[''],
            assessment_expire_appointment_days_mins:[''],
            add_assessment_settings: this.fb.array([]),
            all_operators: false,
            notification_settings: [false],
            grouping_assessments: [false],
            notificationdevice: [[]],
            respondent:[[]],
            device_launch_type: [''],
            all_respondent:[false],
        })
        if (this.data['selectedBlockModal'].isConditional == true && this.data['cell']['value']['name'] == 'conditionalBlock' && !this.isEdit) {
            this.selectedBlock = 'conditionalBlock';
            this.activeField = this.data['activeField'];
            this.taskForm.get('operator').setValidators(Validators.required);
            this.taskForm.get('code').setValidators(Validators.required);
            if(this.activeField.text == 'Total Score' || this.activeField.input == 'unit'){
                this.taskForm.get('unit').setValidators(Validators.required);
            }
        }
        if(this.data['selectedBlockModal'].isConditional == true && this.data['cell']['value']['name'] == 'multiconditionalBlock' && !this.isEdit){
            this.selectedBlock = 'multiconditionalBlock';
        }
        let model = this.data['mxGraph'].getModel();
        let reschedule = this.data['cell']['id'];
        let arr = [];
        let ids = [];
        Object.values(model['cells']).forEach((e,i) => {
            if(e['block_name'] == 'includeBlock'){
                if(this.isEdit && (e['targetId'] == reschedule || e['targetIds'].includes(reschedule) || e['id'] == reschedule)){
                    e['value']['model'].forEach(el => {
                        if(!ids.includes(el.assessment_id)){
                            ids.push(el.assessment_id);
                            arr.push(el);
                        }
                    })
                }else if(e['id'] == reschedule){
                    e['value']['model'].forEach(el => {
                        if(!ids.includes(el.assessment_id)){
                            ids.push(el.assessment_id);
                            arr.push(el);
                        }
                    })
                }
            }
        })
        if(this.isEdit){
            let selected_assessment = [];
            let selected_ids = [];
            if(this.data['cell']['value']['model']['assessments']){
                this.data['cell']['value']['model']['assessments'].forEach((e,i)=>{
                    if(ids.includes(e.assessment_id)){
                        selected_assessment.push(e);
                        selected_ids.push(e.assessment_id);
                    }
                });
            }
            this.assessmentList = selected_assessment;
            if(arr.length > this.assessmentList.length){
               this.list_color = true;
            }
            arr.forEach((e,i)=>{
                if(!selected_ids.includes(e.assessment_id)){
                    this.assessmentList.push(e);
                }
            })
            this.taskForm.get('assessments').setValue(this.assessmentList);
        }else{
            this.assessmentList = arr;
            this.taskForm.get('assessments').setValue(this.assessmentList);
        }
        this.taskForm.valueChanges.subscribe({
            next: (res) => {
                this.checkCondition();
            }
        })
    }
    ngOnInit() {
        if (this.isEdit) {
            let parent = this.data.mxGraph.getModel().getCell(this.data.cell['parentId']);
            this.defaultSetting = this.data['cell']['value']['model']['assessment_settings'];
            this.taskForm.get('assessment_settings').setValue(this.data['cell']['value']['model']['assessment_settings']);
            this.taskForm.controls['notification_settings'].setValue(this.data['cell']['value']['model']['notification_settings']);           
            this.taskForm.controls['grouping_assessments'].setValue(this.data['cell']['value']['model']['grouping_assessments']);
            this.notification_setting = this.data['cell']['value']['model']['notification_settings'];
            this.taskForm.controls['assessment_expire_link_settings'].setValue(this.data['cell']['value']['model']['assessment_expire_link_settings']);
            this.expiration_settings = this.data['cell']['value']['model']['assessment_expire_link_settings'];
            this.deviceList = this.data['cell']['value']['model']['notificationDevice'].concat([]);
            this.taskForm.get('notificationdevice').setValue(this.deviceList)
            let differenceArray = this.notificationDeviceList.filter(x => !this.deviceList.includes(x));
            this.notificationDeviceList = differenceArray
            if(this.notification_setting == true){
                this.taskForm.get('notificationdevice').setValidators(Validators.required);
                this.taskForm.get('notificationdevice').updateValueAndValidity();
                this.checkInDeviceCheck()
                this.taskForm.controls['device_launch_type'].setValue(this.data['cell']['value']['model']['device_launch_type']);
            }
            if(this.data['cell']['value']['model']['respondent']){
                this.taskForm.controls['respondent'].setValue(this.data['cell']['value']['model']['respondent']);
                if(this.data['cell']['value']['model']['respondent'].length == this.respondentList.length) { this.taskForm.controls['all_respondent'].setValue(true); }
            }
            this.edit.emit(this.isEdit);
            if(this.data['cell']['value']['model']['notificationDevice'].length == this.respondentList.length) { this.taskForm.controls['all_notification_device'].setValue(true); }
            if(this.data['selectedBlockModal'].isConditional == true && parent['block_name'] == 'multiconditionalBlock'){
                this.selectedBlock = 'multiconditionalBlock';
                if(this.defaultSetting == true && this.data['cell']['value']['model']['add_assessment_settings'] != undefined ){
                    if(this.data['cell']['value']['model']['add_assessment_settings'].length > 0){
                        this.data['cell']['value']['model']['add_assessment_settings'].forEach((e,i)=>{
                            this.addSetting(e,i);
                        })
                    }
                    if(this.data['cell']['value']['model']['assessment_expiration_option']) {
                        this.taskForm.get('assessment_expiration_option').setValue(this.data['cell']['value']['model']['assessment_expiration_option']);
                        this.selectedExpiryOption = this.data['cell']['value']['model']['assessment_expiration_option']
                        this.expireValidation(this.data['cell']['value']['model']['assessment_expiration_option']);
                    }else{
                        this.expireValidation(1);
                    }
                }
                this.taskForm.get('assessment_expire_hrs').setValue(this.data['cell']['value']['model']['assessment_expire_hrs']);
                this.taskForm.get('assessment_expire_mins').setValue(this.data['cell']['value']['model']['assessment_expire_mins']);
                this.taskForm.get('assessment_expire_operator').setValue(this.data['cell']['value']['model']['assessment_expire_operator']);
                this.taskForm.get('assessment_expire_appointment_days_hrs').setValue(this.data['cell']['value']['model']['assessment_expire_appointment_days_hrs']);
                this.taskForm.get('assessment_expire_appointment_days_mins').setValue(this.data['cell']['value']['model']['assessment_expire_appointment_days_mins']);
                // this.taskForm.get('condition').setValue(this.data['cell']['value']['condition']['condition'])
            }else{
                if(this.data['selectedBlockModal'].isConditional == true && parent['block_name'] == 'conditionalBlock' ){
                    this.selectedBlock = 'conditionalBlock';
                    this.activeField = this.data['activeField'];
                    if(this.activeField.text == 'Total Score' || this.activeField.input == 'unit'){
                        this.taskForm.get('unit').setValidators(Validators.required);
                    }
                    this.taskForm.get('operator').setValidators(Validators.required);
                    this.taskForm.get('code').setValidators(Validators.required);
                    this.taskForm.get('operator').setValue(this.data['selectedBlockModal']['condition']['operator']);
                    this.taskForm.get('code').setValue(this.data['selectedBlockModal']['condition']['value']);
                    this.taskForm.get('code2').setValue(this.data['selectedBlockModal']['condition']['value2']);
                    this.taskForm.get('unit').setValue(this.data['selectedBlockModal']['condition']['unit']);
                    this.temp_data = this.data['selectedBlockModal']['condition']['value'];
                    if(this.temp_data == 'English' && !['contains', 'does not contains'].includes(this.data['selectedBlockModal']['condition']['operator'])) this.taskForm.get('code').setValue('EN');
                    if(this.temp_data == 'Spanish' && !['contains', 'does not contains'].includes(this.data['selectedBlockModal']['condition']['operator'])) this.taskForm.get('code').setValue('SP');
                    this.outputModel.emit(this.temp_data);  
                }
                if(this.defaultSetting == true && this.data['cell']['value']['model']['add_assessment_settings'] != undefined ){
                    if(this.data['cell']['value']['model']['add_assessment_settings'].length > 0){
                        this.data['cell']['value']['model']['add_assessment_settings'].forEach((e,i)=>{
                            this.addSetting(e,i);
                        })
                    }
                    if(this.data['cell']['value']['model']['assessment_expiration_option']) {
                        this.taskForm.get('assessment_expiration_option').setValue(this.data['cell']['value']['model']['assessment_expiration_option']);
                        this.selectedExpiryOption = this.data['cell']['value']['model']['assessment_expiration_option']
                        this.expireValidation(this.data['cell']['value']['model']['assessment_expiration_option']);
                    }else{
                        this.expireValidation(1);
                    }
                }
                this.taskForm.get('assessment_expire_hrs').setValue(this.data['cell']['value']['model']['assessment_expire_hrs']);
                this.taskForm.get('assessment_expire_mins').setValue(this.data['cell']['value']['model']['assessment_expire_mins']);
                this.taskForm.get('assessment_expire_operator').setValue(this.data['cell']['value']['model']['assessment_expire_operator']);
                this.taskForm.get('assessment_expire_appointment_days_hrs').setValue(this.data['cell']['value']['model']['assessment_expire_appointment_days_hrs']);
                this.taskForm.get('assessment_expire_appointment_days_mins').setValue(this.data['cell']['value']['model']['assessment_expire_appointment_days_mins']);
            }    
        }
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        this.taskForm.get('assessments').setValue(this.assessmentList)
    }

    selectConditionCode(evt,i){
        if(evt.value == 'EN') this.taskForm.value['code'] = 'English';
        if(evt.value == 'SP') this.taskForm.value['code'] = 'Spanish';
    }

    addSetting(val,i){
        try {
            let set = {
                launch_option: val ? val['launch_option'] : 2,
                launch_days: val ? val['launch_days'] : '',
                launch_hrs: val ? val['launch_hrs'] : '',
                launch_mins: val ? val['launch_mins'] : '',
                launch_before_hrs: val ? val['launch_before_hrs'] : '',
                launch_before_mins: val ? val['launch_before_mins'] : '',
                execution_operator: val ? val['execution_operator'] : '',
                execution_days: val ? val['execution_days'] : '',
                retry_remainder: [],
                retry_count: val ? val['retry_count'] : ''
            }
            const formSet = this.taskForm.controls.add_assessment_settings as FormArray;
            formSet.push(this.fb.group({
                launch_option: [set.launch_option, [Validators.required]],
                launch_days: [set.launch_days],
                launch_hrs: [set.launch_hrs],
                launch_mins: [set.launch_mins],
                launch_before_hrs: [set.launch_before_hrs],
                launch_before_mins: [set.launch_before_mins],
                execution_operator: [set.execution_operator, [Validators.required]],
                execution_days: [set.execution_days, [Validators.required]],
                retry_remainder: new FormArray([
                ]),
                retry_count: [set.retry_count, [Validators.required]],
            }))
            if (val) {
                this.executeDayChange(this.data['cell']['value'], i, val);
                this.launchSet(this.data['cell']['value'], i, val);
                if (val['retry_remainder'].length >= 1) {
                    val['retry_remainder'].forEach((elem, ind) => {
                        this.addRemainder(val['retry_count'], i, elem);
                    })
                }
            }
        } catch (e) {

        }
    }

    addRemainder(evt,ind,val){
        try {
            let set = this.taskForm.controls.add_assessment_settings as FormArray;
            let retry = set.controls[ind].get('retry_remainder') as FormArray;
            let self = {
                retry_days: val ? val['retry_days'] : '',
                retry_option: val ? val['retry_option'] : 1,
                retry_hrs: val ? val['retry_hrs'] : '',
                retry_mins: val ? val['retry_mins'] : '',
                retry_before_hrs: val ? val['retry_before_hrs'] : '',
                retry_before_mins: val ? val['retry_before_mins'] : ''
            }
            if (!val) {
                retry.clear();
                for (let i = 0; i < evt.value; i++) {
                    retry.push(this.createDynamicForm(self));
                    this.retrySetting(evt,ind,i,1)
                }
            } else {
                retry.push(this.createDynamicForm(self));
            }
        } catch (e) {

        }
        
    }

    createDynamicForm(self : any = '') {
        return this.fb.group({
            retry_days:[self.retry_days],
            retry_option:[self.retry_option,[Validators.required]],
            retry_hrs:[self.retry_hrs],
            retry_mins:[self.retry_mins],
            retry_before_hrs:[self.retry_before_hrs],
            retry_before_mins:[self.retry_before_mins]
          });
    }

    launchSet(evt,i,val){
        let launch;
        launch = val ? val : evt.value;
        const setting = this.taskForm.controls.add_assessment_settings as FormArray;
        try {
            if (!(launch == 1 || launch == 2)) {
                if (launch == 3) {
                    setting.controls[i].get('launch_days').setValidators(Validators.required);
                    setting.controls[i].get('launch_hrs').setValidators(Validators.required);
                    setting.controls[i].get('launch_mins').setValidators(Validators.required);
                    setting.controls[i].get('launch_before_hrs').setValue('');
                    setting.controls[i].get('launch_before_mins').setValue('');
                    setting.controls[i].get('launch_before_hrs').clearValidators();
                    setting.controls[i].get('launch_before_mins').clearValidators();
                }
                if (launch == 4) {
                    setting.controls[i].get('launch_before_hrs').setValidators(Validators.required);
                    setting.controls[i].get('launch_before_mins').setValidators(Validators.required);
                    setting.controls[i].get('launch_days').setValue('');
                    setting.controls[i].get('launch_hrs').setValue('');
                    setting.controls[i].get('launch_mins').setValue('');
                    setting.controls[i].get('launch_days').clearValidators();
                    setting.controls[i].get('launch_hrs').clearValidators();
                    setting.controls[i].get('launch_mins').clearValidators();
                }
            }else{
                setting.controls[i].get('launch_days').setValue('');
                setting.controls[i].get('launch_hrs').setValue('');
                setting.controls[i].get('launch_mins').setValue('');
                setting.controls[i].get('launch_days').clearValidators();
                setting.controls[i].get('launch_hrs').clearValidators();
                setting.controls[i].get('launch_mins').clearValidators();
                setting.controls[i].get('launch_before_hrs').setValue('');
                setting.controls[i].get('launch_before_mins').setValue('');
                setting.controls[i].get('launch_before_hrs').clearValidators();
                setting.controls[i].get('launch_before_mins').clearValidators();
            }
            setting.controls[i].get('launch_days').updateValueAndValidity();
            setting.controls[i].get('launch_hrs').updateValueAndValidity();
            setting.controls[i].get('launch_mins').updateValueAndValidity();
            setting.controls[i].get('launch_before_hrs').updateValueAndValidity();
            setting.controls[i].get('launch_before_mins').updateValueAndValidity();
        } catch (e) {

        }
    }

    expireValidation(val){
        this.selectedExpiryOption = val
        try {
            if (val == 1) {
                this.taskForm.get('assessment_expire_appointment_days_hrs').setValidators(Validators.required);
                this.taskForm.get('assessment_expire_appointment_days_mins').setValidators(Validators.required);
                this.taskForm.get('assessment_expire_hrs').setValue('');
                this.taskForm.get('assessment_expire_mins').setValue('');
                this.taskForm.get('assessment_expire_operator').setValue('');
                this.taskForm.get('assessment_expire_hrs').clearValidators();
                this.taskForm.get('assessment_expire_mins').clearValidators();
                this.taskForm.get('assessment_expire_operator').clearValidators();
            }
            if (val == 2) {
                this.taskForm.get('assessment_expire_appointment_days_hrs').clearValidators();
                this.taskForm.get('assessment_expire_appointment_days_mins').clearValidators();
                this.taskForm.get('assessment_expire_appointment_days_hrs').setValue('');
                this.taskForm.get('assessment_expire_appointment_days_mins').setValue('');
                this.taskForm.get('assessment_expire_hrs').setValidators(Validators.required);
                this.taskForm.get('assessment_expire_mins').setValidators(Validators.required);
                this.taskForm.get('assessment_expire_operator').setValidators(Validators.required);
            }
            this.taskForm.get('assessment_expire_appointment_days_hrs').updateValueAndValidity();
            this.taskForm.get('assessment_expire_appointment_days_mins').updateValueAndValidity();
            this.taskForm.get('assessment_expire_hrs').updateValueAndValidity();
            this.taskForm.get('assessment_expire_mins').updateValueAndValidity();
            this.taskForm.get('assessment_expire_operator').updateValueAndValidity();
        } catch (e) {

        }
       
    }

    retrySetting(evt,i,j,val){
        let option;
        let set = this.taskForm.controls.add_assessment_settings as FormArray;
        let retry = set.controls[i].get('retry_remainder') as FormArray;
        option = val ? val : retry.controls[j].get('retry_option').value;
        try {
            if (option == 1) {
                retry.controls[j].get('retry_days').setValidators(Validators.required);
                retry.controls[j].get('retry_hrs').setValidators(Validators.required);
                retry.controls[j].get('retry_mins').setValidators(Validators.required);
                retry.controls[j].get('retry_before_hrs').setValue('');
                retry.controls[j].get('retry_before_mins').setValue('');;
                retry.controls[j].get('retry_before_hrs').clearValidators();
                retry.controls[j].get('retry_before_mins').clearValidators();
            }
            if (option == 2) {
                retry.controls[j].get('retry_days').clearValidators();
                retry.controls[j].get('retry_hrs').clearValidators();
                retry.controls[j].get('retry_mins').clearValidators();
                retry.controls[j].get('retry_days').setValue('');
                retry.controls[j].get('retry_hrs').setValue('');
                retry.controls[j].get('retry_mins').setValue('');
                retry.controls[j].get('retry_before_hrs').setValidators(Validators.required);
                retry.controls[j].get('retry_before_mins').setValidators(Validators.required);
            }
            retry.controls[j].get('retry_days').updateValueAndValidity();
            retry.controls[j].get('retry_hrs').updateValueAndValidity();
            retry.controls[j].get('retry_mins').updateValueAndValidity();
            retry.controls[j].get('retry_before_hrs').updateValueAndValidity();
            retry.controls[j].get('retry_before_mins').updateValueAndValidity();
        } catch (e) {

        }        
    }

    defaultSettings(event){
        if(event.checked == true){
            this.taskForm.get('assessment_settings').setValue(true);
            this.defaultSetting = true;
            this.taskForm.get('assessment_expiration_option').setValidators(Validators.required);
            this.expireValidation(this.taskForm.get('assessment_expiration_option').value ? this.taskForm.get('assessment_expiration_option').value : 1);
            const formSet = this.taskForm.controls.add_assessment_settings as FormArray;
            if(formSet.value.length === 0) {
                this.addSetting('','');
            }
        }else{
            const formSet = this.taskForm.controls.add_assessment_settings as FormArray;
            formSet.value.forEach((e,i) => {
                formSet.removeAt(i);
                formSet.clear();
            })
            this.taskForm.get('add_assessment_settings').updateValueAndValidity();
            this.taskForm.get('assessment_settings').setValue(false);
            this.defaultSetting = false;
            this.taskForm.get('assessment_expiration_option').clearValidators();
            this.taskForm.get('assessment_expire_appointment_days_hrs').clearValidators();
            this.taskForm.get('assessment_expire_appointment_days_mins').clearValidators();
            this.taskForm.get('assessment_expire_hrs').clearValidators();
            this.taskForm.get('assessment_expire_mins').clearValidators();
            this.taskForm.get('assessment_expire_operator').clearValidators();
            this.taskForm.get('assessment_expiration_option').setValue(1);
            this.taskForm.get('assessment_expire_appointment_days_hrs').reset();
            this.taskForm.get('assessment_expire_appointment_days_mins').reset();
            this.taskForm.get('assessment_expire_hrs').reset();
            this.taskForm.get('assessment_expire_mins').reset();
            this.taskForm.get('assessment_expire_operator').reset();
            this.taskForm.get('assessment_expiration_option').updateValueAndValidity();
            this.taskForm.get('assessment_expire_appointment_days_hrs').updateValueAndValidity();
            this.taskForm.get('assessment_expire_appointment_days_mins').updateValueAndValidity();
            this.taskForm.get('assessment_expire_hrs').updateValueAndValidity();
            this.taskForm.get('assessment_expire_mins').updateValueAndValidity();
            this.taskForm.get('assessment_expire_operator').updateValueAndValidity();
        }
    }


    expireSettings(event){
        if(event.checked == true){
            this.taskForm.get('assessment_expire_link_settings').setValue(true);
            this.expiration_settings = true;
            this.taskForm.get('assessment_expiration_option').setValidators(Validators.required);
            this.expireValidation(this.taskForm.get('assessment_expiration_option').value);
        }else{
            this.taskForm.get('assessment_expire_link_settings').setValue(false);
            this.expiration_settings = false;
            this.taskForm.get('assessment_expiration_option').clearValidators();
            this.taskForm.get('assessment_expiration_option').reset();
            this.taskForm.get('assessment_expire_appointment_days_hrs').clearValidators();
            this.taskForm.get('assessment_expire_appointment_days_mins').clearValidators();
            this.taskForm.get('assessment_expire_hrs').clearValidators();
            this.taskForm.get('assessment_expire_mins').clearValidators();
            this.taskForm.get('assessment_expire_operator').clearValidators();
            this.taskForm.get('assessment_expire_appointment_days_hrs').updateValueAndValidity();
            this.taskForm.get('assessment_expire_appointment_days_mins').updateValueAndValidity();
            this.taskForm.get('assessment_expire_hrs').updateValueAndValidity();
            this.taskForm.get('assessment_expire_mins').updateValueAndValidity();
            this.taskForm.get('assessment_expire_operator').updateValueAndValidity();
        }
    }

    setOperator(){
        this.taskForm.get('code').setValue('');
        this.taskForm.get('code2').setValue('');
        this.taskForm.get('unit').setValue('');
        if((this.taskForm.get('operator').value === 'equal' || this.taskForm.get('operator').value === 'not equal') && 
        (this.activeField.text == 'Clinician Email (EMR)' || this.activeField.text == 'Patient Email' || this.activeField.text == 'Patient Email (EMR)')){
            this.taskForm.get('code').setValidators([Validators.required, ValidationService.emailValidator]);
        }else{
            this.taskForm.get('code').setValidators([Validators.required]);
        }
        if(this.taskForm.get('operator').value === 'exist' || this.taskForm.get('operator').value === 'not exist' ||
        this.taskForm.get('operator').value === 'empty' || this.taskForm.get('operator').value === 'not empty' ||
        this.taskForm.get('operator').value === 'Yes' || this.taskForm.get('operator').value === 'No'){
            this.taskForm.get('code').setValue(['?']);
        }else if(this.taskForm.get('operator').value === 'between'){
            this.taskForm.get('code2').setValidators(Validators.required);
        }else{
            this.taskForm.get('code2').clearValidators();
        }
        this.taskForm.get('code').updateValueAndValidity();
        this.taskForm.get('code2').updateValueAndValidity();
        this.taskForm.get('unit').updateValueAndValidity();
        this.taskForm.get('all_operators').setValue(false);
    }

    removeCell() {
        let dialogConfig = new MatDialogConfig();
        dialogConfig.width = '40%';
        dialogConfig.data = { 'yes': null, 'no': null, 'option': null };
        dialogConfig.position = { top: '70px' };
        dialogConfig.disableClose = true;
        dialogConfig.data = {
            title: 'Delete Flow?',
            sub_title: 'Are you sure? You want to delete this flow?',
            no_button: 'No, keep it',
            yes_button: 'Yes, Delete'
        }
        let deleteRef = this.dialog.open(DeleteDialogComponent, dialogConfig);
        deleteRef.afterClosed().subscribe(
            value => {
                this.mx_graphService.setGraphLoadingTrue()
                if (value == 'yes') {
                    this.dialogRef.close('remove');
                }
                if(value == 'option'){
                    this,this.dialogRef.close('particular');
                }
            }
        );
      }

    checkAssessments() {
        const formSet = this.taskForm.controls.add_assessment_settings as FormArray;
        let isAssessment = this.taskForm.controls['assessment_settings'].value
        if(isAssessment && formSet.value.length === 0) {
            return true
        } else {
            return false
        }
    }

    addValue() {
        this.mx_graphService.setGraphLoadingTrue()
        this.data['selectedBlockModal']['model'] = {
            'assessments': this.taskForm.controls['assessments'].value,
            'assessment_settings':this.taskForm.controls['assessment_settings'].value,
            'notification_settings' : this.taskForm.controls['notification_settings'].value,
            'grouping_assessments': this.taskForm.controls['grouping_assessments'].value,
            'notificationDevice' : this.taskForm.controls['notification_settings'].value ? this.taskForm.controls['notificationdevice'].value : [],
            'device_launch_type': this.taskForm.controls['notification_settings'].value ? this.taskForm.controls['device_launch_type'].value : '',
            'respondent' : this.taskForm.controls['respondent'].value,
            'assessment_expire_link_settings': this.taskForm.controls['assessment_expire_link_settings'].value,
            'assessment_expiration_option':this.taskForm.controls['assessment_expiration_option'].value,
            'assessment_expire_hrs':this.taskForm.controls['assessment_expire_hrs'].value,
            'assessment_expire_mins':this.taskForm.controls['assessment_expire_mins'].value,
            'assessment_expire_operator':this.taskForm.controls['assessment_expire_operator'].value,
            'assessment_expire_appointment_days_hrs':this.taskForm.controls['assessment_expire_appointment_days_hrs'].value,
            'assessment_expire_appointment_days_mins':this.taskForm.controls['assessment_expire_appointment_days_mins'].value,
            'add_assessment_settings':this.taskForm.controls['add_assessment_settings'].value,
        }
        if(this.isEdit){
            let parent = this.data.mxGraph.getModel().getCell(this.data.cell['parentId']);
            if (this.data['selectedBlockModal'].isConditional == true && parent['block_name'] == 'conditionalBlock' ) {
              this.data['selectedBlockModal']['condition']['operator'] = this.taskForm.value['operator'];
              this.data['selectedBlockModal']['condition']['value'] = this.taskForm.value['code'];
              this.data['selectedBlockModal']['condition']['value2'] = this.taskForm.value['code2'];
              this.data['selectedBlockModal']['condition']['unit'] = this.taskForm.value['unit'];
            }
            if(this.data['selectedBlockModal'].isConditional == true && parent['block_name'] == 'multiconditionalBlock' ) {
                if(this.data['selectedBlockModal']['condition']['rules'] == undefined){
                    this.data['selectedBlockModal']['condition'] = this.taskForm.value;
                }else{
                    this.data['selectedBlockModal']['condition']['value'] = this.taskForm.value;
                }
            }
          }else{
            if (this.data['selectedBlockModal'].isConditional == true && this.data['cell']['value']['name'] == 'conditionalBlock') {
              this.data['selectedBlockModal']['condition']['operator'] = this.taskForm.value['operator'];
              this.data['selectedBlockModal']['condition']['value'] = this.taskForm.value['code'];
              this.data['selectedBlockModal']['condition']['value2'] = this.taskForm.value['code2'];
              this.data['selectedBlockModal']['condition']['unit'] = this.taskForm.value['unit'];
            }
            if(this.data['selectedBlockModal'].isConditional == true && this.data['cell']['value']['name'] == 'multiconditionalBlock') {
              this.data['selectedBlockModal']['condition'] = this.taskForm.value;
            }
          }
        this.dialogRef.close(this.data);

    }

    closeModal() {
        this.mx_graphService.setGraphLoadingTrue()
        this.dialogRef.close('close');
    }

    isNumber(evt) {
        evt = (evt) ? evt : window.event;
        let keys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab']
        var charCode = evt.keyCode;
        if (((charCode >= 48 && charCode <= 57 || charCode >= 96 && charCode <= 105) && !evt.shiftKey) || keys.includes(evt.key)) {
          return true;
        }
        return false;
      }

    onPaste(event) {
        let clipboardData = event.clipboardData;
        let pastedText = clipboardData.getData('text');
        var number = new RegExp("^[0-9]+$");
        if (number.test(pastedText)) {
            return true;
        }
        else {
            return false;
        }
    }

    selectCondtionAllOperators(event) {
        try {
            if (event.checked) {

                this.taskForm.get('all_operators').setValue(true);
                this.operatorValuesList = this.activeField.options;
                this.operatorValuesList.forEach(e => {
                    if (e) {
                        this.valuesArr.push(e.id);
                    }
                })
                this.taskForm.get('code').setValue(this.valuesArr.concat([]))
                this.matSelect_j._results[0].options._results.forEach((x,y) => {
                    x.select();
                  });
            } else {
                this.matSelect_j._results[0].options._results.forEach((x,y) => {
                    x.deselect();
                  });
                this.taskForm.get('all_operators').setValue(false);
                this.taskForm.get('code').setValue([]);
            }
        }
        finally {
            this.valuesArr = [];
        }
    }

    checkConditionOperators(i) {
        let count;
        count = this.taskForm.controls['code'].value;
        if (count.length == this.activeField.options.length) {
            this.taskForm.get('all_operators').setValue(true);
        } else {
            this.taskForm.get('all_operators').setValue(false);
        }
    }

    checkCondition() {
        if (this.data['selectedBlockModal'].isConditional == true && this.selectedBlock == 'multiconditionalBlock') {
            if (this.taskForm.controls.rules) {
                if(this.taskForm.controls.rules.value.length == 0){
                    this.enableSaveBtn = false;
                    return;
                } 
                if (this.taskForm.controls.rules.value && this.taskForm.controls.rules.value.length) {
                    const all_check = this.taskForm.controls.rules.value.map((e, i) => {
                        const sub_rule_check = e.rules.map((se, j) => {
                            if (se.rules) {
                                if (se.rules[j] && se.rules[j]['condition']) {
                                    const sub_check = se.rules.map((el) => {
                                        const check_sub_length = el.rules.length > 0;
                                        return check_sub_length ? this.taskForm.valid : check_sub_length;
                                    });
                                    return sub_check.every(u => !!u);
                                }
                                const check_sub_length = se.rules.length > 0;
                                return check_sub_length ? this.taskForm.valid : check_sub_length;
                            }
                            return this.taskForm.valid;
                        });
                        return sub_rule_check.length > 0 ? sub_rule_check.every(u => !!u) && this.taskForm.valid : false;
                    });
                    this.enableSaveBtn = all_check.every(v => !!v);
                    return;
                }
            }
        }else{
            this.enableSaveBtn = this.taskForm.valid;
            return
        }
    }
    
    abovePeriodChange () {
        let periodValue = this.taskForm.get('above_launch_period').value
        if(periodValue == 'Current') {
            this.taskForm.get('above_launch_day').setValue(0)
        }
    }

    notificationSettings(event){
        if(event.checked == true){
            this.taskForm.get('notification_settings').setValue(true);
            this.notification_setting = true;
            this.taskForm.get('notificationdevice').setValidators(Validators.required);
            this.taskForm.get('notificationdevice').updateValueAndValidity();
            this.checkInDeviceCheck()
        }else{
            this.taskForm.get('notification_settings').setValue(false);
            this.notification_setting = false;
            this.taskForm.get('notificationdevice').clearValidators();
            this.taskForm.get('notificationdevice').updateValueAndValidity();
            if(this.taskForm.get('device_launch_type')) {
                this.taskForm.get('device_launch_type').clearValidators();
                this.taskForm.get('device_launch_type').updateValueAndValidity();
            }
        }
    }

    grouping_settings(event){
        if(event.checked == true){
            this.taskForm.get('grouping_assessments').setValue(true);
        }else{
            this.taskForm.get('grouping_assessments').setValue(false);
        }
    }

    notificationDrop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex,
            );
        }
        this.taskForm.get('notificationdevice').setValue(this.deviceList)
        this.checkInDeviceCheck()
    }

    checkInDeviceCheck() {
        let inDeviceKey = 'In-Clinic Device'
        let allThreeKey = 'All Three'
        if(this.deviceList.includes(inDeviceKey) || this.deviceList.includes(allThreeKey)) {
            this.enableLaunch = true
            this.taskForm.get('device_launch_type').setValidators(Validators.required);
            this.taskForm.get('device_launch_type').updateValueAndValidity();
        } else {
            this.enableLaunch = false
            this.taskForm.get('device_launch_type').setValue(null);
            this.taskForm.get('device_launch_type').clearValidators();
            this.taskForm.get('device_launch_type').updateValueAndValidity();
        }
    }

    checkRespondent() {
        let count = this.taskForm.controls['respondent'].value;
        if(count.length == this.respondentList.length){
            this.taskForm.get('all_respondent').setValue(true);
        }else{
            this.taskForm.get('all_respondent').setValue(false);
        }
    }

    selectAllRespondent(event) {
        let respondents = this.respondentList.map(arr => arr.value);
        if (event.checked) {
            this.taskForm.get('all_respondent').setValue(true);
            this.taskForm.get('respondent').setValue(respondents.concat([]));
        } else {
            this.taskForm.get('all_respondent').setValue(false);
            this.taskForm.get('respondent').setValue([]);
        }
    }

    onBegainDeleteAction(rule: FormGroup, index: number) {
        this.deleteModalContent.title = 'Delete condition';
        this.deleteModalContent.deletableIndex = index;
        this.openDeleteConfirmation = true;
        let dialogConfig = new MatDialogConfig();
        dialogConfig.width = '40%';
        dialogConfig.data = { 'yes': null, 'no': null };
        dialogConfig.position = { top: '70px' };
        dialogConfig.disableClose = true,
            dialogConfig.data = {
                title: 'Delete Schedule block?',
                sub_title: 'Are you sure ? you want to delete this Schedule block?',
                no_button: 'No, keep it',
                yes_button: 'Yes, Delete'
            }
        let dialogRef = this.dialog.open(DeleteDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            value => {
                if (value == 'yes') {
                    this.removeTriggerCondition();
                }
            }
        );
    }

    removeTriggerCondition() {
        const { deletableIndex } = this.deleteModalContent;
        this.removeSetByIndex(deletableIndex);
        this.openDeleteConfirmation = false;
        this.deleteModalContent.deletableIndex = -1;
        this.deleteConfirmTextInput = '';
    };

    removeSetByIndex(index: number) {
        const control = <FormArray>this.taskForm.controls['add_assessment_settings'];
        control.removeAt(index);
    }

    executeDayChange(set: FormGroup, index: number,val) {
        let form = this.taskForm.controls.add_assessment_settings as FormArray;
        let reminder = form.controls[index].get('retry_remainder') as FormArray;
        reminder.clear();
        val ? '' : form.controls[index].get('retry_count').setValue(0);
        val ? '' : form.controls[index].get('launch_days').setValue('');
        let selectedDays;
        if(val){
            selectedDays = val['execution_days'];
        }else{
            selectedDays = set.value.execution_days
        }
        
        let emptyArray = [];
        for (var i = 0; i <= selectedDays + 1; i++) {
            let obj = { 
                'value': i,
                'retry': i
            }
            emptyArray.push(obj)
        }
        this.selectedRetryList[index] = emptyArray

        let remainderArray = [];
        for(let i = 0; i <= selectedDays; i++) {
            let objValue = { 
                'value' : i,
                'remainder': i
            }
            remainderArray.push(objValue)
        }
        this.selectedRemainderList[index] = remainderArray
        this.selectedLaunchList[index] = remainderArray
    }
}