import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidepanelComponent } from './cs-sidepanel/sidepanel.component';
import {FormsModule} from "@angular/forms";
import {ModalModule} from "ngx-bootstrap";
import {TranslateModule} from '@ngx-translate/core';
import { SidepanelOptions } from './services/sidepanel-options';
import { SidepanelModalService } from './services/sidepanel-modal.service';

@NgModule({
  declarations: [SidepanelComponent],
  imports: [
    FormsModule,
    CommonModule,
    TranslateModule,
    ModalModule.forRoot()
  ],
  providers: [
    SidepanelOptions,
    SidepanelModalService
  ],
  entryComponents: [
    SidepanelComponent
  ]

})
export class SidepanelModule { }
