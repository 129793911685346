import {
    AfterViewInit,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    Output,
    ViewChild,
  } from '@angular/core';
  import {
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
  } from '@angular/forms';
  import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE,
    MatDatepicker,
  } from '@angular/material';
  import { MomentDateAdapter } from '@angular/material-moment-adapter';
  import { Subscription } from 'rxjs/internal/Subscription';
  import * as moment from 'moment';
  import { Moment } from 'moment';
import { PatientService } from 'app/support/services/patient.service';
  
  export const YEAR_MODE_FORMATS = {
    parse: {
      dateInput: 'YYYY',
    },
    display: {
      dateInput: 'YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    },
  };
  
  @Component({
    selector: 'app-year-picker',
    templateUrl: './year-picker.component.html',
    styleUrls: ['./year-picker.component.scss'],
    providers: [
      {
        provide: DateAdapter,
        useClass: MomentDateAdapter,
        deps: [MAT_DATE_LOCALE],
      },
      { provide: MAT_DATE_FORMATS, useValue: YEAR_MODE_FORMATS },
      {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => YearPickerComponent),
        multi: true,
      },
    ],
  })
  export class YearPickerComponent
    implements ControlValueAccessor, AfterViewInit {
    @Input() isEdit:any;
    @Input() temp_data: any;
    value: FormControl = new FormControl();
    currentDate : any;
    resetSubscription: Subscription
  
    // Function to call when the date changes.
    onChange = (year: number) => { };
  
    // Function to call when the input is touched.
    onTouched = () => { };
    existingDate: any;
    editDate: any;
  
    constructor(
      private patientService: PatientService
    ) {
      this.currentDate = new Date();
      if(this.temp_data != undefined){
        this.writeValue(this.temp_data);
      }
    }

    ngOnInit() {
      this.resetSubscription = this.patientService.clearPicker$.subscribe(data => {
        this.clearPickerValue()
      })
    }
  
    ngAfterViewInit() {
      
    }

    clearPickerValue() {
      this.temp_data = ''
      this.value.setValue('',{ emitEvent: false });
    }
  
    writeValue(date: Date): void {
      if(this.isEdit == true){
        if(date){
          this.editDate = date;
          this.existingDate = new Date(this.editDate, 0,1);
        }else{
          this.existingDate = new Date(this.temp_data, 0,1);
        }
        this.value.setValue(this.existingDate,{ emitEvent: false });
      }else{
        if (date) {
          const momentDate = moment(date);
          if (momentDate.isValid()) {
            momentDate.set({ date: 1 });
            this.value.setValue(moment(date), { emitEvent: false });
          }
        }
      }
    }

    registerOnChange(fn: any): void {
      this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
      this.onTouched = fn;
    }
  
    _yearSelectedHandler(chosenDate: Moment, datepicker: MatDatepicker<Moment>) {
      datepicker.close();
      this.value.setValue(chosenDate, { emitEvent: false });
      this.onChange(chosenDate.toDate().getFullYear());
      this.onTouched();
    }
  
    _openDatepickerOnClick(datepicker: MatDatepicker<Moment>) {
      if (!datepicker.opened) {
        datepicker.open();
      }
    }

    ngOnDestroy() {
      this.resetSubscription.unsubscribe()
    }
  }
  