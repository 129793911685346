import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { SnackbarCongiration } from 'app/shared/components/snack-bar/SnackbarConfiguration';
import { PatientService } from 'app/support/services/patient.service';
import { ConfimerComponent } from 'app/shared/components/dialog-box/confimer/confimer.component';

@Component({
  selector: 'app-create-patientgroup',
  templateUrl: './create-patientgroup.component.html',
  styleUrls: ['./create-patientgroup.component.scss']
})
export class CreatePatientgroupComponent implements OnInit {
  group_name: FormControl;
  patientList:  Array<any> = [];
  list_of_patient: FormControl;
  no_of_patient: FormControl;
  grp_data: Object;
  clinic_id: FormControl;
  clinic_name: FormControl;
  groupType: FormControl;
  query:any;
  dynamicGroupWarning:Boolean = false;
  // applyFilter:boolean = true;

  constructor(
    public dialogRef: MatDialogRef<CreatePatientgroupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackbar: SnackbarCongiration,
    private patientService: PatientService,
    public dialog: MatDialog,
  ) {
    this.group_name = new FormControl('', [Validators.pattern('[A-Za-z]([ ]?[A-Za-z0-9-_]+)*[ ]*'), Validators.required,Validators.maxLength(50)]);
    this.list_of_patient = new FormControl(null);
    this.no_of_patient = new FormControl(null);
    this.clinic_id = new FormControl(null);
    this.clinic_name = new FormControl(null);
    this.groupType = new FormControl('Static',);
   }

  ngOnInit() {
  }

  closeModal() {
    this.dialogRef.close('no');
  }

  // dynamicValid(data){
   
  //   console.log(data)
  //   if(data == "Dynamic"){
      
  //     this.patientService.getApplydynamicFilter().subscribe(data=>{
  //     if( !data){
       
  //       this.snackbar.triggerSnackBar({
  //         message: 'Please Use Apply Filter For Dynamic Group',
  //         type: 'error'
  //       });

  //       this.applyFilter = false;
  //     }
  //   })
  // }
  // else{
  //   this.applyFilter = true;
  // }

  // }
  
  savePatientgroup() {

    this.patientService.patientQueryData$.subscribe(data=>{
      this.query = data;
    })
    let selectedPatients = this.data['group_type'] === 'Dynamic' ? this.data['patient_grp_list'] : this.data['patient_grp_list'].map(a => a.id);
    this.grp_data = {
      'group_name' : this.group_name.value,
      'list_of_patient': selectedPatients,
      'clinic_name':this.data['clinic_name'],
      'clinic_id':this.data['clinic_id'],
      'group_type':this.data['group_type'],
      'query':this.query.query,
      'warning': this.dynamicGroupWarning
    }
    this.patientService.createPatientGroup(this.grp_data).subscribe(response =>{
      if(response['status'] == 'failure') {
        this.snackbar.triggerSnackBar({
          message:'Patient group name already exist',
          type: 'error'
        });
        this.dialogRef.close('no');
        return
      }
      if(response['message'] == 'exists'){
        // this.snackbar.triggerSnackBar({
        // message: 'This filter already exists in dynamic group',
        // type: 'error'
        // });
        this.openConfirme();
      }
      else{
        this.snackbar.triggerSnackBar({
        message: response['message'],
        type: 'success'
        });
        this.dialogRef.close('yes');
      }
      },
    error => {
      this.snackbar.triggerSnackBar({
        message:'Patient group name already exist',
        type: 'error'
      });
      this.dialogRef.close('no');
    });

    // this.patientService.setApplydynamicFilter(false);
  }

  openConfirme() {
		let dialogConfig = new MatDialogConfig();
		dialogConfig.position = { top: '70px' };
		dialogConfig.width = '600px';
		dialogConfig.disableClose = true;
		dialogConfig.restoreFocus = false;
		dialogConfig.data = {
			message: "This filter already exists in dynamic group, would you like to continue?",
			item: 'Proceed'
		}

		let dialogRef = this.dialog.open(ConfimerComponent, dialogConfig);
    
		dialogRef.afterClosed().subscribe(
			value => {
				if (value == 'yes') {
          this.dynamicGroupWarning = true
					this.savePatientgroup()
          
				}else{
          this.closeModal();
        }

        this.dynamicGroupWarning = false
			}
		);
		return dialogRef;
	}
}
