import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TriggerRulePanelComponent } from './trigger-rule-panel/trigger-rule-panel.component';
import { SharedModule } from 'app/shared/shared.module';
import { AddBlocksPanelComponent } from './add-blocks-panel/add-blocks-panel.component';
import { ConditionalBlockPanelComponent } from './conditional-block-panel/conditional-block-panel.component';
import { AddTasksPanelComponent } from './add-tasks-panel/add-tasks-panel.component';
import { ConditionEdgeComponent } from './condition-edge/condition-edge.component';
import { YearPickerComponent } from './year-picker/year-picker.component';
import { MulticonditionPanelComponent } from './multicondition-panel/multicondition-panel.component';
import { CreatePatientPanelComponent } from './create-patient-panel/create-patient-panel.component';
import { UpdatePatientPanelComponent } from './update-patient-panel/update-patient-panel.component';
import { CancelAppointmentPanelComponent } from './cancel-appointment-panel/cancel-appointment-panel.component';
import { LaunchAssessmentPanelComponent } from './launch-assessment-panel/launch-assessment-panel.component';
import { ReschedulePanelComponent } from './reschedule-panel/reschedule-panel.component';
import { ReassignPanelComponent } from './reassign-panel/reassign-panel.component';
import { GotoPanelComponent } from './goto-panel/goto-panel.component';
import { CreateClinicianPanelComponent } from './create-clinician-panel/create-clinician-panel.component';
import { AssignClinicianPanelComponent } from './assign-clinician-panel/assign-clinician-panel.component';
import { IncludePanelComponent } from './include-panel/include-panel.component';
import { ScheduleBlockComponent } from './schedule-block/schedule-block.component';
import { LaunchIncludePanelComponent } from './launch-include-panel/launch-include-panel.component';
import { DefaultPanelComponent } from './default-panel/default-panel.component';
import { MultipleConditionComponent } from './multiple-condition/multiple-condition.component';
import { UpdateClinicianPanelComponent} from './update-clinician-panel/update-clinician-panel.component'
import { RescheduleBlockComponent } from './reschedule-block/reschedule-block.component';
import { ReassignBlockComponent } from './reassign-block/reassign-block.component';


@NgModule({
  declarations: [
    TriggerRulePanelComponent, 
    AddBlocksPanelComponent,
    ConditionalBlockPanelComponent,
    AddTasksPanelComponent,
    ConditionEdgeComponent,
    MulticonditionPanelComponent,
    CreatePatientPanelComponent,
    YearPickerComponent,
    UpdatePatientPanelComponent,
    CancelAppointmentPanelComponent,
    LaunchAssessmentPanelComponent,
    ReschedulePanelComponent,
    ReassignPanelComponent,
    GotoPanelComponent,
    CreateClinicianPanelComponent,
    AssignClinicianPanelComponent,
    IncludePanelComponent,
    ScheduleBlockComponent,
    LaunchIncludePanelComponent,
    DefaultPanelComponent,
    MultipleConditionComponent,
    UpdateClinicianPanelComponent,
    RescheduleBlockComponent,
    ReassignBlockComponent
    ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports:[],
  entryComponents:[
    CreatePatientPanelComponent,
    UpdatePatientPanelComponent,
    CancelAppointmentPanelComponent,
    LaunchAssessmentPanelComponent,
    ReschedulePanelComponent,
    ReassignPanelComponent,
    GotoPanelComponent,
    CreateClinicianPanelComponent,
    AssignClinicianPanelComponent,
    IncludePanelComponent,
    ScheduleBlockComponent,
    LaunchIncludePanelComponent,
    MultipleConditionComponent,
    UpdateClinicianPanelComponent,
    RescheduleBlockComponent,
    ReassignBlockComponent,
  ]
})
export class WorkflowModalsModule { }
