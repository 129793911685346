import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'app/support/services/common.service';

@Component({
  selector: 'app-transfer-dyanic-smartest',
  templateUrl: './transfer-dyanic-smartest.component.html',
  styleUrls: ['./transfer-dyanic-smartest.component.scss']
})
export class TransferDyanicSmartestComponent implements OnInit {

  taskList: Array<any> = [];
  Loading: any = true;
  dynmaic_data: any;

  constructor(private api: CommonService, public dialogRef: MatDialogRef<TransferDyanicSmartestComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.dynmaic_data = data['dynamic_smartest_unique']
  }

  ngOnInit() {
    this.get_dynamic_status()
  }

  closeModal() {
    this.dialogRef.close();
  }

  get_dynamic_status(){
    this.Loading = true
    this.taskList = []
    this.api.getdynamicSmarTest(this.dynmaic_data).subscribe((res) => {
      this.taskList = res['data']
      this.Loading = false
    })
  }
}
