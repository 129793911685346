import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeightInputComponent } from './height-input/height-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TimeInputComponent } from './time-input/time-input.component';



@NgModule({
  declarations: [HeightInputComponent, TimeInputComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  exports: [
    HeightInputComponent,
    TimeInputComponent
  ]
})
export class OverriddenControlsModule { }
