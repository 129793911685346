import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { InterfaceService } from 'app/support/services/auth/interface.service';
import { HttpClient } from '@angular/common/http';
import { SnackbarCongiration } from '../../snack-bar/SnackbarConfiguration';

import  *  as  statusmessage  from  'assets/messages/messages_en.json';

@Component({
    selector: 'app-deactivate-dialog',
    templateUrl: './status-change-dialog.component.html',
    styleUrls: ['./status-change-dialog.component.scss']
})
export class StatusChangeDialogComponent extends InterfaceService implements OnInit {

    loading: boolean = false;

    /**
       * Constructor
       *
       * @param {MatDialogRef<StatusChangeDialogComponent>} dialogRef
       */
    constructor(private http: HttpClient,
        public dialogRef: MatDialogRef<StatusChangeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private snackBar: SnackbarCongiration,
    ) {
        super();
    }

    ngOnInit() {
    }

    closeModal(param: any): void {
        if(param == 'yes') {
            this.loading = true;
            this.http.get(this.getApiUrl(this.data.url), this.getHttpOptions('json', true))
                .subscribe(
                    (response: any) => {
                        this.loading = false;
                        if(response['status'] == 'warning-liability'){
                            param = 'no';
                            this.snackBar.triggerSnackBar({
                                message: statusmessage['activatedeactivate']['alerts'][response['message_key']],
                                type: 'warning'
                            });
                            this.dialogRef.close(param);
                        }else if (response['status'] == 'warning-user') {
                            this.snackBar.triggerSnackBar({
                                message: statusmessage['activatedeactivate']['alerts'][response['message_key']],
                                type: 'warning'
                            });                            
                        }
                        else {
                            this.snackBar.triggerSnackBar({
                               message: statusmessage['activatedeactivate']['alerts'][response['message_key']],
                                type: 'success'
                            });
                            this.dialogRef.close(param);
                        }
                        
                    },
                    (error: any) => {
                        this.loading = false;
                        this.snackBar.triggerSnackBar({
                            message: error['message'],
                            type: 'error'
                        });
                    }
            );
        }
        else this.dialogRef.close();
    }
}
