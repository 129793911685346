import { Component, Input } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";

@Component({
    selector: "app-default-panel",
    templateUrl: "./default-panel.component.html",
    styleUrls: ["./default-panel.component.scss"],
})
export class DefaultPanelComponent {
    _data: any;
    edit: boolean = false;
    genderList: Array<any> = [];
    raceList: Array<any> = [];
    ethnicityList: Array<any> = [];
    stateList: Array<any> = [];
    languageList: Array<any> = [];
    insurersList: Array<any> = [];
    clinicianList: Array<any> = [];
    taskForm: FormGroup = new FormGroup({});
    constructor(private fb: FormBuilder) {}
    @Input()
    set data(data) {
        this._data = data;
        this.edit = this._data["isEdit"];
        for (const [key, value] of Object.entries(
            this._data["selectedTriggerFields"]
        )) {
            if (key.split(".")[1] == "clinician_system") {
                const clinicians = Array.isArray(value["options"])
                    ? value["options"]
                    : [];
                this.clinicianList = [
                    ...[{ id: "all_clinician", value: "All Clinician" }],
                    ...clinicians,
                ];
            } else if (key.split(".")[1] == "gender_system") {
                this.genderList = value["options"];
            } else if (key.split(".")[1] == "race_system") {
                this.raceList = value["options"];
            } else if (key.split(".")[1] == "ethnicity_system") {
                this.ethnicityList = value["options"];
            } else if (key.split(".")[1] == "state_of_residence_system") {
                this.stateList = value["options"];
            } else if (key.split(".")[1] == "preferred_language_system") {
                this.languageList = value["options"];
            } else if (key.split(".")[1] == "insurers_system") {
                this.insurersList = value["options"];
            }
        }
    }

    @Input()
    set form(taskForm) {
        this.taskForm = taskForm;
        this.taskForm.addControl("gender", new FormControl());
        this.taskForm.addControl("race", new FormControl());
        this.taskForm.addControl("ethnicity", new FormControl());
        this.taskForm.addControl("state_of_residence", new FormControl());
        this.taskForm.addControl("preferred_language", new FormControl());
        this.taskForm.addControl("clinician", new FormControl());
        this.taskForm.addControl("api_insurance", new FormControl());
        if (this.edit) {
            this.taskForm
                .get("gender")
                .setValue(this._data["cell"]["value"]["default"]["gender"]);
            this.taskForm
                .get("race")
                .setValue(this._data["cell"]["value"]["default"]["race"]);
            this.taskForm
                .get("ethnicity")
                .setValue(this._data["cell"]["value"]["default"]["ethnicity"]);
            this.taskForm
                .get("state_of_residence")
                .setValue(
                    this._data["cell"]["value"]["default"]["state_of_residence"]
                );
            this.taskForm
                .get("preferred_language")
                .setValue(
                    this._data["cell"]["value"]["default"]["preferred_language"]
                );
            this.taskForm
                .get("clinician")
                .setValue(this._data["cell"]["value"]["default"]["clinician"]);
            this.taskForm
                .get("api_insurance")
                .setValue(
                    this._data["cell"]["value"]["default"]["api_insurance"]
                );
        }
    }
}
