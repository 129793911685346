import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { FormGroup, FormBuilder } from '@angular/forms';
import { UsersService } from 'app/support/services/users.service';
import { SnackbarCongiration } from 'app/shared/components/snack-bar/SnackbarConfiguration';


@Component({
  selector: 'app-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss']
})
export class NotificationSettingsComponent implements OnInit {

  notificationForm: FormGroup;
  notificatin_data: any;
  showView: boolean = false;
  current_url:string;

  constructor(
    private fb: FormBuilder,
    private userService: UsersService,
    private snackBar: SnackbarCongiration,
    private router: Router,
  ) {
    this.getNotificationsData();
  }

  ngOnInit() {

  }

  getNotificationsData() {
    this.showView = false;
    this.userService.getUserNotificationPreferences()
      .subscribe(
        response => {
          this.notificatin_data = response['data']
          this.createNotificationForm();
        },
        error => {

        }
      )
  }

  createNotificationForm() {
    this.notificationForm = this.fb.group({
        email: [false],
        sms: [false],
        web: [false]
      });
    this.showView = true;
    this.setNotificationForm();
  }

  setNotificationForm(){
    for(let i=0;i<3;i++){
      if(this.notificatin_data[i]['is_enabled']){
        this.notificationForm.controls[this.notificatin_data[i]['preference_name']].setValue(true);
      }
      else{
        this.notificationForm.controls[this.notificatin_data[i]['preference_name']].setValue(false);
      } 
    }
  } 
    
  changeNotification(event, type) {
    let formData = this.notificationForm.value;
    if (!event.checked && !formData['email'] && !formData['sms'] && !formData['web']) {
      this.notificationForm.get(type).setValue(true);
      this.snackBar.triggerSnackBar({
        message: 'Anyone of the notification must be enabled',
        type: 'warning'
      });
    } else {
      let data = this.notificatin_data.filter(function (item) {
        item.is_enabled = type;
        return item.preference_name == type;
      });
      data[0]['is_enabled'] = event.checked;
      this.userService.updateUserNotificationPreferences(data[0])
        .subscribe(
          response => {
            if (response['status'] == 'success') {
              this.snackBar.triggerSnackBar({
                message: response['msg'],
                type: 'success'
              });
            }
            else if (response['status'] == 'warning') {
              this.notificationForm.get(type).setValue(!event.checked);
              this.snackBar.triggerSnackBar({
                message: response['msg'],
                type: 'warning'
              });
            }
          },
          error => {
            this.snackBar.triggerSnackBar({
              message: 'Internal Server Error',
              type: 'error'
            });
          }
        )
    }
  }
}
