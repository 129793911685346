// Configurations
import { environment ,localmiddleware} from 'environments/environment'


// Get ApiURL
export function GetApiurl(path: string, params?: Object,middleware?) {

    if (middleware){
       let  baseUrl = localmiddleware.baseUrl; 
       let url = baseUrl + path;
       if (params instanceof Object && Object.keys(params).length > 0) {
           url += '?';
   
           Object.entries(params).forEach(([param, value]) => {
               url += `${encodeURIComponent(param)}=${encodeURIComponent(value)}&`;
           });
       }
   
       return url;
    }
    else{
         let baseUrl = environment.baseUrl;
         let url = baseUrl + path;
         if (params instanceof Object && Object.keys(params).length > 0) {
             url += '?';
     
             Object.entries(params).forEach(([param, value]) => {
                 url += `${encodeURIComponent(param)}=${encodeURIComponent(value)}&`;
             });
         }
     
         return url;
    }
   
}

// Image types
export const validImageTypes = ['image/png', 'image/jpg', 'image/gif', 'image/jpeg'];
export const fileSize = 1048576;  // Equilent to 2 MB

export const publishSubscribeKey = {
    publishKey: 'pub-c-ba7c02a4-496e-48d5-9dda-150a261fbd99',
    subscribeKey: 'sub-c-a0bd7180-59c5-11e9-be9b-e284dc0944ea'
}
