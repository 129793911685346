import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
    selector: 'app-delete-dialog',
    templateUrl: './delete-dialog.component.html',
    styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit {
    yes: any;
    no: any;
    optional: boolean = false;
    /**
       * Constructor
       *
       * @param {MatDialogRef<DialogComponent>} dialogRef
       */
    constructor(
        public dialogRef: MatDialogRef<DeleteDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }

    ngOnInit() {
        this.optional = this.data['option'] ? this.data['option'] : false;
    }

    closeModal(param): void {
        this.dialogRef.close(param);
    }
}
