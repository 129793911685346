import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { SnackbarCongiration } from "app/shared/components/snack-bar/SnackbarConfiguration";
import { SmartTestService } from "app/support/services/smart-test.service";
import { Subscription } from "rxjs";

@Component({
    selector: "app-create-smart-test",
    templateUrl: "./create-smart-test.component.html",
    styleUrls: ["./create-smart-test.component.scss"],
})
export class CreateSmartTestComponent {
    date: Date;
    events: any[];
    notes: any[];
    settings: any;
    enable_mail_form: boolean = true;
    smartForm: FormGroup;
    clinicsList: Array<any> = [];
    clinic_drop: boolean = false;
    clinic_value = "";
    fullScreenModal: any = {
        title: "",
        subTitle: "",
    };
    dataLoad: boolean = false;
    existCheck: boolean = false;
    nameCheck: Subscription;

    /**
     * Constructor
     */
    constructor(
        private fb: FormBuilder,
        private snackbar: SnackbarCongiration,
        private smartTestService: SmartTestService,

        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<CreateSmartTestComponent>
    ) {
        // Set the defaults
        this.date = new Date();
        this.settings = {
            notify: true,
            cloud: false,
            retro: true,
        };
        this.smartForm = this.fb.group({
            name: ["", [Validators.maxLength(45)]],
            description: [""],
        });
    }

    enableMailForm() {
        this.enable_mail_form = true;
    }
    disable() {
        this.enable_mail_form = false;
    }
    getSettingsForClinic() {}
    nameExist(): Promise<any> {
        this.existCheck = true;
        return new Promise((resolve) => {
            let value = this.smartForm.get("name").value;
            let name = this.smartForm.controls["name"];
            if (value == "") {
                name.setErrors({ required: true });
            } else {
                if (this.nameCheck) {
                    this.nameCheck.unsubscribe();
                }
                this.nameCheck = this.smartTestService
                    .nameExistCheck({ name: value.trim() })
                    .subscribe((res) => {
                        if (res["status"] == "warning") {
                            resolve(true);
                            name.setErrors({ Exist: true });
                            name.markAsTouched();
                        } else {
                            resolve(false);
                            name.setErrors(null);
                            name.updateValueAndValidity();
                            this.existCheck = false;
                        }
                    });
            }
        });
    }
    saveSmartTest() {
        if (!this.dataLoad) {
            this.dataLoad = true;
            let smartTestData = this.smartForm.value;
            this.smartTestService
                .createSmartTest(smartTestData)
                .subscribe((respone) => {
                    this.reloadCurrentPage();
                    this.snackbar.triggerSnackBar({
                        message: respone["message"],
                        type: "success",
                    });
                    this.dialogRef.close();
                    // this.dataLoad = false;
                });
        }
    }

    reloadCurrentPage() {
        this.smartTestService.refreshRowData("refresh");
    }

    cancelSmartTest() {
        this.dialogRef.close();
    }

    saveConfigureSmartTest() {
        if (!this.dataLoad) {
            this.dataLoad = true;
            let smartTestData = this.smartForm.value;
            this.smartTestService
                .createSmartTest(smartTestData)
                .subscribe((response) => {
                    this.dialogRef.close(response);
                });
        }
    }

    cloneSmartTest(event) {
        this.dataLoad = true;
        let closeData = {
            id: this.data["cloneData"]
                ? this.data["cloneData"]["id"]
                : this.data["id"],
            name: this.smartForm.value.name,
            description: this.smartForm.value.description,
        };

        this.smartTestService.workflowClone(closeData).subscribe((response) => {
            this.dialogRef.close(response);
            this.snackbar.triggerSnackBar({
                message: response["message"],
                type: "success",
            });
            response["action"] = event;

            this.dataLoad = false;
        });
    }

    descCheck(evt) {
        evt = evt ? evt : window.event;
        const pattern = /[^A-Za-z0-9]/;
        let eventKey = evt.key;
        var charCode = evt.keyCode;
        let keys = [191, 219, 220, 221, 222];
        if (keys.includes(charCode)) {
            return false;
        } else if (charCode != 32 && pattern.test(eventKey)) {
            evt.preventDefault();
        } else {
            return true;
        }
    }
}
