import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { DataImportService } from 'app/support/services/data-import.service';
import { SnackbarCongiration } from 'app/shared/components/snack-bar/SnackbarConfiguration';

@Component({
	selector: 'app-data-import',
	templateUrl: './data-import.component.html',
	styleUrls: ['./data-import.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class DataImportComponent implements OnInit {

	selectedFile: any;
	selectedOption = 'Patient';
	defaultOptions = ['Patient', 'Clinician', 'Workflow']
	flag: string
	validationMessage: any;
	inProgress: boolean = false;
	progress: boolean = false;
	errorMsg: string;
	@ViewChild('fileInput', {static: false}) inputFileRef: ElementRef<HTMLInputElement>;
	constructor(
		private dataImportService: DataImportService,
		private snackBar: SnackbarCongiration,
	) { }

	ngOnInit() {
	}

	selectedUploadOption(event) {
		this.selectedOption = event.value
	}

	selectFile(event) {
		this.errorMsg = undefined
		event.preventDefault();
		const target: HTMLInputElement = <HTMLInputElement>event.target;
		const imagefile: FileList = target.files;
		const fileType = imagefile[0].type;
		const fileTypeArray = ['text/csv', 'application/vnd.ms-excel']
		if (!fileTypeArray.includes(fileType)) {
            this.errorMsg = 'File format supported is .CSV, Please upload a .csv file format'
        }
		this.selectedFile = imagefile[0]
	}

	upload() {
		this.inProgress = true
		this.flag = 'upload'
		this.dataImportService.uploadData(this.selectedFile, this.selectedOption, this.flag).then(
			(data) => {
				this.inProgress = false
				this.validationMessage = data
			}
		)
	}

	reset(){

				this.progress = false
				this.validationMessage = undefined
				this.selectedFile = undefined
				this.inputFileRef.nativeElement.value=''
				this.flag = "";


	}

	submit() {
		this.progress = true
		this.flag = 'submit'
		this.dataImportService.uploadData(this.selectedFile, this.selectedOption, this.flag).then(
			(data) => {
				this.progress = false
				this.selectedFile = undefined
				this.inputFileRef.nativeElement.value=''
				this.validationMessage = undefined
				this.flag = 'upload'
				if (data['status']) {
					this.snackBar.triggerSnackBar({
						message: 'Records imported successfully',
						type: 'success'
					});
				}
			},
			(error: any) => {
				this.progress = false
				this.snackBar.triggerSnackBar({
					message: 'Error in data uploading',
					type: 'error'
				});
			}
		)
	}

}
