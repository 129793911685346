import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmartTestListComponent } from './smart-test-list/smart-test-list.component';
import { RouterModule, Routes } from '@angular/router';
import { WorkFlowComponent } from './work-flow/work-flow.component';
import { AuthGuardService } from 'app/support/services/auth/auth-guard.service';
import { SharedModule } from 'app/shared/shared.module';
import { SidepanelModule } from 'app/sidepanel/sidepanel.module';
import { EditSmartTestComponent } from './edit-smart-test/edit-smart-test.component';
import { ReferenceComponent } from './reference/reference.component';
import { WorkflowModalsModule } from '../workflow-modals/workflow-modals.module';
import { AddBlocksPanelComponent } from '../workflow-modals/add-blocks-panel/add-blocks-panel.component';
import { AddTasksPanelComponent } from '../workflow-modals/add-tasks-panel/add-tasks-panel.component';
import { ConditionEdgeComponent } from '../workflow-modals/condition-edge/condition-edge.component';
import { ConditionalBlockPanelComponent } from '../workflow-modals/conditional-block-panel/conditional-block-panel.component';
import { TriggerRulePanelComponent } from '../workflow-modals/trigger-rule-panel/trigger-rule-panel.component';
import { ModalModule } from 'ngx-bootstrap';
import { WorkflowSideNavComponent } from './workflow-side-nav/workflow-side-nav.component';
import { CreateSmartTestComponent } from './create-smart-test/create-smart-test/create-smart-test.component';
import { AssignSmartTestModalComponent, DeleteAssignClinicWorkflowComponent } from './assign-smart-test-modal/assign-smart-test-modal/assign-smart-test-modal.component';
import { MulticonditionPanelComponent } from '../workflow-modals/multicondition-panel/multicondition-panel.component';
const SMART_TEST_ROUTES: Routes = [
  {
    path: '',
    component: SmartTestListComponent,
    canActivate: [AuthGuardService],
    data: {
        roles: ["view#DynamicSmartTest"]
    }
  },
  {
    path: 'work-flow',
    component: WorkFlowComponent,
    canActivate: [AuthGuardService],
  }
]

@NgModule({
  declarations: [
    SmartTestListComponent, 
    WorkFlowComponent,
    CreateSmartTestComponent,
    ReferenceComponent,
    WorkflowSideNavComponent,
    EditSmartTestComponent,
    ReferenceComponent,
    CreateSmartTestComponent,
    AssignSmartTestModalComponent,
    DeleteAssignClinicWorkflowComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(SMART_TEST_ROUTES),
    SharedModule,
    SidepanelModule,
    WorkflowModalsModule,
    ModalModule.forRoot()
  ],
  exports: [
    WorkFlowComponent
  ],
  entryComponents: [
    EditSmartTestComponent,
    ReferenceComponent,
    TriggerRulePanelComponent,
    AddBlocksPanelComponent,
    ConditionalBlockPanelComponent,
    AddTasksPanelComponent,
    ConditionEdgeComponent,
    WorkflowSideNavComponent,
    CreateSmartTestComponent,
    AssignSmartTestModalComponent,
    MulticonditionPanelComponent,
    DeleteAssignClinicWorkflowComponent
  ]
})
export class SmartTestModule { }
