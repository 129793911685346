import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Output, Input } from '@angular/core';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatAutocomplete, MatAutocompleteTrigger, MatAutocompleteSelectedEvent } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { startWith, map } from 'rxjs/operators';
import { SearchList, SearchUrl } from './slim-search';
import { InterfaceService } from 'app/support/services/auth/interface.service';
import { SmartTestService } from 'app/support/services/smart-test.service';

import { close } from 'fs';

@Component({
    selector: 'slim-search',
    templateUrl: './slim-search.component.html',
    styleUrls: ['./slim-search.component.scss']
})
export class SlimSearchComponent extends InterfaceService implements OnInit {
    removable: boolean = true;
    loading: boolean = false;
    separatorKeyCodes: number[] = [ENTER];
    searchControl: FormControl = new FormControl();
    subscriptions: Object = {};

    filteredOptions: Observable<string[]>;
    selectedOptions: string[] = [];
    currentOption: Object = {};

    allOptions: Array<any> = [];
    tempOptions: Array<any> = [];

    isKey: boolean = true;
    searchQuery: Object = {};
    

    searchResultUrl: string = '';
    searchValuesUrl: string = '';

    valueList: Array<any> = [];
    tempValueList: Array<any> = [];
    defaultsTo: boolean = false;
    refreshvalue :boolean = false;

    @ViewChild('searchInput', { static: false }) searchInput: ElementRef<HTMLInputElement>;
    @ViewChild('autoComplete', { static: false }) matAutocomplete: MatAutocomplete;
    @ViewChild('trigger', { static: false, read: MatAutocompleteTrigger }) trigger: MatAutocompleteTrigger;
    scrollEvent = (event: any): void => {
        if (this.trigger.panelOpen)
            this.trigger.closePanel();
        this.searchInput.nativeElement.blur();
        //   this.trigger.updatePosition();
    };

    @Output('searchResult') searchResult: EventEmitter<any> = new EventEmitter();
    @Output('searchCall') searchCall: EventEmitter<any> = new EventEmitter();
    @Output('clearResult') clearResult: EventEmitter<any> = new EventEmitter();
    @Output('lastRequestCancel') lastRequestCancel: EventEmitter<any> = new EventEmitter();

    @Input('searchList')
    set setOptions(options: SearchList[]) {
        this.allOptions = options;
        this.tempOptions = options;
    }

    @Input('searchUrl')
    set setUrl(url: SearchUrl) {
        this.searchResultUrl = url.searchResultUrl;
        this.searchValuesUrl = url.searchValuesUrl;
        if (this.selectedOptions.length) {
            this.selectedOptions = [];
            this.clearResult.emit();
            this.tempOptions.forEach((element, index) => {
                this.tempOptions[index].display = true;
                if ('checked' in this.tempOptions[index])
                    this.tempOptions[index].checked = false;
            });
            this.allOptions = this.tempOptions;
            this.tempValueList = this.valueList.concat([]);
            this.isKey = true;
            this.searchInput.nativeElement.value = '';
            this.searchControl.reset();
            this.searchQuery = {};
            // this.searchInput.nativeElement.focus();
            this.trigger.closePanel();
        }
        if (url.hasOwnProperty('defaultsTo') && url.defaultsTo) this.defaultsTo = true;
    }

    @Input('valueList')
    set setValue(value: Array<any>) {
        if (value != undefined) {
            this.valueList = value.concat([]);
            this.tempValueList = value.concat([]);
        }
    }

    @Input('activeOptions')
    set setActiveOptions(value: Array<any>) {
        value.forEach((element, index) => {
            let idx = this.tempOptions.findIndex(el => el['field'] == element['field']);
            if (idx > -1) {
                this.tempOptions[idx]['active'] = element['visible'];
                if (this.isKey) {
                    this.allOptions[this.allOptions.findIndex(el => el['field'] == element['field'])]['active'] = element['visible'];
                    this.setFilter(this.searchControl.value);
                }
                if (!element['visible'])
                    for (let option of this.selectedOptions)
                        if (option.split(':')[0] == this.tempOptions[idx]['searchKey']) {
                            this.remove(option);
                            break;
                        }
            }
        });
        if (this.defaultsTo && value.length) {
            let idx = 0;
            for (let el of this.allOptions) {
                if (el['active']) {
                    this.defaultsTo = false;
                    this.currentOption['key'] = this.tempOptions[idx]['field'].split('.');
                    if ('replace' in this.tempOptions[idx])
                        this.currentOption['option'] = this.tempOptions[idx];
                    else if ('option' in this.currentOption)
                        delete this.currentOption['option'];
                    this.selectedOptions.push(this.tempOptions[idx]['searchKey'] + ':');
                    this.tempOptions[idx].display = false;
                    this.retrieveValue(this.tempOptions[idx]['property'], false);
                    this.isKey = !this.isKey;
                    this.searchControl.reset();
                    break;
                }
                idx++;
            }
        }
    }

    constructor(private http: HttpClient, private fb: FormBuilder,private smartTestService: SmartTestService) {
        super();
        this.loading = false;
        this.setFilter();
    }

    ngOnInit() {
        this.smartTestService.newRowData$.subscribe(data => {
            // this.clearSearch();
            this.setFilter();
        });
        // window.addEventListener('scroll', this.scrollEvent, true);
    }

    setFilter(initialValue: any = null) {
        this.filteredOptions = this.searchControl.valueChanges.pipe(
            startWith(initialValue),
            map((options: any) => options ? this._filter(options) : this.allOptions)
        );
    }

    private _filter(value: any): string[] {
        let filterValue: any;
        if (typeof value == 'string') {
            filterValue = value.toString().toLowerCase();
        }
        else {
            filterValue = (typeof value == 'object' ? value['searchKey'] : value).toString().toLowerCase();
        }
        let filtered = this.allOptions.filter(
            option => {
                if (this.isKey) {
                    return option['searchKey'].toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
                }
                else {
                    option = option.toString();
                    return (this.currentOption['key'][0] == 'gender' && filterValue == 'male') ? option.toLowerCase() == 'male' :
                        option.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
                }
            }
        ).map(option => option);
        return filtered;
    }

    remove(selectedOption: any): void {
        const selectedOptionsIndex = this.selectedOptions.indexOf(selectedOption);
        let selectedOptionKey = selectedOption.split(':')[0];
        this.tempOptions.forEach((element, elementIndex) => {
            if (element['searchKey'] == selectedOptionKey) {
                this.tempOptions[elementIndex]['display'] = true;
                if ('checked' in element) {
                    this.tempOptions[elementIndex]['checked'] = false;
                }
                if (this.selectedOptions[this.selectedOptions.length - 1] == selectedOption) {
                    this.allOptions = this.tempOptions;
                    this.isKey = true;
                    this.loading = false;
                }
                delete this.searchQuery[element.property];
                this.selectedOptions.splice(selectedOptionsIndex, 1);
            }
        });
        this.searchInput.nativeElement.value = '';
        this.searchControl.reset();
        this.trigger.closePanel();
        if (this.selectedOptions.length == 0) {
            this.clearResult.emit();
            this.tempValueList = this.valueList.concat([]);
            this.searchInput.nativeElement.blur();
        }
        else {
            if (!this.isKey) {
                for (let options of this.tempOptions)
                    if (options['searchKey'] == this.selectedOptions[this.selectedOptions.length - 1].split(':')[0]) {
                        this.retrieveValue(options['property']);
                        break;
                    }
            }
            this.retrieveData();
            // setTimeout(() => {
            //     this.trigger.openPanel();
            // }, 0);
            // this.searchInput.nativeElement.focus();
        }
    }

    checked(event, triggger: MatAutocompleteTrigger, value: any, isCheckbox: boolean) {
        let index = this.tempOptions.indexOf(value);

        if (this.tempOptions[index]['checked']) {
            let idx = this.selectedOptions.indexOf(value['searchKey']);
            this.selectedOptions.splice(idx, 1);
            delete this.searchQuery[value['searchKey']];
            if (this.selectedOptions.length == 0) {
                this.clearResult.emit();
            }
        }
        else {
            this.selectedOptions.push(value['searchKey']);
            this.searchQuery[value.property] = true;
        }
        if (!isCheckbox) {
            this.tempOptions[index]['checked'] = !this.tempOptions[index]['checked'];
        }
        this.allOptions = this.tempOptions;
        this.searchInput.nativeElement.value = '';
        this.searchControl.reset();
        triggger.closePanel();
        event.stopPropagation();
        triggger.openPanel();
        this.searchInput.nativeElement.focus();
    }

    emitSearch() {
        if (this.isKey && this.selectedOptions.length) {
            this.loading = true;
        }
    }

    clearSearch() {
        this.lastRequestCancel.emit();
        if (this.subscriptions['data']) { this.subscriptions['data'].unsubscribe(); }
        if (this.selectedOptions.length) {
            this.selectedOptions = [];
            this.clearResult.emit();
            this.tempOptions.forEach((element, index) => {
                this.tempOptions[index].display = true;
                if ('checked' in this.tempOptions[index])
                    this.tempOptions[index].checked = false;
            });
            this.allOptions = this.tempOptions;
            this.tempValueList = this.valueList.concat([]);
            this.isKey = true;
            this.searchInput.nativeElement.value = '';
            this.searchControl.reset();
            this.searchQuery = {};
            // this.searchInput.nativeElement.focus();
            this.trigger.closePanel();
            // event.stopPropagation();
            // triggger.openPanel();
        }
    }

    bkspcRemove(event: any) {
        this.loading = false;
        if (this.selectedOptions.length == 1 && event.target.value == '') {
            this.clearResult.emit();
            this.searchInput.nativeElement.blur();
        }
        if (this.selectedOptions.length && event.target.value == '') {
            let removed = this.selectedOptions.pop().split(':')[0];
            this.tempOptions.forEach(
                (el, idx) => {
                    if (el['searchKey'] == removed) {
                        if ('checked' in el) {
                            this.tempOptions[idx].checked = false;
                        }
                        delete this.searchQuery[el.property];
                        this.retrieveData();
                        this.tempOptions[idx].display = true;
                        this.allOptions = this.tempOptions;

                        this.setFilter();
                        this.isKey = true;
                        this.trigger.closePanel();
                        if (this.selectedOptions.length)
                            setTimeout(() => {
                                this.trigger.openPanel();
                            }, 0);
                    }
                }
            );
        }
    }

    select(event: MatAutocompleteSelectedEvent): void {
        let viewValue = event.option.viewValue;
        let value = event.option.value;
        if (this.isKey) {
            this.loading = true;
            let index = this.tempOptions.indexOf(value);
            if (!('checked' in value)) {
                if (value['searchValues'].length) {
                    this.allOptions = value['searchValues'].concat([]);
                    this.setFilter();
                    this.trigger.closePanel();
                    // setTimeout(() => {
                    //     this.trigger.openPanel();
                    // }, 0);
                    this.searchInput.nativeElement.value = '';
                    this.searchControl.reset();
                    this.removable = true;
                    this.loading = false;
                }
                else {
                    this.retrieveValue(value['property']);
                }

                this.currentOption['key'] = this.tempOptions[index]['field'].split('.');
                if ('replace' in this.tempOptions[index])
                    this.currentOption['option'] = this.tempOptions[index];
                else if ('option' in this.currentOption)
                    delete this.currentOption['option'];
                this.selectedOptions.push(viewValue + ':');
                this.tempOptions[index].display = false;
            }
            else {
                if (this.tempOptions[index]['checked']) {
                    let idx = this.selectedOptions.indexOf(viewValue);
                    this.selectedOptions.splice(idx, 1);
                    delete this.searchQuery[this.tempOptions[index].property];
                    if (this.selectedOptions.length == 0) {
                        this.clearResult.emit();
                    }
                }
                else {
                    this.selectedOptions.push(viewValue);
                    this.searchQuery[value.property] = true;
                }
                this.tempOptions[index].checked = !this.tempOptions[index].checked;
                this.allOptions = this.tempOptions;
                this.isKey = !this.isKey;
            }
        }
        else {
            let index = this.selectedOptions.length - 1;
            let keyValue = this.selectedOptions[index].split(':')[0];
            this.tempOptions.forEach(
                (el, idx) => {
                    if (el['searchKey'] == keyValue) {
                        this.searchQuery[el.property] = viewValue;
                        this.selectedOptions[index] = keyValue + ': ' + viewValue;
                        this.allOptions = this.tempOptions;
                    }
                }
            );
            this.loading = false;
            this.searchInput.nativeElement.blur();
            this.retrieveData();
        }
        this.isKey = !this.isKey;
        this.searchInput.nativeElement.value = '';
        this.searchControl.reset();

        this.trigger.closePanel();
        // setTimeout(() => {
        //     this.trigger.openPanel();
        // }, 0);
    }

    hasKey(key: any, filtered: Object): boolean {
        key = key.toString();
        return key in filtered;
    }

    retrieveData() {
        if (this.searchResultUrl != 'patients/search-patient') {
            if (this.searchResultUrl != 'audit-log/auditlog-search-result') {
                if(this.searchResultUrl == 'users/search-user' || this.searchResultUrl == 'clinics/search-clinic'){
                    this.searchCall.emit({ url: this.searchResultUrl, query: this.searchQuery })
                }else{
                    this.subscriptions['data'] = this.http.post(this.getApiUrl(`${this.searchResultUrl}/`), JSON.stringify({ searchQuery: this.searchQuery }),
                    this.getHttpOptions('json', true)).subscribe(
                        (response: any) => {
                            this.searchResult.emit(response);
                            this.tempValueList = response['data'].concat([]);
                            if (this.removable)
                                this.loading = false;
                        },
                        (error: any) => {
                            this.loading = false;
                        }
                    );
                }
            }

            if (this.searchResultUrl == 'audit-log/auditlog-search-result') {
                this.lastRequestCancel.emit();

                /*  
                    If we did two search like clinic_name and status
                    if we search clinic name filter first then we secondary filter the status. so that 
                    time we need to unsubscribe the previous search.
                    so we did this below statement

                    if (this.subscriptions['data']) { this.subscriptions['data'].unsubscribe(); }

                 */
                if (this.subscriptions['data']) { this.subscriptions['data'].unsubscribe(); }

                this.subscriptions['data'] = this.http.post(this.getApiUrl(`${this.searchResultUrl}/`, [], true), JSON.stringify({ searchQuery: this.searchQuery }),
                    this.getHttpOptions('json', true)).subscribe(
                        (response: any) => {
                            response['query'] = this.searchQuery;
                            this.searchResult.emit(response);
                            this.tempValueList = response['data'].concat([]);
                            if (this.removable)
                                this.loading = false;
                        },
                        (error: any) => {
                            this.loading = false;
                        }
                    );
            }
        }
        else {
            this.searchResult.emit({ url: this.searchResultUrl, query: this.searchQuery })
        }
    }

    retrieveValue(value: any, openPanel: boolean = true) {
        this.allOptions = [];
        this.removable = false;
        this.loading = true;
        this.searchInput.nativeElement.blur();
        if (this.searchValuesUrl != 'audit-log/auditlog-search-values') {
            this.subscriptions['value'] = this.http.post(this.getApiUrl(`${this.searchValuesUrl}/`), { searchKey: value, searchQuery: this.searchQuery },
                this.getHttpOptions('json', true)).subscribe((response: string[]) => {
                    this.allOptions = response.concat([]);
                    this.setFilter();
                    this.trigger.closePanel();
                    if (openPanel) {
                        setTimeout(() => {
                            this.trigger.openPanel();
                        }, 0);
                        this.searchInput.nativeElement.focus();
                    }
                    this.searchInput.nativeElement.value = '';
                    this.searchControl.reset();
                    this.removable = true;
                    this.loading = false;
                }
                );
        }
        if (this.searchValuesUrl == 'audit-log/auditlog-search-values') {
            this.subscriptions['value'] = this.http.post(this.getApiUrl(`${this.searchValuesUrl}/`, [], true), { searchKey: value, searchQuery: this.searchQuery },
                this.getHttpOptions('json', true)).subscribe((response: string[]) => {
                    this.allOptions = response.concat([]);
                    this.setFilter();
                    this.trigger.closePanel();
                    if (openPanel) {
                        setTimeout(() => {
                            this.trigger.openPanel();
                        }, 0);
                        this.searchInput.nativeElement.focus();
                    }
                    this.searchInput.nativeElement.value = '';
                    this.searchControl.reset();
                    this.removable = true;
                    this.loading = false;
                }
                );
        }
    }

    filterValue(event: any) {
        if (!this.isKey && this.allOptions.length && this.searchResultUrl != 'patients/search-patient') {
            let value = event.target.value;
            let tempValueList = this.tempValueList.filter(el => {
                let keyVal = el;
                let idx = 0;
                for (let key of this.currentOption['key']) {
                    if (keyVal[key] != null) {
                        if (keyVal[key] != '') {
                            if (this.currentOption.hasOwnProperty('option') && this.currentOption['key'].length - 1 == idx)
                                keyVal = this.currentOption['option']['replace'][el[this.currentOption['option']['property']].toString()]['value'];
                            else
                                keyVal = keyVal[key];
                            idx++;
                        }
                        else {
                            if (this.currentOption.hasOwnProperty('option') && this.currentOption['key'].length - 1 == idx)
                                keyVal = this.currentOption['option']['replace'][el[this.currentOption['option']['property']].toString()]['value'];
                            else
                                keyVal = '';
                            break;
                        }
                    }
                    else if (this.currentOption.hasOwnProperty('option') && this.currentOption['key'].length - 1 == idx)
                        keyVal = this.currentOption['option']['replace'][el[this.currentOption['option']['property']].toString()]['value'];
                    else {
                        keyVal = '';
                        break;
                    }
                }
                keyVal = keyVal.toString();
                return !(this.currentOption['key'][0] == 'gender' && value == 'male') ? keyVal.toLowerCase().indexOf(value.toLowerCase()) > -1 :
                    keyVal.toLowerCase() == 'male';
            });
            this.searchResult.emit({ data: tempValueList });
        }
    }

    getSearchQuery() {
        return this.searchQuery;
    }

    ngOnDestroy() {
        // Unsubscribe on service scope destroy
        Object.entries(this.subscriptions).forEach(
            ([name, subscription]) => subscription.unsubscribe()
        );
    }

}
