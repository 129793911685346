import { Contact } from './contact';

export class User {
    public id?: number = 0;
    public name?: string = '';
    public email?: string = '';
    public first_name?: string = '';
    public middle_name?: string = '';
    public last_name?: string = '';
    public clinics?: Array<Object> = [];
    public agencies?: Object = null;
    public roles?: Array<string> = [];
    public accesses?: Array<string> = [];
    public user_type?: Object = null;
    public mobile?: string = '';
    public is_active?: boolean = false;
    public is_auth_logged_in?: boolean = null;
    public profile_image?: boolean = null;
    public system_admin?: boolean = false;
    public country_code?: string = '';
    public is_superuser?: boolean = false;
    public self_biller?: boolean = false;
    public date_joined?: any = null;
    public last_login?: any = null;
    public timeout?: number = 30;
    public device?: boolean = false;
    public clinic_id?: number = 0;
    public alternate_id?: string = '';

    constructor(data?: Object) {
        if (data) {
            this.id = data['id'] || this.id;
            this.name = data['name'] || this.name;
            this.email = data['email'] || this.email;
            this.first_name = data['first_name'] || this.first_name;
            this.middle_name = data['middle_name'] || this.middle_name;
            this.last_name = data['last_name'] || this.last_name;
            this.alternate_id = data['alternate_id'] || this.alternate_id;
            this.clinics = data['clinics'] || this.clinics;
            this.agencies = data['agencies'] || this.agencies;
            this.roles = data['roles'] || this.roles;
            this.accesses = data['accesses'] || this.accesses;
            this.user_type = data['user_type'] || this.user_type;
            this.mobile = data['mobile'] || this.mobile;
            this.is_active = data['is_active'] || this.is_active;
            this.is_auth_logged_in = data['is_auth_logged_in'];
            this.profile_image = data['profile_image'] || this.profile_image;
            this.system_admin = data['system_admin'] || this.system_admin;
            this.is_superuser = data['is_superuser'] || this.is_superuser;
            this.self_biller = data['self_biller'] || this.self_biller;
            this.date_joined = data['date_joined'] || this.date_joined;
            this.last_login = data['last_login'] || this.last_login;
            this.country_code = data['country_code'] || this.country_code;
            this.timeout = data['timeout'] || this.timeout;
            this.device = data['device'] || this.device;
            this.clinic_id = data['clinic_id'] || this.clinic_id;
        }
    }
}