import { FuseConfigService } from 'app/shared/services/config.service';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AuthService } from 'app/support/services/auth/auth.service';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { LiabilityService } from 'app/support/services/liability.service';
import { SnackbarCongiration } from 'app/shared/components/snack-bar/SnackbarConfiguration';
import { Permissions } from 'app/support/permissions/permissions';

import * as moment from 'moment';
import { Router } from '@angular/router';
import { PhoenixWebsocketService } from 'app/support/services/phoenix-websocket.service';

@Component({
    selector: 'app-liabiality-acceptance',
    templateUrl: './liabiality-acceptance.component.html',
    styleUrls: ['./liabiality-acceptance.component.scss']
})
export class LiabialityAcceptanceComponent implements OnInit {

    liabilities: any;
    liabilityForm: FormGroup;
    clinics: FormArray;
    showView: boolean = false;
    all_accepted: boolean = false;
    anyone_accepted: boolean = false;
    selectedIndex = 0;
    constructor(
        private cd: ChangeDetectorRef,
        private router: Router,
        private _fuseConfigService: FuseConfigService,
        private authService: AuthService,
        private permission: Permissions,
        private liabilityservice: LiabilityService,
        private fb: FormBuilder,
        private snackBar: SnackbarCongiration,
        private phoenixwsService: PhoenixWebsocketService
    ) {
        
        this.getLiabilityData();
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        }

    }

    ngOnInit() {
    }

    getLiabilityData() {
        this.liabilityservice.getLiabilityData()
            .subscribe(
                (response) => {
                    this.liabilities = response['data'];
                    if (response['all_accepted']) {
                        if(localStorage.getItem("notification_view")){
                            this.router.navigate([localStorage.getItem("notification_view")]);
                            localStorage.removeItem('notification_view')
                        }else{
                            this.permission.navigateToFirstPermissionList();
                        }
                    } else {
                        this.all_accepted = response['all_accepted'];
                    }
                    this.anyone_accepted = response['anyone_accepted'];
                    this.liabilityForm = this.fb.group({
                        liabilities: this.fb.array([])
                    });
                    this.addLiability();
                    for(var [key, item] of  this.liabilities.entries()){
                        if(!item.all_liability_accepted){
                            this.selectedIndex = key;
                            break;
                        }
                    }
                    this.showView = true;
                },
                (error) => {
                }
            )
    }

    addLiability() {
        let liabilityArray = this.liabilityForm.get('liabilities') as FormArray;
        if (this.liabilities.length) {
            this.liabilities.forEach((element, index) => {
                let liabl = this.fb.group({
                    liablity: element.liability_name,
                    all_accepted: element.all_liability_accepted,
                    clinics: this.fb.array([])
                })
                if (element.clinics.length) {
                    let clinicsArr = liabl.get('clinics') as FormArray;
                    element.clinics.forEach((element, index) => {
                        let clinicgroup = this.fb.group({});
                        clinicgroup.addControl('clinic_id', new FormControl({ value: element['id'], disabled: element['is_liability_accepted'] }));
                        clinicgroup.addControl('clinic', new FormControl({ value: element['is_liability_accepted'], disabled: element['is_liability_accepted'] }, Validators.required));
                        clinicgroup.addControl('clinic_name', new FormControl({ value: element['clinic'], disabled: true }));
                        clinicsArr.push(clinicgroup);
                    });
                }
                liabilityArray.push(liabl);
            });
        }
    }

    logout(): void {
        this.phoenixwsService.closeConnection();
    }

    selectAll(liabindex, event) {
        let liabilityArray = this.liabilityForm.get('liabilities') as FormArray;
        let clinicsArray = liabilityArray.controls[liabindex].get('clinics') as FormArray;
        if (event.checked) {
            clinicsArray.controls.forEach(element => {
                element.get('clinic').setValue(true);
            });
            liabilityArray.controls[liabindex].get('all_accepted').setValue(true);
        } else {
            clinicsArray.controls.forEach(element => {
                if (!element.get('clinic').disabled) {
                    element.get('clinic').setValue(false);
                }
            });
            liabilityArray.controls[liabindex].get('all_accepted').setValue(false);
        }
    }

    checkValueChange(index){
        let changed = []
        let liabilityArray = this.liabilityForm.get('liabilities') as FormArray;
        let form_data = liabilityArray.controls[index].value;
        if('clinics' in form_data && form_data['clinics'].length){
            form_data['clinics'].forEach((element, clinic_index) => {
                let arr = this.liabilities[index]['clinics'].filter(function(item){
                    return (item.id == element.clinic_id && item.is_liability_accepted === element.clinic)
                });
                if(arr.length) changed.push("false")
                else changed.push("true")
            });
        }
        if(changed. indexOf("true") !== -1) return true
        else return false;
    }

    submitLiability(liabindex) {
        let liabiality_data = this.liabilityForm.value['liabilities'][liabindex];
        liabiality_data['id'] = this.liabilities[liabindex]['id'];

        if (liabiality_data['clinics']) {
            liabiality_data['clinics'].forEach((baseelement, index) => {
                if (!baseelement['clinic']) {
                    liabiality_data['clinics'].splice(index, 1);
                }
            })
            this.liabilityservice.acceptLiabities(liabiality_data)
                .subscribe(
                    (response) => {
                        if (response['status'] == 'success') {
                            this.liabilities[liabindex]['anyone_accepted'] = true;
                            this.snackBar.triggerSnackBar({
                                message: response['message'],
                                type: 'success'
                            })
                            if (this.all_accepted) {
                                if(localStorage.getItem("notification_view")){
                                    this.router.navigate([localStorage.getItem("notification_view")]);
                                    localStorage.removeItem('notification_view')
                                }else{
                                    this.permission.navigateToFirstPermissionList();
                                }
                            } else {
                                this.getLiabilityData();
                            }
                            this.showView = true;
                        }
                    },
                    (error) => {
                    }
                )
        }
    }

    skipToApp() {
        if(localStorage.getItem("notification_view")){
            this.router.navigate([localStorage.getItem("notification_view")]);
            localStorage.removeItem('notification_view')
        }else{
            this.permission.navigateToFirstPermissionList();
        }
    }

    checkClinic(liabindex, event) {
        let liabilityArray = this.liabilityForm.get('liabilities') as FormArray;
        if(!event.checked){
            liabilityArray.controls[liabindex].get('all_accepted').setValue(false);
        }else{
            let all_accepted = [];
            this.liabilityForm.value['liabilities'][liabindex]['clinics'].forEach(element => {
                if (element['clinic']) {
                    all_accepted.push("true")
                } else {
                    all_accepted.push("false")
                }
            });
            if(all_accepted[0]=="true" && this.checlAllTrue(all_accepted)){
                liabilityArray.controls[liabindex].get('all_accepted').setValue(true);
            }else{
                liabilityArray.controls[liabindex].get('all_accepted').setValue(false);
            }
        }
    }
    checlAllTrue(arr){
        return new Set(arr).size == 1;
    }

}
