import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseConfigService } from 'app/shared/services/config.service';
import { AuthService } from 'app/support/services/auth/auth.service';

@Component({
    selector: 'clinic-mail-verify',
    templateUrl: './clinic-mail-verify.component.html',
    styleUrls: ['./clinic-mail-verify.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ClinicMailVerifyComponent implements OnInit {
    page: string = 'login';
    success: boolean = false;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private authService: AuthService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
        this.route.queryParams.subscribe(param => {this.success = JSON.parse(param['success'])});
        authService.authenticated() ? this.page = 'dashboard' : this.page = 'login';
    }

    ngOnInit() {
    }

}
