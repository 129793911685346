// import { Component, OnInit } from '@angular/core';
import { Component, OnInit, NgZone } from '@angular/core';
import { Columns, GridConfig, Renderers, RowEvent, HeaderEvent } from 'app/shared/grid-datatable/grid-datatable';
// import {selectedOptions} from 'app/shared/extended-search/slim-search/slim-search.component'
// import {GridDatatableComponent} from 'app/shared/grid-datatable/grid-datatable';
import { HeaderRendererComponent } from 'app/shared/grid-datatable/header-renderer/header-renderer.component';
import { Router } from '@angular/router';
import { CellRendererComponent } from 'app/shared/grid-datatable/cell-renderer/cell-renderer.component';
import { AuthService } from 'app/support/services/auth/auth.service';
import { DeviceSettingsService } from 'app/support/services/device-settings.service';
import { GlobalService } from 'app/support/services/auth/global.service';
import { FormControl } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { MatAutocomplete, MatAutocompleteTrigger, MatAutocompleteSelectedEvent } from '@angular/material';
import { element } from 'protractor';
const moment = _moment

@Component({
  selector: 'app-audits',
  templateUrl: './audits.component.html',
  styleUrls: ['./audits.component.scss']
})
export class AuditsComponent implements OnInit {

  // @ViewChild(GridDatatableComponent)

  columns: Columns;
  config: GridConfig;
  renderers: Renderers;
  accesses: any;
  input_data: any;
  input: any;
  params: any = {};
  selected: boolean = false;
  tzoffset: any;
  // current_year: any = new Date().getFullYear();
  date = new Date();
  current_date: any = new Date(this.date.valueOf());
  start = this.date.setDate(this.date.getDate() - 7);
  End_date = new FormControl(moment());
  Start_date = new FormControl(moment(this.start.valueOf()));
  startDate: any = (new Date(this.Start_date.value.startOf('day') - (new Date()).getTimezoneOffset() * 60000)).toISOString();
  endDate: any = (new Date(this.End_date.value.endOf('day') - (new Date()).getTimezoneOffset() * 60000)).toISOString();
  // this.tzoffset = (new Date()).getTimezoneOffset() * 60000;
  // this.endDate=this.End_date;
  // this.startDate=this.Start_date;
  // End_date = new FormControl(moment());

  constructor(
    private router: Router,
    private globalService: GlobalService,
    private ngZone: NgZone,
    private device_settings: DeviceSettingsService
  ) {

    // this.startDate= (new Date(this.Start_date.value.startOf('day') - (new Date()).getTimezoneOffset() * 60000)).toISOString();
    // this.endDate= (new Date(this.End_date.value.endOf('day') - (new Date()).getTimezoneOffset() * 60000)).toISOString();

    this.setConfigurations();
  }

  setConfigurations() {
    const customComparator = (valueA, valueB) => {
      return valueA.toLowerCase().localeCompare(valueB.toLowerCase(), undefined, { numeric: true, sensitivity: 'base' });
    };

    let icons = {
      sortUnSort: '<img src="assets/images/icons/unsort.png" width="14" />',
      sortAscending: '<img src="assets/images/icons/upsort.png" width="12" />',
      sortDescending: '<img src="assets/images/icons/downsort.png" width="12" />'
    };

    this.config = {
      search: true,

      searchUrl: {
        searchValuesUrl: `audits/search-audits-values/?startDate=${this.startDate}&endDate=${this.endDate}`,
        searchResultUrl: `audits/search-audit/?startDate=${this.startDate}&endDate=${this.endDate}`
      },
      searchList: [
        // { searchKey: 'Number', property: 'number', field: 'number', display: true, searchValues: [], active: false },
        { searchKey: 'Entity Name', property: 'table_name', field: 'table_name', display: true, searchValues: [], active: true },
        { searchKey: 'Field Name', property: 'field_name', field: 'field_name', display: true, searchValues: [], active: true },
        // { searchKey: 'New Value', property: 'new_value', field: 'new_value', display: true, searchValues: [], active: false },
        // { searchKey: 'Old Vlaue', property: 'old_value', field: 'old_value', display: true, searchValues: [], active: false },
        { searchKey: 'Pk Id', property: 'pk_id', field: 'pk_id', display: true, searchValues: [], active: true },
        { searchKey: 'Updated By', property: 'created_by', field: 'created_by', display: true, searchValues: [], active: true },
        // { searchKey: 'Date Time', property: 'created_at', field: 'created_at', display: true, searchValues: [], active: false },

      ],
      sidepan: true,
      sidepanMenu: ['columns'],
      remoteUrl: 'audits',
      remoteParams: {},
      sortModel: 'name'
    };

    this.renderers = {
      headerRenderer: [{ column: 'Actions', renderer: ['create'] }],
      cellRenderer: [{ column: 'Actions', renderer: ['status'] }],
      table: 'Patients'
    };
    this.globalService.user$.subscribe(data => {
      this.accesses = data.accesses;
    })

    this.columns = {
      columnDefs: [
        {
          headerName: 'Entity Name', headerTooltip: 'Entity', field: 'table_name', width: 220, sortable: true,
          tooltipField: 'mrn', suppressMovable: true, sortingOrder: ['asc', 'desc'], unSortIcon: true, icons: icons, comparator: customComparator
        },
        {
          headerName: ' Field Name', headerTooltip: 'Field', field: 'field_name', sortable: true, width: 245, unSortIcon: true,
          icons: icons, comparator: customComparator, tooltipField: 'field name', suppressMovable: true, sortingOrder: ['asc', 'desc']
          //   valueGetter: 'node.data.name ? node.data.name:"- - - -"'
        },
        {
          headerName: 'New Value', headerTooltip: 'New value', field: 'new_value', icons: icons, unSortIcon: true, sortable: true, comparator: customComparator,
          width: 200, tooltipField: 'new value', suppressMovable: true, valueGetter: 'node.data.new_value? node.data.new_value: "- - - -"'
        },
        {
          headerName: 'Old Value', headerTooltip: 'Old value', field: 'old_value', icons: icons, unSortIcon: true, sortable: true, comparator: customComparator,
          width: 200, suppressMovable: true, sortingOrder: ['asc', 'desc'], valueGetter: 'node.data.old_value? node.data.old_value:"- - - -"'
        },
        {
          headerName: 'PK ID', headerTooltip: 'pk Id', field: 'pk_id', sortable: true, width: 130, unSortIcon: true,
          icons: icons, tooltipField: 'pk Id', suppressMovable: true, sortingOrder: ['asc', 'desc'], valueGetter: 'node.data.pk_id ? node.data.pk_id:"- - - -"'
        },
        {
          headerName: 'Updated By', headerTooltip: 'updated by', field: 'created_by', sortable: true, width: 190, unSortIcon: true,
          icons: icons, comparator: customComparator, tooltipField: 'updated by', suppressMovable: true, sortingOrder: ['asc', 'desc'], valueGetter: 'node.data.created_by ? node.data.created_by:"- - - -"'
        },
        {
          headerName: 'Date time', headerTooltip: 'date time', field: 'created_at', sortable: true, width: 225,
          icons: icons, unSortIcon: true, comparator: customComparator, tooltipField: 'date time', suppressMovable: true, sortingOrder: ['asc', 'desc'],
          valueGetter: function (params) {
            if (!params.data.created_at) {
              return '- - - -'
            } else {
              return moment(params.data.created_at).format('MMM-DD-YYYY, hh:mm A')
            }
          }
        }


      ],
      columnTog: [
        { columnName: 'Date Time', field: 'created_at', visible: true },
        { columnName: 'Entity Name', field: 'table_name', visible: true },
        { columnName: 'Field Name', field: 'field_name', visible: true },
        { columnName: 'New Vlaue', field: 'new_value', visible: true },
        { columnName: 'Old Value', field: 'old_value', visible: true },
        { columnName: 'Pk Id', field: 'pk_id', visible: true },
        { columnName: 'Updated By', field: 'created_by', visible: true },
      ]
    };

  }

  ngOnInit() {
    this.dateRange();
  }

  dateRange() {
    // @ViewChild('trigger', { static: false, read: MatAutocompleteTrigger }) trigger: MatAutocompleteTrigger;
    let tzoffset = (new Date()).getTimezoneOffset() * 60000;
    this.startDate = (new Date(this.Start_date.value.startOf('day') - tzoffset)).toISOString();
    this.endDate = (new Date(this.End_date.value.endOf('day') - tzoffset)).toISOString();
    this.params['startDate'] = this.startDate;
    this.params['endDate'] = this.endDate;
    this.selected = true;
    this.config['searchUrl'] = {
      searchValuesUrl: `audits/search-audits-values/?startDate=${this.startDate}&endDate=${this.endDate}`,
      searchResultUrl: `audits/search-audit/?startDate=${this.startDate}&endDate=${this.endDate}`
    }
    this.selected = true;
    this.device_settings.getDateRangeAudits(this.startDate, this.endDate)
      .subscribe(
        response => {
          this.input_data = response['data'];
          // console.log(this.input_data)
          // for(var temp in this.input){

          //     if(this.input[temp]['field_name']=='dob'){
          //       if(this.input[temp]['old_value']){
          //         console.log(this.input[temp]['old_value']);
          //         // var date_value=new Date(temp['old_value'])
          //         this.input[temp]['old_value']=this.input[temp]['old_value'].slice(0,10);
          //         console.log(this.input[temp]['old_value']);
          //       }
          //       if(this.input[temp]['new_value']){
          //         var date_value=new Date(this.input[temp]['new_value'])
          //         this.input[temp]['new_value']=this.input[temp]['new_value'].slice(0,10);
          //       }
          //     }
          //   } 
          // console.log(this.input);
          // this.input_data=this.input;
          // console.log(this.input_data);

        },
        error => {

        }
      )
  }

  downloadCsv() {
    var FileSaver = require('file-saver');
    let content = [];
    let temp_array = []
    let headers = ['Entity Name', 'Field Name', 'New Value', 'Old Value', 'PK ID', 'Updated By', 'Date Time'];
    content.push(headers)
    let fields = ['table_name', 'field_name', 'new_value', 'old_value', 'pk_id', 'created_by', 'created_at']
    for (let value in this.input_data) {
      temp_array = [];
      for (var temp in fields) {
        if (fields[temp] == 'created_at') {
          let date = moment(this.input_data[value][fields[temp]]).format('MMM-DD-YYYY hh:mm A')
          temp_array.push(date)
        }
        else if ((fields[temp] == 'new_value' || fields[temp] == 'old_value') && this.input_data[value]['field_name'] == 'preferred_communication') {
          if (this.input_data[value][fields[temp]]) {
            temp_array.push(this.input_data[value][fields[temp]].replace(/,/g, "&"))
          }
          else {
            temp_array.push(this.input_data[value][fields[temp]])
          }
        }
        else {
          temp_array.push(this.input_data[value][fields[temp]])
        }
      }
      content.push(temp_array);
    }
    // content.push(this.input_data.join());
    var blob = new Blob([content.join('\n')], { type: 'text/csv;charset=utf-8' });
    FileSaver.saveAs(blob, 'AuditData' + '.csv');
  }
}
