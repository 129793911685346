import { FuseNavigation } from 'app/shared/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'item',
        icon: 'dashboard',
        url: '/dashboard'
    },
    {
        id: 'clinics',
        title: 'Clinics',
        type: 'item',
        icon: 'local_hospital',
        url: '/clinics'
    },
    {
        id: 'users',
        title: 'Users',
        type: 'item',
        icon: 'supervisor_account',
        url: '/users'
    },
    {
        id: 'patients',
        title: 'Patients',
        type: 'item',
        icon: 'search',
        url: '/patients'
    },
    {
        id: 'reports',
        title: 'Reports',
        type: 'item',
        icon: 'signal_cellular_alt',
        url: '/reports'
    },
    {
        id: 'assessments',
        title: 'Assessments',
        type: 'item',
        icon: 'assessments',
        url: '/assessments'
    },
    {
        id: 'activateassessments',
        title: 'Activate Assessments',
        type: 'item',
        icon: 'assessments',
        url: '/activate-assessments'
    },
    {
        id: 'agencies',
        title: 'Agencies',
        type: 'item',
        icon: 'device_hub',
        url: '/agency'
    },
    {
        id: 'insurers',
        title: 'Insurers',
        type: 'item',
        icon: 'verified_user',
        url: '/insurers'
    },
    {
        id: 'importtool',
        title: 'Import Data',
        type: 'item',
        icon: 'verified_user',
        url: '/import-data'
    },
    {
        id: 'smarttest',
        title: 'SMARTest',
        type: 'item',
        icon: 'smarttest',
        url: '/smart-test'
    },
   
    // {
    //     id       : 'results',
    //     title    : 'Results',
    //     type     : 'item',
    //     icon     : 'timeline',
    //     url      : '/results'
    // },
    {
        id: 'runassessment',
        title: 'Run Assessment',
        type: 'item',
        icon: 'assessments',
        url: '/assign-assessments'
    },
    {
        id: 'assessmentqueue',
        title: 'Assessment Queue',
        type: 'item',
        icon: 'assessments',
        url: '/assessments/assessment-queue'
    },
    // {
    //     id       : 'assessmentview',
    //     title    : 'Assessment View',
    //     type     : 'item',
    //     icon     : 'assessments',
    //     url      : 'assessments/detail-view'
    // },
    {
        id: 'devicelaunch',
        title: 'Device Launch',
        type: 'item',
        icon: 'assessments',
        url: '/take-assessment/device-take'
    },
    {
        id: 'clinicsettings',
        title: 'Clinic Settings',
        type: 'item',
        icon: 'assessments',
        url: '/device-settings'
    },
    {
        id: 'admin',
        title: 'Admin',
        type: 'collapsable',
        icon: 'search',
        children: [
            {
                id: 'liability',
                title: 'Disclaimer',
                type: 'item',
                icon: 'search',
                url: '/liability'
            },
            {
                id: 'api-users-generate',
                title: 'API Users',
                type: 'item',
                icon: 'search',
                url: '/api-users-generate'
            },
            {
                id: 'systemsettings',
                title: 'System Settings',
                type: 'item',
                icon: 'search',
                url: '/system-settings'
            },
            {
                id: 'listmanagement',
                title: 'List Management',
                type: 'item',
                icon: 'listmanagement',
                url: '/custom-groups'
            },
            {
                id: 'audits',
                title: 'Audits',
                type: 'item',
                icon: 'search',
                url: '/audits'
            },
            {
                id: 'transferlogs',
                title: 'Transfer Logs',
                type: 'item',
                icon: 'search',
                url: '/transfer-logs'
            },
            {
                id: 'emailtemplate',
                title: 'Template',
                type: 'item',
                icon: 'emailtemplate',
                url: '/email-template'
            },
            {
                id: 'registerdevice',
                title: 'Devices',
                type: 'item',
                icon: 'registerdevice',
                url: '/register-device'
            }
        ]
    },
];
