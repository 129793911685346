import { Injectable } from '@angular/core';
import { InterfaceService } from './auth/interface.service';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { $ } from 'protractor';

@Injectable({
    providedIn: 'root'
})
export class AgencyService extends InterfaceService {

    constructor(
        private http: HttpClient
    ) {
        super();
    }

    public createAgency(data: any) {
        return this.http.post(this.getApiUrl(`agency/`), data, this.getHttpOptions('json', true)).pipe(
            map(Response => Response),
            catchError(this.handleError)
        );
    }

    public getAgencyList() {
        return this.http.get(this.getApiUrl(`agency/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }
    
    getAgency(agency_id: string){
        return this.http.get(this.getApiUrl(`agency/${agency_id}/get-agency`),this.getHttpOptions('json',true)).pipe(
            map(response =>response),
            catchError(this.handleError)
        );
    }

    agencyChangeStatus(agency_id: string, status: boolean){
        return this.http.get(this.getApiUrl(`agency/${agency_id}/change-status/${ status }`),this.getHttpOptions('json',true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    updateAgency(agency_id: string, data){
        return this.http.put(this.getApiUrl(`agency/${agency_id}/`), data, this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getAgencyData(agency_id: string){
        return this.http.get(this.getApiUrl(`agency/${agency_id}/get-agency-data`),this.getHttpOptions('json',true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    getZipCode(value: any) {
        return this.http.get(this.getApiUrl(`agency/${value}/get-zip-code/`), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }

    agencyExist(value: any) {
        return this.http.get(this.getApiUrl(`agency/agency-exist`, { agency: value}), this.getHttpOptions('json', true)).pipe(
            map(response => response),
            catchError(this.handleError)
        );
    }
}
