import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FuseConfigService } from 'app/shared/services/config.service';
import { AuthService } from 'app/support/services/auth/auth.service';

@Component({
  selector: 'permission-denied',
  templateUrl: './permission-denied.component.html',
  styleUrls: ['./permission-denied.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PermissionDeniedComponent implements OnInit {
  page: string = 'login';

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   */
  constructor(
      private _fuseConfigService: FuseConfigService,
      private authService: AuthService,
      private router: Router
  )
  {
      // Configure the layout
      this._fuseConfigService.config = {
          layout: {
              navbar   : {
                  hidden: true
              },
              toolbar  : {
                  hidden: true
              },
              footer   : {
                  hidden: true
              },
              sidepanel: {
                  hidden: true
              }
          }
      };
      authService.authenticated() ? this.page = 'dashboard' : this.page = 'login';
  }

  ngOnInit() {
  }

}
