import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'app/support/services/dashboard.service';
import { Router } from '@angular/router';
import { ActivateDialogComponent } from 'app/shared/components/dialog-box/activate-dialog/activate-dialog.component';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { CanComponentDeactivate } from 'app/support/services/auth/can-deactivate.guard';

@Component({
    selector: 'app-dashbard-settings',
    templateUrl: './dashbard-settings.component.html',
    styleUrls: ['./dashbard-settings.component.scss']
})
export class DashbardSettingsComponent implements CanComponentDeactivate {
    
    showView: boolean = false;
    DashboardList: any = []
	SelectedChartList: any = []
	copyChartList: any = [];
	submitChange: boolean = false;
    constructor(
        private dashboardService: DashboardService,
		private router: Router,
		private dialog: MatDialog
    ) { }

    ngOnInit() {
        this.getChartSettings();
	}
	
    getChartSettings() {
		this.dashboardService.getChartSettings().subscribe(data => {
			if (data) {
                this.showView = true;
				this.DashboardList = data['chart_data'];
				this.SelectedChartList = data['selected_chart'];
				this.copyChartList = JSON.parse(JSON.stringify(this.SelectedChartList))
			} else {
                this.showView = true;
            }
		})
    }
    
    getCheckedCharts(event) {
		let index = this.SelectedChartList.findIndex(i => i.id == event['id'])
		if (index != -1) {
			return true;
		} else {
			return false;
		}
	}

	selectChart(event, data) {
		this.DashboardList.forEach(value => {
			if(value['id'] == data['id']){
				value['selected'] = event.checked;
			}
		});
		if (!event.checked) {
			let idx = this.SelectedChartList.findIndex(i => i.id == data['id'])
			this.SelectedChartList.splice(idx, 1)
		} else {
			this.SelectedChartList.push(data)
		}
    }
    
    submitChart() {
		this.submitChange = true;
        let val: any = []
        this.SelectedChartList.map(x => {
			val.push(x.id)
		})
		this.dashboardService.changeChartSettings(val).subscribe(res => {
			if (res) {
				this.router.navigate(['/dashboard'])
			}
		})
	}
	
	confirm(): Promise<any> {
        return new Promise((resolve) => {
            if (this.checkChange()) {
                const dialogConfig = new MatDialogConfig();
                dialogConfig.width = '500px';
                dialogConfig.disableClose = true;
                dialogConfig.data = { 'yes': null, 'no': null };
                let dialogRef = this.dialog.open(ActivateDialogComponent, dialogConfig);
                dialogRef.afterClosed().subscribe(
                    value => {
                        if (value == 'yes') {
                            if (localStorage.getItem('tokenData')) localStorage.removeItem('tokenData');
                            this.dialog.closeAll();
                            resolve(true);
                        } else {
                            if (localStorage.getItem('tokenData')) {
                                let data = JSON.parse(localStorage.getItem('tokenData'))
                                localStorage.setItem("token", data['token']);
                                localStorage.setItem("firebase:host:phoenix-d7e6a.firebaseio.com", data['firebase:host:phoenix-d7e6a.firebaseio.com']);
                                localStorage.removeItem('tokenData');
                            }
                            this.dialog.closeAll();
                            resolve(false);
                        }
                    }
                );
            } else {
                resolve(true);
            }
        })
    }
	checkChange() {
		let check_array1= []
		let check_array2= [] 
		this.SelectedChartList.map(e1 => {
			check_array1.push(e1.id)
		})
		this.copyChartList.map(e2 => {
			check_array2.push(e2.id)
		})
		check_array1.sort();
		check_array2.sort();
		if(!this.submitChange) {
			if(JSON.stringify(check_array1) == JSON.stringify(check_array2)) {
				return false;
			} else {
				return true;
			}
		} else {
			return false;
		}
		
	}

	
}
