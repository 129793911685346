import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';


import { FuseModule } from 'app/shared/fuse-module/fuse.module';
import { SharedModule } from 'app/shared/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule, FuseNavigationModule } from 'app/shared/components';

import { fuseConfig } from 'app/support/theme-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { FeaturesModule } from './features/features.module';
import { SupportModule } from './support/support.module';

//firebase notification imports
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
// import { firebaseConfig } from 'firebaseConfig';
import { AsyncPipe } from '@angular/common';
import { PhoenixWebsocketService } from './support/services/phoenix-websocket.service';
// import { firebaseConfig } from '../app/support/firebase/firebaseconfig'
import { NgIdleModule } from '@ng-idle/core';
import { SessionLogoutComponent } from './shared/components/dialog-box/session-logout/session-logout.component';
import { HttpConfigInterceptor } from './interceptor/httpconfig.interceptor';
import { ConfimerComponent } from './shared/components/dialog-box/confimer/confimer.component';
import { SmartTestModule } from './features/main/smart-test/smart-test.module';
//Mat date time picker module

const appRoutes: Routes = [
    {
        path      : '**',
        redirectTo: 'sample'
    }
];

@NgModule({
    declarations: [
        AppComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot([], {anchorScrolling: 'enabled'}),

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,
        NgIdleModule.forRoot(),

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        SharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        FuseNavigationModule,

        // App modules
        LayoutModule,
        FeaturesModule,
        SupportModule,
        SmartTestModule

        //firebase imports
        // AngularFireDatabaseModule,
        // AngularFireAuthModule,
        // AngularFireMessagingModule,
        // AngularFireModule.initializeApp(firebaseConfig)
    ],
    bootstrap   : [
        AppComponent
    ],
    providers: [
        // FirebaseService, 
        AsyncPipe, 
        PhoenixWebsocketService,
        { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }],
    entryComponents: [
        SessionLogoutComponent,
        ConfimerComponent,
    ]
})
export class AppModule
{
}
