export class Contact {
    public id?: number = -1;
    public contact_email?: Array<any> = [];
    public contact_phone?: Array<any> = [];
    public first_name: string = '';
    public middle_name?: string = '';
    public last_name: string = '';
    public zip_code: string = '';
    public address_one: string = '';
    public address_two?: string = '';
    public contact_type?: Object = null;
    public city?: string = '';
    public state?: string = '';
    public country?: Object = null;

    constructor(data?: Object) {
        if (data) {
            this.id = data['id'] || this.id;
            this.contact_email = data['contact_email'] || this.contact_email;
            this.first_name = data['first_name'] || this.first_name;
            this.middle_name = data['middle_name'] || this.middle_name;
            this.last_name = data['last_name'] || this.last_name;
            this.contact_phone = data['contact_phone'] || this.contact_phone;
            this.zip_code = data['zip_code'] || this.zip_code;
            this.address_one = data['address_one'] || this.address_one;
            this.address_two = data['address_two'] || this.address_two;
            this.contact_type = data['contact_type'] || this.contact_type;
            this.city = data['city'] || this.city;
            this.state = data['state'] || this.state;
            this.country = data['country'] || this.country;
        }
    }
}